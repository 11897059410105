import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import MetaMaskHook from "../../hooks/MetaMaskHook";
import cancelVector from "../../images/others/Vector.png";
import { networkID } from "../../utils/config";
import { ETH_Auction_Contract, MATIC_Auction_Contract, Web3 } from "../../utils/web3";
import {ethEnabled} from "../../utils/web3.function";
import {ErrorMessage} from "../../utils/config";

const WithdrawModal = ({show, openWithdrawModal}) => {
	const {loggedUser} = useSelector(s => s.user);
	const [coins, setCoins] = useState([{'id':networkID.ETH, 'name':'WETH'}, {'id':networkID.MATIC, 'name':'WMATIC'}]);
	const [currrentCoin, setCurrentCoin] = useState(networkID.ETH);
	const [userBalance, setUserBalance] = useState(0);
    const { isMetaMaskInstall, accountId, isDataLoading, isMetaMaskConnected, networkVersion } = MetaMaskHook();
	const [alert, _alert] = useState({
		show: false,
		title: "",
		type: "success" || "error",
	  });

	useEffect(async() => {
	
		if (currrentCoin !== networkVersion) {
			setUserBalance(0);
		}
		if (currrentCoin == networkID.MATIC) {
			const userBalance = await MATIC_Auction_Contract.methods.balanceOf(loggedUser.wallet_type[0].wallet_key).call();
			setUserBalance(userBalance);
		} else if (currrentCoin == networkID.ETH) {
			const userBalance = await ETH_Auction_Contract.methods.balanceOf(loggedUser.wallet_type[0].wallet_key).call();
			setUserBalance(userBalance);
			console.log(userBalance)
		} 
	    
	},[currrentCoin])

	const withdraw = async () => {
		console.log('withdraw')
		if (userBalance > 0) {
			if (isMetaMaskInstall) {
				if (isMetaMaskConnected) {
			        if (currrentCoin == networkID.ETH) {
				
		     	        await ETH_Auction_Contract.methods.withdraw(userBalance.toString()).send({
			    	        from: accountId
			            }).then(async res => {
				            const userBalance = await ETH_Auction_Contract.methods.balanceOf(loggedUser.wallet_type[0].wallet_key).call();
				            setUserBalance(userBalance);
			            }).catch(err => {
				            console.log(err,'err')
			            })
		            } else if (currrentCoin == networkID.MATIC) {
				        const transactionBalance = Web3.utils.toWei(
					        userBalance.toString(),
					        "ether"
				        );
				        const wrappedMaticBalanceFromWei = Number(
					        Web3.utils.fromWei(transactionBalance.toString())
				        );

				        await MATIC_Auction_Contract.methods.withdraw(wrappedMaticBalanceFromWei.toString()).send({
				            from: accountId
			            }).then(async res => {
				            const userBalance = await MATIC_Auction_Contract.methods.balanceOf(loggedUser.wallet_type[0].wallet_key).call();
				            setUserBalance(userBalance);
				   
			            }).catch(err => {
				            console.log(err,'err')
			            })
			        }
	    	    } else {
                    _alert({ show: true, type: "warning", title: ErrorMessage.connectMetamask });
                    await ethEnabled();
                }
	        } else {
                _alert({ show: true, type: "error", title: ErrorMessage.metamaskInstallation });
            }
		}
	}

	return (
		<>
		    <Modal
                show = {show}
				centered
				size="md"
			>
				<Modal.Header>
					<div className="w-100 d-flex justify-content-between align-items-center"
					    onClick={() => openWithdrawModal(false)}>
							<div style={{fontWeight: 800, color: '#162869', fontSize: '32px'}}>Withdraw</div>
							<Image className="mouse-pointer" src={cancelVector} height={18} width={18}/>	
					</div>
				</Modal.Header>
				<Modal.Body>
				    <div className="forms">
						{currrentCoin == networkVersion ? '' :<div style={{color:'red'}}> {'Choose correct provider in Metamask'}</div> }
                        <select className="form-style mouse-pointer" value={currrentCoin} 
                                onChange={(e) => setCurrentCoin(e.target.value)}>
                            {coins?.map((item, index) =>
                                <option key={item.id} value={item.id}>{item.name}</option>
                            )}
                        </select>
						<div>
						    <input
                                type="number"
                                placeholder="Amount"
                                className="purchaseModalInput font_color_black mb-3"
                                value={userBalance}
								readOnly={true}
                            />
						</div>
						<div>
						{userBalance > 0 && <button className="btn-blue btn-white btn-lg mb-3" type="button" onClick={() => withdraw()}>Withdraw</button>}
						</div>
                    </div>
				</Modal.Body>
			</Modal>
			<SweetAlert type={alert.type} title={alert.title} onConfirm={() => _alert(s => ({...s, show: false}))} show={alert.show}/>
		</>
	)
}

export default WithdrawModal;