import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import Loader from "../../components/loader";
import SweetAlert from "react-bootstrap-sweetalert";
import {logout} from "../../redux/reducers/user";
import {useGoogleLogout} from "react-google-login";
import {GoogleClientId} from "../../utils/config";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import '../style.css';

const LogoutModal = ({logoutModal,showLogoutModal}) => {


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {signOut} = useGoogleLogout({clientId: GoogleClientId});

    const [show, _show] = useState(false);
    const [loading, _loading] = useState(false);
    const [sweetAlert, _alert] = useState({show: false, title: '', type: 'success'});

    const _logout = async () => {
        signOut()
        dispatch(logout())
        _loading(true)
        await localStorage.removeItem('user');
        setTimeout(() => {
            navigate('/')
            showLogoutModal();
            _loading(false);
        }, 1000);
    }

    return (
        <>
        
            <Modal
                show={logoutModal}
                centered
            >
                <Modal.Body>
                    <div className="container p-5">
                        <div className="row d-flex justify-content-center align-items-center text-center">
                            <div>
                                <p className="font_color fw-bold" style={{fontSize: '32px'}}>Are You Sure?</p>
                                <span className="font10" style={{lineHeight: 2, color: '#000000'}}>
                                    Are you sure want to logout from your account? all the wallet details will be saved.
                                </span>
                            </div>

                            <div className="mt-5 row d-flex justify-content-center align-items-center">
                                <button className="btn-blue  fw-bolder col-5" onClick={() => showLogoutModal()}>
                                    Cancel
                                </button>
                                <button className="btn-blue btn-white fw-bolder ms-3 col-5" onClick={() => _logout()}>
                                    Logout
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <SweetAlert type={sweetAlert.type} title={sweetAlert.title} onConfirm={() => _alert(s => ({...s, show: false}))} show={sweetAlert.show}/>
            <Loader show={loading}/>
        </>
    )
}

export default LogoutModal;



