import API from "../redux/API";
import {setLoggedUser} from "../redux/reducers/user";
import {useDispatch} from "react-redux";

export const UserNameSplit = (name = '', no = 15) => {
    return name.length > no ? `${name.slice(0, no)}...` : name;
}

export const formatLike = (n = 0) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export const UserNickName = (userEmail = '') => {
    if (userEmail !== '') {
        const sliceNo = userEmail.indexOf("@");
        const nickName = userEmail.slice(0, sliceNo);
        return nickName;
    }
}


export const updateCurrentUser = async (userState) => {
    try {
        return API({url: `user/me`, method: 'get'}).then(async res => {
            const userData = {...userState, ...res.data.data};
            localStorage.setItem('user', JSON.stringify(userData));
            return userData;
        });
    } catch (err) {
        console.log('err', err);
    }
}

