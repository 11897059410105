import React from "react";
import Modal from 'react-bootstrap/Modal';
import verifiedImg from "../../images/others/verified1.png";
import {Link} from "react-router-dom";
import waitingImg from "../../images/others/waiting.png";
import {useSelector} from "react-redux";
import {kycStatus} from "../../utils/config";
import '../style.css';

const KycVerifiedModal = ({verifiedModal}) => {

    const {loggedUser} = useSelector(s => s.user);

    return (
        <>
            <Modal
                show={verifiedModal}
                centered
            >
                <Modal.Body>
                    {
                        loggedUser?.kyc_status === kycStatus.waiting &&
                        <div className="d-flex justify-content-center align-items-center flex-column"
                             style={{height: '70vh'}}>
                            <div>
                                <div className=" d-flex justify-content-center align-items-center flex-column"
                                     style={{height: '100%'}}>
                                    <img src={waitingImg} style={{height: '150px', width: '150px'}}/>
                                    <div className="mt-2">
                                        <span className="statusLable">Waiting...</span>
                                    </div>
                                    <div className="w-50 mt-3 kycDescription">
                                            <span>
                                                KYC certification is under review.
                                                The personal verification process takes an average of 48 hours.
                                                If the verification result is not confirmed, please contact the customer center.
                                            </span>
                                    </div>
                                    <div
                                        className="mt-5 w-100 d-flex justify-content-center align-items-center">
                                        <div className="me-2">
                                            <Link to="/kyc/status"><button className="btn-blue">Go To My KYC</button></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        loggedUser?.kyc_status === kycStatus.verified &&
                        <div className="d-flex justify-content-center align-items-center flex-column"
                             style={{height: '70vh'}}>
                            <div>
                                <div className=" d-flex justify-content-center align-items-center flex-column"
                                     style={{height: '100%'}}>
                                    <img src={verifiedImg} style={{height: '150px', width: '150px'}}/>
                                    <div className="mt-2">
                                        <span className="statusLable">You are Verified!</span>
                                    </div>
                                    <div className="w-50 mt-3 kycDescription">
                                        <span>I am a KYC certified customer.</span>
                                    </div>
                                    <div
                                        className="mt-5 w-100 d-flex justify-content-center align-items-center">
                                        <div className="me-2">
                                            <Link to="/kyc/status"><button className="btn-blue">Go To My KYC</button></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </Modal.Body>
            </Modal>
        </>
    )
}

export default KycVerifiedModal;



