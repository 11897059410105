import React, { useEffect, useState } from "react";
import { Dropdown, Container, Navbar, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { useSelector } from "react-redux";
import LogoIcon from "../images/logo/logo.png";
import profileIco from "../images/others/login ico.png";
import MetaIcon from "../images/others/meta-icon.svg";
import UserIcon from "../images/others/user-icon.svg";
import BellIcon from "../images/others/bell-icon.svg";
import nav_logo from "../images/logo/logoFull.png";
import LogoutModal from "../Modals/logoutModal";
import MetamaskModal from "../Modals/metamaskModal";
import KycModal from "../Modals/NftDetails/kycModal";
import Notification from "./Notification";
import _ from "lodash";
import PopoverContainerWithRef from "@idui/react-popover";
import API from "../redux/API";
import Loader from "./loader";
import "./Layout/Layout.css";
import "./header.css";
import axios from "axios";

const Header = () => {
  const { loggedUser } = useSelector((s) => s.user);
  const navigate = useNavigate();

  const [menuHide, _menuHide] = useState(false);
  const [modalShow, _modalShow] = useState(false);
  const [logoutModal, _logoutModal] = useState(false);
  const [kycModal, _kycModal] = useState(false);
  const [notificationData, _notificationData] = useState([]);
  const [loading, _loading] = useState(false);
  const [artCategory, setArtCategory] = useState([]);

  const modalOpen = () => {
    _modalShow(!modalShow);
  };

  const showLogoutModal = () => {
    _logoutModal(!logoutModal);
  };

  const showKycModal = () => {
    _kycModal(!kycModal);
  };

  const animation = {
    animate: {
      opacity: 1,
      scale: 1,
    },
    exit: {
      opacity: 0,
      scale: 0.9,
      transition: {
        duration: 0.1,
      },
    },
    initial: {
      opacity: 0,
      scale: 0.9,
    },
  };

  useEffect(() => {
    if (!_.isEmpty(loggedUser)) {
      getNotification();
    }
    getAllCategories(1);
  }, [loggedUser]);

  const getAllCategories = (page) => {
    // _loading(true);
    API({ url: "user/art-work/category?page=1&limit=10", method: "GET" })
      .then(async (res) => {
        setArtCategory(res.data);
      })
      .catch((error) => console.log("error", error))
      .finally(() => _loading(false));
  };

  const getNotification = () => {
    // _loading(true);
    API({ url: "user/notifications?unviewedOnly=true", method: "GET" })
      .then(async (res) => {
        _notificationData(res.data);
      })
      .catch((error) => console.log("error", error))
      .finally(() => _loading(false));
  };

  const setMarked = (notificationId) => {
    _loading(true);
    API({
      url: `user/notification?notification_id=${notificationId}&viewed=true`,
      method: "PUT",
    })
      .then(async (res) => {
        getNotification();
      })
      .catch((error) => console.log("error", error))
      .finally(() => _loading(false));
  };

  return (
    <>
      <div id="top" />
      <div>
        <header className="navbar navbar-expand-lg navbar-light filled-header">
          <Navbar expand="lg">
            <Container>
              <Navbar.Brand style={{ color: "white" }} className="d-flex">
                <Link to="/" style={{ textdecoration: "none", color: "white" }}>
                  <div className="logo">
                    <img src={nav_logo} alt="" className="nav-logo mt-2 me-1" />
                  </div>
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="navlinks"
                  style={{ maxHeight: "100px" }}
                  navbarScroll
                >
                  <Link className="font-face-gm" to="/hero-section">칸태움.io란?</Link> {/* Hero Section */}
                  {artCategory.map((item) => {
                    if (item.usage === "사용함") {
                      return (
                        <Link
                          key={item._id}
                          className="font-face-gm"
                          to={`/marketplace/category/${item.id}`}
                        >
                          {item.category}
                        </Link>
                      );
                    }
                    return null;
                  })}
                  {localStorage.getItem("user") && (
                    <Link to="/profile">My NFW</Link>
                  )}
                </Nav>
                <div className="align-items-center loginBtn-margin">
                  {!loggedUser ? (
                    <Link
                      to="/login"
                      className="nav-link login-in-btn d-flex align-items-center"
                    >
                      <div className="loginBtn-br pe-2">Log in</div>
                      <img
                        src={profileIco}
                        alt=""
                        className="profileImg-ico ms-2"
                      />
                    </Link>
                  ) : (
                    <>
                      <div
                        className="header-icons mouse-pointer"
                        style={{ position: "relative" }}
                      >
                        <div onClick={() => modalOpen()}>
                          <img src={MetaIcon} alt="icon" />
                        </div>
                        <PopoverContainerWithRef
                          animation={animation}
                          width="250"
                          lazy
                          offset={[10, 150]}
                          onChangeOpen={function noRefCheck() {}}
                          onFocus={function noRefCheck() {}}
                          withArrow={false}
                          content={
                            <div>
                              <Notification
                                loading={loading}
                                notificationData={notificationData}
                                setMarked={setMarked}
                              />
                            </div>
                          }
                        >
                          <div>
                            <img src={BellIcon} alt="icon" />
                          </div>
                        </PopoverContainerWithRef>
                        <PopoverContainerWithRef
                          animation={animation}
                          width="350"
                          lazy
                          offset={[10, 350]}
                          onChangeOpen={function noRefCheck() {}}
                          onFocus={function noRefCheck() {}}
                          withArrow={false}
                          content={
                            <div className="userMenu">
                              <ul>
                                <li onClick={() => navigate("/profile")}>
                                  My NFW
                                </li>
                                <li onClick={() => modalOpen()}>
                                  Connect Wallet
                                </li>
                                <li onClick={() => navigate("/kyc/status")}>
                                  KYC Verification
                                </li>
                                <li
                                  style={{ color: "#408CFF" }}
                                  onClick={() => navigate("/upload-nft")}
                                >
                                  Issue NFW
                                </li>
                                <hr />
                                <li onClick={() => showLogoutModal()}>
                                  Logout
                                </li>
                              </ul>
                            </div>
                          }
                        >
                          <div>
                            <img
                              src={loggedUser?.profile_image || UserIcon}
                              alt="icon"
                              className="user-icon mouse-pointer"
                            />
                          </div>
                        </PopoverContainerWithRef>
                      </div>
                    </>
                  )}
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
      </div>
      <MetamaskModal modalShow={modalShow} modalOpen={modalOpen} />
      <LogoutModal
        logoutModal={logoutModal}
        showLogoutModal={showLogoutModal}
      />
      <KycModal kycModal={kycModal} showKycModal={showKycModal} />
      <Loader show={loading} />
    </>
  );
};

export default Header;
