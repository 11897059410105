import {Web3 } from "../utils/web3";
import web3s from 'web3';
import {useEffect, useState,} from "react";
import { useSelector} from "react-redux";
// import { provider } from "../utils/walletconnect";

function MetaMaskHook() {
    const [isMetaMaskInstall, setIsMetaMaskInstall] = useState();
    const [isMetaMaskConnected, setIsMetaMaskConnected] = useState(true);
    const [accountId, setAccountId] = useState();
    const [networkVersion, setNetworkVersion] = useState();
    const [isDataLoading, setIsDataLoading] = useState(true);
    const { loggedUser } = useSelector((s) => s.user);

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const setAccountDetails = async () => {
        await Web3.eth.getAccounts(async (error, accounts) => {
            if (error) {
                alert(error);
            } else if (accounts.length === 0) {
                setIsMetaMaskConnected(false);
            } else {
                setIsMetaMaskConnected(true);
                setAccountId(accounts[0]);
            }
        });
    }

    // subscription for account change detection
    const accountChangeDetection = async () => {
        window.ethereum.on("accountsChanged", async function () {
            setIsDataLoading(true);
            await setAccountDetails().finally(() => setIsDataLoading(false));
        });
    }

    // subscription for networkVersion change detection
    const networkVersionChangeDetection = async () => {
        window.ethereum.on('networkChanged', function (networkId) {
            setNetworkVersion(networkId);
        });
    }

    useEffect(() => {
        async function loadWeb3() {
                
            if (window.ethereum) {
                console.log('aa')
                await sleep(500);
                setIsMetaMaskInstall(true);
                // first time set set current network version Id.
                setNetworkVersion(window.ethereum.networkVersion);

                // first time call for load initial details
                await setAccountDetails();

                // subscription for account change detection
                await accountChangeDetection();

                // subscription for network Version change detection
                //await networkVersionChangeDetection();

            } else {
                   setIsMetaMaskInstall(false);
            } 
        }

        loadWeb3().finally(() => setIsDataLoading(false));

        return () => {
            if (window.web3) {
                window?.ethereum.removeListener('accountsChanged', accountChangeDetection);
                window?.ethereum.removeListener('networkChanged', networkVersionChangeDetection);
            }
        }

    }, []);

    useEffect(async() => {
        await networkVersionChangeDetection();
    },[networkVersion])

    return {isMetaMaskInstall, accountId, networkVersion, isDataLoading, isMetaMaskConnected};
}

export default MetaMaskHook;
