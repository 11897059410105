import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Image, Spinner } from "react-bootstrap";
import cancelVector from "../../images/others/Vector.png";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../../components/loader";
import { AdminAccountId, contractType, endTime, ErrorMessage, networkID } from "../../utils/config";
import creatorImg from "../../images/others/creator.jpg";
import { Formik } from 'formik';
import * as yup from 'yup';
import { ethEnabled } from "../../utils/web3.function";
import MetaMaskHook from "../../hooks/MetaMaskHook";
import { useSelector } from "react-redux";
import { Eth_1155_CONTRACT, Eth_721_Contract, MATIC_721_Contract, MATIC_1155_Contract, Web3, ETH_721_OpenSea_Contract, MATIC_721_OpenSea_Contract } from "../../utils/web3";
import API from "../../redux/API";
import NftFormat from "../../components/NftFormat";
import OpenMapModal from "../GoogleModal/OpenMapModal";
import { useNavigate } from "react-router-dom";
import { UserNickName } from "../../utils/function";
import '../style.css';

const NftSellingModal = (props) => {

    const {
        show,
        resaleOpenModal,
        currentArtWorkNft,
        getArtWorkPendingNFT,
        getMyNftData,
        pendingNFT
    } = props;

    const styles = {
        ethImage: {
            marginTop: '-100px',
            marginLeft: '90%'
        },
        lineHeight: {
            lineHeight: 2
        },
        ethText: {
            marginTop: '-48px',
            marginLeft: '85%',
            color: '#A9DEEE'
        },
        error: {
            color: 'red'
        }

    }

    const NormalNftType = [{ lable: 'Fixed Price', value: 'fixed_price' }, { lable: 'Auction', value: 'auction' }];
    const RealEstateNftType =  [{ lable: 'Fixed Price', value: 'fixed_price' }];
    const nftType = currentArtWorkNft?.real_estate_type === true ? RealEstateNftType : NormalNftType;

    let defaultValues = {
        fixed_price: {
            fixed_price: '0.001',
            form_of_sale: nftType[0]?.value
        },
        auction: {
            auction_end_time: endTime[0]?.value,
            auction_start_price: '0.001',
            form_of_sale: ''
        }
    }

    let validationSchema = {
        fixed_price: {
            fixed_price: yup.number().typeError('you must specify a number').required('Fixed Price is Required').moreThan(0,"Fixed price must be greater than 0"),
            form_of_sale: yup.string().required('Form type is Required')
        },
        auction: {
            auction_end_time: yup.string().required('auction end time is Required'),
            auction_start_price: yup.number().typeError('you must specify a number').required('auction start price is Required'),
            form_of_sale: yup.string().required('Form type is Required')
        }
    }

    const navigator = useNavigate();
    const { loggedUser, ETHAddress } = useSelector(s => s.user);
    const [initialFormValues, _initialFormValues] = useState(defaultValues.fixed_price);
    const [schemaValidation, _schemaValidation] = useState(yup.object().shape(validationSchema.fixed_price));
    const [sweetAlert, _alert] = useState({ show: false, title: '', type: 'success' });
    const [loading, _loading] = useState(false);
    const { isMetaMaskInstall, accountId, isDataLoading, isMetaMaskConnected, networkVersion } = MetaMaskHook();
    const [modalShow, _modalShow] = useState(false);
    const [coords, _coords] = useState({ lat: '', long: '' })
    const [modalset, _setModal] = useState(false);

    useEffect(() => {

        if (currentArtWorkNft?.real_estate_type) {
            _initialFormValues(defaultValues?.fixed_price);
        }

        return (() => {
            _initialFormValues(null);
        });
    }, []);

    useEffect(() => {
        _setModal(show);
    },[show])

    const setApproval = async () => {
        _loading(true);
        if (currentArtWorkNft.sale_coin == 1) {
            const contract = currentArtWorkNft.contract_type === contractType.erc721 ? MATIC_721_OpenSea_Contract : MATIC_1155_Contract;
            return await contract.methods.setApprovalForAll(AdminAccountId, true).send({ from: accountId }).then(res => {
                return true;
            }).catch(error => {
                _alert({ show: true, type: "warning", title: error?.message });
                _loading(false);
    
            });;
        } else {
            const contract = currentArtWorkNft.contract_type === contractType.erc721 ? ETH_721_OpenSea_Contract : Eth_1155_CONTRACT;
            return await contract.methods.setApprovalForAll(AdminAccountId, true).send({ from: accountId }).then(res => {
                return true
            }).catch(error => {
                _alert({ show: true, type: "warning", title: error?.message });
                _loading(false);
            });
        }
    }

    const art_work_mint_721 = async (artWorkCreationFormData, contract) => {
        _loading(true)
        await contract.methods.mint(currentArtWorkNft?.files?.length).send({ from: accountId, gasPrice: Web3.utils.toWei('50', 'gwei')  }).then(async obj => {
            const approval = await setApproval();
            if (approval) {
                artWorkCreationFormData.mintResponse = obj;
                API({
                    url: 'user/art-work/complete-mint',
                    data: artWorkCreationFormData,
                    method: 'PUT',
                }).then(res => {
                    defaultValues = {
                        fixed_price: {
                            fixed_price: '',
                            form_of_sale: nftType[0]?.value
                        },
                    }
                    _initialFormValues(defaultValues.fixed_price);
                    _alert({ show: true, type: "success", title: res?.data?.message });
                    if(pendingNFT){
                        getArtWorkPendingNFT();
                    }else{
                        getMyNftData();
                    }
                    resaleOpenModal();
                }).catch(error => {
                    _alert({ show: true, type: "error", title: error?.message });

                }).finally(() => {
                    _loading(false)
                })
            } else {
                _alert({ show: true, type: "warning", title: approval?.message });
                _loading(false)
            }

        }).catch(error => {
            _alert({ show: true, type: "warning", title: error?.message });
            _loading(false);

        });
    }

    const art_work_mint_1155 = async (artWorkCreationFormData, contract) => {
        _loading(true)
        await contract.methods?.mint(currentArtWorkNft.quantity).send({ from: accountId, gasPrice: Web3.utils.toWei('50', 'gwei') }).then(async obj => {

            const approval = await setApproval();

            if (approval) {
                artWorkCreationFormData.mintResponse = obj;

                API({
                    url: 'user/art-work/complete-mint',
                    data: artWorkCreationFormData,
                    method: 'PUT',
                }).then(res => {
                    defaultValues = {
                        fixed_price: {
                            fixed_price: '',
                            form_of_sale: nftType[0]?.value
                        },
                    }
                    _initialFormValues(defaultValues.fixed_price);
                    _alert({ show: true, type: "success", title: res?.data?.message });
                    getArtWorkPendingNFT();
                    resaleOpenModal();
                }).catch(error => {
                    _alert({ show: true, type: "error", title: error?.message });
                }).finally(() => _loading(false));

            } else {
                _alert({ show: true, type: "warning", title: approval?.message });
                _loading(false)
            }
        }).catch(error => {
            _alert({ show: true, type: "warning", title: error?.message });
            _loading(false)
        }).finally(() => _loading(false));
    }

    const resell_Artwork = async (formData) => {
        _loading(true);
        const approval = await setApproval();

        if (approval) {
        
            API({
                url: `user/art-work/purchase/sell?art_work_id=${formData?.art_work_id}`,
                data: formData,
                method: 'PUT'
            }).then(async res => {
                window.location.reload();
            }).catch(err => {
                _alert({ show: true, title: err.message, type: 'error' })
            }).finally(() => _loading(false));
        } else {
            _alert({ show: true, type: "warning", title: 'Resale Failed' });
            _loading(false)
        }
       
    }

    const metamaskValidation = async () => {
        if (!isDataLoading) {
            if (isMetaMaskInstall) {
                if (isMetaMaskConnected) {
                    if (accountId && accountId === ETHAddress) {
                        return true;
                    } else {
                        if (ETHAddress) {
                            _alert({
                                show: true, type: "warning", title: ErrorMessage.selectLinkWallet
                            });
                        } else {
                            _alert({
                                show: true, type: "warning", title: ErrorMessage.updateWallet
                            });
                        }
                    }
                } else {
                    _alert({ show: true, type: "warning", title: ErrorMessage.connectMetamask });
                    await ethEnabled();
                }
            } else {
                _alert({ show: true, type: "error", title: ErrorMessage.metamaskInstallation });
            }
        }
        return false;
    }

    const handleSubmit = async (e) => {
        let formData = {
            art_work_id: currentArtWorkNft.art_work_id,
            form_of_sale: e?.form_of_sale,
        }
        if (e.form_of_sale === 'auction') {
            formData = {
                ...formData,
                auction_start_price: e.auction_start_price,
                auction_end_hours: e.auction_end_time
            }

        } else {
            formData = {
                ...formData,
                sale_price: e.fixed_price
            }
        }
        const isMetamaskValidate = await metamaskValidation();

            //set the art-work for real Estate Type
            if (currentArtWorkNft?.form_of_sale !== "waiting_for_sale") {
                if (currentArtWorkNft?.verification_status === 'verified') {
                    if (currentArtWorkNft?.contract_type === contractType.erc1155) {

                        if (currentArtWorkNft.sale_coin == 1) {
                            if (networkVersion == networkID.MATIC) {
                                await art_work_mint_1155(formData, MATIC_1155_Contract);
                            } else {
                              _alert({
                                show: true,
                                type: "warning",
                                title: ErrorMessage.MATIC_Network_Version,
                              });
                            }
                        }else{
                            if (networkVersion == networkID.ETH) {
                                await art_work_mint_1155(formData, Eth_1155_CONTRACT);
                            } else {
                              _alert({
                                show: true,
                                type: "warning",
                                title: ErrorMessage.ETH_Network_Version,
                              });
                            }
                        }

                    } else {

                        if (currentArtWorkNft.sale_coin == 1) {
                            if (networkVersion == networkID.MATIC) {
                                await art_work_mint_721(formData, MATIC_721_Contract);
                            } else {
                              _alert({
                                show: true,
                                type: "warning",
                                title: ErrorMessage.MATIC_Network_Version,
                              });
                            }
                        }else{
                            if (networkVersion == networkID.ETH) {
                                await art_work_mint_721(formData, Eth_721_Contract);
                            } else {
                              _alert({
                                show: true,
                                type: "warning",
                                title: ErrorMessage.ETH_Network_Version,
                              });
                            }
                        }
                    }
                } else {
                    _alert({ show: true, type: "warning", title: "Your Art Work is not Verified" });
                }
            }
            //set the Art For Normal ==> Resell
            else {
                await resell_Artwork(formData);
            }

    }

    const handleFormType = (value, formValues) => {
        const commonValues = { ...formValues, form_of_sale: value }

        if (value === 'fixed_price') {
            _initialFormValues({ ...defaultValues.fixed_price, ...commonValues });
            _schemaValidation(yup.object().shape(validationSchema.fixed_price))
        } else if (value === 'auction') {
            _initialFormValues({ ...defaultValues.auction, ...commonValues });
            _schemaValidation(yup.object().shape(validationSchema.auction));
        }
    }

    const modalOpen = () => {
        _modalShow(!modalShow);
        _coords({ lat: currentArtWorkNft?.map_address?.latitude, long: currentArtWorkNft?.map_address?.longitude })
    }


    return <>

        <Modal
            show={modalset}
            centered
            size="lg"
        >
            <Modal.Body>
                <div className="float-end mouse-pointer me-3" onClick={() => {_setModal(!modalset)}}>
                    <Image src={cancelVector} height={18} width={18} />
                </div>
                <div className="container mt-3 p-3 ">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="mt-3 w-100">
                            <div className="p-2 cardLable">
                                <span
                                    className="fw-bold regal-blue-color  inquiry-box-shadow">{currentArtWorkNft?.real_estate_type ? 'Real Estate' : 'Normal'}</span>
                            </div>

                            <div style={{ height: 'auto' }}>
                                <NftFormat type={currentArtWorkNft?.title_image_format}
                                    file={currentArtWorkNft?.title_image} />
                            </div>
                        </div>
                        <div className="mt-3">
                            <h2 className="regal-blue-color">{currentArtWorkNft?.title}</h2>
                        </div>
                        <div className="mt-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="mouse-pointer"
                                    onClick={() => navigator(`/profile/${currentArtWorkNft?.creator?.user_id}`)}>
                                    <Image className="rounded-circle"
                                        src={`${currentArtWorkNft?.creator?.creator_profile_image ?
                                            currentArtWorkNft?.creator?.creator_profile_image :
                                            creatorImg}`}
                                        height={45} width={45} />
                                    <span
                                        className="fw-bold ms-2">{currentArtWorkNft?.creator?.creator_nickname ? currentArtWorkNft?.creator?.creator_nickname : UserNickName(currentArtWorkNft?.creator?.creator_email)}</span>
                                </div>
                                <div className="mouse-pointer"
                                    onClick={() => navigator(`/profile/${currentArtWorkNft?.current_owner?.current_owner_id}`)}>
                                    <Image className="rounded-circle"
                                        src={`${currentArtWorkNft?.current_owner?.current_owner_profile_image ?
                                            currentArtWorkNft?.current_owner?.current_owner_profile_image :
                                            creatorImg}`}
                                        height={45} width={45} />
                                    <span className="fw-bold ms-2">
                                        <span
                                            className="fw-bold ms-2 ">{currentArtWorkNft?.current_owner?.current_owner_nickname}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <h6 className="fw-bolder regal-blue-color">Attached Files</h6>
                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                {currentArtWorkNft?.nft_token?.map((item, index) =>
                                    <div style={{ height: 'auto', width: '100px' }} key={index}>
                                        <NftFormat type={item.format} file={item?.file} />
                                    </div>
                                )}
                            </div>
                        </div>
                        {currentArtWorkNft?.real_estate_type && <>
                            <div className="mt-5">
                                <h6 className="fw-bolder regal-blue-color">Address</h6>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6>{currentArtWorkNft?.map_address?.address}</h6>
                                    <button className="btn-blue btn-sm" onClick={() => modalOpen()}>show Map</button>
                                </div>
                            </div>
                            <section className="d-flex justify-content-center align-items-center mt-5">
                                <div className="mt-3 w-75 border-bottom">
                                    <h6 className="fw-bolder regal-blue-color text-center border-bottom mb-2">other
                                        Details</h6>
                                    <div className="d-flex justify-content-between ">
                                        <div className="w-100 p-3 border-end ">
                                            <div className="d-flex justify-content-between align-items-center ">
                                                <div>listing address</div>
                                                <div>{currentArtWorkNft?.address ? currentArtWorkNft?.address : '-'}</div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>building type</div>
                                                <div>{currentArtWorkNft?.buildingType ? currentArtWorkNft?.buildingType : '-'}</div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>dedicated area</div>
                                                <div>{currentArtWorkNft?.area ? currentArtWorkNft?.area : '-'}</div>
                                            </div>
                                        </div>
                                        <div className="w-100 p-3 border-end ">
                                            <div className="d-flex justify-content-between align-items-center ">
                                                <div>construction company</div>
                                                <div>{currentArtWorkNft?.contractor ? currentArtWorkNft?.contractor : '-'}</div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>lowest-highest floor</div>
                                                <div>{currentArtWorkNft?.floorData ? currentArtWorkNft?.floorData : '-'}</div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>Expected move-in date</div>
                                                <div
                                                    style={{ fontSize: '12px' }}>{currentArtWorkNft?.moveInData ? currentArtWorkNft?.moveInData : '-'}</div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </section>
                        </>}

                        <section>
                            <div className="mt-3">
                                <h6 className="fw-bolder regal-blue-color mt-3">Existing Quantity</h6>
                                <div className="d-flex justify-content-between align-items-center">
                                    <input
                                        type="text"
                                        className="purchaseModalInputDisable font_color_black"
                                        value={currentArtWorkNft?.quantity || ''}
                                        disabled
                                        readOnly
                                    />
                                </div>
                            </div>
                        </section>
                        <section className="mt-3">
                            <div className="mt-3">
                                <h6 className="fw-bolder regal-blue-color">Royalty</h6>
                                <div className="row border-bottom">
                                    <h6 className="col-5">Percentage</h6>
                                    <h6 className="col-6">Wallet Address</h6>
                                </div>
                                {currentArtWorkNft?.royalty?.map((item, index) =>
                                    <div className="d-flex justify-content-between align-items-center mt-3" key={index}>
                                        <h6>{item?.percentage}</h6>
                                        <h6>{item?.wallet_address}</h6>
                                    </div>)}
                            </div>
                        </section>
                        <Formik
                            initialValues={initialFormValues}
                            onSubmit={handleSubmit}
                            validationSchema={schemaValidation}
                            enableReinitialize={true}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                errors,
                                values,
                            }) => (
                                <>
                                    <div className="mt-5 d-flex flex-row mouse-pointer justify-content-start w-100">

                                        {nftType?.map((item) =>
                                            currentArtWorkNft?.contract_type === contractType.erc721 ?
                                                <div className="d-flex" key={item?.value}>
                                                    <input
                                                        type="radio"
                                                        name="form_of_sale"
                                                        className="inputRadio ms-3"
                                                        style={{ height: 20, width: 20 }}
                                                        id={item.lable}
                                                        value={item.value || ''}
                                                        onChange={e => {
                                                            handleChange(e)
                                                            handleFormType(item.value, values);
                                                        }}
                                                        checked={item.value === values?.form_of_sale}
                                                    />
                                                    <div onClick={() => handleFormType(item.value, values)}>
                                                        <span
                                                            className="fw-bolder ms-2 font_color_black"
                                                        >
                                                            {item?.lable}
                                                        </span>
                                                    </div>
                                                </div>
                                                : (currentArtWorkNft?.contract_type === contractType.erc1155 && item?.value === nftType[0]?.value) &&
                                                <div className="d-flex" key={item?.value}>
                                                    <input
                                                        type="radio"
                                                        name="form_of_sale"
                                                        className="inputRadio ms-3"
                                                        style={{ height: 20, width: 20 }}
                                                        id={item.lable}
                                                        value={item.value || ''}
                                                        onChange={e => {
                                                            handleChange(e)
                                                            handleFormType(item.value, values);
                                                        }}
                                                        checked={item.value === values?.form_of_sale}
                                                    />
                                                    <div onClick={() => handleFormType(item.value, values)}>
                                                        <span
                                                            className="fw-bolder ms-2 font_color_black"
                                                        >
                                                            {item?.lable}
                                                        </span>
                                                    </div>
                                                    {errors.form_of_sale ?
                                                        <p style={styles.error}>{errors.form_of_sale}</p> : null}
                                                </div>
                                        )}
                                    </div>
                                    <section>
                                        <div className="mt-3">
                                        <h6 className="fw-bolder regal-blue-color">Mint NFT Type</h6>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <input
                                                type="text"
                                                className="purchaseModalInputDisable font_color_black"
                                                value={currentArtWorkNft?.sale_coin == 1 ? 'Matic' : 'ETH' || ''}
                                                disabled
                                                readOnly
                                            />
                                        </div>
                                        </div>
                                    </section>
                        
                                    {
                                        values.form_of_sale === 'fixed_price' ? <div className="mt-3">
                                            <span className="fw-bolder font_color_black">Sale Price</span>
                                            <input
                                                type="text"
                                                name="fixed_price"
                                                placeholder="Fixe Price"
                                                className="purchaseModalInputDisable font_color_black"
                                                value={values.fixed_price || ''}
                                                onChange={handleChange}
                                            />
                                            {errors.fixed_price ?
                                                <p style={styles.error}>{errors.fixed_price}</p> : null}
                                        </div> : <>

                                            <div className="mt-3">
                                                <span
                                                    className="fw-bolder font_color_black">Auction Start Price</span>
                                                <input
                                                    type="text"
                                                    value={values.auction_start_price || ""}
                                                    name="auction_start_price"
                                                    placeholder="Auction Start Price"
                                                    className="purchaseModalInputDisable font_color_black"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            {errors.auction_start_price ?
                                                <p style={styles.error}>{errors.auction_start_price}</p> : null}
                                            <div className="mt-3">
                                                <span
                                                    className="fw-bolder font_color_black">Auction End Time</span>
                                                <select
                                                    value={values.auction_end_time || ''}
                                                    name="auction_end_time"
                                                    placeholder="Auction End Time "
                                                    className="purchaseModalInputDisable font_color_black"
                                                    onChange={handleChange}
                                                >
                                                    {endTime?.map((item, index) => <option key={index}
                                                        value={item?.value}>{item?.key}</option>)}
                                                </select>
                                            </div>
                                            {errors.auction_end_time ?
                                                <p style={styles.error}>{errors.auction_end_time}</p> : null}

                                        </>
                                    }

                                    {currentArtWorkNft?.contract_type === contractType.erc1155 &&
                                        <div className="mt-3  displayFinalValues p-4" style={{ width: '60%' }}>
                                            <div
                                                className="d-flex justify-content-start align-items-center text-center">
                                                <h6 className="fw-bold regal-blue-color " style={{ width: '100px' }}>Sale
                                                    Price</h6>
                                                <h6 className="fw-bold regal-blue-color"
                                                    style={{ width: '100px' }}>Multiply</h6>
                                                <h6 className="ms-2 fw-bold regal-blue-color"
                                                    style={{ width: '100px' }}>Quantity</h6>
                                                <h6 className="ms-2 fw-bold regal-blue-color"
                                                    style={{ width: '100px' }}>Total Price</h6>
                                            </div>
                                            <div
                                                className="d-flex justify-content-start align-items-center text-center">
                                                <h6 className="fontSize10"
                                                    style={{ width: '100px ' }}>{`${values?.fixed_price}`}</h6>
                                                <h6 className="ms-2 fontSize10" style={{ width: '100px' }}>*</h6>
                                                <h6 className="ms-2 fontSize10"
                                                    style={{ width: '100px' }}>{`${currentArtWorkNft?.quantity}`}</h6>
                                                <span className="ms-2 fontSize10 d-flex ellipsis"
                                                    style={{ width: '100px' }}>{`${values?.fixed_price * currentArtWorkNft?.quantity}`}</span>
                                            </div>
                                        </div>}
                                       {loggedUser?.user_id === currentArtWorkNft.current_owner.current_owner_id &&
                                            
                                            <div className="mt-5">
                                                {loading ?
                                                    <button
                                                        type="submit"
                                                        className="btnBgBlue modalBtn fw-bolder"
                                                    >
                                                        {currentArtWorkNft?.form_of_sale === 'waiting_for_sale' ? 'Resell' : 'Set-Art-Work'}
                                                        <Spinner animation="border" size="sm" className=" ms-2" />
                                                    </button> :
                                                    <button
                                                        type="submit"
                                                        className="btnBgBlue modalBtn fw-bolder"
                                                        onClick={() => handleSubmit()}
                                                    >
                                                        {currentArtWorkNft?.form_of_sale === 'waiting_for_sale' ? 'Resell' : 'Set-Art-Work'}
                                                    </button>
                                                }
                                            </div>
                                        }
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        <SweetAlert type={sweetAlert.type} title={sweetAlert.title} onConfirm={() => _alert(s => ({ ...s, show: false }))}
            show={sweetAlert.show} />
        <Loader show={loading} />
        <OpenMapModal modalOpen={modalOpen} modalShow={modalShow} coords={coords} />
    </>
}

export default NftSellingModal;