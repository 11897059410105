import React, { useState } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import MaintenanceImg from './maintenance_2.jpg'

const Layout = (props) => {
  
  return (
    <div className={'app'} >
      <Header />
      <div className="app-content">
        {props.children}
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  )
}

export default Layout