import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Card, Row, Col} from "react-bootstrap";
import {Link, useNavigate, useParams} from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";
import API from "../../../redux/API";
import Loader from "../../../components/loader";
import MetaMaskHook from "../../../hooks/MetaMaskHook";
import {ethEnabled} from "../../../utils/web3.function";
import {Web3, ETH_Auction_Contract, MATIC_Auction_Contract} from "../../../utils/web3";
import {
    AdminAccountId,
    contractType,
    ErrorMessage,
    formOfSaleValue, IPFS_URL,
    networkID,
} from "../../../utils/config";
import HeroSection from "../../../components/HeroSection";
import {AuctionMemoized} from "../../../components/AuctionTime";
import PurchaseNft from "./purchaseNft";
import PlaceBid from "./placeBid";
import NftPurchaseComplete from "./nftPurchaseComplete";
import NftFormat from "../../../components/NftFormat";
import _ from 'lodash';
import './Art-Work-Details.css';
import StepProgressBar from "../../../components/StepProgressBar";
import axios from "axios";
import { BASE_URL } from "../../../utils/config";
//import { SSE_URL } from "../../../utils/config";

function NormalArtWorkDetails({data, otherArtWorks}) {
    //set custom rpc to MATIC_AUCTION_CONTRACT


    // MATIC_Auction_Contract.setProvider('https://rpc.ankr.com/polygon/c027bb6dd06d062156c15114f7e4aa2c35384bb5e4e1b717dcfe7ffbf5ecd578');

    const navigate = useNavigate();
    const {artWorkId} = useParams();
    const {id} = useParams();
    const [isMetaMaskTransaction, setMetaMaskTransaction] = useState(false);
    const [isAPITransaction, setAPITransaction] = useState(false);
    const [loading, _loading] = useState(false);
    const {ETHAddress, loggedUser,} = useSelector(s => s.user);
    const {isMetaMaskInstall, accountId, networkVersion, isDataLoading, isMetaMaskConnected} = MetaMaskHook();
    const [sweetAlert, _alert] = useState({show: false, title: '', type: 'success' || 'error'});
    const [quantity, _quantity] = useState(1);
    const [isPlaceBid, _isPlaceBid] = useState(false);
    const [moreArtwork, _moreArtwork] = useState([]);
    const [completeNftPurchase, _completeNftPurchase] = useState(false);
    const [bidAmount, _bidAmount] = useState(0);
    const [totalPrice, _totalPrice] = useState(data?.sale_price || 0);
    const [step, setStep] = useState(0);
    const [tracker, setTracker] = useState({msg: ''});
    const [purchaseHistory, setPurchaseHistory] = useState([]);
    const [creatorNickname, setCreatorNickname] = useState(null);
    const [nicknameMap, setNicknameMap] = useState({});


    // useEffect(() => {
    //     const source = new EventSource(SSE_URL);
    //     let data;

    //     source.onopen = e => {
    //         console.log(e);
    //     }
    //     source.onmessage = e => {
    //         console.log(e);
    //         data = JSON.parse(e.data)
    //         setTracker(data);
    //     }

    //     source.onerror = (e) => {console.error('Error: ',  e);}

    //     return () => {
    //       source.close();
    //     }; 

    // },[]);


    const feesData = {
        usage: data?.adminFeeData?.data?.usage || false,
        admin_fee: data?.adminFeeData?.data?.fees?.admin_fee || 0,
    }

    // seller_other_artworks
    useEffect(() => {
        return (() => {
            _moreArtwork(null);
        })
    }, [])


    useEffect(() => {
        if (tracker.msg == 'Art work fetched') {
            setStep(1);
        } else if (tracker.msg == 'royalty distibuted') {
            setStep(2);
        } else if (tracker.msg == 'ethTrasferFunc done') {
            setStep(3);
        }

    }, [tracker])

    useEffect(() => {
        _loading(true);
        getPurchaseHistory();
    }, [])

    useEffect(() => {
        const fetchNicknames = async () => {
            const newNicknameMap = {};
            for (let i = 0; i < purchaseHistory.length; i++) {
            const user_id = purchaseHistory[i]?.user_id;
            if (user_id && !nicknameMap[user_id]) {
                const nickname = await getNicknameById(user_id);
                newNicknameMap[user_id] = nickname;
            }
            }
            setNicknameMap({ ...nicknameMap, ...newNicknameMap });
            _loading(false);
        };
        fetchNicknames();

        }, [purchaseHistory]);


    
    useEffect(() => {
        if (!_.isEmpty(loggedUser)) {
            if (data?.highest_bid > 0) {
                _bidAmount(data?.highest_bid);
            } else {
                _bidAmount(data?.auction_start_price);
            }

            if ((!data?.is_first_bid && !data?.auction_ended) && loggedUser?.user_id !== 'undefined') {
                _completeNftPurchase(true);
            }
            if (data?.sale_price) {
                if (feesData?.usage) {
                    _totalPrice(data?.sale_price + feesData.admin_fee)
                } else {
                    _totalPrice(data?.sale_price)
                }
            }
            _moreArtwork(otherArtWorks);

            return (() => {
                _bidAmount(null);
                _completeNftPurchase(null);
                _moreArtwork(null);
            });
        }


    }, [data, otherArtWorks])

    const closeSweetAlertHandler = (s) => {
        _alert(s => ({...s, show: false}));
    }

    //creator nickname

    const fetchCreatorNickname = async(data) => {
        const nickname = await getNicknameById(data[0].creator_user_id);
        setCreatorNickname(nickname);
        
    }

    //convert time to KST

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: 'Asia/Seoul' };
        return date.toLocaleDateString('en-US', options);
      }
      
      

    //get purchase history in axios
    const getPurchaseHistory = async() => {
        

        //axios call
        const res = await axios.get(BASE_URL+'/user/art-work/purchase-history-by-id?art_work_id='+artWorkId)
        .then(res => {
            let data = res?.data?.data?.data
            console.log(data);
            setPurchaseHistory(data);
            fetchCreatorNickname(data);
        }).catch(err => {
            console.log(err);
        })

        // _loading(true);
        // await API({
        // API({
        //     url: 'user/art-work/purchase-history-by-id?art_work_id=1b0feaa0-14fd-42be-98cb-c2bda30fda27'
        // }).then(res => {
        //     // if (res?.data?.data?.length > 0) {
        //     //     _completeNftPurchase(true);
        //     // }
        //     console.log(res);
        // }).catch(err => {
        //     _alert({show: true, type: "error", title: ErrorMessage});
        // })


    }

    //get nicknameby id axios
    const getNicknameById = async(id) => {
        console.log("nickname:");
        try {
            // axios call
            const res = await axios.get(BASE_URL+'/user/get-nickname?user_id='+id);
            const data = res?.data?.data?.data?.nickname;
            return data;
        } catch (err) {
            console.log(err);
        }
    }

    const setNickname = async() => {
        let size = purchaseHistory.length;
        //for loop for size
        for(let i = 0; i < size; i++){
            let userId = purchaseHistory[i]?.user_id;
            let nickname = await getNicknameById(''+userId);
            console.log(nickname);
            // update the nickname property of the purchaseHistory element with matching user_id
            setPurchaseHistory(prevState => {
                return prevState.map(purchase => {
                    if (purchase.user_id === userId) {
                        return { ...purchase, nickname: nickname };
                    }
                    return purchase;
                });
            });
        }
    }
    

    const approvalCall = async (userBalance) => {

        if (networkVersion === networkID.ETH) {
            return await ETH_Auction_Contract.methods.approve(AdminAccountId, userBalance.toString()).send({from: accountId}).then(res => {
                setAPITransaction(true);
                // send original amount in api
                let createApiResponse = {
                    id,
                    form_of_sale: data?.form_of_sale,
                    art_work_id: artWorkId,
                    transactionHash: res,
                    amount: bidAmount,
                    fees: {
                        usage: feesData.usage,
                        admin_fee: feesData.admin_fee
                    }
                };

                API({
                    url: 'user/art-work/purchase',
                    data: createApiResponse,
                }).then(res => {
                    _alert({show: true, type: "success", title: res?.data?.message});
                    _isPlaceBid(false);
                    _completeNftPurchase(true);

                }).catch(error => {
                    _alert({show: true, type: "error", title: error.message});
                }).finally(() => setAPITransaction(false));

            }).catch((err) => {
                _alert({show: true, type: "error", title: err.message});
            }).finally(() => setMetaMaskTransaction(false));
        } else {


            return await MATIC_Auction_Contract.methods.approve(AdminAccountId, userBalance.toString()).send({
                from: accountId,
                gasPrice: Web3.utils.toWei('50', 'gwei')
            }).then(res => {
                setAPITransaction(true);
                // send original amount in api
                let createApiResponse = {
                    id,
                    form_of_sale: data?.form_of_sale,
                    art_work_id: artWorkId,
                    transactionHash: res,
                    amount: bidAmount,
                    fees: {
                        usage: feesData.usage,
                        admin_fee: feesData.admin_fee
                    }
                };

                API({
                    url: 'user/art-work/purchase',
                    data: createApiResponse,
                }).then(res => {
                    _alert({show: true, type: "success", title: res?.data?.message});
                    _isPlaceBid(false);
                    _completeNftPurchase(true);

                }).catch(error => {
                    _alert({show: true, type: "error", title: error.message});
                }).finally(() => setAPITransaction(false));

            }).catch((err) => {
                _alert({show: true, type: "error", title: err.message});
            }).finally(() => setMetaMaskTransaction(false));
        }
    }

    const metamaskValidation = async () => {
        if (loggedUser?.user_id) {
            if (data && data?.is_current_owner === false) {
                if (!isDataLoading) {
                    if (isMetaMaskInstall) {
                        if (isMetaMaskConnected) {
                            // check validation for backend and current metamask need to same
                            if (ETHAddress && accountId === ETHAddress) {
                                return true;
                            } else {
                                if (ETHAddress) {
                                    _alert({
                                        show: true, type: "warning", title: ErrorMessage.selectLinkWallet
                                    });
                                } else {
                                    _alert({
                                        show: true, type: "warning", title: ErrorMessage.updateWallet
                                    });
                                }
                            }
                        } else {
                            _alert({show: true, type: "warning", title: ErrorMessage.connectMetamask});
                            await ethEnabled();
                        }
                    } else {
                        _alert({show: true, type: "error", title: ErrorMessage.metamaskInstallation});
                    }
                }

            } else {
                _alert({show: true, type: "warning", title: ErrorMessage.ownerOfNAft});
            }

        } else {
            _alert({show: true, type: "warning", title: ErrorMessage.checkLoggedUser});
        }

        return false;
    }

    const etherTransaction = async () => {
        const BidOrSaleAmount = data?.form_of_sale === formOfSaleValue[1] ? Number(data?.auction_start_price) : Number(data?.sale_price);
        let createApiResponse = {
            transactionHash: {},
            id,
            form_of_sale: data?.form_of_sale,
            art_work_id: artWorkId,
            amount: BidOrSaleAmount,
            sale_coin: data?.sale_coin,
            fees: {
                usage: feesData.usage,
                admin_fee: feesData.admin_fee
            },
            real_estate_type: data?.real_estate_type
        };

        //call when ercType 721
        if (data?.contract_type === contractType.erc721) {
            const ethValue = Web3.utils.toWei((feesData.usage ? (Number(BidOrSaleAmount) + Number(feesData.admin_fee)) : BidOrSaleAmount).toString(), 'ether');
            // fixed price
            if (data?.form_of_sale === formOfSaleValue[0]) {

                setMetaMaskTransaction(true);
                const params = {
                    from: accountId,
                    to: AdminAccountId,
                    value: ethValue.toString(),
                    // gas: 9000000,
                    // gasPrice: 152000000000
                };
                await Web3.eth.sendTransaction(params).then((res) => {
                    setAPITransaction(true);
                    createApiResponse.transactionHash = res;

                    API({
                        url: 'user/art-work/purchase',
                        data: createApiResponse,
                    }).then(res => {
                        _alert({show: true, type: "success", title: res?.data?.message});
                        _isPlaceBid(false);
                        navigate('/profile');
                    }).catch(error => {
                        console.log('error 3', error?.message);
                        _alert({show: true, type: "error", title: error.message});
                    }).finally(() => {
                        setAPITransaction(false);
                        _loading(false);
                    })

                }).catch((err) => {
                    _alert({show: true, type: "warning", title: err.message});
                }).finally(() => setMetaMaskTransaction(false));
            }


            // auction price
            else if (data?.form_of_sale === formOfSaleValue[1]) {
                if (Number(bidAmount) > data?.auction_start_price && Number(bidAmount) > 0 && Number(bidAmount) > data?.highest_bid) {

                    //if the admin fees is applicable
                    const totalBidAmount = data?.adminFeeData?.data?.usage ? Number(data?.adminFeeData?.data?.fees?.admin_fee) + Number(bidAmount) : bidAmount;
                    // start metamask loader
                    setMetaMaskTransaction(true);

                    // get current use Balance
                    let balance = await ETH_Auction_Contract.methods.balanceOf(accountId).call();

                    const wrappedEtherBalanceFromWei = Web3.utils.fromWei(balance.toString());
                    const newWrappedEtherBalance = Number(wrappedEtherBalanceFromWei) + Number(totalBidAmount);
                    const updatedBalance = Web3.utils.toWei(totalBidAmount.toString(), 'ether');

                    await ETH_Auction_Contract.methods.deposit().send({
                        to: AdminAccountId,
                        from: accountId,
                        value: updatedBalance.toString()
                    }).then(async res => {
                        // approve call
                        await approvalCall(updatedBalance);
                    }).catch((err) => {
                        _alert({show: true, type: "warning", title: err.message});
                        setMetaMaskTransaction(false)
                    });

                } else {
                    _alert({
                        show: true,
                        type: "warning",
                        title: `please bid more then ${data?.highest_bid > 0 ? data?.highest_bid : data?.auction_start_price}`
                    });
                }
            }
        }

        // call when erctype 1155
        else if (data?.contract_type === contractType.erc1155) {

            if (Number(quantity) <= Number(data.quantity) && Number(quantity) > 0) {
                //fixed Price
                if (data?.form_of_sale === formOfSaleValue[0]) {
                    // const finalPrice = (quantity * data?.sale_price).toFixed(15);
                    let finalPrice = totalPrice.toFixed(15);
                    const totalSalePrice = Web3.utils.toWei(finalPrice.toString());
                    setMetaMaskTransaction(true);

                    const params = {
                        from: accountId,
                        to: AdminAccountId,
                        value: totalSalePrice.toString(),
                        // gas: 9000000,
                        // gasPrice: 152000000000
                    };

                    await Web3.eth.sendTransaction(params).then((res) => {

                        setAPITransaction(true);
                        createApiResponse.transactionHash = res;
                        createApiResponse.sale_quantity = quantity;
                        createApiResponse.amount = BidOrSaleAmount * quantity;

                        API({
                            url: 'user/art-work/purchase-share',
                            data: createApiResponse,
                        }).then(res => {
                            _alert({show: true, type: "success", title: res?.data?.message});
                            setTimeout(() => {
                                navigate('/profile')
                            }, 1500);

                        }).catch(error => {
                            _alert({show: true, type: "error", title: error.message});
                        }).finally(() => setAPITransaction(false))

                    }).catch((err) => {
                        _alert({show: true, type: "warning", title: err.message});
                    }).finally(() => setMetaMaskTransaction(false));
                }

            } else {
                _alert({show: true, type: "warning", title: `Please enter appropriate Quantity`});
            }
        }
    }

    const maticTransaction = async () => {
        const BidOrSaleAmount = data?.form_of_sale === formOfSaleValue[1] ? Number(data?.auction_start_price) : Number(data?.sale_price);
        let createApiResponse = {
            transactionHash: {},
            id,
            form_of_sale: data?.form_of_sale,
            art_work_id: artWorkId,
            amount: BidOrSaleAmount,
            sale_coin: data?.sale_coin,
            fees: {
                usage: feesData.usage,
                admin_free: feesData.admin_fee
            },
            real_estate_type: data?.real_estate_type
        };

        //call when ercType 721
        if (data?.contract_type === contractType.erc721) {
            const ethValue = Web3.utils.toWei((feesData.usage ? (Number(BidOrSaleAmount) + Number(feesData.admin_fee)) : BidOrSaleAmount).toString(), 'ether');
            //fixed price
            if (data?.form_of_sale === formOfSaleValue[0]) {

                let gasVal = 0;
                await Web3.eth.getGasPrice().then(gasPrice => {
                  const increasedGasPrice = parseInt(gasPrice) + (60 * 10**9);
                  console.log(increasedGasPrice, gasPrice);
                  gasVal = Web3.utils.toWei(increasedGasPrice.toString(), "wei");
                });

                setMetaMaskTransaction(true);
                const params = {
                    from: accountId,
                    to: AdminAccountId,
                    value: ethValue.toString(),
                    gas: '90000',
                    chainId: '0x13881',
                    gasPrice: gasVal
                };

                await Web3.eth.sendTransaction(params).then((res) => {
                    setAPITransaction(true);
                    createApiResponse.transactionHash = res;

                    API({
                        url: 'user/art-work/purchase',
                        data: createApiResponse,
                    }).then(res => {
                        _alert({show: true, type: "success", title: res?.data?.message});
                        _isPlaceBid(false);
                        navigate('/profile');
                    }).catch(error => {
                        console.log('error 3', error?.message);
                        _alert({show: true, type: "error", title: error.message});
                    }).finally(() => {
                        setAPITransaction(false);
                        _loading(false);
                    })

                }).catch((err) => {
                    _alert({show: true, type: "warning", title: err.message});
                }).finally(() => setMetaMaskTransaction(false));
            }
            //auction price
            else if (data?.form_of_sale === formOfSaleValue[1]) {
                if (Number(bidAmount) > data?.auction_start_price && Number(bidAmount) > 0 && Number(bidAmount) > data?.highest_bid) {
                    //if the admin fees is applicable
                    const totalBidAmount = data?.adminFeeData?.data?.usage ? Number(data?.adminFeeData?.data?.fees?.admin_fee) + Number(bidAmount) : bidAmount;
                    // start metamask loader
                    setMetaMaskTransaction(true);

                    // get current use Balance

                    let balance = await MATIC_Auction_Contract.methods.balanceOf(accountId).call();

                    const wrappedEtherBalanceFromWei = Web3.utils.fromWei(balance.toString());
                    const newWrappedEtherBalance = Number(wrappedEtherBalanceFromWei) + Number(totalBidAmount);
                    const updatedBalance = Web3.utils.toWei(totalBidAmount.toString(), 'ether');

                    await MATIC_Auction_Contract.methods.deposit().send({
                        to: AdminAccountId,
                        from: accountId,
                        value: updatedBalance.toString(),
                        gasPrice: Web3.utils.toWei('50', 'gwei')
                    }).then(async res => {
                        // approve call
                        await approvalCall(updatedBalance);
                    }).catch((err) => {
                        _alert({show: true, type: "warning", title: err.message});
                        setMetaMaskTransaction(false)
                    });
                } else {
                    _alert({
                        show: true,
                        type: "warning",
                        title: `please bid more then ${data?.highest_bid > 0 ? data?.highest_bid : data?.auction_start_price}`
                    });
                }
            }
        }

        // call when erctype 1155
        else if (data?.contract_type === contractType.erc1155) {
            if (Number(quantity) <= Number(data.quantity) && Number(quantity) > 0) {
                //fixed Price
                if (data?.form_of_sale === formOfSaleValue[0]) {
                    // const finalPrice = (quantity * data?.sale_price).toFixed(15);
                    let finalPrice = totalPrice.toFixed(15);
                    const totalSalePrice = Web3.utils.toWei(finalPrice.toString());
                    setMetaMaskTransaction(true);

                    const params = {
                        from: accountId,
                        to: AdminAccountId,
                        value: totalSalePrice.toString(),
                        gas: '90000',
                        chainId: '0x13881',
                        gasPrice: Web3.utils.toWei('150', 'gwei')
                        // gas: 39000
                    };

                    await Web3.eth.sendTransaction(params).then((res) => {

                        setAPITransaction(true);
                        createApiResponse.transactionHash = res;
                        createApiResponse.sale_quantity = quantity;
                        createApiResponse.amount = BidOrSaleAmount * quantity;
                        createApiResponse.sale_coin =

                            API({
                                url: 'user/art-work/purchase-share',
                                data: createApiResponse,
                            }).then(res => {
                                _alert({show: true, type: "success", title: res?.data?.message});
                                setTimeout(() => {
                                    navigate('/profile')
                                }, 1500);

                            }).catch(error => {
                                _alert({show: true, type: "error", title: error.message});
                            }).finally(() => setAPITransaction(false))

                    }).catch((err) => {
                        _alert({show: true, type: "warning", title: err.message});
                    }).finally(() => setMetaMaskTransaction(false));
                }

            } else {
                _alert({show: true, type: "warning", title: `Please enter appropriate Quantity`});
            }
        }
    }

    const purchaseNFT = async () => {

        const checkValidation = await metamaskValidation();
        if (checkValidation) {
            //Ether
            if (data.sale_coin == 0) {
                if (networkVersion == networkID.ETH) {
                    await etherTransaction();
                } else {

                    _alert({
                        show: true, type: "warning", title: ErrorMessage.ETH_Network_Version
                    });
                }
            } else if (data.sale_coin == 1) {
                if (networkVersion == networkID.MATIC) {
                    await maticTransaction();
                } else {
                    _alert({
                        show: true, type: "warning", title: ErrorMessage.MATIC_Network_Version
                    });
                }
            } else {
                _alert({
                    show: true, type: "warning", title: ErrorMessage.ETH_Network_Version
                });
            }
        }
    }

    const placeBid = () => {
        _isPlaceBid(!isPlaceBid);
    }

    const cancelApplication = (art_work_id) => {
        //here will code of cancel Bid

        const message = 'Are You Sure';
        if (window.confirm(message) === true) {
            _loading(true)
            API({
                url: `user/art-work/purchase/cancel-bid?art_work_id=${art_work_id}`,
                data: {art_work_id: art_work_id},
                method: 'PUT'
            }).then(res => {
                _alert({show: true, type: "success", title: res?.data?.message});
                _isPlaceBid(false);
                _completeNftPurchase(false);
            }).catch(error => {
                _alert({show: true, type: "error", title: error.message});
            }).finally(() => _loading(false));
        }
    }

    const MoreArtWork = ({moreArtWorkData}) => {

        return <>
            {moreArtWorkData?.map((item, index) =>
                <Col xs={12} md={4} key={item?._id} className="mouse-pointer">
                    <Card className="video-card"
                          onClick={() => navigate(`/profile/${data?.current_owner?.current_owner_id}`)}>
                        <div style={{height: 'auto', width: '100%', padding: '5px'}}>
                            <NftFormat type={item?.nft_token[0]?.format} views={item?.views}
                                       file={item?.nft_token[0]?.file}/>
                        </div>
                        <Card.Body>
                            <Card.Title>{item?.title}</Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
            )}
        </>
    }

    return (
        <>
            {!loading && data ?
                <section className="container mt-3">
                    <Row>
                        <Col xs={12} md={6}>
                            <div className="artwork-head-image">
                                <div className="artwork-calendar d-flex justify-content-between align-items-center">

                                    {data?.form_of_sale === 'auction' ? <>
                                            <AuctionMemoized selling_status={data?.selling_available}
                                                             auction_End_Time={data?.auction_end_time}/>
                                            <h6 className="quantityLable"> 0/{data?.quantity}</h6>  </> :
                                        data.contract_type === contractType.erc1155 &&
                                        <h6 className="quantityLable"> 0/{data?.quantity}</h6>}
                                </div>

                                <div style={{height: 'auto'}}>
                                    <NftFormat type={data?.title_image_format} views={data?.views}
                                               file={data?.title_image}/>
                                </div>
                            </div>

                        </Col>

                        {data?.is_current_owner === false ? <Col xs={12} md={6}>
                                <h1>{data?.title}</h1>
                                {isPlaceBid ?
                                    <PlaceBid
                                        _quantity={_quantity}
                                        quantity={quantity}
                                        placeBid={placeBid}
                                        purchaseNFT={purchaseNFT}
                                        nftDetails={data} _bidAmount={_bidAmount}
                                        bidAmount={bidAmount}
                                        isMetaMaskTransaction={isMetaMaskTransaction}
                                        isAPITransaction={isAPITransaction}
                                    />
                                    : completeNftPurchase ?
                                        <NftPurchaseComplete
                                            cancelApplication={cancelApplication}
                                            nftDetails={data}
                                            formOFSale={data?.form_of_sale}
                                            loading={loading}
                                        />
                                        :  <PurchaseNft
                                        nftDetails={data}
                                        placeBid={placeBid}
                                        formOFSale={data?.form_of_sale}
                                        purchaseNFT={purchaseNFT}
                                        _quantity={_quantity}
                                        quantity={quantity}
                                        isMetaMaskTransaction={isMetaMaskTransaction}
                                        isAPITransaction={isAPITransaction}
                                        _totalPrice={_totalPrice}
                                        totalPrice={totalPrice}
                                        loggedUser={loggedUser}
                                    />
                                }
                            </Col>
                            :
                            <Col xs={12} md={6}
                                 className="robot-font fw-bolder text-center d-flex align-items-center justify-content-center">
                                <div className="w-100">
                                    <div className="regal-blue-color" style={{fontSize: '22px'}}>You are a Owner of
                                        NFT
                                    </div>
                                    <Link to={'/profile'} className="text-decoration-none">
                                        <div className="d-flex justify-content-center mt-3">
                                            <button
                                                className="d-flex align-items-center btnDownload modalBtn fw-bolder text-decoration-none text-center p-3 text-white w-auto">GoTo
                                                MY-NFT
                                            </button>
                                        </div>
                                    </Link>
                                </div>

                            </Col>
                        }
                    </Row>

                    {data?.current_owner?.current_owner_id === loggedUser?.user_id && <div className=" mt-2 mb-5">
                        <label className={"mt-5"}><strong><span className="red">*</span>Attached file</strong></label>
                        {
                            data?.files.length && data?.files?.map((file, index) => {
                                return <div key={index} className=" mt-2 mouse-pointer mb-3 flex-wrap">
                                    <div
                                        className="d-flex align-items-center justify-content-between InputFileName font_color_black me-2"
                                        style={{padding: '12px'}}>
                                        {file}
                                        <button
                                            className="d-flex align-items-center btnDownload modalBtn fw-bolder text-decoration-none text-center p-3 text-white w-auto"
                                            onClick={() => window.open(`${IPFS_URL}${file}`)}>Download
                                        </button>
                                    </div>
                                </div>
                            })
                        }

                    </div>
                    }
                    {isAPITransaction && <StepProgressBar currentStep={step}/>}

                    {/*more Art-Work*/}
                    <section className="mt-5">
                        <Row className="artwork-heading">
                            <Col md={12}>
                                <h2 className="fw-bold regal-blue-color">More Works
                                    by {data?.current_owner?.current_owner_nickname}</h2>
                            </Col>
                        </Row>

                        <Row className="artwork-card-section mt-3">
                            {!loading && moreArtwork?.length > 0 ? <MoreArtWork moreArtWorkData={moreArtwork}/> :
                                <h4 className="noDataAvailableMessage">Art-Work Data Not Available...!</h4>}
                        </Row>
                    </section>
                </section>
                : <h4 className="noDataAvailableMessage">Art-Work Details not Found...!</h4>
            }
                <section className="container" style={{marginTop: '5%'}}>
                    <Row className="artwork-heading">
                        <Col md={12}>
                            <h2 className="fw-bold regal-blue-color"> Purchase History</h2>
                        </Col>
                        <Col md={12}>
                            <h4 className="fw-bold regal-blue-color"> Creator : { data?.creator?.creator_nickname}</h4>
                        </Col>
                        {purchaseHistory?.length > 0 ?(
                        <table className="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">NickName</th>
                            <th scope="col">Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {purchaseHistory?.map((item, index) => {
                            return <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{nicknameMap[item?.user_id]}</td>
                                <td>{formatDate(item?.updated_at)}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>)
                        :(<h4>No Purchase History</h4>)}
                    </Row>
                
                 </section>

            <Loader show={loading || isMetaMaskTransaction || isAPITransaction}/>
            <SweetAlert type={sweetAlert.type} title={sweetAlert.title} onConfirm={() => closeSweetAlertHandler()}
                        show={sweetAlert.show}/>

        </>

    );
}

export default NormalArtWorkDetails;
