module.exports = {
  //BASE_URL: 'http://localhost:4000/api',
  //SSE_URL : "http://localhost:4000/api/connect",
  //META_DATA_URL: "http://localhost:8000",

   BASE_URL: "https://backend.khanteum.io/api",
   SSE_URL: "https://backend.khanteum.io/sse-connection/connect",
   META_DATA_URL: "https://metadata.khanteum.io",

  // BASE_URL: "https://dev-backend.khanteum.io/api",
  // SSE_URL : "https://dev-backend.khanteum.io/sse-connection/connect",
  // META_DATA_URL: "https://dev-metadata.khanteum.io",

  GoogleClientId:
    "982396550934-f2d1sthom16qkpord98d6m5p87821d07.apps.googleusercontent.com",
  GoogleClientsecret: "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",
  googleApiKey: "AIzaSyAdXiTDbmAhMrhQ8G5N0rU2ZkuDN53xarI",
  formOfSaleValue: ["fixed_price", "auction", "waiting_for_sale"],
  artCategory: ["art", "game", "music", "video"],

  //AdminAccountId: '0x368BB039F1B17F9c5C5D8BE6CEef79536B7168cA',
  AdminAccountId: "0xFB6aa4EFe9C42ee963ABb41362334DC938c29959",

  // Addressed For Ether
  ERC_721_ETH_CONTRACT: "0xC8165010CF62097299FB93A17091FfD8e3be8E77",
  WRAPPED_ETH_CONTRACT: "0xc3e424666E85478f76bDca435240180e9064B85c",
  ERC_1155_ETH_CONTRACT: "0x8098fd14F5597eee12A075d67d98f128A1c4c866",
  OPENSEA_ERC_721_ETH_CONTRACT: "0x192E5758D8b7b549CE250e79FBE6f972AAC3976d",
  OPENSEA_ERC_1155_ETH_CONTRACT: "0xeD3129b24989f92b8429201Fe8D2FeEea038FdaF",
  ETH_AUCTION_CONTRACT: "0x715aEE0945E8A1Fb4d5eF663a8351Dd4F683a05D",
  //Address for Matic
  ERC_721_POLYGON: "0x85b7f2a335bcDCe6eFDB08C6110b9Ea61e72501d",
  ERC_1155_POLYGON: "0xED033A36b301cFE569c3Cc091EC321651A85d544",
  WRAPPED_MATIC_CONTRACT: "0xa884FCf520B5f8Bae3E2733d7DCdbFb5143aF719",
  OPENSEA_MATIC_721_ETH_CONTRACT: "0x82Ae40f94C396aBDE8213313e0fc209bD0aa5894",
  OPENSEA_MATIC_1155_ETH_CONTRACT: "0x2f47EC311409686C4fee4b2B526C8867E02d5c4f",
  MATIC_AUCTION_CONTRACT: "0x0609761c88438B35b231CB2119E91a91ef2c33b4",

  //tesnetwork
  // Addressed For Ether
  // ERC_721_ETH_CONTRACT: "0x188694a73eb716F4649fAA2E540223575Df38189",
  // WRAPPED_ETH_CONTRACT: "0x2E6Bc2B7d3337577D25b72Faf028116CcB424477",
  // ERC_1155_ETH_CONTRACT: "0xf9Ec455cD8398fb21B4979d7D30762c843AF0F68",
  // OPENSEA_ERC_721_ETH_CONTRACT: "0x42afaE47D4b021Df25Fa8a5BDb2aa246efA112A6",
  // OPENSEA_ERC_1155_ETH_CONTRACT: "0xbC77cdD2F057a9adaEb9EDb9014cb4088C727B0d",
  // ETH_AUCTION_CONTRACT: "0xfe68667815f8735EC5103709Eb61F8D8A6386D4a",
  // //Address for Matic
  // ERC_721_POLYGON: "0xb05d05cfe67b3cd15E6bAd08A2Ba475B9975F33A",
  // ERC_1155_POLYGON: "0x92b877Ff23ea8E71C709Cc23c1bb69F1254d296A",
  // WRAPPED_MATIC_CONTRACT: "0xE542dEa7d5bA68AEf9eF1782098f4D61679F4373",
  // // OPENSEA_MATIC_721_ETH_CONTRACT: "0x4B0cDA8BeAfDdCB192856d965a1dAcb945E9bc71", //Old - No Gift
  // OPENSEA_MATIC_721_ETH_CONTRACT:'0x384667c159F814450d91755b178Af718D853bFad',
  // OPENSEA_MATIC_1155_ETH_CONTRACT: "0x318c5Fa16c4495aF497545B5a76ae0CF4C23fa41",
  // MATIC_AUCTION_CONTRACT: "0xD5Ce30EF373c15E5D9D0f84AE58b582eF36295Cb",

  saleCoin: [
    { id: 0, coin_name: "ETH" },
    { id: 1, coin_name: "Polygon" },
  ],


  //for transaction checking

  polyScanUrl: {
    etherCoin: "https://etherscan.io/tx/",
    polyCoin: "https://polygonscan.com/tx/",
  },
  contractType: {
    erc721: "erc_721",
    erc1155: "erc_1155",
  },
  //testnetwork
  // networkID: {
  //   ETH: "11155111",
  //   MATIC: "80001",
  // },

  //mainnetwork
  networkID: {
    ETH: "1",
    MATIC: "137",
  },
  ErrorMessage: {
    ETH_Network_Version: "Please Select Ether Server",
    MATIC_Network_Version: "Please Select Matic Server",
    metamaskInstallation: "Please Install The metamask First",
    selectLinkWallet: "Please connect correct metamask wallet",
    updateWallet: "please update your metamask wallet details from profile",
    connectMetamask: "please connect meta mask First",
    checkLoggedUser: "please login first",
    ownerOfNAft: "you are owner of this nft you not purchase this NFT",
  },
  kycStatus: {
    unverified: "unverified",
    waiting: "waiting",
    verified: "verified",
  },
  IPFS_URL: "https://onofft.mypinata.cloud/ipfs/",
  endTime: [
    { key: "4 minutes", value: 4 / 60 },
    { key: "10 minutes", value: 10 / 60 },
    { key: "72 hours", value: "72" },
    { key: "96 hours", value: "96" },
    { key: "120 hours", value: "120" },
    { key: "144 hours", value: "144" },
    { key: "168 hours", value: "168" },
  ],
  arrangementList: [
    { lable: "All", value: "all" },
    { lable: "On sale NFT", value: "on_sale" },
    { lable: "Latest", value: "latest" },
    { lable: "Popularity", value: "popularity" },
    { lable: "Purchase Approval", value: "approval" },
  ],
  arrangementListWithoutPurchaseApproval: [
    { lable: "All", value: "all" },
    { lable: "On sale NFT", value: "on_sale" },
    { lable: "Latest", value: "latest" },
    { lable: "Popularity", value: "popularity" },
  ],
  myNftCategoryList: [
    { lable: "Owned", value: "owned" },
    { lable: "Created", value: "created" },
  ],
  nftType: [
    { lable: "Single", value: "0" },
    { lable: "Multi", value: "1" },
  ],
  mintNftType: [
    { lable: "ETH", value: "0" },
    { lable: "Matic", value: "1" },
  ],
  form_of_sale: [
    { lable: "Open Bid", value: "auction" },
    { lable: "Fixed Price", value: "fixed_price" },
  ],
  formType: [
    { lable: "Normal", value: false },
    { lable: "Real Estate", value: true },
  ],
  countryList: [
    { lable: "Korea Republic", value: "Korea-Republic" },
    { lable: "USA", value: "USA" },
    { lable: "China", value: "China" },
    { lable: "Japan", value: "Japan" },
  ],
  idTypeList: [
    { lable: "Registation Card", value: "0" },
    { lable: "Driver License", value: "1" },
    { lable: "Passport", value: "2" },
  ],
};
