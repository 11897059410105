import React from 'react';
import UserProfileLayout from '../../components/UserProfileLayout';
import './profileCard.css';

const ProfileCard = ({loggedUser}) => {
    return <>
        <div className="editprofile-section">
          <UserProfileLayout loggedUser={loggedUser}/>
      </div>
    </>
}

export default ProfileCard;