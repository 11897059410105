import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import axios from "axios";
import { BiSearch } from "react-icons/bi";
import Pagination from "react-bootstrap/Pagination";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../utils/config";
import Loader from "../../../components/loader";
import "./noticeList.css";
import API from "../../../redux/API";

function NoticeList() {
  const [noticeList, setNoticeList] = useState([]);
  const [search, _search] = useState("");
  const navigate = useNavigate();
  const [currentPage, _currentPage] = useState(1);
  const [lastPage, _lastPage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [limit, _limit] = useState(100);

  useEffect(() => {
    const timeout = setTimeout(() => {
      getNotice();
    }, 700);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentPage, search]);

  const getNotice = () => {
    setIsLoading(true);
    API({
      url: `/notice/pagination?page=${currentPage}&limit=${limit}${
        search && "&title=" + search
      }`,
      method: "GET",
    })
      .then((res) => {
        if (currentPage > 1) {
          setNoticeList([...noticeList, ...res?.data]);
          _lastPage(res?.lastPage);
        } else {
          setNoticeList(res?.data);
          _currentPage(res?.currentPage);
          _lastPage(res?.lastPage);
        }
        setIsLoading(false);
      })
      .catch((e) => console.log("Error : ", e));
  };

  const redirect = (id, views) => {
    setIsLoading(true);
    const data = {views: views+1};
    API({
      url: `/notice/edit/${id}`,
      method: "PUT",
      data: data
    }).then((res) => {
      navigate(`/noticeDetails/${id}`);
      setIsLoading(false);
    }).catch((e) => console.log("Error : ", e));
  };

  return (
    <>
      <div id="notice-list" className="container mb-5 w-100">
        <div className="row justify-content-center ptb-3 p-2 pt-5 p-lg-2">
          <div className="col-12 ">
            <h1 className="robot-font fw-bolder text-center pt-5 regal-blue-color">
              Notice
            </h1>
          </div>
          <div className="col-12 d-flex justify-content-end">
            <div className="">
              <input
                type="text"
                className="inputSearch"
                placeholder="search..."
                onChange={(e) => {
                  _search(e.target.value);
                  if (e.target.value) _currentPage(1);
                }}
              />
              <BiSearch
                size="20"
                style={{ color: "#A9DEEE" }}
                className=" searchIcon"
              />
            </div>
          </div>
          <div className="col-12">
            <div className="row mainRow">
              <div className="col-lg-12 col-12 overflow-auto p-0 ps-sm-4 pe-sm-4 table-wrapper">
                <table className="robot-font table-text-color w-100 mt-5 text-center border-top  border-bottom border-color">
                  <thead className="headBackground">
                    <tr>
                      <th className="border-bottom border-color p-3 regal-blue-color">
                        NO
                      </th>
                      <th className="border-bottom border-start border-color p-3 regal-blue-color w-50 text-uppercase">
                        Title
                      </th>
                      <th className="border-bottom border-start border-color p-3 regal-blue-color w-25 text-uppercase">
                        Views
                      </th>
                      <th className="border-bottom border-start border-color p-3 regal-blue-color w-25 text-uppercase">
                        Registration Date
                      </th>
                      <th className="border-bottom border-start border-color p-3 regal-blue-color w-25 text-uppercase">
                        go to Details
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading &&
                      noticeList?.filter((val) => {
                        if (val.usage != "사용안함") {
                          return val;
                        }
                      }).map((k, i) => {
                        return (                          
                            <tr key={k?._id}>
                              <td className="border-bottom border-color pt-3 pb-3 regal-blue-color">
                                {i + 1}
                              </td>
                              <td className="border-bottom border-start border-color pt-3 pb-3 regal-blue-color">
                                {k?.title}
                              </td>
                              <td className="border-bottom border-start border-color pt-3 pb-3 regal-blue-color">
                                {k?.views}
                              </td>
                              <td className="border-bottom border-start border-color pt-3 pb-3  regal-blue-color">
                                {moment(k?.created_at).format(
                                  "YYYY-MM-DD, H:mm:ss"
                                )}
                              </td>
                              <td className="border-bottom border-start border-color pt-3 pb-3 pe-2 regal-blue-color">
                                <button
                                  className="btn-blue"
                                  onClick={() => redirect(k?.notice_id, k?.views)}
                                >
                                  View
                                </button>
                              </td>
                            </tr>                        
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {!lastPage && (
                <div className="mt-3 d-flex justify-content-center align-items-center">
                  <span
                    className="fw-bold mouse-pointer"
                    onClick={() => _currentPage(currentPage + 1)}
                  >
                    load more
                  </span>
                </div>
              )}
              <div className="col-12 col-lg-12 mt-3 ps-2 ">
                {!isLoading && noticeList?.data?.data?.length ? (
                  <Pagination className="justify-content-center">
                    <Pagination.Item
                      onClick={() => _currentPage(1)}
                      disabled={currentPage === 1}
                    >
                      {" "}
                      First
                    </Pagination.Item>

                    <Pagination.Item
                      onClick={() => _currentPage((p) => p - 1)}
                      disabled={currentPage === 1}
                    >
                      Prev
                    </Pagination.Item>
                    <Pagination.Item disabled> {currentPage}</Pagination.Item>
                    <Pagination.Item
                      onClick={() => _currentPage((p) => p + 1)}
                      disabled={currentPage === noticeList?.count}
                    >
                      Next
                    </Pagination.Item>
                    <Pagination.Item
                      onClick={() => _currentPage(noticeList?.count)}
                      disabled={currentPage === noticeList?.count}
                    >
                      End
                    </Pagination.Item>
                  </Pagination>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader show={isLoading} />
    </>
  );
}

export default NoticeList;
