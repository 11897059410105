import React from "react";
import UserImg from "../../../../images/others/user-img.png";
import {UserNickName} from "../../../../utils/function";
import {useNavigate} from "react-router-dom";
import {Spinner} from "react-bootstrap";
import '../Art-Work-Details.css';

const NftPurchaseComplete = ({cancelApplication,formOFSale,nftDetails,loading}) => {

    const navigate = useNavigate();
    return <>
        <div>
            <div className="bid-setting-section">
                <div className="bid-setting-box">
                    <h4>Your purchase request has been completed!</h4>
                    <div className="user-bid-box">
                        <img src={nftDetails?.creator?.user_profile || UserImg} alt="image" className="user-image rounded-circle"/>
                        <div className="tl ml-15">
                            {nftDetails?.auction_ended === true ? <span className="fw-bolder regal-blue-color">Auction is over</span>: <p>{UserNickName(nftDetails?.creator?.creator_email)} purchase request!</p>}
                            {/*<h5>14.31 ETH</h5>*/}
                        </div>
                    </div>
                    {formOFSale === 'auction' &&
                        <div className="btn-section">
                            {loading ?
                                <button className="btn-blue">
                                    Cancel application  <Spinner animation="border" size="sm" className="white ms-2"/>
                                </button> :
                                <button className="btn-blue" onClick={() => cancelApplication(nftDetails?.art_work_id)}>
                                    Cancel application
                                </button>
                            }

                            <button className="btn-white" onClick={()=>navigate('/marketplace')}>Back to list</button>
                        </div>
                    }
                </div>
            </div>
        </div>
    </>
}

export default NftPurchaseComplete;
