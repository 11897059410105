import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../utils/config";
import axios from "axios";
import API from "../../../redux/API";

function NoticeDetails() {
  const [noticeData, setNoticeData] = useState({
    title: "",
    content: "",
    views: 0,
    created_at: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    getNoticeDetails();
  }, []);

  const getNoticeDetails = () => {
    setNoticeData(true);
    API({
      url: `/notice/${id}`,
      method: "GET",
    })
      .then((response) => {
        setNoticeData(response?.data);
      })
      .catch((e) => console.log("Error : ", e));
    setNoticeData(false);
  };
  return (
    <div>
      <div className="container mb-5 w-100">
        <div className="row justify-content-center ptb-3 p-2 pt-5 p-lg-2">
          <div className="col-12 ">
            <h1 className="robot-font fw-bolder text-center pt-5 regal-blue-color">
              Notice
            </h1>
          </div>
          <div className="col-12 robot-font mt-5 ">
            <div className="row ">
              <div className="col-lg-12 col-12">
                <div className="d-flex justify-content-between border-top border-bottom pt-3">
                  <div>
                    <p
                      className=" ms-3 fw-bold font-cool-purple-color"
                      style={{ fontSize: "10px", margin: 0 }}
                    >
                      Notice
                    </p>
                    <p
                      className="ms-3 fw-bold regal-blue-color"
                      style={{ margin: 0 }}
                    >
                      {" "}
                      {noticeData?.title}
                    </p>
                  </div>
                  <div className="text-center">
                    <p
                      className="me-3 regal-blue-color"
                      style={{ margin: 0, fontSize: "14px" }}
                    >
                      {moment(noticeData?.created_at).format("YYYY-MM-DD")}
                    </p>
                    <p
                      className="me-3 regal-blue-color"
                      style={{ fontSize: "14px" }}
                    >
                      {noticeData?.views}
                    </p>
                  </div>
                </div>
                <div className="border-bottom">
                  <div className="pt-5 pb-5 ms-3 regal-blue-color ">
                    <div className="row">
                      <div className="col-12">
                        {noticeData?.content &&
                          noticeData.content
                            .split("\n")
                            .map((paragraph, index) => {
                              if (paragraph.trim()) {
                                return (
                                  <p
                                    key={index}
                                    className="me-3 regal-blue-color"
                                    style={{ fontSize: "14px", width: "100%"  }}
                                  >
                                    {paragraph}
                                  </p>
                                );
                              } else {
                                return null; 
                              }
                            })}
                      </div>
                    </div>
                  </div>
                  {/*<div className="d-flex justify-content-start align-items-center border-top border-bottom mt-5">*/}
                  {/*  {*/}
                  {/*     noticeData?.prev_id ? (*/}
                  {/*          <p className="noticeDetailsBgColor p-3 m-0 w-20" onClick={() => navigate(`noticeDetails/${noticeData?.prev_id}`)}>Prev</p>*/}
                  {/*       )*/}
                  {/*         :*/}
                  {/*        <p className="ms-3 m-0 textColor">There are no previous post</p>*/}
                  {/*  }*/}
                  {/*</div>*/}
                  {/*<div className="d-flex justify-content-start align-items-center border-top border-bottom">*/}
                  {/*  {*/}
                  {/*      noticeData?.nextId ? (*/}
                  {/*          <p className="noticeDetailsBgColor p-3 m-0" onClick={() => navigate(`noticeDetails/${noticeData?.next_id}`)}>Next</p>*/}
                  {/*      )*/}
                  {/*          :*/}
                  {/*          <p className="ms-3 m-0 textColor">There are no next article</p>*/}
                  {/*  }*/}
                  {/*</div>*/}
                  <div className="d-flex align-items-center mt-5">
                    <button
                      onClick={() => navigate("/notice-list")}
                      className="listBtn"
                      style={{ background: "#162869", color: "white" }}
                    >
                      list
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoticeDetails;
