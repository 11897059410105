import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

import SweetAlert from "react-bootstrap-sweetalert";
import API from "../../redux/API";
import HeroSection from "../../components/HeroSection";
import "./ResetPassword.css";
import Loader from "../../components/loader";
import { BsEye, BsEyeSlash } from "react-icons/bs";

function ResetPassword() {
  const navigate = useNavigate();
  let { search } = useLocation();
  const [validated, setValidated] = useState(false);
  const [hasToken, _hasToken] = useState(false);
  const [email, _email] = useState("");
  const [password, _password] = useState(null);
  const [confirm_password, _confirm_password] = useState(null);
  const [alert, _alert] = useState({ show: false, title: "", type: "success" });
  const [loading, _loading] = useState({ sendEmail: false, sendCode: false });
  const [loadingSubmit, _loadingSubmit] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [passwordShow, _passwordShow] = useState(false);
  const [confirmPasswordShow, _confirmPasswordShow] = useState(false);
  const [validateCode, _validateCode] = useState(false);
  const [time, _time] = useState({ minutes: 0, seconds: 0 });
  const [verification_code, _verification_code] = useState("");
  const [emailVerify, _emailVerify] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const token = query.get("reset_password_token");
    _hasToken(token);
  }, []);

  useEffect(() => {
    if (isCodeSent) {
      let Minutes = 60 * 3;
      startTimer(Minutes);
    }
  }, [isCodeSent]);

  const onClickSendVerification = (event) => {
    let emailPtn = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!emailPtn.test(email)) {
      event?.preventDefault();
      event?.stopPropagation();
      _alert({ show: true, title: "Input valid Email ID", type: "error" });
    } else {
      _loading({ ...loading, sendEmail: true });
      API({ url: "user/reset-password", data: { email } })
        .then((res) => {
          setIsCodeSent(true);
          _emailVerify(true);
        })
        .catch((err) => {
          _alert({ show: true, title: err?.message, type: "error" });
        })
        .finally(() => _loading({ ...loading, sendEmail: false }));
    }
  };

  const onVerifyCodeBtnClick = async (event) => {
    event?.preventDefault();

    if (verification_code.length > 0) {
      _loading({ ...loading, sendCode: true });
      API({ url: "user/verify-code", data: { email, verification_code } })
        .then(async (res) => {
          _alert({ show: true, title: res?.data?.message, type: "success" });
          _validateCode(true);
          setIsCodeSent(false);
        })
        .catch((err) => {
          _alert({ show: true, title: err?.error?.message, type: "error" });
        })
        .finally(() => _loading({ ...loading, sendCode: false }));
    } else {
      _alert({ show: true, title: "Enter valid Verification code", type: "error" });
    }
  };

  const onSendCodeBtnClick = async (event) => {
    let emailPtn = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let passwordPtn = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z]).{8,}$/;
    if (!emailPtn.test(email)) {
      event?.preventDefault();
      event?.stopPropagation();
      _alert({ show: true, title: "Input valid Email ID", type: "error" });
    } else if (!passwordPtn.test(password)) {
      event?.preventDefault();
      event?.stopPropagation();
      _alert({
        show: true,
        title:
          "Password should contain at least one character, one number, one special character and minimum length 8.",
        type: "error",
      });
    } else {
      const form = event?.currentTarget;
      if (form?.checkValidity() === false) {
        event?.preventDefault();
        event?.stopPropagation();
      } else {
        event?.preventDefault();
        if (password !== confirm_password) {
          _alert({
            show: true,
            title: "password not matched",
            type: "warning",
          });
        } else {
          _loadingSubmit(true);
          API({
            url: "user/password-change",
            data: { email, password, confirm_password },
          })
            .then((res) => {
              _alert({
                show: true,
                title: "Password update Successfully",
                type: "success",
              });
              setTimeout(() => {
                navigate("/login");
              }, 500);
            })
            .catch((err) => {
              _alert({ show: true, title: err?.message, type: "error" });
            })
            .finally(() => _loadingSubmit(false));
        }
      }
      setValidated(true);
    }
  };

  const startTimer = (duration) => {
    let timer = duration,
      minutes,
      seconds;
    const interval = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      _time({
        minutes: minutes,
        seconds: seconds,
      });

      if (--timer < 0) {
        timer = duration;
      }

      if (minutes === "00" && seconds === "00") {
        clearInterval(interval);
        setIsCodeSent(false);
        if (!validateCode) {
          _emailVerify(false);
        }
      }
    }, 1000);
  };

  const showMyPassword = () => {
    let x = document.getElementById("myInputPassword");
    if (x.type === "password") {
      x.type = "text";
      _passwordShow(true);
    } else {
      x.type = "password";
      _passwordShow(false);
    }
  };

  const showMyConfirmPassword = () => {
    let x = document.getElementById("myInputPasswordConfirm");

    if (x.type === "password") {
      x.type = "text";
      _confirmPasswordShow(true);
    } else {
      x.type = "password";
      _confirmPasswordShow(false);
    }
  };

  return (
    <>
      <div>
        <HeroSection />
      </div>
      <div className="reset-page">
        <div className="signup-page">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-6">
                <div className="signup-box">
                  <div className="row text-center">
                    <div className="col welcome-text">
                      <h1>Forgot Password</h1>
                      <div className="d-flex justify-content-center"></div>
                    </div>
                  </div>
                  <div className="row text-center">
                    <div className="col signup-link mt-3">
                      Remember your password?
                      <Link to="/login/#top" className="text-blue underline">
                        Log in
                      </Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="signin-container">
                      <div className="signin-form">
                        <Form.Group className="mb-3">
                          <div className="d-flex justify-content-between">
                            <div>
                              <Form.Label className="label">
                                E-mail(ID)
                              </Form.Label>
                            </div>
                            <div className="">
                              {loading.sendEmail ? (
                                <button
                                  type="button"
                                  className="reset-submit btn-sm d-flex align-items-center justify-content-center text-center"
                                  onClick={() => onClickSendVerification()}
                                >
                                  send{" "}
                                  <Spinner
                                    animation="border"
                                    size="sm"
                                    className="ms-2"
                                  />
                                </button>
                              ) : (
                                !emailVerify && (
                                  <button
                                    type="button"
                                    className="reset-submit btn-sm d-flex align-items-center justify-content-center text-center"
                                    onClick={() => onClickSendVerification()}
                                  >
                                    send
                                  </button>
                                )
                              )}
                            </div>
                          </div>

                          <Form.Control
                            type="text"
                            value={email}
                            required
                            onChange={(e) => _email(e.target.value)}
                            disabled={emailVerify}
                            style={{ color: "black" }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Email is Required
                          </Form.Control.Feedback>
                        </Form.Group>
                        {isCodeSent && (
                          <Form.Group className="mb-3">
                            <div className="d-flex justify-content-between">
                              <div>
                                <Form.Label>Verification Code</Form.Label>
                              </div>
                              <div>
                                {loading.sendCode ? (
                                  <button
                                    type="button"
                                    className="reset-submit btn-sm d-flex align-items-center justify-content-center text-center"
                                    onClick={() => onVerifyCodeBtnClick()}
                                  >
                                    confirm{" "}
                                    <Spinner
                                      animation="border"
                                      size="sm"
                                      className="ms-2"
                                    />
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="reset-submit btn-sm d-flex align-items-center justify-content-center text-center"
                                    onClick={() => onVerifyCodeBtnClick()}
                                  >
                                    confirm
                                  </button>
                                )}
                              </div>
                            </div>
                            <Form.Control
                              type="text"
                              className="outline-0"
                              // required
                              onChange={(e) =>
                                _verification_code(e.target.value)
                              }
                            />

                            <p
                              style={{
                                marginTop: "-50px",
                                float: "right",
                                marginRight: "80px",
                                color: "red",
                              }}
                            >
                              {time.minutes === "00" &&
                              time.seconds === "00" ? (
                                <span>Resend Code</span>
                              ) : (
                                `${time.minutes}:${time.seconds}`
                              )}
                            </p>
                            <Form.Control.Feedback type="invalid">
                              Verification Code is Required
                            </Form.Control.Feedback>
                            <div></div>
                          </Form.Group>
                        )}

                        {validateCode && (
                          <>
                            <Form.Group className="mb-3">
                              <Form.Label>Password</Form.Label>
                              <Form.Control
                                type="password"
                                required
                                id="myInputPassword"
                                onChange={(e) => _password(e.target.value)}
                              />
                              <div
                                style={{
                                  marginTop: "-10%",
                                  marginLeft: "100%",
                                  cursor: "pointer",
                                }}
                              >
                                {passwordShow ? (
                                  <BsEyeSlash
                                    onClick={() => showMyPassword()}
                                  />
                                ) : (
                                  <BsEye onClick={() => showMyPassword()} />
                                )}
                              </div>
                              <Form.Control.Feedback type="invalid">
                                Password is Required
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Confirm Password</Form.Label>
                              <Form.Control
                                type="password"
                                required
                                id="myInputPasswordConfirm"
                                onChange={(e) =>
                                  _confirm_password(e.target.value)
                                }
                              />
                              <div
                                style={{
                                  marginTop: "-10%",
                                  marginLeft: "100%",
                                  cursor: "pointer",
                                }}
                              >
                                {confirmPasswordShow ? (
                                  <BsEyeSlash
                                    onClick={() => showMyConfirmPassword()}
                                  />
                                ) : (
                                  <BsEye
                                    onClick={() => showMyConfirmPassword()}
                                  />
                                )}
                              </div>
                              <Form.Control.Feedback type="invalid">
                                Confirm Password is Required
                              </Form.Control.Feedback>
                            </Form.Group>

                            <div className="signup-button">
                              {loadingSubmit ? (
                                <button
                                  type="button"
                                  className="register-submit button-gradient-color mb-3 btn btn-primary"
                                >
                                  Change Account{" "}
                                  <Spinner
                                    animation="border"
                                    size="sm"
                                    className="ms-2"
                                  />
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="register-submit button-gradient-color mb-3 btn btn-primary"
                                  onClick={() => onSendCodeBtnClick()}
                                >
                                  Change Account
                                </button>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SweetAlert
        type={alert.type}
        title={alert.title}
        onConfirm={() => _alert((s) => ({ ...s, show: false }))}
        show={alert.show}
      />
      <Loader show={loading.sendCode || loading.sendEmail || loadingSubmit} />
    </>
  );
}

export default ResetPassword;
