import React from "react";
import {Container} from "react-bootstrap";
import EditProfileForm from "./editProfileForm";
import './editForm.css';

const EditProfile = () => {
    return <>
        <div>
            <div className="white-bar"/>
            <div className="header-gradient"/>
            <Container>
               <EditProfileForm/>       
            </Container>
        </div>
    </>
}

export default EditProfile;