import React, { useEffect, useState } from "react";
import { Form, Accordion } from "react-bootstrap";
import _ from "underscore";
import './faq.css';
import API from "../../../redux/API";
import Loader from "../../../components/loader";

function FAQ() {

  const [activeCategory, setActiveCategory] = useState();
  const [categories, _categories] = useState({});
  const [loading, _setLoading] = useState(false);

  useEffect(() => {
    getAllFAQ();
  }, [])

  const getAllFAQ = () => {
    _setLoading(true);
    try{
      API({ url: `/frequent/pagination?page=1&limit=10`, method: 'GET' }).then(res => {
        const cat = _.groupBy(res.data, (k) => { return k?.category });
        _categories(cat);
        setActiveCategory(_.keys(cat)?.[0]);
      })
    }catch(e) {
      console.log("Error : ",e)
    }finally {
      _setLoading(false);
    }

  }

  return (
    <>


      <div id="faq" className="container mb-5 w-100 mt-5">
        <div className="row justify-content-center align-items-center mb-5 m-lg-1">
          <div className="col col-md-12 col-lg-12 col-xl-10 col-xxl-9">
            <div className="d-none d-lg-block">
              <div className="row justify-content-center align-items-center flex-wrap">
                {_.keys(categories)?.map((category, categoryIdx) => {
                  return (
                    <div
                      key={categoryIdx + 23}
                      className={`col text-center p-2 robot-font fw-bolder text-capitalize border mouse-pointer ${activeCategory === category &&
                        "background-cool-purple-color white "
                        } ${categoryIdx === 0 && "rounded-start"} ${categoryIdx === _.keys(categories).length - 1 && "rounded-end"
                        }`}
                      onClick={() => setActiveCategory(category)}
                    >
                      {category}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="d-block d-lg-none">
              <div className="row justify-content-center align-items-center">
                <div className="col-3 col-sm-2 robot-font fw-bold">Select Category:</div>
                <div className="col-9 col-sm-5">
                  <Form.Select onChange={(e) => setActiveCategory(e.target.value)}
                    className=" rounded-3 white button-gradient-color"
                    value={activeCategory} aria-label="Default select example">
                    {_.keys(categories)?.map((category, categoryIdx) => {
                      return (
                        <option value={category} key={categoryIdx}>{category}</option>
                      )
                    })}
                  </Form.Select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <Accordion defaultActiveKey="0" >
            {categories[activeCategory]?.map((item, i) => {
              return (
                <Accordion.Item eventKey={i}>
                  <Accordion.Header className="AccordionHeader">{item?.questions}</Accordion.Header>
                  <Accordion.Body>
                    {item?.answer}
                  </Accordion.Body>
                </Accordion.Item>
              )
            })}
          </Accordion>
        </div>
      </div>
      <Loader show={loading} />
    </>
  )
}

export default FAQ;
