import React, {memo, useEffect, useState} from "react";
import moment from "moment-timezone";
import {ListGroup, Spinner} from "react-bootstrap";
import _ from "lodash";

const AuctionTime = ({selling_status ,auction_End_Time, isArtWorkDetails = false}) => {
    const [auctionEndTime, setAuctionEndTime] = useState({D: '', H: '', M: '', S: ''});

    let intervalTime;
    useEffect(() => {
        let auctionTimeOut;
        intervalTime = setInterval(() => {

            const data = updateAuctionTime();
        
            if (data) {
                console.log(data, auctionEndTime)
                auctionTimeOut = setTimeout(()=>{
                    // if (selling_status) {
                    //    // window.location.reload()
                    // }
                },2000);

                clearInterval(intervalTime);
            }

        }, 1000);

        return () => {
            clearInterval(intervalTime);
            setAuctionEndTime(null)
        }
    }, []);

    const updateAuctionTime = () => {
        const duration = moment.duration(moment(auction_End_Time).diff(moment()));
        if (duration._milliseconds < 0) {
            clearInterval(intervalTime);
        }
        const D = duration.days() > 0 ? duration.days() : 0;
        const H = duration.hours() > 0 ? duration.hours() : 0;
        const M = duration.minutes() > 0 ? duration.minutes() : 0;
        const S = duration.seconds() > 0 ? duration.seconds() : 0;

        if (duration.asHours() <= 0 && duration?.minutes() <= 0 && duration.seconds() <= 0) {
            setAuctionEndTime({
                D: D,
                H: H,
                M: M,
                S: S
            })
            return true;
        }else{
            setAuctionEndTime({
                D: D,
                H: H,
                M: M,
                S: S
            })
            return false;
        }

    }

    return (
        <>
            {isArtWorkDetails ?
                <ListGroup horizontal>
                    <div className="auction-timer-box">
                        {(auctionEndTime?.D || auctionEndTime?.D === 0 ) ? <>
                            <div >{auctionEndTime?.D}</div>
                            <div >Days</div>
                        </> : <div className="d-flex justify-content-center align-items-center h-100"><Spinner animation="border" size="sm" className="text-white"/></div>}
                    </div>
                    <div className="auction-timer-box-other" >
                        {(auctionEndTime?.H || auctionEndTime?.H === 0 ) ? <>
                            <div >{auctionEndTime?.H}</div>
                            <div >Hours</div>
                        </> : <div className="d-flex justify-content-center align-items-center h-100"><Spinner animation="border" size="sm" className="text-white"/></div>}
                    </div>
                    <div className="auction-timer-box-other" >
                        {(auctionEndTime?.M || auctionEndTime?.M === 0 ) ? <>
                            <div >{auctionEndTime?.M}</div>
                            <div >Minutes</div>
                        </> : <div className="d-flex justify-content-center align-items-center h-100"><Spinner animation="border" size="sm" className="text-white"/></div>}
                    </div>
                    <div className="auction-timer-box-other" >
                        {(auctionEndTime?.S || auctionEndTime?.S === 0 ) ? <>
                            <div >{auctionEndTime?.S}</div>
                            <div >Seconds</div>
                        </> : <div className="d-flex justify-content-center align-items-center h-100"><Spinner animation="border" size="sm" className="text-white"/></div>}
                    </div>
                </ListGroup>
                :
                <ListGroup horizontal>
                    <ListGroup.Item className="days yellow-box">

                        {(auctionEndTime.D || auctionEndTime.D === 0 ) ? <>
                            <div className="heading">{auctionEndTime.D}</div>
                            <div className="day">Days</div>
                        </> : <div className="d-flex justify-content-center align-items-center h-100"><Spinner animation="border" size="sm" className="text-white"/></div>}
                    </ListGroup.Item>
                    <ListGroup.Item className="hours blue-box">
                        {(auctionEndTime.H || auctionEndTime.H === 0) ? <>
                            <div className="heading">{auctionEndTime.H}</div>
                            <div className="day">Hours</div>
                        </> : <div className="d-flex justify-content-center align-items-center h-100"><Spinner animation="border" size="sm" className="text-white"/></div>}
                    </ListGroup.Item>
                    <ListGroup.Item className="minutes blue-box">

                        {(auctionEndTime.M || auctionEndTime.M === 0) ?  <>
                            <div className="heading">{auctionEndTime.M}</div>
                            <div className="day">minutes</div>
                        </> : <div className="d-flex justify-content-center align-items-center h-100"><Spinner animation="border" size="sm" className="text-white"/></div>}
                    </ListGroup.Item>
                    <ListGroup.Item className="seconds blue-box">
                        {(auctionEndTime.S || auctionEndTime.S === 0) ? <>
                            <div className="heading">{auctionEndTime.S}</div>
                            <div className="day">Seconds</div>
                        </> : <div className="d-flex justify-content-center align-items-center h-100"><Spinner animation="border" size="sm" className="text-white"/></div>}
                    </ListGroup.Item>
                </ListGroup>
            }
        </>
    )
}


export const AuctionMemoized = memo(AuctionTime, (pp, np) => _.isEqual(pp, np));


