import React, {useEffect} from "react";
import {Routes, Route, Navigate} from "react-router-dom";
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import ResetPassword from './pages/Reset-Password';
import ArtWorkDetails from "./pages/Art-Work-Details";
import EditProfile from "./pages/Profile/editProfile/index";
import Home from "./pages/Home";
import Marketplace from "./pages/Marketplace";
import {FAQ, NoticeDetails, NoticeList} from './pages/ServiceCenter';
import UploadNft from './pages/Profile';
import UserProfile from './pages/Profile/userProfile';
import EnquiryQuestion from "./pages/ServiceCenter/Inquiry/question";
import InquiryAnswer from "./pages/ServiceCenter/Inquiry/answer";
import InquiryRegistration from "./pages/ServiceCenter/Inquiry/registration";
import RealEstate from "./pages/RealEastate";
import Kyc from "./pages/KYC-Verification";
import NftRealEstate from "./pages/Nft-Real-Estate";
import {useDispatch, useSelector} from "react-redux";
import KycStatus from "./pages/KYC-Verification/KYC-Status";
import PendingNftList from "./pages/Profile/pendingNFT";
import {updateCurrentUser} from "./utils/function";
import {setLoggedUser} from "./redux/reducers/user";
import MarketplaceCategory from "./pages/Marketplace/MarketplaceCategory";
import PageNotFound from "./pages/PageNotFound";
import NftBook from "./pages/Nft-Book";
import WhatIsKhanteum from "./pages/What-Is-Khanteum";
import TermsModal from "./components/TermsModal/TermsModal";
import PrivacyPolicy from "./components/PrivacyPolicy";
import App from "./components/Description/App";

/**
 * @author
 * @function Routes
 **/

const AppRoutes = (props) => {
    const {loggedUser} = useSelector(s => s.user);
    const dispatch = useDispatch();

    useEffect(async () => {
        if (loggedUser) {
            await updateCurrentUser(loggedUser)
                .then(async (response) => {
                    await dispatch(setLoggedUser(response));
                }).catch((err) => {
                    console.log('err', err);
                })
        }
    }, []);

    function RequireAuth({children}) {
        const userData = localStorage.getItem('user')
        return userData ? children : <Navigate to="/login" replace/>;
    }

    function PublicRoute({children}) {
        const userData = localStorage.getItem('user')
        return userData ? <Navigate to="/profile" replace/> : children;
    }

    return (
        <Routes>
            <Route path="/" exact={true} element={<Home/>}/>
            <Route path="/profile" exact={true}>
                <Route path=":userId" element={<UserProfile/>}/>
                <Route path="" element={<RequireAuth><UserProfile/></RequireAuth>}/>
            </Route>
            <Route path="/upload-nft" exact={true} element={<RequireAuth><UploadNft/></RequireAuth>}/>
            <Route path="/pending-nft" exact={true} element={<RequireAuth><PendingNftList/></RequireAuth>}/>
            <Route path="/edit-profile" exact={true} element={<RequireAuth><EditProfile/></RequireAuth>}/>
            <Route path="/signup" exact={true} element={<PublicRoute><SignUp/></PublicRoute>}/>
            <Route path="/login" exact={true} element={<PublicRoute><Login/></PublicRoute>}/>
            <Route path="/reset-password" exact={true} element={<ResetPassword/>}/>
            <Route path="/marketplace" exact={true} element={<Marketplace/>}/>
            <Route path="/nft-book" exact={true} element={<NftBook/>}/>
            <Route path="/marketplace/category/:category" exact={true} element={<MarketplaceCategory/>}/>
            <Route path="/real-estate" exact={true} element={<NftRealEstate/>}/>
            <Route path="/art-work/details/:artWorkId/:id" exact={true} element={<ArtWorkDetails/>}/>
            <Route path="/faq" exact={true} element={<RequireAuth><FAQ/></RequireAuth>}/>
            <Route path="/inquiry" exact={true} element={<RequireAuth><EnquiryQuestion/></RequireAuth>}/>
            <Route path="/inquiry-question" exact={true} element={<RequireAuth><EnquiryQuestion/></RequireAuth>}/>
            <Route path="/inquiry-answer/:id" exact={true} element={<RequireAuth><InquiryAnswer/></RequireAuth>}/>
            <Route path="/inquiry-registration" exact={true} element={<RequireAuth><InquiryRegistration/></RequireAuth>}/>
            <Route path="/noticeDetails/:id" exact={true} element={<NoticeDetails/>}/>
            <Route path="/notice-list" exact={true} element={<NoticeList/>}/>
            <Route path="/real-estate" exact={true} element={<RealEstate/>}/>
            <Route path="/kyc/apply" exact={true} element={<RequireAuth><Kyc/></RequireAuth>}/>
            <Route path="/kyc/status" exact={true} element={<RequireAuth><KycStatus/></RequireAuth>}/>
            <Route path="/terms" exact={true} element={<TermsModal/>} />
            <Route path="/hero-section" exact={true} element={<WhatIsKhanteum/>} />
            <Route path="/privacy" exact={true} element={<PrivacyPolicy/>} />
            <Route path="/kwm-description" exact={true} element={<App />} />
            <Route path="*" exact={true} element={<PageNotFound/>} />
        </Routes>
    )

}
export default AppRoutes;
