import React, {useCallback, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {Image, Spinner} from "react-bootstrap";
import cancelVector from "../../images/others/Vector.png";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../../components/loader";
import API from "../../redux/API";
import {useDropzone} from "react-dropzone";
import NftFormat from "../../components/NftFormat";
import OpenMapModal from "../GoogleModal/OpenMapModal";
import '../style.css';


const CertificateVerify = (props) => {

    const {show, openVerifyModal, currentArtWorkNft,getArtWorkPendingNFT} = props;
    const [sweetAlert, _alert] = useState({show: false, title: '', type: 'success'});
    const [loading, _loading] = useState(false);
    const [certificateAttached, _certificateAttached] = useState([]);
    const [modalShow,_modalShow] =useState(false);
    const [coords,_coords] = useState({lat:'',long:''})
    const typeValidator = (file) => {
        if (file.size > (100 * 1024 * 1024)) {
            return {
                code: "size-too-large",
                message: `file size is larger than 100MB`
            };
        }
        return null;
    };

    const removeCertificate = (file) => {
        const newFiles = [...certificateAttached]
        newFiles.splice(newFiles.indexOf(file), 1)
        _certificateAttached(newFiles)
    }

    const attachedCertificate = useCallback(acceptedFiles => {
        _certificateAttached([...acceptedFiles])

    }, [certificateAttached]);

    const {getRootProps: getRootCertificate, getInputProps: getInputCertificate} = useDropzone({
        multiple: false,
        accept: 'application/pdf',
        maxFiles: 10,
        minSize: 1,
        maxSize: 100 * 1024 * 1024,
        validator: typeValidator,
        onDrop: attachedCertificate,

    });

    const uploadCertificate = (certificateFormData) => {
        _loading(true);
        API({url: 'user/art-work/update-pdf', data:certificateFormData, method: 'POST'}).then(res => {
            _alert({show: true, title: res?.data?.message, type: 'success'});
            openVerifyModal();
            getArtWorkPendingNFT();
        }).catch(err => {
            _alert({show: true, title: err?.message, type: 'error'});
        }).finally(()=>_loading(false));
    }

    const handleSubmit = () => {
        if(certificateAttached?.length <= 0){
            _alert({show: true, type: "warning", title: "Please select the Certificate File!..."});
        }else{
            const certificateFormData = new FormData();

            certificateAttached.forEach(file => {
                certificateFormData.append('file', file);
            });

            certificateFormData.append('art_work_id',currentArtWorkNft?.art_work_id);
            uploadCertificate(certificateFormData)
        }
    }


    const modalOpen = () =>{
        _modalShow(!modalShow);
        _coords({lat:currentArtWorkNft?.map_address?.latitude,long:currentArtWorkNft?.map_address?.longitude})
    }
    return <>

        <Modal
            show={show}
            centered
            size="lg"
        >
            <Modal.Body>
                <div className="float-end mouse-pointer me-3" onClick={() => openVerifyModal()}>
                    <Image src={cancelVector} height={18} width={18}/>
                </div>

                <div className="container mt-3 p-3 ">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="mt-3">
                            <div className="position-absolute p-2 cardLable">
                                <span>{currentArtWorkNft?.verification_status}</span>
                            </div>
                            <NftFormat type={currentArtWorkNft?.title_image_format} file={currentArtWorkNft?.title_image}/>
                        </div>

                        <div className="mt-5 ">
                            <h4 className="fw-bolder regal-blue-color">{currentArtWorkNft?.title}</h4>
                        </div>
                        <div className="mt-3">
                            <h6 className="fw-bolder regal-blue-color">Attached Files</h6>
                            <div className="d-flex justify-content-between align-items-center flex-wrap" >
                                {currentArtWorkNft?.nft_token?.map((item, index) =>
                                    <div style={{height:'100px',width:'100px'}} key={index}>
                                        <NftFormat type={item.format} file={item?.file} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="mt-3">
                            <h6 className="fw-bolder regal-blue-color">Address</h6>
                            <div className="d-flex justify-content-between align-items-center">
                                <h6>{currentArtWorkNft?.map_address?.address}</h6>
                                <button className="btn-blue" onClick={()=>modalOpen()}>show Map</button>
                            </div>

                        </div>
                        <div className="mt-3 w-75 border-bottom" >
                            <h6 className="fw-bolder regal-blue-color text-center border-bottom mb-2">other Details</h6>
                            <div className="d-flex justify-content-between ">
                                <div className="w-100 p-3 border-end ">
                                    <div className="d-flex justify-content-between align-items-center ">
                                        <div>listing address</div>
                                        <div>{currentArtWorkNft?.address ? currentArtWorkNft?.address : '-'}</div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>building type</div>
                                        <div>{currentArtWorkNft?.buildingType ? currentArtWorkNft?.buildingType : '-'}</div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>dedicated area</div>
                                        <div>{currentArtWorkNft?.area ? currentArtWorkNft?.area : '-'}</div>
                                    </div>
                                </div>
                                <div className="w-100 p-3 border-start">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>construction company</div>
                                        <div>{currentArtWorkNft?.contractor ? currentArtWorkNft?.contractor : '-'}</div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>lowest-highest floor</div>
                                        <div>{currentArtWorkNft?.floorData ? currentArtWorkNft?.floorData : '-'}</div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>Expected move-in date</div>
                                        <div>{currentArtWorkNft?.moveInData ? currentArtWorkNft?.moveInData : '-'}</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <section>
                            <div className="mt-3">
                                <h6 className="fw-bolder regal-blue-color">Quantity</h6>
                                <div className="d-flex justify-content-between align-items-center">
                                    <input
                                        type="text"
                                        className="purchaseModalInputDisable font_color_black"
                                        value={currentArtWorkNft?.quantity}
                                        disabled
                                        readOnly
                                    />
                                </div>
                            </div>
                        </section>
                        <section className="mt-3">
                            <div className="mt-3">
                                <h6 className="fw-bolder regal-blue-color">Royalty</h6>
                                <div className="row border-bottom">
                                    <h6 className="col-5">Percentage</h6>
                                    <h6 className="col-6">Wallet Address</h6>
                                </div>
                                {currentArtWorkNft?.royalty?.map((item,index) =>
                                    <div className="d-flex justify-content-between align-items-center mt-3" key={index}>
                                        <h6>{item?.percentage}</h6>
                                        <h6>{item?.wallet_address}</h6>
                                    </div>)}
                            </div>
                        </section>
                        <div className="mt-3">
                            <h6 className="fw-bolder regal-blue-color">Upload Certificate</h6>
                            {certificateAttached?.length > 0 ?
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="w-100">
                                        {certificateAttached?.map((item,index)=>
                                            <div key={index} style={{height:'45px'}}  className="InputFileName mouse-pointer">
                                                {item?.name}
                                            </div>)}
                                    </div>
                                    <div className="mt-2 ms-2 mouse-pointer" onClick={() => removeCertificate()}>
                                        <h4 className="btn-blue">X</h4>
                                    </div>
                                </div>
                                : <div {...getRootCertificate()}>
                                    <input {...getInputCertificate()}  />
                                    <div style={{height: '50px'}} className="InputFileName mouse-pointer">
                                        <span>upload Certificate</span>
                                    </div>
                                </div>}
                        </div>
                        <div className="mt-3 d-flex justify-content-center align-items-center">
                            {loading ?
                            <button className="btn-blue">
                                Upload <Spinner animation="border" size="sm" className=" ms-2"/>
                            </button>
                                :
                            <button className="btn-blue" onClick={()=>handleSubmit()}>
                                Upload
                            </button>
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        <SweetAlert type={sweetAlert.type} title={sweetAlert.title} onConfirm={() => _alert(s => ({...s, show: false}))}
                    show={sweetAlert.show}/>
        <Loader show={loading}/>
        <OpenMapModal modalOpen={modalOpen} modalShow={modalShow} coords={coords}/>
    </>
}

export default CertificateVerify;