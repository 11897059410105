import React from "react";
import userImage from "../../../images/others/creator.jpg";
import { AiTwotoneHeart} from "react-icons/ai";
import {useNavigate} from "react-router-dom";

const Slide = React.memo(function (StackedCarouselSlideProps) {
    const {
        data,
        dataIndex,
    } = StackedCarouselSlideProps;

    const navigate = useNavigate();
    const cover  = data[dataIndex]?.image;
    const title = data[dataIndex]?.title;
    const likeCount = data[dataIndex]?.totalLikes;
    const creator_nickname = data[dataIndex]?.creator_nickname;
    const user_id = data[dataIndex]?.user_id

    return (
        <div className="realEstateSlider">
            <div className="p-2" style={{background:'white'}}>
                <div style={{marginLeft: '6rem'}}>
                   <span className="recentPrice">
                       Recent Price
                   </span>
                    <span className="priceEth">3201.2 ETH</span>
                </div>
                <div className="d-flex align-items-center justify-content-between text-center mt-3">
                    <div className="d-flex justify-content-center align-items-center" style={{marginLeft: '6rem'}} onClick={() => navigate(`/profile/${user_id}`)}>
                        <img src={userImage} style={{height: '45px', width: '45px'}}
                             className="img-fluid rounded-circle"/>
                        <span className=" text-center priceEth">{creator_nickname}</span>
                    </div>
                    <div style={{marginRight: '6rem'}}>
                        <div className="d-flex justify-content-center align-items-center likeLable mouse-pointer">
                            <AiTwotoneHeart size="20" style={{color: 'red'}}/>
                            <span className="ms-2">{Number(likeCount)}</span>
                        </div>
                    </div>
                </div>
                <div className="realEstateHeader">
                    <h4 className="regal-blue-color fw-bold" style={{marginLeft: '6rem'}}>{title}</h4>
    
                </div>
                <div style={{minHeight:'100%', minWidth:'100%', justifyContent:'center', alignItems:'center', display:'flex'}}>
                {/* style={{maxWidth:'772px', maxHeight:'400px', width: 'auto', height:'auto', objectFit: 'cover', borderRadius: 10, backgroundPosition: 'center'}} */}
                    <img
                        style={{maxWidth:'493px', height:'382px', width: 'auto', objectFit: 'cover', borderRadius: 10, backgroundPosition: 'center'}}
                        draggable={false}
                        src={cover}
                    />
                </div>
            </div>
        </div>
    );
});

export default Slide