import {createSlice,} from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: { ETHAddress: undefined, loggedUser: null, MATICAddress: undefined },
  reducers: {
    setETHAddress: (state, { payload }) => {
      state.ETHAddress = payload
    },
    setMaticAddress: (state, { payload }) => {
      state.MATICAddress = payload
    },
    setLoggedUser: (state, { payload }) => {
      const wallet_type = payload?.wallet_type || [];
      state.loggedUser = { ...state.loggedUser, ...payload };
      state.ETHAddress = wallet_type.find(k => k.wallet_name === 'ETH')?.wallet_key;
      state.MATICAddress = wallet_type.find(k => k.wallet_name === 'MATIC')?.wallet_key;
    },
    logout: (state, { payload }) => {
      state.loggedUser = null
      state.ETHAddress = null
      state.MATICAddress = null
    },
  },
  extraReducers: {

  }
})

export const { setETHAddress, setLoggedUser, setMaticAddress, logout } = userSlice.actions

export default userSlice.reducer
