import React, {useEffect, useState} from 'react';

import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import _ from 'lodash';

import ProfileViewModal from '../../../Modals/profileViewModal';
import UserProfileLayout from '../../../components/UserProfileLayout';
import API from "../../../redux/API";
import Loader from "../../../components/loader";
import MetamaskModal from "../../../Modals/metamaskModal";
import NftFormat from "../../../components/NftFormat";
import {arrangementList, arrangementListWithoutPurchaseApproval, myNftCategoryList} from "../../../utils/config";
import NftSellingModal from "../../../Modals/NftSellingModal";
import {AuctionMemoized} from "../../../components/AuctionTime";
import "./userProfile.css";
import UserNftCard from "../../../components/UserNftCard";
import NftPurchaseModal from "../../../Modals/NftPurchaseModal";
import userNftCard from "../../../components/UserNftCard";
import FollowListModal from "../../../Modals/FollowListModal";
import Pagination from "react-bootstrap/Pagination";
import WithdrawModal from '../../../Modals/WithdrawModal';

function ProfileCard() {
    const userId = useParams();
    const navigate = useNavigate();
    const {loggedUser, ETHAddress} = useSelector(s => s.user);
    const [modalOpen, _modalOpen] = useState(false);
    const [categoryNft, _categoryNft] = useState(myNftCategoryList[0]?.value);
    const [arrangementNft, _arrangementNft] = useState(arrangementList[0]?.value);
    const [myArtwork, _myArtwork] = useState([]);
    const [loading, _loading] = useState(false);
    const [sweetAlert, _alert] = useState({show: false, title: '', type: 'success' || 'error'});
    const [show, _show] = useState(false);
    const [modalArtWorkData, _modalArtWorkData] = useState();
    const [page, _page] = useState(1);
    const [lastPage, _lastPage] = useState(false);
    const [openResell,_openResell] = useState(false);
    const [resellData,_resellData] = useState('');
    const [purchaseData, _purchaseData] = useState("");
    const [purchaseModalOpen, _purchaseModalOpen] = useState(false);
    const [arrangementListOption, _arrangementListOption] = useState([]);
    const [allPurchaseNFT, _allPurchaseNFT] = useState([]);
    const [openFollowListModal,_openFollowListModal] = useState(false);
    const [openWithdrawModal, _openWithdrawModal] = useState(false);
    const [currentPage, _currentPage] =  useState(1);

    useEffect(() => {
        if(userId?.userId && userId?.userId !== loggedUser?.user_id){
            _arrangementListOption(arrangementListWithoutPurchaseApproval);
            getMyNftData(true);
        }else{
            _arrangementListOption(arrangementList);
        }
    },[userId?.userId]);

    useEffect(() => {
        if (loggedUser) {
            if ((!ETHAddress || ETHAddress === 'undefined')) {
                openModal();
            }
        }

        return (() => {
            _show(null);
        });
    }, [loggedUser])

    useEffect(() => {
        if (categoryNft && arrangementNft) {
            getMyNftData();
            _currentPage(1);
        }
    }, [categoryNft,arrangementNft]);


    const openModal = () => {
        _show(!show);
    }

    const loadMoreData = (prev = true) => {

        _page(page + 1);
        loadMorePages()
    }

    const loadMorePages = () => {
        _loading(true);
        API({
            url: `user/art-work/my-mint?page=${currentPage}&limit=9&category=${categoryNft}&arrangement=${arrangementNft}`,
            method: 'GET'
        }).then(async res => {
            // const Id = _.isEmpty(userId) ? loggedUser.user_id : userId.userId;
            // const filter = data?.filter((item)=> item?.current_owner?.current_owner_id === Id);
            _myArtwork(res?.data?.data);
            _lastPage(res.data.lastPage)
        }).catch(err => {

            const message = err.message ? err.message : 'Data Not Found';
            _alert({show: true, type: "error", title: message})
        }).finally(() => {
            _loading(false);
        })
    }

    const getMyNftData = () => {
        _loading(true);
        let getDataUrl ="";
        if(userId?.userId){
            getDataUrl = `user/art-work/my-mint?page=${currentPage}&limit=9&category=${categoryNft}&arrangement=${arrangementNft}&userId=${userId.userId}`;
        }else{
            getDataUrl = `user/art-work/my-mint?page=${currentPage}&limit=9&category=${categoryNft}&arrangement=${arrangementNft}`;
        }

        API({
            url: getDataUrl,
            method: 'GET'
        }).then(async res => {
            // const Id = _.isEmpty(userId) ? loggedUser.user_id : userId.userId;
            // const filter = data?.filter((item)=> item?.current_owner?.current_owner_id === Id);
            _myArtwork(res?.data?.data);
            _lastPage(res.data.lastPage)
        }).catch(err => {

            const message = err.message ?err.message : 'Data Not Found';
            _alert({show: true, type: "error", title: message})
        }).finally(() => {
            _loading(false);
        })
    }

    const showModal = (data) => {
        _modalOpen(!modalOpen);
        _modalArtWorkData(data)
    }

    const setResell = (data) => {
        _openResell(!openResell);
        _resellData(data)

    }

    const closeSweetAlertHandler = (s) => {
        _alert(s => ({ ...s, show: false }));
    }

    const setpurchaseModalOpen = (value,nftDetails) => {
        _purchaseData(nftDetails);
        _purchaseModalOpen(value);
    }

    useEffect(() => {
        getMyNftData();
    },[currentPage])

    return (
        <div className="d-flex flex-wrap user-details-n-nft-area">
            {/*user profile View*/}

            <div className="editprofile-section profilecard-edit-section user-details-part">
                <UserProfileLayout loggedUser={loggedUser} openFollowListModal={() => _openFollowListModal(true)} openWithdrawModal={() => _openWithdrawModal(true)}/>
            </div>

            <div className='d-flex justify-content-start align-items-center flex-wrap user-nft-section'>

                {/*Filter the NFT*/}
                <div className="row mb-4 w-100">
                    <div className="form-group col-sm-6">
                        <label className="form-label robot-font fw-bold regal-blue-color fs-5 mb-3">
                            <span className="red">*</span>NFT arrangement
                        </label>

                        <div className="forms">
                            <select className="form-style mouse-pointer" value={arrangementNft}
                                    onChange={(e) => _arrangementNft(e.target.value)}>
                                {arrangementListOption?.map((item, index) =>
                                    <option key={`${item.value} + ${index}`} value={item.value}>{item.lable}</option>
                                )}

                            </select>
                        </div>
                    </div>
                    <div className="form-group col-sm-6">
                        <label className="form-label robot-font fw-bold regal-blue-color fs-5 mb-3">
                            <span className="red">*</span>Category
                        </label>
                        <div className="forms">
                            <select className="form-style" value={categoryNft}
                                    onChange={(e) => _categoryNft(e.target.value)}>
                                {myNftCategoryList.map((item, index) => <option key={index} value={item.value}>{item.lable}</option>)}

                            </select>
                        </div>
                    </div>
                </div>

                {/*ArtWork Card*/}
                {!loading && myArtwork.length > 0 ? <>
                        {
                            myArtwork.map((item, index) =>{
                                return (
                                    <UserNftCard
                                        key={`userNftCard.${index}`}
                                        item={item}
                                        userId={userId}
                                        nftType="myNft"
                                        showModal={showModal}
                                        setResell={setResell}
                                        openPurchaseModal={setpurchaseModalOpen}
                                    />
                                )})
                        }
                        {!lastPage &&
                            <div className="load-button">
                                <button className="btn-blue"
                                        disabled={lastPage}
                                        onClick={() => loadMoreData( true)}
                                >
                                    Load more
                                </button>
                            </div>}
                    </>
                    : <>

                        <div className="profile-select  d-flex justify-content-center align-items-center flex-column mt-5 ">
                            <h4>More Art-work Data not Found...!</h4>
                        </div>
                    </>
                }
                <br/>
                <div className="col-12 col-lg-12 mt-3 ps-2 ">
                    {!loading ? (
                        <Pagination className="justify-content-center">
                            <Pagination.Item
                                onClick={() => _currentPage(1)}
                                disabled={currentPage === 1}
                            >
                                {" "}
                                First
                            </Pagination.Item>

                            <Pagination.Item
                                onClick={() => _currentPage((p) => p - 1)}
                                disabled={currentPage === 1}
                            >
                                Prev
                            </Pagination.Item>
                            <Pagination.Item disabled> {currentPage}</Pagination.Item>
                            <Pagination.Item
                                onClick={() => _currentPage((p) => p + 1)
                                }
                            >
                                Next
                            </Pagination.Item>
                            <Pagination.Item
                                onClick={() => _currentPage(myArtwork.length)}
                            >
                                End
                            </Pagination.Item>
                        </Pagination>
                    ) : null}
                </div>
            </div>

            <div className="">

                <ProfileViewModal show={modalOpen} showModal={showModal} loggedUser={loggedUser} userId={userId}
                                  modalArtWorkData={modalArtWorkData} navigate={navigate}/>
                <Loader show={loading}/>
                <SweetAlert type={sweetAlert.type} title={sweetAlert.title} onConfirm={() => closeSweetAlertHandler()}
                            show={sweetAlert.show}/>
                <MetamaskModal modalOpen={openModal} modalShow={show}/>
                <NftSellingModal
                    show={openResell}
                    resaleOpenModal={setResell}
                    currentArtWorkNft={resellData}
                    getMyNftData={getMyNftData}
                    loggedUser={loggedUser}
                    userId={userId}
                />
                <NftPurchaseModal
                    show={purchaseModalOpen}
                    nftData={purchaseData}
                    closePurchaseModal={setpurchaseModalOpen}
                    getMyNftData={getMyNftData}
                />
                <FollowListModal
                    show={openFollowListModal}
                    openFollowListModal={_openFollowListModal}
                />
                <WithdrawModal
                    show={openWithdrawModal}
                    openWithdrawModal={_openWithdrawModal}
                />
            </div>
        </div>
    )
}

export default ProfileCard
