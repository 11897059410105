import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
// import { FaMapPin } from 'react-icons/all';
import Geocode from "react-geocode";
import { googleApiKey } from "../../utils/config";
import { GoogleMap, LoadScript, Autocomplete, Marker } from '@react-google-maps/api';
const GoogleMapAddress = ({ modalShow, modalOpen, _coords, coords, _mapAddress, address, ...rest },props) => {
    Geocode.setApiKey(googleApiKey);
    const [permissionModal, _permissionModal] = useState(false);
    const [autocomplete, setAutocomplete] = useState(null);

    useEffect(() => {
        //askPermission();
    }, []);


    const askPermission = () => {
        navigator.permissions.query({ name: "geolocation" }).then(function (result) {
            if (result.state === "granted") {
                getNavigator();
            } else if (result.state === 'prompt') {
                getNavigator();
            } else if (result.state === 'denied') {
                _permissionModal(true);
            }
            result.onchange = function (locationEvent) {
                if (locationEvent.currentTarget.state === 'denied') {
                    _permissionModal(true);
                } else {
                    reloadPage();
                }
            }
        });
    }

    const reloadPage = () => {
        window.location.reload();
    }

    const getNavigator = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((location) => {
                getCurrentAddress(location.coords.longitude, location.coords.latitude);
                _coords({ long: location.coords.longitude, lat: location.coords.latitude });
            });
        }
    }


    const getCurrentAddress = (lat, lng) => {

        Geocode?.fromLatLng(lat, lng).then(
            (response) => {
                const address = response?.results[0]?.formatted_address;
                _mapAddress(address)
            },
            (error) => {
                console.error(error);
            }
        );
    }

    const onLoad = (autocomplete) => {
       setAutocomplete(autocomplete)
     }
 
     
  const  _click = event => {
       console.log(event)
     let latValue = event.latLng.lat(),
         lngValue = event.latLng.lng();
     Geocode.fromLatLng(latValue, lngValue).then(
       response => {
         const address = response.results[0].formatted_address;
         _coords({ lat: latValue, long: lngValue });
         _mapAddress(address ? address : "")
       },
       error => {
         console.error(error);
       });
   };

   const  onPlaceChanged = () => {
    if (autocomplete !== null) {
        var place = autocomplete.getPlace();
        const address = place.formatted_address,
        latValue = place.geometry.location.lat(),
        lngValue = place.geometry.location.lng();
        _coords({ lat: latValue, long: lngValue });
        _mapAddress(address ? address : "")
        // console.log(this.autocomplete.getPlace())
    } else {
        // console.log('Autocomplete is not loaded yet!')
    }
}

    function _onClick(obj) {
        _coords({ lat: obj.lat, long: obj.lng });
        getCurrentAddress(obj.lat, obj.lng);
    }
    const styles = {
        cancelBtn: {
            background: '#162869',
            // padding: 12px 20px;
            textAlign: 'center',
            borderRadius: '5px',
            float: 'right',
            boxShadow: '0px 0px 20px 0px #16286940',
            color: ' #fff',
            textDecoration: 'none',
            fontSize: '16px',
            fontFamily: 'Nunito Sans',
            fontWeight: 600,
            transition: 'all ease 0.2s',
            border: 'none'
        }
    }
    return (
        <>

            <Modal
                show={modalShow}
                centered
            >
                <Modal.Body>
                    <div className="d-flex justify-content-end">
                        <button onClick={() => modalOpen()} style={styles.cancelBtn}>X</button>
                    </div>
                    <div style={{ height: '75vh', width: '100%' }}>
                    <LoadScript  googleMapsApiKey={googleApiKey} libraries={['places']} >
                        <Autocomplete
                            onLoad={onLoad}
                            onPlaceChanged={onPlaceChanged}
                        >
                            <input
                                type="text"
                                placeholder="enter place"
                                className="form-control mt-2 mb-3"
                                defaultValue={address}
                            />
                        </Autocomplete>
                        <GoogleMap
                            mapContainerStyle={{
                                // width: "75vh",
                                height: "90%"
                            }}
                            center={{
                                lat: coords.lat,
                                lng: coords.long
                            }}
                            zoom={12}
                            onClick={_click}
                        >
                            
                            <Marker
                                name={"Dolores park"}
                                draggable={true}
                                onDragEnd={_click}
                                position={{
                                    lat: coords.lat,
                                    lng: coords.long
                                }}
                            />
                        </GoogleMap>
                        
                    </LoadScript>
                </div>

                    {/* <Autocomplete 
                        height="75vh"
                        width="100%"
                        zoom={12}
                        coords={coords}
                        _coords={_coords}
                        _mapAddress={_mapAddress} /> */}
                     {/* <div style={{ height: '75vh', width: '100%' }}>

                        <GoogleMapReact
                            bootstrapURLKeys={{ key: googleApiKey, language: 'ko', region: "ko", libraries: ['places'] }}
                            center={{ lat: Number(coords?.lat), lng: Number(coords?.long) }}
                            defaultZoom={12}
                            draggable
                            onClick={_onClick}
                            yesIWantToUseGoogleMapApiInternals
                        >
                            <Marker lat={coords?.lat} lng={coords?.long} />
                        </GoogleMapReact>
                    </div>  */}

                </Modal.Body>
            </Modal>

            <Modal
                show={permissionModal}
                centered
                size="lg"
            >
                <Modal.Body>
                    <div className="container p-5">
                        <div className="row d-flex justify-content-center align-items-center text-center">
                            <div>
                                <div className="font_color fw-bold" style={{ fontSize: '32px' }}>We need Location
                                    Permission
                                </div>
                                <div className="font10" style={{ lineHeight: 2, color: '#000000' }}>
                                    We Need Location Permission To Create a Real Estate NFT
                                </div>
                                <div className="mt-2">
                                    <ul style={{ lineHeight: 2, color: '#000000', fontSize: '13px' }}>
                                        <li><b>1.</b> Open Chrome settings</li>
                                        <li><b>2.</b> Under Privacy and Security, you can find "Site settings"</li>
                                        <li><b>3.</b> Click on "location" to find the sites you allowed location
                                            permission to.
                                        </li>
                                        <li><b>4.</b> Once Reload the Site</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default GoogleMapAddress;



