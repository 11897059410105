import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import PendingNftCardList from "./pendingNftListCard";

const PendingNftList = () => {

    return <>
        <div>
            <div className="white-bar"/>
            <div className="header-gradient"/>
            <Container>
                <Row>
                    <div className="col-sm-12">
                        <PendingNftCardList/>
                    </div>
                </Row>
            </Container>
        </div>
    </>
}

export default PendingNftList;