import React, {useState, useEffect} from "react";
import {Image, Modal} from "react-bootstrap";
import creatorImg from '../../images/others/creator.jpg';
import vectorImg from '../../images/others/VectorArrow.jpg';
import NftFormat from "../../components/NftFormat";
import {AdminAccountId, ErrorMessage, IPFS_URL, networkID, polyScanUrl, saleCoin} from "../../utils/config";
import '../style.css';
import {UserNickName} from "../../utils/function";
import {Link} from "react-router-dom";
import UserImg from '../../images/others/user-img.png';
import * as yup from "yup";
import API from "../../redux/API";
import {useSelector} from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import _ from 'lodash';
import { BASE_URL } from "../../utils/config";
import axios from "axios";
import { ETH_721_OpenSea_Contract, MATIC_721_OpenSea_Contract, Web3 } from "../../utils/web3";
import MetaMaskHook from "../../hooks/MetaMaskHook";


const ProfileViewModal = (props) => {

    const {show, showModal, userId, modalArtWorkData, navigate} = props;
    const {loggedUser, ETHAddress} = useSelector(s => s.user);
    const [giftBlock, setGiftBlock] = useState(false);
    const [email, setEmail] = useState("");
    const [alert, _alert] = useState({show: false, title: '', type: 'success' || 'error'});
    const [purchasedHistory, setPurchasedHIstory] = useState([]);
    const [nicknameMap, setNicknameMap] = useState();
    const [artWorkId, setArtWorkId] = useState("");
    const {
        isMetaMaskInstall,
        accountId,
        networkVersion,
        isDataLoading,
        isMetaMaskConnected,
      } = MetaMaskHook();
    

    const schema = yup.object().shape({
        email: yup.string().email('Must be a valid email').max(255).required('Email is required'),
    })

    const showGiftBlock = () => {
      if (loggedUser?.kycVerified) {
        setGiftBlock(!giftBlock);
      } else {
        _alert({
          show: true,
          title: "You are not KYC Verified",
          type: "error",
        });
      }
    }

    useEffect(() => {
        
        getPurchaseHistory();
    }, [modalArtWorkData]);

    useEffect(() => {
        
        fetchNicknames();

        }, [purchasedHistory]);


    //get purchase history in axios
    const getPurchaseHistory = async() => {
        if (!modalArtWorkData) {
            return;
        }
    
        //axios call
        const res = await axios.get(BASE_URL+'/user/art-work/purchase-history-by-id?art_work_id='+modalArtWorkData.art_work_id)
        .then(res => {
            let data = res?.data?.data?.data
            console.log("purchase history:",data);
            setPurchasedHIstory(data);
            fetchNicknames();
        }).catch(err => {
            console.log(err);
        })
    }

    //fetch purchase history nicknames
    const fetchNicknames = async () => {
        console.log("fetch nicknames");
        const newNicknameMap = {};
        for (let i = 0; i < purchasedHistory.length; i++) {
        let user_id = purchasedHistory[i]?.user_id;
        if (user_id && !nicknameMap[user_id]) {
            const nickname = await getNicknameById(user_id);
            newNicknameMap[user_id] = nickname;
        }
        }
        setNicknameMap({ ...nicknameMap, ...newNicknameMap });
        
    };

     //get nicknameby id axios
     const getNicknameById = async(id) => {
        try {
            // axios call
            const res = await axios.get(BASE_URL+'/user/get-nickname?user_id='+id);
            const data = res?.data?.data?.data?.nickname;
            console.log("nickname:",data);
            return data;
        } catch (err) {
            console.log(err);
        }
    }

    const sendGift = async (e) => {
      e.preventDefault();

      if (email === loggedUser?.email) {
        _alert({
          show: true,
          title: "You can't enter your self email.",
          type: "error",
        });
        return;
      }

      schema
        .validate({ email })
        .then((value) => {
          try {
            const payload = {
              email,
              art_work_id: modalArtWorkData?.art_work_id,
              id: modalArtWorkData?._id,
              userId: loggedUser?.user_id,
            };
            API({ url: `user/art-work/gift-art`, method: "PUT", data: payload })
              .then(async (data) => {
                if (modalArtWorkData?.sale_coin == 0) {
                  if (data.data.data[0]?.wallet_type[0]?.wallet_key) {
                    if (networkVersion == networkID.ETH) {
                      if (accountId && accountId == ETHAddress) {
                        let walletId =
                          data.data.data[0]?.wallet_type[0]?.wallet_key;
                        let isApproved = false;

                        await ETH_721_OpenSea_Contract.methods
                          .isApprovedForAll(walletId, AdminAccountId)
                          .call(function (err, res) {
                            if (err) {
                              console.log("An error occurred", err);
                              return;
                            }
                            console.log("The balance is: ", res);
                            isApproved = res;
                          });

                        await ETH_721_OpenSea_Contract.methods
                          .sendGift(
                            walletId,
                            modalArtWorkData.nft_token[0]?.token_id
                          )
                          .send({ from: accountId })
                          .then(async (res) => {
                            const payload = {
                              email,
                              art_work_id: modalArtWorkData?.art_work_id,
                              id: modalArtWorkData?._id,
                              userId: loggedUser?.user_id,
                              confirmation: true,
                              isApproved,
                            };

                            API({
                              url: `user/art-work/gift-art`,
                              method: "PUT",
                              data: payload,
                            })
                              .then(async (data) => {
                                _alert({
                                  show: true,
                                  title: data?.data?.message,
                                  type: "success",
                                });
                              })
                              .catch((e) => {
                                _alert({
                                  title: e?.message,
                                  show: true,
                                  type: "error",
                                });
                              });
                          });
                      } else {
                        _alert({
                          show: true,
                          type: "warning",
                          title: ErrorMessage.selectLinkWallet,
                        });
                      }
                    } else {
                      _alert({
                        show: true,
                        type: "warning",
                        title: ErrorMessage.ETH_Network_Version,
                      });
                    }
                  }
                } else if (modalArtWorkData?.sale_coin == 1) {
                  if (data.data.data[0]?.wallet_type[0]?.wallet_key) {
                    if (networkVersion == networkID.MATIC) {
                      if (accountId && accountId == ETHAddress) {
                        let walletId =
                          data.data.data[0]?.wallet_type[0]?.wallet_key;
                        let isApproved = false;

                        await MATIC_721_OpenSea_Contract.methods
                          .isApprovedForAll(walletId, AdminAccountId)
                          .call(function (err, res) {
                            if (err) {
                              console.log("An error occurred", err);
                              return;
                            }
                            console.log("The approval is: ", res);
                            isApproved = res;
                          });

                        await MATIC_721_OpenSea_Contract.methods
                          .sendGift(
                            walletId,
                            modalArtWorkData.nft_token[0]?.token_id
                          )
                          .send({
                            from: accountId,
                            gasPrice: Web3.utils.toWei("250", "gwei"),
                          })
                          .then(async (res) => {
                            const payload = {
                              email,
                              art_work_id: modalArtWorkData?.art_work_id,
                              id: modalArtWorkData?._id,
                              userId: loggedUser?.user_id,
                              confirmation: true,
                              isApproved,
                            };

                            API({
                              url: `user/art-work/gift-art`,
                              method: "PUT",
                              data: payload,
                            })
                              .then(async (data) => {
                                _alert({
                                  show: true,
                                  title: data?.data?.message,
                                  type: "success",
                                });
                              })
                              .catch((e) => {
                                _alert({
                                  title: e?.message,
                                  show: true,
                                  type: "error",
                                });
                              });
                          });
                      } else {
                        _alert({
                          show: true,
                          type: "warning",
                          title: ErrorMessage.selectLinkWallet,
                        });
                      }
                    } else {
                      _alert({
                        show: true,
                        type: "warning",
                        title: ErrorMessage.MATIC_Network_Version,
                      });
                    }
                  }
                }

                // _alert({show: true, title: data?.data?.message , type: 'success'});
              })
              .catch((e) => {
                _alert({ title: e?.message, show: true, type: "error" });
              });
          } catch (e) {
            console.log("Sending gift error : ", e);
          }
        })
        .catch((e) => {
          _alert({
            show: true,
            title: "Please enter valid email.",
            type: "error",
          });
        });
    };

    return (<>

            <Modal
                show={show}
                centered
                onHide={showModal}
            >
                <Modal.Body>
                    <div className="container">
                        <h6 style={{fontSize: '16px', color: '#162869',}}>View</h6>
                        <div className="d-flex justify-content-end align-items-center">
                            <h6 style={{
                                background: '#162869',
                                color: 'white',
                                padding: '5px',
                                borderRadius: '5px',
                                fontSize: '14px',
                                textAlign: 'center'
                            }}>{modalArtWorkData?.form_of_sale ? modalArtWorkData?.form_of_sale : modalArtWorkData?.verification_status}</h6>
                            
                            
                            
                            <h6 style={{fontSize: '16px', color: '#162869', marginLeft: '2px'}}>Now</h6>
                            <h6 style={{fontSize: '16px', color: '#162869', marginLeft: '2px'}}>
                                {modalArtWorkData?.form_of_sale === 'auction' ? `${modalArtWorkData?.auction_start_price} ${modalArtWorkData?.sale_coin == 0 ? 'ETH' : 'MATIC'}`  : `${modalArtWorkData?.sale_price} ${modalArtWorkData?.sale_coin == 0 ? 'ETH' : 'MATIC'}`  } 
                                <br/>
                                {modalArtWorkData?.isPricePublic ? `${modalArtWorkData?.korean_price} KRW` : ''}

                            </h6>
                        </div>
                        <div className="mt-3">
                            <NftFormat file={modalArtWorkData?.title_image}
                                       type={modalArtWorkData?.title_image_format}/>
                        </div>

                        {modalArtWorkData?.current_owner?.current_owner_id === loggedUser?.user_id &&
                            <div className="mt-5">
                                <span className="fw-bolder" style={{color: '#000000'}}>Attached file</span>

                                {modalArtWorkData?.pdfDoc.length > 0 &&
                                    <div className="d-flex justify-content-end mt-2 mouse-pointer mb-3">
                                        <input placeholder={modalArtWorkData?.pdfDoc} disabled
                                               className="InputFileName font_color_black me-2"/>
                                        <button
                                            className="btnDownload modalBtn fw-bolder col-4 text-decoration-none text-center text-white"
                                            onClick={() => window.open(`${IPFS_URL}${modalArtWorkData?.pdfDoc}`)}>Download
                                        </button>
                                    </div>
                                }
                                { 
                                    modalArtWorkData?.files.length && modalArtWorkData?.files?.map((file, index) => {
                                        return <div key={index} className="d-flex justify-content-end mt-2 mouse-pointer mb-3">
                                                <input placeholder={file} disabled
                                                       className="InputFileName font_color_black me-2"/>
                                                <button
                                                    className="btnDownload modalBtn fw-bolder col-4 text-decoration-none text-center text-white"
                                                    onClick={() => window.open(`${IPFS_URL}${file}`)}>Download
                                                </button>
                                            </div>
                                    })
                                }
                            </div>
                        }
                        <div className="mt-5">
                            <div className="row d-flex justify-content-between align-items-center mouse-pointer"
                                 onClick={() => navigate(`/profile/${modalArtWorkData?.creator?.user_id}`)}>
                                <div className="col-2 mouse-pointer">
                                    <Image className="rounded-circle"
                                           src={modalArtWorkData?.creator?.user_profile ? modalArtWorkData?.creator?.user_profile : creatorImg}
                                           height={45} width={45}/>
                                </div>
                                <div className="col-10 d-flex justify-content-between align-items-center"
                                     style={{borderBottom: '0.765818px solid #ACB3CA'}}>
                                    <div>
                                        <h6 className=""
                                            style={{
                                                fontSize: '18px',
                                                color: '#162869',
                                                marginLeft: '15px'
                                            }}>Creator</h6>
                                    </div>
                                    <div className=" d-flex justify-content-center">
                                        <h6 className="me-2">{modalArtWorkData?.creator?.creator_nickname || UserNickName(modalArtWorkData?.creator?.creator_email)}</h6>
                                        <Image src={vectorImg} height={20} width={20}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-between align-items-center mt-3 mouse-pointer"
                                 onClick={() => navigate(`/profile/${modalArtWorkData?.current_owner?.current_owner_id}`)}>
                                <div className="col-2 mouse-pointer">
                                    <Image className="rounded-circle"
                                           src={modalArtWorkData?.current_owner?.current_owner_profile ? modalArtWorkData?.creator?.user_profile : creatorImg}
                                           height={45} width={45}/>
                                </div>
                                <div className="col-10 d-flex justify-content-between align-items-center"
                                     style={{borderBottom: '0.765818px solid #ACB3CA'}}>
                                    <div>
                                        <h6 className=""
                                            style={{fontSize: '18px', color: '#162869', marginLeft: '15px'}}>Owner</h6>
                                    </div>
                                    <div className=" d-flex justify-content-center">
                                        <h6 className="me-2">{modalArtWorkData?.current_owner?.current_owner_nickname || UserNickName(modalArtWorkData?.current_owner?.current_owner_email)}</h6>
                                        <Image src={vectorImg} height={20} width={20}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h4 style={{
                            color: '#162869',
                            fontWeight: 700,
                            marginTop: '35px',
                            borderBottom: '0.765818px solid #ACB3CA'
                        }}>History</h4>
                        <div className="mt-3 p-2 mouse-pointer overflow-scroll">
                            {modalArtWorkData?.purchase_history ?
                                <div style={{height: '180px'}}>
                                   
                                    {purchasedHistory.map((history, index) => {
                                        return (
                                            <Link
                                                to={`/profile/${history?.user_id}`}
                                                key={`purchaseHistory,${index}`}
                                                className="d-flex justify-content-between text-decoration-none"
                                            >
                                                <div className="col-6 d-flex flex-row align-items-center">
                                                    <Image src={history?.user_profile || UserImg} height={45} width={45}/>
                                                    <h6 className="" style={{
                                                        fontSize: '18px',
                                                        color: '#162869',
                                                        marginLeft: '15px'
                                                    }}>{nicknameMap[history?.user_id]}</h6>
                                                </div>
                                                {/* <div className="col-6 d-flex flex-row align-items-center justify-content-end" onClick={() => history?.transactionHash && window.open(`${polyScanUrl.etherCoin}${history?.transactionHash}`)}>
                                                    <Image src={vectorImg} height={20} width={20}/>
                                                </div> */}
                                            </Link>
                                        )
                                    })}
                                </div> :
                                <div className="d-flex justify-content-center align-items-center">
                                    <span className="fw-bolder regal-blue-color">History Not Found</span>
                                </div>}
                        </div>
                        
                           
                                <div>
                                    <button
                                        type="type"
                                        className="btnDownload modalBtn"
                                        onClick={showGiftBlock}
                                    >Send a Gift</button>
                                </div>
                            
                        
                        {
                            giftBlock && (
                                <form className="gift-section mt-4 d-flex flex-column align-items-center" onSubmit={sendGift}>
                                    <input type="email" name="email" onChange={({target}) => setEmail(target.value)} placeholder="type receiver email" />
                                    <button type="submit" className="btnDownload mt-2">Gift</button>
                                </form>
                            )
                        }
                        <div className="d-flex justify-content-center align-items-center">
                            {modalArtWorkData?.form_of_sale === "waiting_for_sale" ? (
                                    <>
                                        <div className="mt-5 col-4 me-2">
                                            <button className="modalBtn bg_white fw-bolder "
                                                    onClick={() => navigate('/marketplace')}>
                                                Go to Listing
                                            </button>
                                        </div>
                                        <div className="mt-5 col-4">
                                            <button className="btnBgBlue modalBtn fw-bolder" onClick={() => showModal()}>
                                                Close
                                            </button>
                                        </div>
                                    </>) :

                                <div className="mt-5 col-4">
                                    <button className="btnBgBlue modalBtn fw-bolder" onClick={() => showModal()}>
                                        Close
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <SweetAlert type={alert.type} title={alert.title} onConfirm={() => _alert(s => ({...s, show: false}))}
                        show={alert.show}/>
        </>
    )
}

export default ProfileViewModal;
