import React, {memo, useEffect} from 'react';
import {Container, Row, Col} from "react-bootstrap";
import ProfileCard from './userProfileCard';
import _ from "lodash";

const UserProfile = () => {


    return <>
        <div>
            <div className="white-bar"/>
            <div className="header-gradient"/>
            <Container>
                <Row>
                    <div className="col-sm-12">
                        <ProfileCard/>
                    </div>
                </Row>
            </Container>
        </div>
    </>
}

const MemoizedUserProfile = memo(UserProfile, (pp, np) => _.isEqual(pp, np));

export default MemoizedUserProfile;