import React, {useEffect, useState} from "react";
import {Container, Row} from "react-bootstrap";
import UserProfileLayout from "../../../components/UserProfileLayout";
import waitingImg from '../../../images/others/waiting.png';
import verifiedImg from '../../../images/others/verified1.png';
import {useNavigate, Link} from "react-router-dom";
import API from "../../../redux/API";
import {useDispatch, useSelector} from "react-redux";
import {kycStatus} from "../../../utils/config";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../../../components/loader";
import {setLoggedUser} from "../../../redux/reducers/user";
import {updateCurrentUser} from "../../../utils/function";
import './kycStatus.css';

const KycStatus = () => {

    const navigate = useNavigate();
    const [alert, _alert] = useState({show: false, title: '', type: 'success' || 'error'});
    const [isLoading, setIsLoading] = useState(false);
    const {loggedUser} = useSelector(s => s.user);
    const dispatch = useDispatch();


    useEffect( () =>{
        return () => {
            setIsLoading(null);
            _alert(null);
        }
    },[]);


    const currentUser = async () => {
        setIsLoading(true);
        await updateCurrentUser(loggedUser)
            .then(async(  res)=> {
                await dispatch(setLoggedUser(res));
            }).catch((err)=>{
                _alert({ show: true, title: err.message, type: 'error' });
            }).finally(()=>{
                setIsLoading(false);
            })
    }

    const stopApplyingVerification = () => {
        setIsLoading(true);
        API({url: '/user/kyc-cancel', method: 'PUT'}).then( async  res => {
            _alert({ show: true, title: res.message, type: 'success' });
            await  currentUser();
        }).catch(err => {
            _alert({ show: true, title: err.message, type: 'error' });
        })
    }

    const deleteVerification = () => {
        setIsLoading(true);
        API({url: '/user/kyc-delete', method: 'DELETE'}).then( async res => {
            _alert({ show: true, title: res.message, type: 'success' });
            setTimeout(async ()=>{
                await  currentUser();
            },2000)
        }).catch(err => {
            _alert({ show: true, title: err.message, type: 'error' });
        })
    }

    const KycUnverified = () => {
        return <div className="d-flex justify-content-center align-items-center flex-column mt-5"
                    style={{height: '70vh'}}>
            <div>
                <div className=" d-flex justify-content-center align-items-center flex-column"
                     style={{height: '100%'}}>
                    <img src={waitingImg} style={{height: '150px', width: '150px'}}/>
                    <div className="mt-2">
                        <span className="statusLable">Apply...</span>
                    </div>
                    <div className="w-50 mt-3 kycDescription">
                                            <span>
                                                KYC Verification is pending.
                                                The personal verification process takes an average of 48 hours.
                                            </span>
                    </div>
                    <div
                        className="mt-5 w-100 d-flex justify-content-center align-items-center">
                        <div>
                            <Link to="/kyc/apply"><button className="btn-blue btn-white">Apply for KYC</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    const KycWaiting = () => {
        return <div className="d-flex justify-content-center align-items-center flex-column mt-5"
                     style={{height: '70vh'}}>
                    <div>
                        <div className=" d-flex justify-content-center align-items-center flex-column"
                             style={{height: '100%'}}>
                            <img src={waitingImg} style={{height: '150px', width: '150px'}}/>
                            <div className="mt-2">
                                <span className="statusLable">Waiting...</span>
                            </div>
                            <div className="w-50 mt-3 kycDescription">
                                            <span>
                                                KYC certification is under review.
                                                The personal verification process takes an average of 48 hours.
                                                If the verification result is not confirmed, please contact the customer center.
                                            </span>
                            </div>
                            <div
                                className="mt-5 w-100 d-flex justify-content-center align-items-center">
                                <div className="me-2">
                                    <button className="btn-blue"  onClick={() => navigate('/profile')}>Go To My NFW</button>
                                </div>
                                <div>
                                    <button className="btn-blue btn-white" onClick={() => stopApplyingVerification()}>Stop applying</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

    }

    const KycVerified = () => {
        return <div className="d-flex justify-content-center align-items-center flex-column mt-5"
                    style={{height: '70vh'}}>
            <div>
                <div className=" d-flex justify-content-center align-items-center flex-column"
                     style={{height: '100%'}}>
                    <img src={verifiedImg} style={{height: '150px', width: '150px'}}/>
                    <div className="mt-2">
                        <span className="statusLable">You are Verified!</span>
                    </div>
                    <div className="w-50 mt-3 kycDescription">
                        <span>I am a KYC certified customer.</span>
                    </div>
                    <div
                        className="mt-5 w-100 d-flex justify-content-center align-items-center">
                        <div className="me-2 ">
                            <button className="btn-blue w-75" onClick={() =>deleteVerification()}>Delete verification information</button>
                        </div>
                        <div className="">
                            <button className="btn-blue btn-white" onClick={() => navigate('/profile')}>Go To My NFW </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    return <>
        <div>
            <div className="white-bar"/>
            <div className="header-gradient"/>
            <Container>
                <Row className="container-fluid">
                    <div className="col-sm-12">
                        <section>
                            <div className="editprofile-section profilecard-edit-section">
                                <UserProfileLayout loggedUser={loggedUser}/>
                            </div>
                        </section>
                        <section className="mt-5">
                            <div className="ms-5">
                                <span className="ms-5 kycHeader">My KYC</span>
                            </div>
                            {loggedUser?.kycVerified ?
                                <KycVerified/>
                                : (!loggedUser?.kycVerified && loggedUser?.kyc_status === kycStatus.waiting) ?
                                        <KycWaiting/>
                                    :
                                        <KycUnverified/>
                            }
                        </section>
                    </div>
                </Row>
            </Container>
        </div>
        <SweetAlert type={alert.type} title={alert.title} onConfirm={() => _alert(s => ({ ...s, show: false }))} show={alert.show} />
        <Loader show={isLoading} />
    </>
}

export default KycStatus;
