import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import Layout from "./components/Layout/Layout";
import { setLoggedUser } from "./redux/reducers/user";

function App() {
  const [loaded, _loaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const local = localStorage.getItem("user");
    if (local) {
      dispatch(setLoggedUser(JSON.parse(local)));
    }
    _loaded(true);
  }, []);

  return (
    <Router>
      <ScrollToTop />
      {loaded && (
        <Layout>
          <AppRoutes />
        </Layout>
      )}
    </Router>
  );
}

export default App;

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
