import React, {useEffect, useState} from "react";
import {Dropdown, OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";
import API from "../../redux/API";

const Notification = ({notificationData,setMarked,loading}) => {



    return <>
        <div>
                {loading ?
                    <div className="d-flex justify-content-center align-items-center" style={{height:'100px'}}>
                        <Spinner animation="border" size="md" className="ms-2 regal-blue-color"/>
                    </div>
                    :notificationData?.length > 0 ?
                        notificationData?.map((item, index) =>
                            <Dropdown.Item eventKey={index} key={index} onClick={() => setMarked(item?.notification_id)}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="fw-bold regal-blue-color">{item?.content}</h6>
                                </div>
                            </Dropdown.Item>
                        )
                        :
                        <div className="p-2 text-center">
                            <span className="fw-bold regal-blue-color">No Notifications found</span>
                        </div>
                }
        </div>
    </>
}

export default Notification;