import {Web3} from './web3';

export const ethEnabled = async () => {
    if (window.ethereum) {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        return true;
    }
    return false;
}


export const changeNetwork = async () => {
    if (window.ethereum) {
        await window.ethereum.request({method: 'wallet_switchEthereumChain', params: [{chainId: '0xf00'}]});
        return true;
    }
    return false;
}

export const getBalance = async (walletAddress) => {
    if (Web3) {
        return await Web3.eth.getBalance(walletAddress)
    }
    return false;
}
