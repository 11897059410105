import {React, useState, useEffect} from 'react';
import './userNftCard.css';
import {AuctionMemoized} from "../AuctionTime";
import NftFormat from "../NftFormat";
import _ from "lodash";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import { BASE_URL } from "../../utils/config";
import axios from "axios";


const UserNftCard = ({item, userId = {}, nftType, showModal , openVerifyModal, resaleOpenModal, setResell, openPurchaseModal}) => {

    const [timesSold, setTimesSold] = useState(0);

    const navigate = useNavigate();
    const {loggedUser, ETHAddress} = useSelector(s => s.user);

    useEffect(() => {
        getPurchaseHistory();
    }, []);


    //get purchase history in axios
    const getPurchaseHistory = async() => {
        

        //axios call
        const res = await axios.get(BASE_URL+'/user/art-work/purchase-history-by-id?art_work_id='+item?.art_work_id)
        .then(res => {
            let data = res?.data?.data?.data
            console.log(data);
            let length = data.length;
            setTimesSold(length);
        }).catch(err => {
            console.log(err);
        })
    }

    return(
        <>
            <div className="profile-card pb-3 mouse-pointer">
                <div className="position-relative"  onClick={() => navigate(`/art-work/details/${item?.art_work_id}/${item?._id}`)}>
                    {item?.form_of_sale === "waiting_for_sale" ? (
                        <span className="nft-status-waitingForSale position-absolute bg-white rounded text-sm fw-bold mt-3 ms-3 px-2">Waiting for Sale</span>
                    ) : (<span className="nft-status-onSale position-absolute rounded text-sm fw-bold mt-3 ms-3 px-2 text-white">On Sale</span>)}
                    {item?.form_of_sale === 'auction' &&
                        <div className="artwork-calendar mt-2 ms-2">
                            <AuctionMemoized auction_End_Time={item?.auction_end_time} isArtWorkDetails={true}   />
                        </div>}
                    <div className="p-2" style={{height:'280px',width:'100%'}}>
                        <NftFormat type={item?.title_image_format} file={item?.title_image}/>
                    </div>
                </div>
                <div style={{padding: '10px'}}>
                    <div>
                        <p className='fw-bold' style={{margin: 0}}>{item?.title}</p>
                    </div>
                    <div>
                        {/* {timesSold > 0 && <p className='fw-light fs-6' style={{margin: 0}}>Sold {timesSold} Time(s)</p>} */}
                        {timesSold > 0 && <p className='fw-light fs-6' style={{margin: 0}}>{timesSold}</p>}
                    </div>
                   
                </div>
                <div className="d-flex justify-content-between align-items-center p-2">
                    {/*<div>*/}
                    {/*    <span className="fw-bold regal-blue-color">{item?.form_of_sale ? item?.form_of_sale : item?.verification_status}</span>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    {item?.form_of_sale !== 'waiting_for_sale' && <p style={{margin: 0}}> {item?.pending === "false" && item?.form_of_sale === 'auction' ?*/}
                    {/*        `${item?.auction_start_price} ETH` : `${item?.sale_price} ETH`}</p>}*/}
                    {/*</div>*/}
                    {
                        item?.real_estate_type === false ? (
                            <>
                                <div><span className="fw-bold regal-blue-color">{item?.form_of_sale}</span></div>
                                <div>
                                    {item?.form_of_sale !== 'waiting_for_sale' && <p style={{margin: 0}}> {item?.form_of_sale === 'fixed_price' ?
                                        `${item?.sale_price || ""} ${item?.sale_coin == 1 ? 'Matic': 'ETH'}` : `${item?.auction_start_price || ""} ${item?.sale_coin == 1 ? 'Matic': 'ETH'}` }</p>}
                                </div>
                            </>
                        ) : (
                            <>
                                <div><span className="fw-bold regal-blue-color">{item?.verification_status}</span></div>
                                <div>
                                        <p style={{margin: 0}}> {item?.pending === false && item?.form_of_sale === 'fixed_price' && `${item?.sale_price} ${item?.sale_coin == 1 ? 'Matic': 'ETH'}`}</p>
                                </div>
                            </>
                        )
                    }
                </div>

                <div className="d-flex justify-content-around align-items-center p-2">
                    <div className="d-flex justify-content-between">
                        <button className="btn-blue-view " onClick={() => showModal(item)}>View</button>
                    {(loggedUser?.user_id === userId.userId || _.isEmpty(userId)) &&
                        <>
                            {
                                nftType === "myNft" ? (
                                    <>

                                        {
                                            item?.form_of_sale === "waiting_for_sale" ? <button className="btn-blue-view ms-2" onClick={() => setResell(item)}>Resell</button>
                                                : item?.pending === true && <button className="btn-blue-view ms-2" onClick={() => navigate('/pending-nft')}>Set art work</button>
                                        }
                                    </>
                                )
                                    :
                                    item?.verification_status === "verified" ?
                                        <button className="btn-blue-view ms-2" onClick={() => resaleOpenModal(item)}>set Art Work</button>
                                        : item?.verification_status === "rejected" ? <button className=" btn-blue-view ms-2" onClick={() => openVerifyModal(item)}>Verify Again</button>
                                            : <span className="statusLablePendingNft">{item?.verification_status}</span>
                            }
                            {
                                item?.purchase_approval === "approved" && item?.form_of_sale !== "waiting_for_sale" && (
                                    <button className="btn-blue-view ms-2" onClick={() => openPurchaseModal(true,item)}>Purchase</button>
                                )
                            }
                        </>
                    }
                    </div>
                </div>
            </div>
        </>
    )
};

export default UserNftCard;