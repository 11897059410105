
import React, { memo } from 'react';
import { Modal, Spinner } from 'react-bootstrap';

const Loader = ({ show }) => {
  return (
    show ?
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        centered
        contentClassName={'loading'}
      >
        <Spinner animation="grow" style={{ alignSelf: 'center' }} />
      </Modal>
      : null
  )
}

export default memo(Loader);
