import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import cancelVector from "../../../images/others/Vector.png";
import { Image, Spinner } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../../../components/loader";
import TermsModal from "../../../components/TermsModal/TermsModal";
import API from "../../../redux/API";
import "../../style.css";
import { useNavigate, useParams } from "react-router-dom";
import { contractType } from "../../../utils/config";

const NftPurchaseModal = ({
  modalPurchase,
  showPurchaseModal,
  realEstateData,
  closePurchaseModel,
  transactionFee,
}) => {
  const styles = {
    ethImage: {
      marginTop: "-100px",
      marginLeft: "90%",
    },
    lineHeight: {
      lineHeight: 2,
    },
    ethText: {
      marginTop: "-54px",
      marginLeft: "86%",
      color: "#A9DEEE",
    },
    ethTextFee: {
      marginTop: "-35px",
      marginLeft: "88%",
      color: "#A9DEEE",
    },
    ethTextAuction: {
      marginTop: "-35px",
      marginLeft: "88%",
      color: "#A9DEEE",
    },
    inputPadding: {
      paddingRight: "60px",
    },
  };

  const navigate = useNavigate();
  const { id } = useParams();
  const [checked, _checked] = useState(true);
  const [sweetAlert, _alert] = useState({
    show: false,
    title: "",
    type: "success",
  });
  const [loading, _loading] = useState(false);
  const [show, _show] = useState(false);
  const [totalPrice, _totalPrice] = useState(realEstateData?.sale_price);
  const [quantity, _quantity] = useState(1);
  const feesData = {
    usage: realEstateData?.adminFeeData?.data?.usage || false,
    admin_fee: realEstateData?.adminFeeData?.data?.fees?.admin_fee || 0,
  };

  const handleChange = () => {
    _checked(!checked);
  };

  const purchaseRequest = async () => {
    if (realEstateData.contract_type === contractType.erc1155) {
      // for type 1155 nft
      const payload = {
        quantity: quantity,
      };
      if (
        Number(quantity) <= Number(realEstateData.quantity) &&
        Number(quantity) > 0
      ) {
        _loading(true);
        API({
          url: `user/art-work/purchase/request?art_work_id=${realEstateData?.art_work_id}&id=${id}`,
          method: "POST",
          data: payload,
        })
          .then(({ data }) => {
            _alert({
              show: true,
              type: "success",
              title: "Artwork purchased Successfully",
            });
            setTimeout(() => {
              closePurchaseModel();
              navigate("/profile");
            }, 2000);
          })
          .catch((error) => {
            _alert({ show: true, type: "error", title: error?.message });
          })
          .finally(() => {
            _loading(false);
          });
      } else {
        _alert({
          show: true,
          type: "warning",
          title: `Please enter appropriate Quantity`,
        });
      }
    } else {
      // for type 721 nft
      _loading(true);
      API({
        url: `user/art-work/purchase/request?art_work_id=${realEstateData?.art_work_id}&id=${realEstateData?._id}`,
        method: "POST",
      })
        .then(({ data }) => {
          _alert({
            show: true,
            type: "success",
            title: "Artwork purchased Successfully",
          });
          setTimeout(() => {
            closePurchaseModel();
            navigate("/profile");
          }, 2000);
        })
        .catch((error) => {
          _alert({ show: true, type: "error", title: error?.message });
        })
        .finally(() => {
          _loading(false);
        });
    }
  };

  const handleSubmit = async () => {
    if (checked) {
      await purchaseRequest();
    } else {
      _alert({
        show: true,
        title: "please select The Agree button",
        type: "warning",
      });
    }
  };

  const openTermCondition = () => {
    _show(!show);
  };

  const setTotalPrice = ({ target }) => {
    _quantity(target.value);
    if (feesData.usage) {
      _totalPrice(
        Number(target.value) * Number(realEstateData?.sale_price) +
          feesData.admin_fee
      );
    } else {
      _totalPrice(Number(target.value) * Number(realEstateData?.sale_price));
    }
  };

  return (
    <>
      <Modal show={showPurchaseModal} centered>
        <Modal.Body>
          <div
            className="float-end mouse-pointer me-3"
            onClick={() => modalPurchase()}
          >
            <Image src={cancelVector} height={18} width={18} />
          </div>
          <div className="container mt-3 p-3">
            <div className="row d-flex justify-content-center align-items-center">
              <div>
                <p
                  className="font_color fw-bolder text-center "
                  style={{ fontSize: "22px" }}
                >
                  Purchase request (Fixed Price)
                </p>
                <div className="col-12 text-start">
                  <span
                    className="font10 font_color_black "
                    style={styles.lineHeight}
                  >
                    When applying for a purchase, contact is made after delivery
                    of the application to the seller, and the NFT transaction is
                    completed after purchase consultation. Therefore, please
                    agree to the terms and conditions below regarding
                    information transfer.
                  </span>
                </div>
              </div>
              <div className="mt-5">
                <div>
                  <span className="fw-bolder font_color_black">Price</span>
                  <input
                    className="purchaseModalInputDisable font_color_black text-end"
                    value={realEstateData?.sale_price}
                    disabled
                    style={styles.inputPadding}
                  />
                  <h6 style={styles.ethTextAuction}>{realEstateData?.sale_coin == 1 ? 'Matic': 'ETH'}</h6>
                </div>
                {realEstateData?.contract_type === contractType.erc1155 && (
                  <div className="mt-5">
                    <span className="fw-bolder font_color_black">Quantity</span>
                    <input
                      type="number"
                      placeholder="Quantity"
                      className="purchaseModalInput font_color_black text-end"
                      style={styles.inputPadding}
                      value={quantity}
                      onChange={setTotalPrice}
                    />
                  </div>
                )}
                {feesData?.usage && (
                  <div className="mt-5">
                    <span className="fw-bolder font_color_black">Fee</span>
                    <input
                      placeholder="Fees"
                      className="purchaseModalInputDisable font_color_black text-end"
                      style={styles.inputPadding}
                      value={feesData.admin_fee || 0}
                      disabled
                    />
                    <h6 style={styles.ethTextFee}>{realEstateData?.sale_coin == 1 ? 'Matic': 'ETH'}</h6>
                  </div>
                )}

                <div className="mt-5 d-flex justify-content-between">
                  <div className="fw-bolder font_color_black">Total</div>
                  <span>
                    <span>{totalPrice || 0}</span>
                    <span style={{ color: "rgb(169, 222, 238)" }}> {realEstateData?.sale_coin == 1 ? 'Matic': 'ETH'}</span>
                  </span>
                </div>
                <div className="mt-5 d-flex flex-row mouse-pointer">
                  <input
                    type="radio"
                    name="radio"
                    className="inputRadio"
                    style={{ height: 20, width: 20 }}
                    checked={checked}
                    onChange={() => handleChange()}
                  />
                  <p
                    className="fw-bolder ms-2 font_color_black"
                    onClick={() => handleChange()}
                  >
                    Agree
                  </p>
                </div>
                <div
                  className="mt-2 purchaseModalInput font_color_black d-flex justify-content-start align-items-center mouse-pointer"
                  onClick={() => openTermCondition()}
                >
                  <span className="">Terms and Conditions</span>
                </div>
              </div>
              <div className="mt-5">
                {loading ? (
                  <button className="btnBgBlue modalBtn fw-bolder">
                    Purchase Approval{" "}
                    <Spinner animation="border" size="sm" className=" ms-2" />
                  </button>
                ) : (
                  <button
                    className="btnBgBlue modalBtn fw-bolder"
                    onClick={() => handleSubmit()}
                  >
                    Purchase Approval
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <SweetAlert
        type={sweetAlert.type}
        title={sweetAlert.title}
        onConfirm={() => _alert((s) => ({ ...s, show: false }))}
        show={sweetAlert.show}
      />
      <Loader show={loading} />

    </>
  );
};

export default NftPurchaseModal;
