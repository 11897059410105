import axios from "axios";
import { BASE_URL } from "../utils/config";
import store from '../redux/store';
import { logout } from "./reducers/user";

const API = async ({ url, data = {}, method = 'POST', isFormdata = false, passToken = true }) => {

  const googleSignOut = () => {
    const auth2 = window?.gapi?.auth2?.getAuthInstance()
    if (auth2 != null) {
      auth2.signOut().then((r) => {
        auth2.disconnect()
      }).catch(er => {
      })
    }
  }

  const getUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : undefined;
  let headers = { 'Content-Type': 'application/json', 'Cache-Control': 'No-Cache' };

  if (isFormdata) {
    headers = { 'Content-Type': 'multipart/form-data', 'Cache-Control': 'No-Cache' }
  }
  if (getUser?.accessToken && passToken) {
    headers = { ...headers, 'Authorization': `Bearer ${getUser?.accessToken}` }
  }
  return new Promise((resolve, reject) => {
    axios({
      url,
      method,
      baseURL: BASE_URL,
      data,
      headers,
      timeout: 300 * 1000
    }).then(res => {
      if (res && res.data)
        resolve(res.data);
      else
        resolve(res)
    }).catch(error => {
      if (error.response) {
        if ([401, 410].includes(error?.response?.status)) {
          googleSignOut()
          store.dispatch(logout());
          localStorage.removeItem('user');
          setTimeout(() => {
            window.location.replace('/login')
          }, 2000);
        }
        reject(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        reject(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        reject(error)
      }
    })
  })

}

export default API
