import React, {memo} from "react";
import {IPFS_URL} from "../../utils/config";
import _ from "lodash";

const videoWrapper = {
    minWidth: "185px",
};

const NftFormat = ({
                       type,
                       views,
                       file,
                       isMintCreation = false,
                       video = {height: "100%", width: "100%"},
                   }) => {


    const imageRegex = new RegExp("image*");
    const audioRegex = new RegExp("audio*");
    const videoRegex = new RegExp("video*");
    const pdfRegex = new RegExp("pdf*");
    const url = isMintCreation ? URL.createObjectURL(file) : `${IPFS_URL}${file}`;
    return (
        <>
            {imageRegex.test(type) ? (
                <div style={{ position: 'relative' }}>
                    {views == null ? null : (<div style={{
                        position: 'absolute',
                        zIndex: '3',
                        background: 'rgba(255,255,255,0.3)',
                        color: 'white',
                        padding: '1%',
                        borderBottomRightRadius: '20%'
                    }}>
                        <span className="">Views: {views}</span>
                    </div>)}
                    <div>
                        <img
                            src={url}
                            alt="img"
                            className="img-fluid"
                            style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                display: 'flex',
                                width: 'auto',
                                height: 'auto',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        />
                    </div>
                </div>
            ) : audioRegex.test(type) ? (
                <div
                    className="d-flex justify-content-center align-items-center audioBackground p-2"
                    style={{
                        height: "100%",
                        width: "100%",
                        background: "#EEF2FF",
                        position: "relative",
                    }}
                >
                    <audio
                        controls
                        className="inquiry-box-shadow"
                        style={{width: "100%"}}
                    >
                        <source src={url} type="audio/mpeg"/>
                    </audio>
                </div>
            ) : videoRegex.test(type) ? (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={videoWrapper}
                >
                    <video style={{width: "100%"}} controls>
                        <source src={url}/>
                    </video>
                </div>
            ) : pdfRegex.test(type) ? (
                <div style={{height: "100%", width: "100%"}}>
                    <embed src={url} width="150px" height="100%"/>
                </div>
            ) : (
                <span>{file?.name}</span>
            )}
        </>
    );
};

const MemoizedNftFormat = memo(NftFormat, (pp, np) => _.isEqual(pp, np));

export default MemoizedNftFormat;
