import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Spinner, Ratio } from 'react-bootstrap';
import Creatable from 'react-select';
import CloseIcon from '../../images/others/close-icon.svg';
import SendIcon from '../../images/others/send-icon.svg';
import { useDropzone } from 'react-dropzone';
import { FieldArray, Formik } from 'formik';
import * as yup from 'yup';
import { AdminAccountId, ErrorMessage, networkID, META_DATA_URL } from '../../utils/config';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { utils } from 'ethers';
import SweetAlert from 'react-bootstrap-sweetalert';
import { ethEnabled } from '../../utils/web3.function';
import MetaMaskHook from '../../hooks/MetaMaskHook';
import { useSelector } from 'react-redux';
import Loader from '../../components/loader';
import { useNavigate } from 'react-router-dom';
import {
  Eth_721_Contract,
  Eth_1155_CONTRACT,
  MATIC_721_Contract,
  MATIC_1155_Contract,
  Web3,
  ETH_721_OpenSea_Contract,
  ETH_1155_OpenSea_Contract,
  MATIC_721_OpenSea_Contract,
  MATIC_1155_OpenSea_Contract,
} from '../../utils/web3';
import {create} from "ipfs-http-client";
import API from '../../redux/API';
import GoogleMapAddress from '../../Modals/GoogleModal';
import KycModal from '../../Modals/NftDetails/kycModal';
import {
  endTime,
  nftType,
  form_of_sale,
  formType,
  saleCoin,
  mintNftType,
} from '../../utils/config';
import NftFormat from '../../components/NftFormat';
import './style.css';
import MemoizedNftFormat from '../../components/NftFormat';
import CreatableSelect from 'react-select/creatable';
import axios from "axios";

const MintNFT = () => {
  const [maxRoyalties, _maxRoyalties] = useState(99);
  const [uploadLimit, _uploadLimit] = useState(10 * 1024 * 1024);

  const [category, setCategory] = useState('');

  const styles = {
    error: {
      color: 'tomato',
      fontWeight: 600,
      fontSize: '12px',
    },
    resize: {
      height: '150px',
      width: '150px',
    },
  };

  let defaultValues = {
    normal: {
      formType: formType[0]?.value,
      nftType: nftType[0]?.value,
      saleCoin: saleCoin[0].id,
      form_of_sale: form_of_sale[0]?.value,
      title: '',
      art_work_category: '',
      royalties: [{ royalty: 1, walletAddress: '' }],
      quantity: 1,
      sale_price: 0.001,
      auction_end_hours: endTime[0].value,
      auction_start_price: 0.001,
      description: '',
      url: ''
    },
    real_estate: {
      formType: '',
      nftType: '',
      saleCoin: saleCoin[0].id,
      form_of_sale: form_of_sale[0]?.value,
      title: '',
      address: '',
      type: 'house',
      area: '',
      company: '',
      floor: '',
      date: '',
      description: '',
      royalties: [{ royalty: 1, walletAddress: '' }],
      quantity: '',
      sale_price: '',
      auction_end_hours: endTime[0].value,
      auction_start_price: 0.001,
      longitude: '',
      latitude: '',
    },
  };

  yup.addMethod(yup.array, 'maxArray100', function (key, errorMessage) {
    return this.test(`max-array-100`, errorMessage, function (value) {
      const { createError } = this;
      return value &&
        value.reduce((sum, tax) => sum + tax?.royalty, 0) > maxRoyalties
        ? createError({
            path: key,
            message: errorMessage,
          })
        : true;
    });
  });

  yup.addMethod(yup.array, 'uniqueWallet', function (message) {
    return this.test('unique', message, function (list) {
      const mapper = (x) => x.walletAddress;
      const set = [...new Set(list.map(mapper))];
      const isUnique = list.length === set.length;
      if (isUnique) {
        return true;
      }

      const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
      return this.createError({
        path: `royalties[${idx}].walletAddress`,
        message: message,
      });
    });
  });

  const arraySchema = yup
    .array()
    .maxArray100(
      'royalties.maxArray',
      `Sum of all the royalty should not be more than ${maxRoyalties}.`
    )
    .of(
      yup.object().shape({
        royalty: yup
          .number()
          .required('Royalty is required')
          .positive('Royalty must be in positive number')
          .integer('Royalty must be integer number')
          .min(0, 'Royalty must be greater than or equal to 1')
          .max(99, 'Royalty must be less than or equal to 99'),
        walletAddress: yup
          .string()
          .required('wallet address is required')
          .test(
            'valid address',
            'please enter valid wallet address',
            function (value) {
              return value ? utils.isAddress(value) : true;
            }
          ),
      })
    )
    .uniqueWallet('wallet address must be unique');

  let validationSchema = {
    normal: {
      title: yup
        .string()
        .required('Title is required')
        .max(20, 'Title must smaller than 20 character'),
      description: yup.string().required('Description is required'),
      formType: yup.string().required('Form type is required'),
      art_work_category: yup.string().required('NFT Category is required'),
      quantity: yup
        .number()
        .typeError('Quantity should be number')
        .required('Quantity is required')
        .min(1, 'Minimum quantity should be 1'),
      auction_start_price: yup
        .string()
        .test(
          'valid auction_start_price',
          'The field should have 3 decimals only',
          function (value) {
            return value.match(/^(\d*\.{0,1}\d{0,3}$)/);
          }
        ),
      sale_price: yup
        .string()
        .test(
          'sale_price',
          'The field should have 3 decimals only',
          function (value) {
            return value.match(/^(\d*\.{0,1}\d{0,3}$)/);
          }
        ),
      royalties: arraySchema,
    },
    description: yup.string().required('Title is required'),
    real_estate: {
      title: yup
        .string()
        .required('title is required')
        .max(20, 'Title must smaller than 20 character'),
      description: yup.string().required('Description is required'),
      formType: yup.string().required('Form type is required'),
      royalties: arraySchema,
      address: yup.string().required('Address is required'),
      quantity: yup.string().required('Quantity is required'),
      auction_start_price: yup
        .string()
        .test(
          'valid auction_start_price',
          'The field should have 3 digits only',
          function (value) {
            return value.match(/^(\d*\.{0,1}\d{0,3}$)/);
          }
        ),
    },
  };

  const navigate = useNavigate();
  const {
    isMetaMaskInstall,
    accountId,
    networkVersion,
    isDataLoading,
    isMetaMaskConnected,
  } = MetaMaskHook();
  const { ETHAddress, loggedUser } = useSelector((s) => s.user);
  const [selectNftType, _selectNftType] = useState(nftType);
  const [titleImageFileTypes] = useState([
    'image/jpeg', // for jpg/jpeg images
    'image/png', // for png images
    'image/gif', // for gif images
    'image/webp', // for webp images
  ]);
  const [attachedFileTypes, setFileTypes] = useState([
    'image/jpeg', // for jpg/jpeg images
    'image/png', // for png images
    'image/gif', // for gif images
    'audio/mpeg', // for mp3 audio
    'video/mp4', // for mp4 video
    'image/webp', // for webp images
    'application/msword', // for .doc extension
    'application/vnd.ms-powerpoint', // for ppt extension
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // for pptx extension
    'video/x-msvideo', // for .avi images
    'video/quicktime', // for .mov extension
    'application/pdf', // for pdf file
    'application/epub+zip', // for epub file
    'application/x-hwp', // for hwp file
  ]);
  const [initialValues, setInitialValues] = useState(defaultValues.normal);
  const [isLoading, setIsLoading] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [fileAttached, _fileAttached] = useState([]);
  const [certificateAttached, _certificateAttached] = useState([]);
  const [formValidation, _formValidation] = useState(
    yup.object().shape(validationSchema.normal)
  );
  const [alert, _alert] = useState({
    show: false,
    title: '',
    type: 'success' || 'error',
  });
  const [artCategory, _artCategory] = useState([]);
  const [show, _show] = useState(false);
  const [showKyc, _showKyc] = useState(false);
  const [coords, _coords] = useState({ long: '', lat: '' });
  const [mapAddress, _mapAddress] = useState('');
  const realEstateAddressType = [
    { value: 'house', label: 'House' },
    { value: 'building', label: 'Building' },
  ];
  const videoRegex = new RegExp('video*');
  const audioRegex = new RegExp('audio*');
  const [mintNftType, _setMintNftType] = useState(saleCoin);

  const projectId='2DQoPRDNXBhgKUnCawZZ3QdWcKb'
  const projectSecret='a2f00eee820e18edb7814370a6edc2f5'
  const projectIdAndSecret = `${projectId}:${projectSecret}`

  async function ipfsClient() {
    const ipfs = await create({ 
      host: "ipfs.infura.io", 
      port: "5001", 
      protocol: "https",
      headers: {
        "Authorization": `Basic ${Buffer.from(projectIdAndSecret).toString("base64")}`
      }
    });
   
    return ipfs;
  }
  
  const openModal = () => {
    _show(!show);
  };

  const openKycModal = () => {
    _showKyc(!showKyc);
  };

  useEffect(() => {
    getRoyaltyData();
    getUploadLimit();
    API({ url: 'user/art-work/category?page=1&limit=10', method: 'GET' })
      .then((res) => {
        let arr = [];
        res.data?.filter((val) => {
          if (val.usage == '사용함') {
            arr.push(val);
          }
        });
        _artCategory(arr);
      })
      .catch((err) => {
        _alert({ show: true, title: err?.message, type: 'error' });
      });
  }, []);

  useEffect(() => {
    if (artCategory.length > 0) {
      defaultValues = {
        normal: {
          ...defaultValues.normal,
          art_work_category: artCategory[0]?.id,
        },
      };
      setInitialValues(defaultValues.normal);
    }

  }, [artCategory]);

  useEffect(() => {
    _formValidation(yup.object().shape(validationSchema.normal));
  }, [_maxRoyalties]);

  const getRoyaltyData = () => {
    API({ url: 'user/art-work/total-royalty', method: 'GET' })
      .then((res) => {
        _maxRoyalties(res?.data?.royalty);
      })
      .catch((err) => {
        _alert({ show: true, title: err?.message, type: 'error' });
      });
  };

  const getUploadLimit = () => {
    API({ url: 'user/art-work/upload-limit', method: 'GET' })
      .then((data) => {
        _uploadLimit(data?.data?.upload_limit);
      })
      .catch((e) => console.log('Error : ', e));
  };
  const typeValidator = (file) => {
    if (file.size > uploadLimit * 1024 * 1024) {
      _alert({
        show: true,
        type: 'warning',
        title: 'Your File size is larger!...',
      });
      return {
        code: 'size-too-large',
        message: `file size is larger than ${uploadLimit}`,
      };
    }
    return null;
  };

  //Nft upload Function
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length === 1) {
        setMyFiles([...acceptedFiles]);
      } else {
        _alert({
          show: true,
          type: 'warning',
          title: 'Please Select Valid  File!...',
        });
      }
    },
    [myFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: category == 4 ? attachedFileTypes : titleImageFileTypes,
    maxFiles: 10,
    minSize: 1,
    maxSize: 100 * 1024 * 1024,
    validator: typeValidator,
    onDrop,
  });

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  //File Attached Function
  const attachedFiles = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 5) {
        _alert({
          show: true,
          type: 'warning',
          title: 'Attached File Accept Only Five Attachment!...',
        });
      } else {
        _fileAttached([...acceptedFiles]);
      }
    },
    [fileAttached]
  );

  const {
    getRootProps: getRootAttachedFile,
    getInputProps: getInputAttachedFile,
  } = useDropzone({
    multiple: true,
    accept: attachedFileTypes,
    maxFiles: 10,
    minSize: 1,
    maxSize: 100 * 1024 * 1024,
    validator: typeValidator,
    onDrop: attachedFiles,
  });

  const removeAttachedFilesFile = (file) => {
    const newFiles = [...fileAttached];
    newFiles.splice(newFiles.indexOf(file), 1);
    _fileAttached(newFiles);
  };

  //File Attached Certificate  Function
  const attachedCertificate = useCallback(
    (acceptedFiles) => {
      // if (acceptedFiles.length === 1) {
      _certificateAttached([...acceptedFiles]);
      // } else {
      //   _alert({
      //     show: true,
      //     type: "warning",
      //     title: "Please Select Valid PDF File!...",
      //   });
      // }
    },
    [certificateAttached]
  );

  const {
    getRootProps: getRootCertificate,
    getInputProps: getInputCertificate,
  } = useDropzone({
    multiple: true,
    accept: 'application/pdf',
    maxFiles: 10,
    minSize: 1,
    maxSize: 100 * 1024 * 1024,
    validator: typeValidator,
    onDrop: attachedCertificate,
  });

  const removeCertificate = (file) => {
    const newFiles = [...certificateAttached];
    newFiles.splice(newFiles.indexOf(file), 1);
    _certificateAttached(newFiles);
  };

  const handleSelectEvents = (commonDataValues, formType) => {
    if (formType == false) {
      setInitialValues({ ...defaultValues.normal, ...commonDataValues });
      _formValidation(yup.object().shape(validationSchema.normal));
    } else if (formType == true) {
      setInitialValues({ ...defaultValues.real_estate, ...commonDataValues });
      _formValidation(yup.object().shape(validationSchema.real_estate));
    }
  };

  const isUserVerified = () => {
    if (loggedUser.kycVerified) {
      return true;
    } else {
      // _alert({show: true, title: "Your KYC is Not Verified Yet...", type: 'error'});
      return false;
    }
  };

  const handleFormType = (value, formValue) => {
    const commonValues = { ...formValue, formType: value };

    if (value === false) {
      handleSelectEvents(commonValues, value);
    } else if (value === true) {
      const isVerify = isUserVerified();
      if (!isVerify) {
        openKycModal();
      } else {
        handleSelectEvents(commonValues, value);
      }
    }
  };

  const handleNftType = (value, formValue) => {
    let commonValues = { ...formValue, nftType: value };

    if (value === '0') {
      commonValues = { ...commonValues, form_of_sale: 'auction', quantity: 1 };
      handleSelectEvents(commonValues, formValue.formType);
    } else if (value === '1') {
      if (formValue.formType == false) {
        commonValues = { ...commonValues, quantity: 2 };
      } else {
        commonValues = { ...commonValues, quantity: 2 };
      }
      commonValues = { ...commonValues, form_of_sale: 'fixed_price' };
      handleSelectEvents(commonValues, formValue.formType);
    }
  };

  const handleFormSaleType = (value, formValue) => {
    const commonValues = { ...formValue, form_of_sale: value };

    if (value === 'fixed_price' || formValue.formType == false) {
      handleSelectEvents(commonValues, formValue.formType);
    } else if (value === 'auction' || formValue.formType == true) {
      handleSelectEvents(commonValues, formValue.formType);
    }
  };

  const handleSaleCoin = (value, formValue) => {
    const commonValues = { ...formValue, saleCoin: value };
    handleSelectEvents(commonValues, formValue.formType);
  };

  const fileValidation = () => {
    if (initialValues.formType == false) {
      if (myFiles.length < 1) {
        _alert({
          show: true,
          type: 'warning',
          title: 'Please  select the Title Image!.....',
        });
        return false;
      }
    } else {
      if (myFiles.length < 1) {
        _alert({
          show: true,
          type: 'warning',
          title: 'Please  select the Title Image!.....',
        });
        return false;
      }
      if (fileAttached.length < 1) {
        _alert({
          show: true,
          type: 'warning',
          title: 'Please select the Attached File!.....',
        });
        return false;
      }
      if (certificateAttached.length < 1) {
        _alert({
          show: true,
          type: 'warning',
          title: 'Please select the Attached Certificate!.....',
        });
        return false;
      }
      if (myFiles.length <= 0) {
        _alert({
          show: true,
          type: 'warning',
          title: 'Please  Upload a image',
        });
        return false;
      }
    }
    return true;
  };

  const metamaskValidation = async () => {
    if (!isDataLoading) {
      if (isMetaMaskInstall) {
        if (isMetaMaskConnected) {
          if (accountId && accountId === ETHAddress) {
            return true;
          } else {
            if (ETHAddress) {
              _alert({
                show: true,
                type: 'warning',
                title: ErrorMessage.selectLinkWallet,
              });
            } else {
              _alert({
                show: true,
                type: 'warning',
                title: ErrorMessage.updateWallet,
              });
            }
          }
        } else {
          _alert({
            show: true,
            type: 'warning',
            title: ErrorMessage.connectMetamask,
          });
          await ethEnabled();
        }
      } else {
        _alert({
          show: true,
          type: 'error',
          title: ErrorMessage.metamaskInstallation,
        });
      }
    }
    return false;
  };

  const uploadJSONData = async (Metadata_URI) => {
    console.log(Metadata_URI);
    const ipfs = await ipfsClient();
    
      let options = {
        warpWithDirectory: true,
        progress: (prog) => console.log(`Saved :${prog}`)
      }

      let result = await ipfs.add({ content: Buffer.from(JSON.stringify(Metadata_URI))});
      console.log(result.cid.string)
      let hash = result.cid.string
      //console.log(`https://ipfs.io/ipfs/${hash}`);
      return hash;
  
  }  

  const uploadDocumentstoIPFS = async (files) => {
    console.log(files.length);
    const ipfs = await ipfsClient();

    const filePromises = files.map((file) => {
      // Return a promise per file
      return new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = async () => {
          let buffer = Buffer(reader.result);
          try {
            const response = await ipfs.add({
              path: file.name,
              content: buffer,
            });
            // Resolve the promise with the response value
            resolve(response);
          } catch (err) {
            reject(err);
          }
        };
        reader.onerror = (error) => {
          reject(error);
        };
      });
    });
  
    // Wait for all promises to be resolved
    const fileInfos = await Promise.all(filePromises);
  
    console.log("COMPLETED");
    //console.log(fileInfos);
  
    // Profit
    return fileInfos;
  };
  

  const convertJsonToFormData = (jsonData) => {
    const artWorkForm = new FormData();
    //title_image
    myFiles.forEach((file) => {
      artWorkForm.append('title_image', file);
    });

    //images
    fileAttached.forEach((file) => {
      artWorkForm.append('images', file);
    });

    if (jsonData.formType == true) {
      //pdf_doc
      certificateAttached.forEach((file) => {
        artWorkForm.append('pdf_doc', file);
      });

      const mapAddressData = {
        address: mapAddress,
        longitude: coords.long,
        latitude: coords.lat,
      };
      artWorkForm.append('map_address', JSON.stringify(mapAddressData));
    }

    Object.keys(jsonData).forEach((key) => {
      if (key === 'title') {
        artWorkForm.append('title', jsonData[key]);
      } else if (key === 'art_work_category' && jsonData.formType == false) {
        artWorkForm.append('art_work_category', jsonData[key]);
      } else if (key === 'formType') {
        artWorkForm.append('real_estate_type', jsonData[key]);
      } else if (key === 'nftType') {
        artWorkForm.append('mint_nft', jsonData[key]);
      } else if (key === 'form_of_sale' && jsonData.formType == false) {
        artWorkForm.append('form_of_sale', jsonData[key]);
      } else if (key === 'saleCoin') {
        artWorkForm.append('sale_coin', jsonData[key]);
      } else if (key === 'quantity') {
        if (jsonData.nftType === '1' && jsonData.formType == true) {
          artWorkForm.append('quantity', '2');
        } else {
          artWorkForm.append('quantity', jsonData[key]);
        }
      } else if (key == 'address') {
        artWorkForm.append('address', jsonData[key]);
      } else if (key == 'description') {
        artWorkForm.append('description', jsonData[key]);
      } else if (key == 'url') {
        artWorkForm.append('url', jsonData[key]);
      } else if (
        key == 'sale_price' &&
        jsonData.form_of_sale == 'fixed_price' &&
        jsonData.formType == false
      ) {
        artWorkForm.append('sale_price', jsonData[key]);
      } else if (
        key == 'auction_start_price' &&
        jsonData.form_of_sale == 'auction' &&
        jsonData.formType == false &&
        jsonData.nftType == '0'
      ) {
        artWorkForm.append('auction_start_price', jsonData[key]);
      } else if (
        key == 'auction_end_hours' &&
        jsonData.form_of_sale == 'auction' &&
        jsonData.formType == false &&
        jsonData.nftType == '0'
      ) {
        artWorkForm.append('auction_end_hours', jsonData[key]);
      } else if (key == 'royalties') {
        const royalty = [];
        jsonData[key].forEach((data) => {
          royalty.push({
            percentage: data.royalty,
            wallet_address: data.walletAddress,
          });
        });
        artWorkForm.append('royalty', JSON.stringify(royalty));
      } else if (key == 'type' && jsonData.formType == true) {
        artWorkForm.append('buildingType', jsonData[key]);
      } else if (key == 'area' && jsonData.formType == true) {
        artWorkForm.append('area', jsonData[key]);
      } else if (key == 'company' && jsonData.formType == true) {
        artWorkForm.append('contractor', jsonData[key]);
      } else if (key == 'floor' && jsonData.formType == true) {
        artWorkForm.append('floorData', jsonData[key]);
      } else if (key == 'date' && jsonData.formType == true) {
        artWorkForm.append('moveInData', jsonData[key]);
      }
    });
    return artWorkForm;
  };

  const reInitialField = () => {
    defaultValues = {
      normal: {
        ...defaultValues.normal,
        art_work_category: artCategory[0]?.id,
      },
    };

    setInitialValues(defaultValues.normal);

    myFiles.length = 0;
    certificateAttached.length = 0;
    fileAttached.length = 0;
    _mapAddress('');
  };

  const maticContract721 = async (artWorkCreationFormData, action, URIs) => {
    let gasVal = 0;
    await Web3.eth.getGasPrice().then(gasPrice => {
      const increasedGasPrice = parseInt(gasPrice) + (60 * 10**9);
      console.log(increasedGasPrice, gasPrice);
      gasVal = Web3.utils.toWei(increasedGasPrice.toString(), "wei");
    });

    await MATIC_721_OpenSea_Contract.methods
      .mint(myFiles.length, URIs)
      .send({
        from: accountId,
        // gas: 9000000,
        gasPrice: gasVal,
      })
      .then(async (obj) => {
        await MATIC_721_OpenSea_Contract.methods
          .setApprovalForAll(AdminAccountId, true)
          .send({
            from: accountId,
            // gas: 9000000,
            gasPrice: Web3.utils.toWei('150', 'gwei'),
          })
          .then((res) => {
            artWorkCreationFormData.append('mintResponse', JSON.stringify(obj));
            
            API({
              url: 'user/art-work',
              data: artWorkCreationFormData,
              isFormdata: true,
            })
              .then((res) => {
                action.resetForm();
                reInitialField();
                _alert({
                  show: true,
                  type: 'success',
                  title: res?.data?.message,
                });
              })
              .catch((error) => {
                _alert({ show: true, type: 'error', title: error?.message });
              })
              .finally(() => {
                setIsLoading(false);
              });
          })
          .catch((error) => {
            _alert({ show: true, type: 'warning', title: error?.message });
            setIsLoading(false);
          });
      })
      .catch((error) => {
        _alert({ show: true, type: 'warning', title: error?.message });
        setIsLoading(false);
      });
  };

  const maticContract1155 = async (e, artWorkCreationFormData, action, metaData) => {
    let tokenID = await MATIC_1155_OpenSea_Contract.methods.nextTokenID().call();
    console.log("Next token ID :",tokenID);
    metaData['token'] = tokenID;
    
    await MATIC_1155_OpenSea_Contract.methods
      ?.mint(e.quantity)
      .send({
        from: accountId,
        // gas: 9000000,
        gasPrice: Web3.utils.toWei('150', 'gwei'),
      })
      .then(async (obj) => {
        await MATIC_1155_OpenSea_Contract.methods
          .setApprovalForAll(AdminAccountId, true)
          .send({
            from: accountId,
  
            // gas: 9000000,
            gasPrice: Web3.utils.toWei('150', 'gwei'),
          })
          .then((res) => {
            artWorkCreationFormData.append('mintResponse', JSON.stringify(obj));

            API({
              url: 'user/art-work',
              data: artWorkCreationFormData,
              isFormdata: true,
            })
              .then((res) => {
                let metadata_URL = `${META_DATA_URL}/polygon/addmetadata`;
                   axios.post(metadata_URL, metaData)
                    .then((res) => {
                      console.log("metadata response",res);
                    })
                    .catch((error) => {
                      console.log("error",error);
                    })

                action.resetForm();
                reInitialField();
                _alert({
                  show: true,
                  type: 'success',
                  title: res?.data?.message,
                });
              })
              .catch((error) => {
                _alert({ show: true, type: 'error', title: error?.message });
              })
              .finally(() => setIsLoading(false));
          })
          .catch((error) => {
            _alert({ show: true, type: 'warning', title: error?.message });
            setIsLoading(false);
          });
      })
      .catch((error) => {
        _alert({ show: true, type: 'warning', title: error?.message });
        setIsLoading(false);
      });
  };

  const contract721 = async (artWorkCreationFormData, action, URIs) => {
   
    await ETH_721_OpenSea_Contract.methods
      .mint(myFiles.length, URIs)
      .send({ from: accountId })
      .then(async (obj) => {
        await ETH_721_OpenSea_Contract.methods
          .setApprovalForAll(AdminAccountId, true)
          .send({ from: accountId })
          .then((res) => {
            artWorkCreationFormData.append('mintResponse', JSON.stringify(obj));
            API({
              url: 'user/art-work',
              data: artWorkCreationFormData,
              isFormdata: true,
            })
              .then((res) => {
                action.resetForm();
                reInitialField();
                _alert({
                  show: true,
                  type: 'success',
                  title: res?.data?.message,
                });
              })
              .catch((error) => {
                _alert({ show: true, type: 'error', title: error?.message });
              })
              .finally(() => {
                setIsLoading(false);
              });
          })
          .catch((error) => {
            _alert({ show: true, type: 'warning', title: error?.message });
            setIsLoading(false);
          });
      })
      .catch((error) => {
        _alert({ show: true, type: 'warning', title: error?.message });
        setIsLoading(false);
      });
  };

  const contract1155 = async (e, artWorkCreationFormData, action, metaData) => {
    let tokenID = await ETH_1155_OpenSea_Contract.methods.nextTokenID().call(); 
    console.log("Next token ID :",tokenID);
    metaData['token'] = tokenID;

    await ETH_1155_OpenSea_Contract.methods
      ?.mint(e.quantity)
      .send({ from: accountId })
      .then(async (obj) => {
        await ETH_1155_OpenSea_Contract.methods
          .setApprovalForAll(AdminAccountId, true)
          .send({ from: accountId })
          .then((res) => {
            artWorkCreationFormData.append('mintResponse', JSON.stringify(obj));

            API({
              url: 'user/art-work',
              data: artWorkCreationFormData,
              isFormdata: true,
            })
              .then((res) => {
                let metadata_URL = `${META_DATA_URL}/eth/addmetadata`;
                   axios.post(metadata_URL,metaData)
                    .then((res) => {
                      console.log("metadata response",res);
                    })
                    .catch((error) => {
                      console.log("error",error);
                    })

                action.resetForm();
                reInitialField();
                _alert({
                  show: true,
                  type: 'success',
                  title: res?.data?.message,
                });
              })
              .catch((error) => {
                _alert({ show: true, type: 'error', title: error?.message });
              })
              .finally(() => setIsLoading(false));
          })
          .catch((error) => {
            _alert({ show: true, type: 'warning', title: error?.message });
            setIsLoading(false);
          });
      })
      .catch((error) => {
        _alert({ show: true, type: 'warning', title: error?.message });
        setIsLoading(false);
      });
  };

  const royaltiesSum = (royalties) => {
    return royalties.reduce((sum, tax) => sum + tax?.royalty, 0);
  };

  const mintNFT = async (e, action) => {
    if (royaltiesSum(e.royalties) > maxRoyalties) {
      _alert({
        show: true,
        type: 'warning',
        title: `Sum of all the royalty should not be more than ${maxRoyalties}.`,
      });
      return;
    }

    if (!e.formType && Number(e.auction_start_price) <= 0) {
      _alert({
        show: true,
        type: 'warning',
        title: 'Auction start price grater Than 0',
      });
    } else if (!e.formType && Number(e.sale_price <= 0)) {
      _alert({
        show: true,
        type: 'warning',
        title: 'sale price grater Than 0',
      });
    // } else if (
    //   e.formType &&
    //   (coords.lat === '' || coords.long === '' || mapAddress === '')
    // ) {
    //   _alert({
    //     show: true,
    //     type: 'warning',
    //     title: 'Please select the Google-Map Address!...',
    //   });
    } else {
      setIsLoading(true);
      let artWorkCreationFormData = convertJsonToFormData(e);
      // if form type is true === NFT type Real Estate NFT false For Normal NFT
      if (e.formType == "true") {
        //e.type = mint type 0 for single mint type and 1 for multiple mint type

        API({
          url: 'user/art-work',
          data: artWorkCreationFormData,
          isFormdata: true,
        })
          .then((res) => {
            action.resetForm();
            reInitialField();
            _alert({ show: true, type: 'success', title: res?.data?.message });
          })
          .catch((error) => {
            _alert({ show: true, type: 'error', title: error?.message });
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        let ipfsImageUrl = [];
        let file = await uploadDocumentstoIPFS(myFiles);
        let fileLength = file.length;
        let fileurl ='';
        let URIs = [];
        let URI_Metadata;
        let metadataURI;
        console.log(file);
        for (let i = 0; i < fileLength; i++) {
          console.log(file[i].cid.string);
          fileurl = file[i].cid.string;
          ipfsImageUrl.push(`https://ipfs.io/ipfs/${fileurl}`); 
        }
   
        for (let i = 0; i < fileLength; i++) {
          URI_Metadata = {
            name: e.title,
            description: e.description,
            image: ipfsImageUrl[i]
        }
          metadataURI = await uploadJSONData(URI_Metadata)
          URIs.push(`https://ipfs.io/ipfs/${metadataURI}`)
        }
        console.log(URIs,"uris",URI_Metadata, e);

        if (e.nftType === '0') {
          await contract721(artWorkCreationFormData, action, URIs);
        } else {
          await contract1155(e, artWorkCreationFormData, action, URI_Metadata);
        }
      }
    }
  };

  const mintMaticNFT = async (e, action) => {
    if (royaltiesSum(e.royalties) > maxRoyalties) {
      _alert({
        show: true,
        type: 'warning',
        title: `Sum of all the royalty should not be more than ${maxRoyalties}.`,
      });
      return;
    }

    if (!e.formType && Number(e.auction_start_price) <= 0) {
      _alert({
        show: true,
        type: 'warning',
        title: 'Auction start price grater Than 0',
      });
    } else if (!e.formType && Number(e.sale_price <= 0)) {
      _alert({
        show: true,
        type: 'warning',
        title: 'sale price grater Than 0',
      });
    }
    // } else if (
    //   e.formType &&
    //   (coords.lat === '' || coords.long === '' || mapAddress === '')
    // ) {
    //   _alert({
    //     show: true,
    //     type: 'warning',
    //     title: 'Please select the Google-Map Address!...',
    //   });
    // }
     else {
      setIsLoading(true);
      let artWorkCreationFormData = convertJsonToFormData(e);

      if (e.formType == 'true') {
        API({
          url: 'user/art-work',
          data: artWorkCreationFormData,
          isFormdata: true,
        })
          .then((res) => {
            action.resetForm();
            reInitialField();
            _alert({ show: true, type: 'success', title: res?.data?.message });
          })
          .catch((error) => {
            _alert({ show: true, type: 'error', title: error?.message });
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        console.log(myFiles)
        let ipfsImageUrl = [];
        let file = await uploadDocumentstoIPFS(myFiles);
        let fileLength = file.length;
        let fileurl ='';
        let URIs = [];
        let URI_Metadata;
        let metadataURI;
        console.log(file);
        for (let i = 0; i < fileLength; i++) {
          console.log(file[i].cid);
          fileurl = file[i].cid.string;
          ipfsImageUrl.push(`https://ipfs.io/ipfs/${fileurl}`); 
        }

        for (let i = 0; i < fileLength; i++) {
          URI_Metadata = {
            name: e.title,
            description: e.description,
            image: ipfsImageUrl[i]
        }
          metadataURI = await uploadJSONData(URI_Metadata)
          URIs.push(`https://ipfs.io/ipfs/${metadataURI}`)
        }
        if (e.nftType === '0') {
          await maticContract721(artWorkCreationFormData, action, URIs);
        } else {
          await maticContract1155(e, artWorkCreationFormData, action, URI_Metadata);
        }
      }
    }
  };

  const handleSubmit = async (e, action) => {
    if (e.nftType === '1' && e.formType == true) {
      e.quantity = 2;
    }

    if (e.nftType == '1' && e.quantity <= 2) {
      _alert({
        show: true,
        type: 'warning',
        title: 'Quantity must be higher than 2',
      });
    } else {
      const isFileValidate = fileValidation();
      if (isFileValidate) {
        const isMetamaskValidate = await metamaskValidation();
        if (isMetamaskValidate) {
          if (e.saleCoin == 0) {
            if (networkVersion == networkID.ETH) {
              await mintNFT(e, action);
            } else {
              _alert({
                show: true,
                type: 'warning',
                title: ErrorMessage.ETH_Network_Version,
              });
            }
          } else if (e.saleCoin == 1) {
            if (networkVersion == networkID.MATIC) {
              await mintMaticNFT(e, action);
            } else {
              _alert({
                show: true,
                type: 'warning',
                title: ErrorMessage.MATIC_Network_Version,
              });
            }
          } else {
            _alert({
              show: true,
              type: 'warning',
              title: ErrorMessage.ETH_Network_Version,
            });
          }
        }
      }
    }
  };

  const getGoogleMapAddress = () => {
    openModal();
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={formValidation}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue,
        }) => (
          <>
            <Col sm={12} md={7} lg={8}>
              <Row className="justify-content-center">
                <Col sm={12} md={10} lg={8}>
                  <div className="myprofile-form-section">
                    <h1 className="blue">NFT Mint</h1>
                    <div className="form-group">
                      <label className="form-label robot-font fw-bold regal-blue-color fs-5 mb-3">
                        <span className="red">*</span>Select
                      </label>
                      <div className="forms">
                        {/* ------------------------------------------------ New code ----------------------------------------------------- */}

                        {formType.map((item, index) => (
                          item.lable !== "Real Estate" ? (
                            <div
                              className="form-check form-check-inline"
                              key={`${item.value} + ${index}`}
                            >
                              <input
                                className="form-check-input mouse-pointer"
                                type="radio"
                                name="formType"
                                id={item.lable}
                                value={item.value}
                                onChange={(e) => {
                                  e.currentTarget.blur();
                                  handleChange(e);
                                  handleFormType(item.value, values);
                                }}
                                checked={item.value === values.formType}
                              />
                              {touched.formType && errors.formType ? (
                                <p style={styles.error}>{errors.formType}</p>
                              ) : null}
                              <label
                                className="form-check-label mouse-pointer"
                                htmlFor="inlineRadio1"
                                onClick={() => handleFormType(item.value, values)}
                              >
                                {item.lable}
                              </label>
                            </div>
                          ) : null
                        ))}







                       {/* -------------------------------------------- Hiding Real Estate Starts --------------------------------------  */}
                        {/* {formType.map((item, index) => (
                          <div
                            className="form-check form-check-inline"
                            key={`${item.value} + ${index}`}
                          >
                            <input
                              className="form-check-input mouse-pointer"
                              type="radio"
                              name="formType"
                              id={item.lable}
                              value={item.value}
                              onChange={(e) => {
                                e.currentTarget.blur();
                                handleChange(e);
                                handleFormType(item.value, values);
                              }}
                              checked={item.value === values.formType}
                            />
                            {touched.formType && errors.formType ? (
                              <p style={styles.error}>{errors.formType}</p>
                            ) : null}
                            <label
                              className="form-check-label mouse-pointer"
                              htmlFor="inlineRadio1"
                              onClick={() => handleFormType(item.value, values)}
                            >
                              {item.lable}
                            </label>
                          </div>
                        ))} */}
                        {/* -------------------------------------------- Hiding Real Estate Ends --------------------------------------  */}
                        
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="form-label robot-font fw-bold regal-blue-color fs-5 mb-3">
                        <span className="red">*</span>NFT
                      </label>
                      <div className="forms">
                        {selectNftType.map((item, index) => (
                          <div
                            className="form-check form-check-inline"
                            key={`${item.value} + ${index}`}
                          >
                            <input
                              className="form-check-input mouse-pointer"
                              type="radio"
                              name="nftType"
                              id={item.lable}
                              value={item.value}
                              onChange={(e) => {
                                e.currentTarget.blur();
                                handleChange(e);
                                handleNftType(item.value, values);
                              }}
                              checked={item.value === values.nftType}
                            />
                            {touched.nftType && errors.nftType ? (
                              <p style={styles.error}>{errors.formType}</p>
                            ) : null}
                            <label
                              className="form-check-label mouse-pointer"
                              htmlFor="inlineRadio1"
                              onClick={() => handleNftType(item.value, values)}
                            >
                              {item.lable}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="form-group flex-direction">
                      <label className="form-label robot-font fw-bold regal-blue-color fs-5 mb-3">
                        <span className="red">*</span>Item Title
                      </label>
                      <div className="forms">
                        <input
                          name="title"
                          className="form-style"
                          type="text"
                          placeholder="Enter item title"
                          value={values.title}
                          onChange={handleChange}
                        />
                        {touched.title && errors.title ? (
                          <p style={styles.error}>{errors.title}</p>
                        ) : null}
                        {values.formType == false && (
                          <>
                            <label className="form-label robot-font fw-bold regal-blue-color fs-5 mt-5">
                              <span className="red">*</span>Item Category
                            </label>
                            <select
                              className="form-style "
                              name="art_work_category"
                              value={values.art_work_category}
                              onChange={handleChange}
                            >
                              {setCategory(values.art_work_category)}
                              {artCategory &&
                                artCategory.map((item, index) => (
                                  <option value={item.id} key={item.id}>
                                    {item.category}
                                  </option>
                                ))}
                            </select>
                            {touched.art_work_category &&
                            errors.art_work_category ? (
                              <p style={styles.error}>
                                {errors.art_work_category}
                              </p>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>

                    <div className="form-group title-image-section">
                      <label className="form-label robot-font fw-bold regal-blue-color fs-5 mb-3">
                        <span className="red">*</span>Title Image
                      </label>

                      {myFiles?.length <= 0 && (
                        <div className="image-upload">
                          <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <img
                              src={SendIcon}
                              alt="icon"
                              className="send-icon"
                            />
                            <h3>Drag or Drop an Image</h3>
                            <p>2000 x 2000px PNG or JPG files recommended</p>
                          </div>
                        </div>
                      )}
                      {myFiles?.map((file, index) => (
                        <div
                          className="image-uploaded"
                          key={`${file.lastModified} + ${index}`}
                          style={{ height: '350px' }}
                        >
                          <NftFormat
                            type={file.type}
                            views={file.views}
                            file={file}
                            isMintCreation={true}
                          />
                        </div>
                      ))}
                    </div>
                    {!myFiles?.length <= 0 && (
                      <div className="form-group flex-direction">
                        <label className="form-label robot-font fw-bold regal-blue-color fs-5 mb-3">
                          <span className="red">*</span>Attached file
                        </label>
                        {myFiles.map((file, index) => (
                          <div
                            className="attach-file"
                            key={`${file.lastModified} + ${index + 1}`}
                          >
                            {file.name ? file.name : 'File name'}
                            <img
                              src={CloseIcon}
                              alt="icon"
                              className="close-icon mouse-pointer ms-3"
                              onClick={removeFile(file)}
                            />
                          </div>
                        ))}
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <button type="button" className="btn-blue">
                            Search File
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="profile-form-section justify-content-center">
                <Col sm={12} md={7} lg={8} className="mx-auto">
                  {/* real Estate Nft Section Start  */}
                  <section>
                    <div className="form-group flex-direction">
                      {/*Attached File*/}
                      <label className="form-label robot-font fw-bold regal-blue-color fs-5 mb-3">
                        {values.formType == true && (
                          <span className="red">*</span>
                        )}
                        {values.formType == true
                          ? 'Additional files (such as internal map, interior images, etc.)'
                          : 'Artwork to upload'}
                      </label>
                      <div className="attach-file">
                        {fileAttached.length > 0
                          ? fileAttached?.map((file, index) => (
                              <div
                                className={
                                  videoRegex.test(file?.type) ||
                                  audioRegex.test(file?.type)
                                    ? 'attachedFileWrapper attacheVideoFileWrapper'
                                    : 'attachedFileWrapper attachOtherFileWrapper'
                                }
                                key={`attachedFile.${index}`}
                              >
                                <MemoizedNftFormat
                                  file={file}
                                  type={file.type}
                                  isMintCreation={true}
                                  key={`attachedFile.${index}`}
                                />
                              </div>
                            ))
                          : 'Attached Files '}
                        <div onClick={() => removeAttachedFilesFile()}>
                          <img
                            src={CloseIcon}
                            alt="icon"
                            className="close-icon mouse-pointer"
                          />
                        </div>
                      </div>
                      <div {...getRootAttachedFile()}>
                        <input
                          {...getInputAttachedFile()}
                          className="btn-blue"
                        />
                        <p className="btn-blue mouse-pointer">Search File</p>
                      </div>
                    </div>
                    {values.formType == true && (
                      <>
                        <div className="form-group flex-direction">
                          <label className="form-label robot-font fw-bold regal-blue-color fs-5 mb-3">
                            <span className="red">*</span>A certified copy of
                            the register
                            <span className="red text-sm">
                              (If not attached, the sales application will be
                              rejected.)
                            </span>
                          </label>
                          <div className="attach-file">
                            {certificateAttached.length > 0
                              ? certificateAttached?.map((file, index) => (
                                  <div
                                    className="d-flex justify-content-center align-items-center"
                                    key={index + 222}
                                    style={{ height: '120px' }}
                                  >
                                    <NftFormat
                                      type={file.type}
                                      views={file.views}
                                      file={file}
                                      isMintCreation={true}
                                    />
                                    <p className="ms-3">
                                      {file?.name
                                        ? file?.name
                                        : 'Attached Files Name'}
                                    </p>
                                  </div>
                                ))
                              : 'Attached Certificate  Files '}

                            <div onClick={() => removeCertificate()}>
                              <img
                                src={CloseIcon}
                                alt="icon"
                                className="close-icon mouse-pointer"
                              />
                            </div>
                          </div>
                          <div {...getRootCertificate()}>
                            <input {...getInputCertificate()} />
                            <p className="btn-blue mouse-pointer">
                              Search File
                            </p>
                          </div>
                        </div>

                        <div className="royalty-section real-estate-detail-section">
                          <label className="form-label robot-font fw-bold regal-blue-color fs-5 mb-3">
                            Real Estate Detail{' '}
                          </label>
                          <div className="row mb-3">
                            {/* <div className="col-12 d-flex">
                              <div className="row w-100 d-flex justify-content-center align-item-center">
                                <div className="col-9">
                                  <input
                                    placeholder="select map Address"
                                    className="art-work-form-control-height form-control"
                                    disabled
                                    value={mapAddress}
                                  />
                                </div>
                                <div className="col-3 mr-0 ">
                                  <button
                                    className="btn-blue d-flex align-items-center"
                                    onClick={() => getGoogleMapAddress()}
                                  >
                                    Address <ImLocation2 color="white ml-1" />
                                  </button>
                                </div>
                              </div>
                            </div> */}
                            <div className="col-12 mt-5">
                              <div>
                                <input
                                  name="address"
                                  placeholder="456-45, Gimpo-si, Gyeonggi-do"
                                  type="text"
                                  id="royalty"
                                  className="art-work-form-control-height form-control "
                                  value={values.address}
                                  onChange={handleChange}
                                />
                              </div>
                              {errors.address ? (
                                <p style={styles.error}>{errors.address}</p>
                              ) : null}
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-4 text-center">
                              <div>
                                <input
                                  disabled
                                  placeholder="Real Estate Type"
                                  className="art-work-form-control-height form-control tr text-center"
                                />
                              </div>
                            </div>

                            <div className="col-8">
                              <div>
                                <CreatableSelect
                                  isClearable
                                  name="type"
                                  id="royalty"
                                  className="realEstateType art-work-form-control-height form-control tr text-start"
                                  onChange={(e) =>
                                    setFieldValue('type', e ? e.value : null)
                                  }
                                  options={realEstateAddressType}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-2">
                              <div>
                                <input
                                  disabled
                                  placeholder="Area"
                                  className="art-work-form-control-height form-control tr text-center"
                                />
                              </div>
                            </div>

                            <div className="col-10">
                              <div>
                                <input
                                  name="area"
                                  placeholder="4126.21m2"
                                  type="number"
                                  id="royalty"
                                  className="art-work-form-control-height form-control "
                                  value={values.area}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-5">
                              <div>
                                <input
                                  disabled
                                  placeholder="Construction Company"
                                  className="art-work-form-control-height form-control tr text-center"
                                />
                              </div>
                            </div>

                            <div className="col-7">
                              <div>
                                <input
                                  name="company"
                                  placeholder="DL ENC"
                                  type="text"
                                  id="royalty"
                                  className="art-work-form-control-height form-control "
                                  value={values.company}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-4">
                              <div>
                                <input
                                  disabled
                                  placeholder="lowest-highest floor"
                                  className="art-work-form-control-height form-control tr text-center"
                                />
                              </div>
                            </div>

                            <div className="col-8">
                              <div>
                                <input
                                  name="floor"
                                  placeholder="1st basement floor - 2nd floor"
                                  type="text"
                                  id="royalty"
                                  className="art-work-form-control-height form-control "
                                  value={values.floor}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-5">
                              <div>
                                <input
                                  disabled
                                  placeholder="Expected Move in Date"
                                  className="art-work-form-control-height form-control tr text-center"
                                />
                              </div>
                            </div>

                            <div className="col-7">
                              <div>
                                <input
                                  name="date"
                                  placeholder="Date"
                                  type="date"
                                  id="royalty"
                                  className="art-work-form-control-height form-control "
                                  value={values.date}
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="form-group flex-direction m-2">
                      <label className="form-label robot-font fw-bold regal-blue-color fs-5 mb-3">
                        <span className="red">*</span>Description
                      </label>
                      <div className="textarea">
                        <textarea
                          name="description"
                          value={values.description}
                          className="form-control"
                          rows={5}
                          cols={6}
                          onChange={handleChange}
                        />
                        {touched.description && errors.description ? (
                          <p style={styles.error}>{errors.description}</p>
                        ) : null}
                      </div>
                    </div>
                  </section>
                  {/* Real estate nft section over */}

                  <div className="form-group flex-direction m-2">
                    <label className="form-label robot-font fw-bold regal-blue-color fs-5 mb-3">
                    Detail View
                    </label>
                    <div className="textarea">
                      <input
                        name="url"
                        value={values.url}
                        className="form-control"
                        onChange={handleChange}
                      />
                      {touched.url && errors.url ? (
                        <p style={styles.error}>{errors.url}</p>
                      ) : null}
                    </div>
                  </div>

                  {/* Royalty Section start */}
                  <section>
                    <div className="royalty-section mt-5">
                      <div className="form-label robot-font fw-bold regal-blue-color fs-5 mb-3">
                        <span className="red">*</span>Royalty
                        <span className="text-sm red">
                          (Up to 5users can share royalty {maxRoyalties}%)
                        </span>
                      </div>
                      <FieldArray
                        name="royalties"
                        render={(arrayHelpers) => (
                          <>
                            {values.royalties &&
                              values.royalties.map((royalty, index) => {
                                const royaltiesError = errors.royalties?.length
                                  ? errors.royalties[index]
                                  : {};
                                const royaltiesTouched = touched.royalties
                                  ?.length
                                  ? touched.royalties[index]
                                  : {};

                                return (
                                  <div className="row" key={`${index}`}>
                                    <div className="col-12">
                                      <div className="row mt-2 col-12">
                                        <div className="col-lg-2 col-md-2 col-sm-2">
                                          <input
                                            name={`royalties.${index}.royalty`}
                                            min={1}
                                            max={100}
                                            value={
                                              values.royalties[index]?.royalty
                                            }
                                            onChange={handleChange}
                                            placeholder="0%"
                                            type="number"
                                            className="royaltiesField form-control art-work-form-control-height w-100"
                                          />
                                          {royaltiesTouched?.royalty &&
                                          royaltiesError?.royalty ? (
                                            <p style={styles.error}>
                                              {royaltiesError?.royalty}
                                            </p>
                                          ) : null}
                                        </div>
                                        <div className="col-lg-9 col-md-9 flex-grow-1 col-sm-10">
                                          <input
                                            name={`royalties.${index}.walletAddress`}
                                            value={
                                              values.royalties[index]
                                                ?.walletAddress
                                            }
                                            onChange={handleChange}
                                            placeholder="Please enter the wallet address where you want to share the royalty."
                                            type="text"
                                            id="royalty"
                                            className="form-control art-work-form-control-height w-100"
                                          />
                                          {royaltiesTouched?.walletAddress &&
                                          royaltiesError?.walletAddress ? (
                                            <p style={styles.error}>
                                              {royaltiesError?.walletAddress}
                                            </p>
                                          ) : null}
                                        </div>
                                        <div className="col-lg-1 col-md-1 col-sm-2">
                                          {values.royalties.length > 1 && (
                                            <button
                                              className="btn-blue"
                                              // style={{padding: "12px 14px"}}
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              <AiOutlineMinus size={15} />
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            {errors?.royalties?.maxArray && (
                              <div className="row">
                                <div className="col-12">
                                  <div className="invalid-feedback d-block">
                                    {errors.royalties?.maxArray}
                                  </div>
                                </div>
                              </div>
                            )}
                            {values.royalties?.length < 5 && (
                              <div className="row mt-2">
                                <div className="col-12 text-center  d-flex align-items-center justify-content-center">
                                  <button
                                    className="d-flex align-content-center btn-blue"
                                    type="button"
                                    onClick={() =>
                                      arrayHelpers.push({
                                        royalty: 1,
                                        walletAddress: '',
                                      })
                                    }
                                  >
                                    <AiOutlinePlus />
                                  </button>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </section>

                  <section>
                    <div className="row mt-5">
                      <div className="form-label robot-font fw-bold regal-blue-color fs-5 mb-3">
                        <span>Quantity</span>
                      </div>
                      <div className="col-12">
                        <input
                          type="number"
                          name="quantity"
                          min="1"
                          value={
                            values.nftType === nftType[0]?.value
                              ? 1
                              : values.nftType === '1' &&
                                values.formType == true
                              ? 2
                              : values.quantity
                          }
                          onChange={handleChange}
                          placeholder="Enter Quantity"
                          id="royalty"
                          className="form-control art-work-form-control-height w-100 mt-2"
                          disabled={
                            values.nftType === nftType[0]?.value ||
                            values.formType == true
                          }
                        />
                      </div>
                      {touched.quantity && errors.quantity ? (
                        <p style={styles.error}>{errors.quantity}</p>
                      ) : null}
                    </div>
                  </section>

                  {(values.formType == false || values.formType == true) && (
                    <>
                      <label className="form-label robot-font fw-bold regal-blue-color fs-5 mt-5">
                        <span className="red">*</span>Mint NFT Type
                      </label>
                      <select
                        className="form-style "
                        name="mint_nft_type"
                        value={values.saleCoin}
                        onChange={(e) => {
                          handleSaleCoin(e.target.value, values);
                        }}
                      >
                        {mintNftType.map((item, index) => (
                          <option value={item.id} key={item.id}>
                            {item.coin_name}
                          </option>
                        ))}
                      </select>
                      {touched.saleCoin && errors.saleCoin ? (
                        <p style={styles.error}>{errors.saleCoin}</p>
                      ) : null}
                    </>
                  )}

                  {/*Sales type */}
                  {values.formType == false && (
                    <section className="mt-5">
                      <div className="form-label robot-font fw-bold regal-blue-color fs-5 mb-3">
                        <span>Sales Type</span>
                        <span className="text-sm red">
                          {' '}
                          After setting the sales type, the application is
                          processed.
                        </span>
                      </div>
                      <div className="row mt-5">
                        {form_of_sale.map((item, index) =>
                          values.nftType === nftType[0]?.value ? (
                            <div
                              className="col-6"
                              key={`${item.value} + ${index}`}
                            >
                              <input
                                className="form-check-input mouse-pointer form-label robot-font fw-bold regal-blue-color "
                                type="radio"
                                name={values.form_of_sale}
                                id={item.lable}
                                value={item.value}
                                onChange={(e) => {
                                  e.currentTarget.blur();
                                  handleChange(e);
                                  handleFormSaleType(item.value, values);
                                }}
                                checked={item.value === values.form_of_sale}
                              />
                              {touched.form_of_sale && errors.form_of_sale ? (
                                <p style={styles.error}>
                                  {errors.form_of_sale}
                                </p>
                              ) : null}
                              <label
                                className="form-check-label mouse-pointer ms-3"
                                htmlFor="inlineRadio1"
                                onClick={() =>
                                  handleFormSaleType(item.value, values)
                                }
                              >
                                {item.lable}
                              </label>
                            </div>
                          ) : (
                            values.nftType === nftType[1]?.value &&
                            item?.value === form_of_sale[1]?.value && (
                              <div className="col-6" key={index}>
                                <input
                                  className="form-check-input mouse-pointer form-label robot-font fw-bold regal-blue-color "
                                  type="radio"
                                  name="fixed_price"
                                  id={item.lable}
                                  value="fixed_price"
                                  onChange={(e) => {
                                    e.currentTarget.blur();
                                    handleChange(e);
                                    handleFormSaleType(item.value, values);
                                  }}
                                  checked
                                />
                                {touched.form_of_sale && errors.form_of_sale ? (
                                  <p style={styles.error}>
                                    {errors.form_of_sale}
                                  </p>
                                ) : null}
                                <label
                                  className="form-check-label mouse-pointer ms-3"
                                  htmlFor="inlineRadio1"
                                  onClick={() =>
                                    handleFormSaleType(item.value, values)
                                  }
                                >
                                  {item.lable}
                                </label>
                              </div>
                            )
                          )
                        )}
                      </div>

                      {values.form_of_sale === form_of_sale[0]?.value &&
                      values.nftType === nftType[0]?.value ? (
                        <div className="row mt-5">
                          <div className="col-12">
                            <span className="form-label robot-font fw-bold regal-blue-color ">
                              Auction Start Price
                            </span>
                            <input
                              type="number"
                              name="auction_start_price"
                              onChange={handleChange}
                              value={values.auction_start_price}
                              id="royalty"
                              className="form-control art-work-form-control-height w-100 mt-2 text-end pe-5"
                            />
                            <p
                              style={{
                                marginTop: '-35px',
                                float: 'right',
                                marginRight: '10px',
                                color: '#A9DEEE',
                              }}
                            >
                              {values.saleCoin == 1 ? 'Matic' : 'ETH'}
                            </p>
                          </div>
                          {touched.auction_start_price &&
                          errors.auction_start_price ? (
                            <p style={styles.error}>
                              {errors.auction_start_price}
                            </p>
                          ) : null}
                          <div className="col-12">
                            <span className="form-label robot-font fw-bold regal-blue-color ">
                              End Time
                            </span>
                            <select
                              name="auction_end_hours"
                              value={values.auction_end_hours}
                              onChange={handleChange}
                              id="royalty"
                              className="form-control art-work-form-control-height w-100 mt-2"
                            >
                              {endTime.map((item, index) => (
                                <option key={item.key} value={item.value}>
                                  {item.key}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      ) : (
                        <div className="row mt-5">
                          <div className="col-12">
                            <span className="form-label robot-font fw-bold regal-blue-color ">
                              Sale Price
                            </span>
                            <input
                              type="number"
                              onChange={handleChange}
                              value={values.sale_price}
                              name="sale_price"
                              id="royalty"
                              className="form-control art-work-form-control-height w-100 mt-2 text-end pe-5"
                            />
                            <p
                              style={{
                                marginTop: '-35px',
                                float: 'right',
                                marginRight: '10px',
                                color: '#A9DEEE',
                              }}
                            >
                              {values.saleCoin == 1 ? 'Matic' : 'ETH'}
                            </p>
                          </div>
                          {touched.sale_price && errors.sale_price ? (
                            <p style={styles.error}>{errors.sale_price}</p>
                          ) : null}
                        </div>
                      )}
                    </section>
                  )}

                  <div className="royalty-btns mt-5">
                    {isLoading ? (
                      <button
                        className="btn-blue"
                        type="submit"
                      >
                        Apply{' '}
                        <Spinner
                          animation="border"
                          size="sm"
                          className=" ms-2"
                        />
                      </button>
                    ) : (
                      <button
                        className="btn-blue"
                        onClick={() => handleSubmit()}
                        type="submit"
                      >
                        Apply
                      </button>
                    )}

                    <button className="btn-blue btn-white">Cancel</button>
                  </div>
                </Col>
              </Row>
            </Col>
          </>
        )}
      </Formik>
      <SweetAlert
        type={alert.type}
        title={alert.title}
        onConfirm={() => {
          _alert((s) => ({ ...s, show: false }));
        }}
        show={alert.show}
      />
      <Loader show={isLoading} />
      <GoogleMapAddress
        modalOpen={openModal}
        modalShow={show}
        coords={coords}
        _coords={_coords}
        _mapAddress={_mapAddress}
        address={mapAddress}
      />
      <KycModal kycModal={showKyc} showKycModal={openKycModal} />
    </>
  );
};
export default MintNFT;
