import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import LogoIcon from "../images/logo/logo.png";
import { Formik } from "formik";
import * as yup from "yup";
import Loader from "./loader";
import SweetAlert from "react-bootstrap-sweetalert";
import API from "../redux/API";
import { Spinner } from "react-bootstrap";
import "./Layout/Layout.css";
import "./footer.css";
import nav_logo from "../images/logo/logoFull.png";

const Footer = () => {
  const initialValue = {
    email: "",
  };
  const [loading, _loading] = useState(false);
  const [alert, _alert] = useState({
    show: false,
    title: "",
    type: "success" || "error",
  });

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
  });
  const handleSubmitForm = (e, action) => {
    _loading(true);
    API({ url: "user/subscribe-newsletter", data: e, method: "POST" })
      .then((res) => {
        _alert({ show: true, type: "success", title: res.message });
        action.resetForm();
      })
      .catch((err) => {
        _alert({
          show: true,
          title: "User has already subscribed",
          type: "error",
        });
      })
      .finally(() => _loading(false));
  };

  return (
    <>
      <div>
        <footer>
          <section className="footer">
            {/*<div className="container">*/}
            {/*  <div className="row bg_green">*/}
            {/*    <div className="footer_up">*/}
            {/*      <h1 id="footer_up_title">*/}
            {/*        Get the latest news and news from NFT Arts and Real Estate*/}
            {/*        by email*/}
            {/*      </h1>*/}
            {/*      <p>*/}
            {/*        Enter an e-mail at the bottom, you can receive various news.*/}
            {/*      </p>*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*      <Formik*/}
            {/*        initialValues={initialValue}*/}
            {/*        onSubmit={handleSubmitForm}*/}
            {/*        validationSchema={schema}*/}
            {/*      >*/}
            {/*        {({ handleSubmit, handleChange, errors, values }) => (*/}
            {/*          <>*/}
            {/*            <div className="row">*/}
            {/*              <div className="col-12 col-lg-8 m-auto  justify-content-center mt-3">*/}
            {/*                <div className="d-flex justify-content-center align-items-center flex-wrap ">*/}
            {/*                  <div className="flex-grow-1">*/}
            {/*                    <input*/}
            {/*                      name="email"*/}
            {/*                      className="w-100"*/}
            {/*                      value={values.email}*/}
            {/*                      onChange={handleChange}*/}
            {/*                      type="text"*/}
            {/*                      placeholder="Your Email"*/}
            {/*                    />*/}
            {/*                    <div className="row">*/}
            {/*                      {errors.email && (*/}
            {/*                        <h6*/}
            {/*                          className="d-flex"*/}
            {/*                          style={{ color: "tomato" }}*/}
            {/*                        >*/}
            {/*                          {errors.email}*/}
            {/*                        </h6>*/}
            {/*                      )}*/}
            {/*                    </div>*/}
            {/*                  </div>*/}
            {/*                  <div className="d-flex mt-md-0 mt-2 ms-md-0 ms-2">*/}
            {/*                    {loading ? (*/}
            {/*                      <button*/}
            {/*                        type="submit"*/}
            {/*                        className="ms-md-2 ms-0"*/}
            {/*                      >*/}
            {/*                        Subscribe{" "}*/}
            {/*                        <Spinner*/}
            {/*                          animation="border"*/}
            {/*                          size="sm"*/}
            {/*                          className=" ms-2"*/}
            {/*                        />*/}
            {/*                      </button>*/}
            {/*                    ) : (*/}
            {/*                      <button*/}
            {/*                        type="submit"*/}
            {/*                        className="ms-md-2 ms-0"*/}
            {/*                        onClick={() => handleSubmit()}*/}
            {/*                      >*/}
            {/*                        Subscribe*/}
            {/*                      </button>*/}
            {/*                    )}*/}
            {/*                  </div>*/}
            {/*                </div>*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*          </>*/}
            {/*        )}*/}
            {/*      </Formik>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="hr"></div>*/}
            <div className="container content">
              <div className="row footer_down">
                <div className="col-sm-12 col-md-6 me-4">
                  <div className="d-flex flex-row align-items-center">
                    <div className="">
                      <img src={nav_logo} alt="" className="nav-logo mt-1 me-1"/>
                    </div>
                  </div>
                  <div className="footer_txt">
                    <div className="">
                      <div className="d-flex flex-row flex-wrap">
                        <div className="me-2">
                          <p>사업자등록증: 229-87-02745</p>
                        </div>
                        <div className="d-flex flex-column flex-wrap">
                          <div className="">
                            <p style={{marginBottom:0}}> <strong>주소:</strong> 서울특별시 구로구 디지털로32가길 25,202호</p>
                          </div>
                          <div className="">
                            <p style={{marginLeft:"2rem"}}>(구로동, 티타운빌딩)</p>
                          </div>

                        </div>
                      </div>
                      <p>카네어스워런트 주식회사(KHANAIRES WARRANT Inc.)</p>
                      <p><strong>TEL:</strong> 02-855-9285 </p>
                      <p> E-mail: cs@khanaireswarrent.com
                      </p>
                      <p>대표이사: 류석구</p>
                    </div>
                  </div>
                </div>
                {/*<div className="col-md-2 col-sm-6 col-xs-12">*/}
                {/*  <div className="footer_k_txt">Real Estate</div>*/}
                {/*  <div className="footer_text_2">*/}
                {/*    <a*/}
                {/*      href="/real-estate"*/}
                {/*      style={{*/}
                {/*        textDecoration: "none",*/}
                {/*        color: "white",*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      Real Estate Market Place*/}
                {/*    </a>*/}
                {/*  </div>*/}
                {/*  <div className="footer_text_2">*/}
                {/*    <Link*/}
                {/*      to="/upload-nft"*/}
                {/*      style={{*/}
                {/*        textDecoration: "none",*/}
                {/*        color: "white",*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      NFT Market Place*/}
                {/*    </Link>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="col-md-2 col-sm-6 col-xs-12 f_txt-">
                  <div className="footer_k_txt">Market Place</div>
                  <div className="footer_text_2">
                    <Link
                      to="/upload-nft"
                      style={{
                        textDecoration: "none",
                        color: "white",
                      }}
                    >
                      Issue NFW
                    </Link>
                  </div>

                  <div className="footer_text_2">
                    <Link to="/privacy" style={{textDecoration:"none", color:"white"}}>
                      Privacy policy
                    </Link>
                  </div>

                  <div className="footer_text_2">
                      <Link to="/terms" style={{textDecoration:"none", color:"white"}}>
                        Terms and conditions
                      </Link>
                  </div>
                </div>
                <div className="col-md-2 col-sm-6 col-xs-12 f_txt-1">
                  <div className="footer_k_txt">Service Center</div>
                  <div className="footer_text_2">
                    <a
                      href="/notice-list"
                      style={{
                        textDecoration: "none",
                        color: "white",
                      }}
                    >
                      Notice
                    </a>
                  </div>
                  <div className="footer_text_2">
                    <a
                      href="/faq"
                      style={{
                        textDecoration: "none",
                        color: "white",
                      }}
                    >
                      FAQ
                    </a>
                  </div>
                  <div className="footer_text_2">
                    <a
                      href="/inquiry"
                      style={{
                        textDecoration: "none",
                        color: "white",
                      }}
                    >
                      1:1 Inquiry
                    </a>
                  </div>
                  <div className="footer_text_2">
                    <a
                      href="https://band.us/band/89490151"
                      style={{
                        textDecoration: "none",
                        color: "white",
                      }}
                    >
                      Community
                    </a>
                  </div>
                </div>
              </div>

              <div className="row footer_copyright">

                {/*<p>이용약관 | 개인정보 처리방침</p>*/}
                <p><strong>Copyright 2023. khanaireswarrent All Rights Reserved.</strong></p>
              </div>
            </div>
          </section>
        </footer>
      </div>
      <Loader show={loading} />
      <SweetAlert
        type={alert.type}
        title={alert.title}
        onConfirm={() => _alert((s) => ({ ...s, show: false }))}
        show={alert.show}
      />
    </>
  );
};

export default Footer;
