import React from 'react';
import ReactDOM from 'react-dom';
import HeroSection from "../../components/HeroSection";


const WhatIsKhanteum = () => {
    return <>
        <section>
            <HeroSection/>
        </section>
    </>
}

export default WhatIsKhanteum;