import React from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import MintNFT from "./mintNft";
import ProfileCard from '../../components/ProfileCard';
import "./style.css";
import KycStatus from "../KYC-Verification/KYC-Status";
import { Navigate } from "react-router-dom";


const UploadNft = () => {

    const { loggedUser } = useSelector(s => s.user);

    return (
        <div>
            <div className="white-bar"/>
            <div className="header-gradient"/>
            <Container>
                <Row>
                    <Col sm={12} md={5} lg={4}>
                        <ProfileCard loggedUser={loggedUser}/>
                    </Col>
                    {loggedUser?.kycVerified ? <MintNFT/> : <Navigate to="/kyc/status" replace/>}
                </Row>
            </Container>
        </div>

    );
}

export default UploadNft;
