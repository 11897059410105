import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import PhotoIcon from "../../images/others/photo.png";
import IdFrontIcon from "../../images/others/idFront.png";
import IdBackIcon from "../../images/others/idBack.svg";
import CloseIcon from "../../images/others/close-icon.svg";
import "./kyc.css";

import { useDropzone } from "react-dropzone";

function Step2(props) {
  const {
    handleSubmit,
    handleChange,
    removeFrontImageFile,
    values,
    backImageFile,
    frontImageFile,
    handleImageBack,
    removeBackImageFile,
    handleImageFront,
    errors,
    isLoading,
    idTypeList,
    goToPrevious,
  } = props;

  const [fileTypes] = useState(["image/jpeg", "image/png"]);

  const typeValidator = (file) => {
    if (file.size > 100 * 1024 * 1024) {
      return {
        code: "size-too-large",
        message: `file size is larger than 100MB`,
      };
    }
    return null;
  };

  const {
    getRootProps: getRootBackImageProps,
    getInputProps: getInputBackImageProps,
  } = useDropzone({
    multiple: false,
    accept: fileTypes,
    maxFiles: 10,
    minSize: 1,
    maxSize: 100 * 1024 * 1024,
    validator: typeValidator,
    onDrop: handleImageBack,
  });

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: fileTypes,
    maxFiles: 10,
    minSize: 1,
    maxSize: 100 * 1024 * 1024,
    validator: typeValidator,
    onDrop: handleImageFront,
  });

  return (
    <>
      <div className="kyc-container mt-5">
        <div>
          <Form>
            <Form.Group className="mb-5">
              <Form.Label className="d-flex label-style">ID Type</Form.Label>
              <div className="forms">
                <select
                  name="id_type"
                  value={values.id_type || ""}
                  className="form-style"
                  onChange={handleChange}
                >
                  {idTypeList.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.lable}
                    </option>
                  ))}
                </select>
              </div>
              <div className="d-flex justify-content-start red-color">
                {errors.id_type && <p>{errors.id_type}</p>}
              </div>
            </Form.Group>
            <Form.Group className="mb-5 title-image-section">
              <Form.Label className="d-flex label-style">
                Upload a photo of the front of your ID Card
              </Form.Label>

              {frontImageFile?.length <= 0 && (
                <div className="d-flex justify-content-center justify-content-md-between flex-wrap">
                  <div className="image-upload id-upload flex-grow-1">
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <img src={PhotoIcon} alt="icon" className="send-icon" />
                      <h3>Click To Upload</h3>
                      {/* <p>2000 x 2000px PNG or JPG files recommended</p> */}
                    </div>
                  </div>
                  <div className="d-flex align-items-end">
                    <div className="mt-3 mt-md-0">
                      <h6>Ex)</h6>
                      <img
                        src={IdFrontIcon}
                        alt="icon"
                        className="px-3"
                        style={{ width: "200px" }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {frontImageFile?.map((file, index) => (
                <div
                  className="image-uploaded"
                  key={`${file.lastModified} + ${index}`}
                >
                  <img
                    src={URL.createObjectURL(file)}
                    alt="img"
                    className="img-fluid w-100"
                    style={{ height: "350px" }}
                  />
                </div>
              ))}
            </Form.Group>
            {!frontImageFile?.length <= 0 && (
              <div className="form-group flex-direction">
                <label className="form-label robot-font fw-bold regal-blue-color fs-5 mb-3">
                  <span className="red">*</span>Attached file
                </label>
                {frontImageFile.map((file, index) => (
                  <div
                    className="attach-file"
                    key={`${file.lastModified} + ${index + 1}`}
                  >
                    {file.name ? file.name : "File name"}
                    <img
                      src={CloseIcon}
                      alt="icon"
                      className="close-icon mouse-pointer"
                      onClick={removeFrontImageFile(file)}
                    />
                  </div>
                ))}
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <button type="button" className="btn-blue">
                    Search File
                  </button>
                </div>
              </div>
            )}

            <Form.Group className="mb-5 title-image-section">
              <Form.Label className="d-flex label-style">
                Upload a photo of the Back of your ID Card
              </Form.Label>

              {backImageFile?.length <= 0 && (
                <div className="d-flex justify-content-center justify-content-md-between flex-wrap">
                  <div className="image-upload id-upload flex-grow-1">
                    <div {...getRootBackImageProps({ className: "dropzone" })}>
                      <input {...getInputBackImageProps()} />
                      <img src={PhotoIcon} alt="icon" className="send-icon" />
                      <h3>Click To Upload</h3>
                      {/* <p>2000 x 2000px PNG or JPG files recommended</p> */}
                    </div>
                  </div>
                  <div className="d-flex align-items-end">
                    <div className="mt-3 mt-md-0">
                      <h6>Ex)</h6>
                      <img
                        src={IdBackIcon}
                        alt="icon"
                        className="px-3"
                        style={{ width: "200px" }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {backImageFile?.map((file, index) => (
                <div
                  className="image-uploaded"
                  key={`${file.lastModified} + ${index}`}
                >
                  <img
                    src={URL.createObjectURL(file)}
                    alt="img"
                    className="img-fluid w-100"
                    style={{ height: "350px" }}
                  />
                </div>
              ))}
            </Form.Group>
            {!backImageFile?.length <= 0 && (
              <div className="form-group flex-direction">
                <label className="form-label robot-font fw-bold regal-blue-color fs-5 mb-3">
                  <span className="red">*</span>Attached file
                </label>
                {backImageFile.map((file, index) => (
                  <div
                    className="attach-file"
                    key={`${file.lastModified} + ${index + 1}`}
                  >
                    {file.name ? file.name : "File name"}
                    <img
                      src={CloseIcon}
                      alt="icon"
                      className="close-icon mouse-pointer"
                      onClick={removeBackImageFile(file)}
                    />
                  </div>
                ))}
                <div {...getRootBackImageProps()}>
                  <input {...getInputBackImageProps()} />
                  <button type="button" className="btn-blue">
                    Search File
                  </button>
                </div>
              </div>
            )}

            <div className="my-5">
              <p style={{ float: "left" }}>
                Please upload the photo as it is, without any hidden parts. Only
                JPG/PNG format is supported. The capacity cannot exceed 8MB.
              </p>
            </div>

            <div className="login-button">
              {isLoading ? (
                <button
                  type="button"
                  className="register-submit button-gradient-color btn btn-primary border-0 blue-color"
                >
                  Next{" "}
                  <Spinner animation="border" size="sm" className=" ms-2" />
                </button>
              ) : (
                <>
                  <button
                    onClick={() => goToPrevious()}
                    type="button"
                    className="register-submit button-gradient-color btn btn-primary border-0 blue-color me-3"
                  >
                    Prev
                  </button>
                  <button
                    onClick={() => handleSubmit()}
                    type="button"
                    className="register-submit button-gradient-color btn btn-primary border-0 blue-color"
                  >
                    Next
                  </button>
                </>
              )}
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default Step2;
