import React from "react";
import {useNavigate} from "react-router-dom";

const PageNotFound = () => {
    const navigate = useNavigate();
    return (
        <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="mt-5 regal-blue-color fw-bolder">Sorry, Page Not Found...!</h3>
            <div className="mt-5">
                <button className="btn-blue" onClick={()=>navigate('/')}>Go to Home</button>
            </div>

        </div>
    )
}
export default PageNotFound;