import React, {useState} from "react";
import TickIcon from "../../images/others/tick.svg";
import {useNavigate} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import "./kyc.css";

function Step3({isFormSubmit = false}) {
    const navigate = useNavigate();
    const [alert, _alert] = useState({ show: false, title: '', type: 'success' || 'error' });

    const redirect = () => {
        if(isFormSubmit){
            navigate('/profile')
        }else {
            _alert({ show: true, type: "warning", title: 'Some Fields are Missing OR Submit your Form!.....' });
        }
    }
    return (
        <>
            <div className="kyc-container mt-5">
                <div>
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="mx-2">
                            <img
                                src={TickIcon}
                                alt="icon"
                                style={{width: "40px", height: "40px"}}
                            />
                        </div>

                        <div>
                            <h1 className="confirm-lable">Submission Complete!</h1>
                        </div>
                    </div>
                    <div className="mt-3">
                        <p>
                            Please wait for the result. The personal verification process
                            takes an average of 48 hours. If the result of real-name
                            authentication is not displayed even after 48 hours have elapsed,
                            If it is not confirmed, please contact the customer center.
                        </p>
                    </div>
                    <div className="login-button">
                            <button
                                type="button"
                                className="register-submit button-gradient-color btn btn-primary border-0 blue-color"
                                onClick={()=>redirect()}
                            >Main Page</button>
                    </div>
                </div>
            </div>
            <SweetAlert type={alert.type} title={alert.title} onConfirm={() => _alert(s => ({ ...s, show: false }))} show={alert.show} />
        </>
    );
}

export default Step3;
