import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import avtar from "../../../images/background/avtar.png";
import tag from "../../../images/background/tag.png";
import certificate from "../../../images/background/ceritificate.png";
import { googleApiKey, IPFS_URL } from "../../../utils/config";
import GoogleMapReact from "google-map-react";
import { Link, useNavigate } from "react-router-dom";
import NftPurchaseModal from "../../../Modals/NftDetails/nftPurchase";
import { AiOutlineHeart, AiTwotoneHeart } from "react-icons/ai";
import API from "../../../redux/API";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../../../components/loader";
import { UserNickName } from "../../../utils/function";
import NftFormat from "../../../components/NftFormat";
import KycModal from "../../../Modals/NftDetails/kycModal";
import "./nftRealEstateDetails.css";

function NftRealEstateDetails({ realEstateData, loggedUser }) {

    const length = Math.round((realEstateData?.nft_token.length / 2) - 1);
    const navigate = useNavigate()
    const [loading, _loading] = useState(false);
    const [alert, _alert] = useState({ show: false, title: '', type: 'success' || 'error' });
    const [isChecked, _isChecked] = useState(length);
    const [showPurchaseModal, _showPurchaseModal] = useState(false);
    const [likeCount, _likeCount] = useState(Number(realEstateData?.totalLikes));
    const [showKyc, _showKyc] = useState(false);
    const [transactionFee, _transactionFee] = useState(0.20);

    useEffect(() => {
        // API({
        //     url: `user/art-work/like?art_work_id=${realEstateData?.art_work_id}`,
        //     method: "PUT"
        // }).then(async res => {
        //     _transactionFee(res);
        // }).catch(error => _alert({show: true, title: error.message, type: 'error'})).finally(() => _loading(false));
    }, []);

    const like = () => {
        if (loggedUser?.user_id) {
            _loading(true);
            API({
                url: `user/art-work/like?art_work_id=${realEstateData?.art_work_id}`,
                method: "PUT"
            }).then(async res => {
                realEstateData.is_liked = !realEstateData?.is_liked;
                _alert({ show: true, title: res.data.message, type: 'success' });
                if (realEstateData.is_liked) {
                    let plus = likeCount + 1
                    _likeCount(plus)
                } else {
                    let minus = (likeCount - 1);
                    _likeCount(minus);
                }

            }).catch(error => _alert({ show: true, title: error.message, type: 'error' })).finally(() => _loading(false));
        } else {
            _alert({ show: true, title: 'please login first', type: 'warning' })
        }
    }

    const renderMarkers = (map, maps) => {
        const myLatLng = { lat: realEstateData?.map_address?.latitude, lng: realEstateData?.map_address?.longitude };

        let marker = new maps.Marker({
            position: myLatLng,
            map: map,
            title: 'Hello World!'
        });
        return marker
    }

    const handleChange = (index) => {
        _isChecked(index);
    }

    const kycModalOpen = () => {
        _showKyc(!showKyc);
    }
    const modalPurchase = () => {
        if (realEstateData?.is_current_owner) {
            _alert({ show: true, title: `You are the owner of art-work`, type: 'warning' })
        } else if (realEstateData?.isPurchaseApprovalSent) {
            _alert({ show: true, title: `You already send purchase request of this NFT`, type: 'warning' })
        } else {
            if (realEstateData?.pending) {
                _alert({ show: true, title: `Waiting for sale`, type: 'warning' })
                return;
            }
            if (loggedUser?.kycVerified) {
                _showPurchaseModal(!showPurchaseModal);
            } else {
                kycModalOpen()
            }
        }
    }

    const closePurchaseModel = () => _showPurchaseModal(!showPurchaseModal)

    return (
        <>
            <section className="container mt-5">
                {/* <Row className="mb-5">
                    <Col md={12}>
                        <Link to="/">
                            <div>Back</div>
                        </Link>
                    </Col>
                </Row> */}
                <Row className="d-flex justify-content-center align-items-center">
                    <Col xs={12} md={6}>
                        <div className="artwork-head-image">
                            <div style={{ height: '350px' }}>
                                <NftFormat type={realEstateData?.title_image_format}
                                    file={realEstateData?.title_image} />
                            </div>

                        </div>
                    </Col>

                    <Col xs={12} md="auto" className="re-right-details-align">
                        <div>
                            <div className="d-flex justify-content-start align-items-center ">
                                <div className="me-2">
                                    <img src={avtar} alt="image" className="artist-avtor" />
                                </div>
                                <div className="fs-5 ml-2 mouse-pointer text-break"
                                    onClick={() => navigate(`/profile/${realEstateData?.current_owner_id}`)}>
                                    <strong className="text-break">{UserNickName(realEstateData?.current_owner?.current_owner_email)}</strong>
                                </div>
                            </div>
                            <div className="d-flex justify-content-start align-items-start ">
                                <div className="me-2">
                                    <h1 className="re-details-title">{realEstateData?.title}</h1>
                                </div>
                                <div
                                    className="d-flex justify-content-center align-items-center likeLable mouse-pointer"
                                    onClick={() => like()}>
                                    {
                                        realEstateData?.is_liked ?
                                            <AiTwotoneHeart size="20" style={{ color: 'red' }} />
                                            :
                                            <AiOutlineHeart size="20" style={{ color: '#183B56' }} />
                                    }
                                    {Number(likeCount)}
                                </div>
                            </div>

                            <div className="d-flex justify-content-start align-items-center flex-wrap mt-3">
                                <div className="d-flex align-items-center re-bid-container w-100">
                                    {
                                        !realEstateData?.pending ? (
                                            <Card.Body>
                                                <Card.Subtitle>Current Sale Price</Card.Subtitle>
                                                <Card.Title>{realEstateData?.sale_price} {realEstateData?.sale_coin == 1 ? 'Matic': 'ETH'}</Card.Title>
                                            </Card.Body>
                                        ) : (
                                            <Card.Body>
                                                <Card.Title>waiting for sale</Card.Title>
                                            </Card.Body>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </section>
            {/*more Art-Work*/}
            <section className="mt-5">
                <Row>
                    <Col md={12} className="blue-bg-gradient" style={{ height: "500px" }}>
                        <div className="carousel-container mt-5">
                            {realEstateData?.nft_token?.map((item, index) =>
                                <input key={index} type="radio" name="position" className="mouse-pointer"
                                    checked={isChecked === index} onChange={(e) => handleChange(index)} />
                            )}
                            <main id="carousel">
                                {realEstateData?.nft_token?.map((item, index) =>
                                    <div className="item " key={index}>
                                        <img src={`${IPFS_URL}${item.file}`} className="img-item" alt="img"
                                            onClick={() => handleChange(index)} />
                                    </div>
                                )}
                            </main>
                        </div>
                    </Col>
                    <Col md={12} style={{ marginTop: "8%" }}>
                        <hr className="mt-5" style={{ width: "90%", margin: "auto" }}></hr>
                    </Col>
                </Row>
            </section>
            <section className="container mt-5" style={{ marginTop: "20%" }}>
                <Row>
                    <Col md={12}>
                        <div className="blue-bg-card">
                            <img src={tag} className="img-tag" alt="img" />
                            <div className="d-flex justify-content-center mb-5">
                                <h1 className="text-center ">Transaction Details</h1>
                            </div>
                            <div className="d-flex justify-content-between align-items-center line-height-style">
                                <div>listing address</div>
                                <div>{realEstateData?.address ? realEstateData?.address : '-'}</div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2 line-height-style">
                                <div>building type</div>
                                <div>{realEstateData?.buildingType ? realEstateData?.buildingType : '-'}</div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2 line-height-style">
                                <div>dedicated area</div>
                                <div>{realEstateData?.area ? <span>{realEstateData?.area}<sup> m2</sup></span> : '-'}</div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2 line-height-style">
                                <div>construction company</div>
                                <div>{realEstateData?.contractor ? realEstateData?.contractor : '-'}</div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2 line-height-style">
                                <div>lowest-highest floor</div>
                                <div>{realEstateData?.floorData ? realEstateData?.floorData : '-'}</div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2 line-height-style">
                                <div>Expected move-in date</div>
                                <div>{realEstateData?.moveInData ? realEstateData?.moveInData : '-'}</div>
                            </div>
                        </div>
                    </Col>
                    <Col md={12} className="mt-5">
                        <div className="blue-bg-card">
                            <img src={tag} className="img-tag" alt="img" />
                            <div className="d-flex justify-content-center mb-5">
                                <h1 className="text-center ">Description</h1>
                            </div>
                            <div className="d-flex justify-content-between align-items-center line-height-style">
                                <div>{realEstateData?.description ? realEstateData?.description : '-'}</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </section>
            <section>
                <Row>
                    <Col md={12}>
                        {/* <div className="mt-5">
                            <h1 className="text-center fw-bold">Map</h1>
                            <hr
                                className="mt-5"
                                style={{ width: "80%", margin: "auto" }}
                            ></hr>
                        </div> */}
                        {/* <div className="mt-5 d-flex justify-content-center align-items-center">
                            <div style={{ height: '350px', width: '50%' }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: googleApiKey }}
                                    center={{
                                        lat: Number(realEstateData?.map_address?.latitude),
                                        lng: Number(realEstateData?.map_address?.longitude)
                                    }}
                                    defaultZoom={12}
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                                >
                                </GoogleMapReact>
                            </div>
                        </div> */}
                    </Col>
                </Row>
            </section>
            <section className="mt-5">
                <Row>
                    {loggedUser?.kycVerified ?
                        <>
                            {realEstateData?.pdfDoc?.map((item, index) =>
                                <Col md={12} className="blue-bg-gradient" style={{ height: "700px" }}>
                                    <div className="d-flex flex-column  mt-5">
                                        {(index == 0) ? <div className="d-flex justify-content-center mb-5">
                                            <h1 className="text-center fw-bold">
                                                A certified copy of the register
                                            </h1>
                                        </div> : ""}

                                        <Col sm={12} md={12}>

                                            <div
                                                className=" d-flex justify-content-center"
                                                style={{ position: "relative", height: '450px', width: '100%' }}
                                            >

                                                <embed
                                                    src={`${IPFS_URL}${item}`}
                                                    className="img-certificate"
                                                    alt="img"
                                                    style={{ width: '50%', height: '100%' }}
                                                />
                                            </div>
                                        </Col>
                                    </div>
                                    {loggedUser?.kycVerified &&
                                        <Col sm={12} md={12} style={{ marginLeft: "-25%" }}>
                                            <div className="login-button d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    className="register-submit button-gradient-color btn btn-primary border-0 "
                                                    onClick={() => window.open(`${IPFS_URL}${item}`)}
                                                >
                                                    Read more
                                                </button>
                                            </div>
                                        </Col>
                                    }
                                </Col>
                            )}
                        </>
                        :
                        <>
                            <Col md={12} className="blue-bg-gradient" style={{ height: "800px" }}>
                                <div className="d-flex flex-column  mt-5">
                                    <div className="d-flex justify-content-center mb-5">
                                        <h1 className="text-center fw-bold">
                                            A certified copy of the register
                                        </h1>
                                    </div>
                                    <Col sm={12} md={12}>
                                        <div
                                            className=" d-flex justify-content-center"
                                            style={{ position: "relative", height: '450px', width: '100%' }}
                                        >
                                            <img
                                                src={certificate}
                                                className="img-certificate"
                                                alt="img"
                                            />
                                            <p className="certificate-text">
                                                This document can only be viewed by KYC certified members.
                                            </p>
                                        </div>
                                    </Col>
                                </div>
                            </Col>
                        </>
                    }
                </Row>
            </section>
            <section style={{ marginTop: "5%" }} className="d-flex justify-content-center align-items-center">
                {realEstateData?.verification_status === 'waiting' ?
                    <div className="d-flex justify-content-center align-items-center flex-column">
                        <span>Your Art-work Not Verified</span>
                        <button className="moreLoad mt-2" onClick={() => navigate('/pending-nft')}>Go Back</button>
                    </div>
                    :
                    realEstateData?.verification_status === 'verified' && realEstateData.form_of_sale === "waiting_for_sale" ?
                        (
                            realEstateData.is_current_owner ? (
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                    <span>Please Set The Art work</span>
                                    <button className="moreLoad mt-2" onClick={() => navigate('/pending-nft')}>Go Back</button>
                                </div>
                            )
                                : (
                                    <div className="d-flex justify-content-center align-items-center flex-column">
                                        <span>Waiting for sale</span>
                                        <button className="moreLoad mt-2" onClick={() => navigate('/pending-nft')}>Go Back</button>
                                    </div>
                                )
                        )
                        :
                        (
                            <Row>
                                <Col md={12}>
                                    <div className=" d-flex justify-content-center">
                                        <Button className="moreLoad" onClick={() => modalPurchase()}>Purchase
                                            request</Button>
                                    </div>
                                </Col>
                            </Row>
                        )
                }
                {/*{*/}
                {/*    realEstateData?.verification_status === 'verified' && realEstateData.pending && realEstateData.form_of_sale === "waiting_for_sale" && (*/}
                {/*        <div className="d-flex justify-content-center align-items-center flex-column">*/}
                {/*            <span>Waiting for sale</span>*/}
                {/*            <button className="moreLoad mt-2" onClick={() => navigate('/pending-nft')}>Go Back</button>*/}
                {/*        </div>*/}
                {/*    )*/}
                {/*}*/}
            </section>
            {showPurchaseModal && <NftPurchaseModal showPurchaseModal={showPurchaseModal} modalPurchase={modalPurchase}
                realEstateData={realEstateData} transactionFee={transactionFee} closePurchaseModel={closePurchaseModel} />}
            <SweetAlert type={alert.type} title={alert.title} onConfirm={() => _alert(s => ({ ...s, show: false }))}
                show={alert.show} />
            <Loader show={loading} />
            <KycModal kycModal={showKyc} showKycModal={kycModalOpen} />
        </>
    );
}

export default NftRealEstateDetails;
