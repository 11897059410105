import React, {useEffect, useState} from 'react';
import NormalArtWorkDetails from "./Normal-Art-Work-Details";
import NftRealEstateDetails from "./Nft-Real-Estate-Details";
import { useParams} from "react-router-dom";
import API from "../../redux/API";
import Loader from "../../components/loader";
import SweetAlert from "react-bootstrap-sweetalert";
import {useSelector} from "react-redux";

const ArtWorkDetails = () => {

    const {artWorkId} = useParams();
    const {id} = useParams()
    const [loading,_loading] = useState(false);
    const [artWorkDetailsData,_artWorkDetailsData] = useState();
    const [sweetAlert, _alert] = useState({show: false, title: '', type: 'success' || 'error'});
    const {loggedUser} = useSelector(s => s.user);
    const [otherArtWorks,_otherArtWorks] = useState([]);
    const [isApproval,_isApproval] = useState(false);

    useEffect(() => {
        _loading(true)
        artWorkDetails();
        return (() => {
            _loading(null);
        })
    }, [artWorkId]);

    const artWorkDetails = () => {
        API({
            url: `user/art-work/details?art_work_id=${artWorkId}&id=${id}`,
            method: 'GET'
        }).then(async res => {
            _otherArtWorks(res.data?.seller_other_artworks);
            _artWorkDetailsData(res?.data?.data);
        }).catch(err => {
            _alert({show: true, type: "error", title: err.message});
        }).finally(() => {
            _loading(false)
        })
    }

    const closeSweetAlertHandler = (s) => {
        _alert(s => ({...s, show: false}));

    }

    return (
        <>
            {artWorkDetailsData?.real_estate_type ?
                <NftRealEstateDetails realEstateData={artWorkDetailsData} loggedUser={loggedUser}/> :
                <NormalArtWorkDetails  data={artWorkDetailsData} otherArtWorks={otherArtWorks}/>}
            <Loader show={loading}/>
            <SweetAlert type={sweetAlert.type} title={sweetAlert.title} onConfirm={() => closeSweetAlertHandler()} show={sweetAlert.show}/>
        </>
    )
}

export default ArtWorkDetails;