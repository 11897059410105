import React, { useEffect, useState } from "react";
import { Route, Router, useNavigate, useParams } from "react-router-dom";
import SearchHeaderLayout from "../../../components/SearchHeaderlayout";
import { Form, FormControl, Row } from "react-bootstrap";
import searchIcon from "../../../images/others/search-icon.svg";
import NftArtCard from "../../../components/NftArtCard";
import Loader from "../../../components/loader";
import SweetAlert from "react-bootstrap-sweetalert";
import API from "../../../redux/API";
import _ from "lodash";
import Switch from "react-switch";

const MarketplaceCategory = () => {
  const { category } = useParams();
  let baseUrl = "user/art-work/market-place?limit=6&nft_type=0";
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [activeCategory, setActiveCategory] = useState("");
  const [artWorkData, _artWorkData] = useState([]);
  const [searchCategory, _searchCategory] = useState([]);
  const [page, _page] = useState(1);
  const [lastPage, _lastPage] = useState(false);
  const [alert, _alert] = useState({
    show: false,
    title: "",
    type: "success" || "error",
  });
  const [categoryName, _categoryName] = useState("1");
  const [catName, _catName] = useState("");
  const [search, setSearch] = useState('');

  useEffect(() => {
    API({ url: "user/art-work/category/fixed", method: "GET" })
      .then(async (res) => {
        setCategoriesList(res?.data?.data);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const filterName = categoriesList.filter(
      (item) => Number(item?.id) === Number(category)
    );
    _categoryName(filterName[0]?.category_name);
  }, [categoriesList, category]);

  useEffect(() => {
    _catName(getCatName(activeCategory));
  }, [activeCategory]);

  useEffect(() => {
    getArtMarketPlaceNft(category);
  }, [category]);

  useEffect(() => {
    if (typeof activeCategory === "number" && activeCategory !== "") {
      searchByCategory();
    }
  }, [activeCategory]);

  const searchByCategory = () => {
    setIsLoading(true);
    const marketPlaceApi = `user/art-work/market-place?page=1&limit=6&art_work_category=${activeCategory}`;
    API({ url: marketPlaceApi, method: "GET" })
      .then(async (res) => {
        const data = res.data.data;
        if (data.length > 0) {
          _searchCategory(res.data.data);
        } else {
          _alert({
            show: true,
            type: "warning",
            title: "Searching Data not Available ...!",
          });
        }
      })
      .catch((err) => console.log("errors", err))
      .finally(() => setIsLoading(false));
  };

  const getCatName = (activeCategory) => {
    switch (activeCategory) {
      case 0:
        return "회원권";
      case 1:
        return "특허권";
      case 2:
        return "디자인";
      case 3:
        return "상표권";
      case 4:
        return "저작권";
      default:
        return "";
    }
  };

  const searchResult = (searchData) => {
    const marketPlaceApi = `user/art-work/search?search=${searchData}`;
    if (searchData !== "") {
      setIsLoading(true);
      API({ url: marketPlaceApi, method: "GET" })
        .then(async (res) => {
          const data = res.data.data;
          if (data.length > 0) {
            _searchCategory(res.data.data);
          } else {
            _alert({
              show: true,
              type: "warning",
              title: "Searching Data not Available ...!",
            });
          }
        })
        .catch((err) => console.log("errors", err))
        .finally(() => setIsLoading(false));
    } else {
      _searchCategory([]);
    }
  };

  const printValue = _.debounce((value) => searchResult(value), 1000);
  const onChange = async ({ target }) => {
    // await printValue(target.value);
    setSearch(target.value)
  };

  const loadMore = (newPage) => {
    _page(page + 1);
    loadMorePages(newPage);
  };

  const loadMorePages = (pages) => {
    API({
      url: `${baseUrl}&art_work_category=${category}&page=${pages}`,
      method: "GET",
    })
      .then(async (res) => {
        try {
          setIsLoading(true);
          const data = res.data.data;
          if (data.length > 0) {
            _artWorkData([...artWorkData, ...data]);
          } else {
            _alert({
              show: true,
              type: "warning",
              title: "No More Art Work...!",
            });
          }
          _lastPage(res.data.lastPage);
        } finally {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  const getArtMarketPlaceNft = (category) => {
    API({
      url: `${baseUrl}&art_work_category=${category}&page=${page}`,
      method: "GET",
    })
      .then(async (res) => {
        try {
          setIsLoading(true);
          _artWorkData(res.data.data);
          _lastPage(res.data.lastPage);
        } finally {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  return (
    <>
      {/*<section>*/}
      {/*    <SearchHeaderLayout pageName={categoryName} isMarketPlace={true} onChange={onChange}/>*/}
      {/*</section>*/}
      <section
        className="container sectionMargin mt-5 mb-5"
        style={{ marginTop: "5%" }}
      >
        <div className="row">
          <div className="nft-search">
            <Form.Select
              aria-label="select"
              className="select-arrow"
              onChange={(e) => setActiveCategory(Number(e.target.value))}
            >
              <option value={-1}>All</option>
              {categoriesList.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.category_name}
                </option>
              ))}
            </Form.Select>

            <div className="search-field">
              <FormControl
                placeholder="Search"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={onChange}
              />
              <img src={searchIcon} alt="icon" />
            </div>
            <button className="btn gradient-lightgreen-btn" onClick={() => searchResult(search)}>Search NFT
                    </button>
            <button
              className="btn gradient-green-btn"
              onClick={() => navigate("/upload-nft")}
            >
              Issuing NFW
            </button>
          </div>

          <h2>{catName}</h2>

          {searchCategory?.length > 0 ? (
            <>
              {!isLoading && searchCategory.length > 0 ? (
                <>
                  <div className="mt-5">
                    <h4 className="fw-bold">Search By Category</h4>
                    <Row className="">
                      {searchCategory?.map((item, index) => (
                        <NftArtCard
                          cardData={item}
                          key={index}
                          isMarketPlace={true}
                          _alert={_alert}
                        />
                      ))}
                    </Row>
                  </div>
                </>
              ) : (
                <div className="d-flex justify-content-center align-items-center mt-5">
                  <h4>Searching Data not Available ...!</h4>
                </div>
              )}
            </>
          ) : (
            <div className="mt-5">
              <div className="d-flex justify-content-between align-items-center">
                {/*<h4 className="fw-bold">Photos by popularity</h4>*/}
              </div>
              {!isLoading && artWorkData.length > 0 ? (
                <>
                  <Row className="">
                    {artWorkData
                      ?.filter((val) => {
                        if (val.selling_available == true) {
                          return val;
                        }
                      })
                      .map((item, index) => (
                        <NftArtCard
                          cardData={item}
                          key={index}
                          isMarketPlace={true}
                          _alert={_alert}
                        />
                      ))}
                  </Row>
                </>
              ) : (
                <div className="d-flex justify-content-center align-items-center mt-5">
                  {/* <h4>Coming Soon</h4> */}
                  <h4></h4>
                </div>
              )}
            </div>
          )}

          <div className="container-fluid mt-5 d-flex justify-content-center align-items-center">
            {!isLoading && artWorkData.length > 0 ? (
              <>
                {!lastPage && (
                  <button className="btn-blue" onClick={() => loadMore(page+1)}>
                    Load More
                  </button>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>
      <Loader show={isLoading} />
      <SweetAlert
        type={alert.type}
        title={alert.title}
        onConfirm={() => _alert((s) => ({ ...s, show: false }))}
        show={alert.show}
      />
    </>
  );
};

export default MarketplaceCategory;