import React from "react";
import Modal from "react-bootstrap/Modal";
import GoogleMapReact from "google-map-react";
import {googleApiKey} from "../../../utils/config";

const OpenMapModal = ({modalShow,modalOpen,coords}) => {

    const renderMarkers = (map, maps) => {
        const myLatLng = {lat: coords?.lat, lng: coords?.long};

        let marker = new maps.Marker({
            position: myLatLng,
            map: map,
            title: 'Map'
        });
        return marker
    }
    return <>
        <Modal
            show={modalShow}
            centered
            onHide={modalOpen}
        >
            <Modal.Body>
                <div style={{height: '50vh', width: '100%'}}>
                    <GoogleMapReact
                        bootstrapURLKeys={{key: googleApiKey}}
                        center={{lat: Number(coords?.lat), lng: Number(coords?.long)}}
                        defaultZoom={12}
                        draggable
                        onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}
                        yesIWantToUseGoogleMapApiInternals
                    >
                    </GoogleMapReact>
                </div>
            </Modal.Body>
        </Modal>
    </>
}

export default OpenMapModal;