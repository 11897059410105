import React, {useEffect, useState} from 'react';
import ProfileViewModal from '../../../Modals/profileViewModal';
import {useSelector} from "react-redux";
import UserProfileLayout from '../../../components/UserProfileLayout';
import ProfileCardImg1 from "../../../images/background/profilecard-1.png";
import {useNavigate, useParams} from "react-router-dom";
import API from "../../../redux/API";
import Loader from "../../../components/loader";
import SweetAlert from "react-bootstrap-sweetalert";
import MetamaskModal from "../../../Modals/metamaskModal";
import {IPFS_URL} from "../../../utils/config";
import NftSellingModal from "../../../Modals/NftSellingModal";
import KycModal from "../../../Modals/NftDetails/kycModal";
import CertificateVerify from "../../../Modals/CertificateVerify";
import "./pendingNft.css";
import UserNftCard from "../../../components/UserNftCard";

function PendingNftCardList() {

    const {userId} = useParams();
    const navigate = useNavigate();
    const {loggedUser} = useSelector(s => s.user);
    const [modalOpen, _modalOpen] = useState(false);
    const [otherUser] = useState(false);
    const [pendingArtwork, _pendingArtwork] = useState([]);
    const [loading, _loading] = useState(false);
    const [sweetAlert, _alert] = useState({show: false, title: '', type: 'success' || 'error'});
    const [show,_show] = useState(false);
    const [resaleModal,_resaleModal] = useState(false);
    const [currentArtWorkNft,_currentArtWorkNft] = useState('');
    const [verified,_verified] = useState(false);
    const [showVerifyModal,_showVerifyModal] = useState(false);

    useEffect(()=>{
        getArtWorkPendingNFT();
        return (()=>{
            getArtWorkPendingNFT(null)
        })
    },[])

    const getArtWorkPendingNFT = () => {
        _loading(true);
        API({ url: "user/art-work/pending?category=all", method: "GET" })
            .then(async (res) => {
                _pendingArtwork(res);
            })
            .catch((error) =>_alert({show: true, title:error.message, type: 'error'}))
            .finally(() =>  _loading(false));
    }

    const openModal = () => {
        _show(!show);
    }

    const showModal = () => {
        _modalOpen(!modalOpen);
    }

    const openVerifyModal = (data) => {
        if(loggedUser.kycVerified){
            _showVerifyModal(!showVerifyModal);
            _currentArtWorkNft(data)
        }else{
            showVerifiedModal();
        }
    }
    const redirect = () => {
        if (otherUser) {
            showModal();
        }
    }

    const closeSweetAlertHandler = (s) => {
        _alert(s => ({...s, show: false}));
    }

    const showVerifiedModal = () => {
        _verified(!verified);
    }

    const resaleOpenModal = (nftData) => {
        if(loggedUser.kycVerified){
            _currentArtWorkNft(nftData)
            _resaleModal(!resaleModal);
        }else{
            showVerifiedModal();
        }

    }


    return (
        <div className="">
            <div className="editprofile-section profilecard-edit-section">
                <UserProfileLayout loggedUser={loggedUser} currentUserId={userId}/>
            </div>
            <div className="mt-5 mb-5">
                <h1 className="">Pending NFW </h1>
            </div>
            <div className="d-flex justify-content-start align-items-center flex-wrap">
                {!loading && pendingArtwork.length > 0 ?  <>
                    { pendingArtwork?.map((item,index)=>
                        <UserNftCard
                             key={`pendingNft.${index}`}
                             nftType="pendingNft"
                             item={item}
                             showModal={showModal}
                             resaleOpenModal={resaleOpenModal}
                             openVerifyModal={openVerifyModal}
                        />
                        )}

                </> : <>
                    <div className="profile-card-no-data-message"/>
                    <div className="profile-card-no-data-message"/>
                    <div className="profile-card-no-data-message">
                        <h4>Art-work Data not Found ...!</h4>
                    </div>
                </> }
            </div>
            <ProfileViewModal show={modalOpen} showModal={showModal}/>
            <Loader show={loading}/>
            <SweetAlert type={sweetAlert.type} title={sweetAlert.title} onConfirm={() => closeSweetAlertHandler()} show={sweetAlert.show}/>
            <MetamaskModal modalOpen={openModal} modalShow={show}/>
            <NftSellingModal
                show={resaleModal}
                resaleOpenModal={resaleOpenModal}
                currentArtWorkNft={currentArtWorkNft}
                getArtWorkPendingNFT={getArtWorkPendingNFT}
                pendingNFT={true}
            />
            <KycModal kycModal={verified} showKycModal={showVerifiedModal}/>
            <CertificateVerify show={showVerifyModal} openVerifyModal={openVerifyModal} currentArtWorkNft={currentArtWorkNft} getArtWorkPendingNFT={getArtWorkPendingNFT}/>
        </div>
    )
}

export default PendingNftCardList
