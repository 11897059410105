import {ERC_721_ETH_CONTRACT,WRAPPED_ETH_CONTRACT, ERC_1155_ETH_CONTRACT, ERC_721_POLYGON, ERC_1155_POLYGON, WRAPPED_MATIC_CONTRACT, OPENSEA_ERC_721_ETH_CONTRACT, OPENSEA_ERC_1155_ETH_CONTRACT, OPENSEA_MATIC_721_ETH_CONTRACT, OPENSEA_MATIC_1155_ETH_CONTRACT, MATIC_AUCTION_CONTRACT, ETH_AUCTION_CONTRACT  } from "./config";
import web3 from 'web3';
import Contract from 'web3-eth-contract';
import Abi from './contract_abi.json';
import AuctionAbi from './auction_abi.json';
import ShareAbi from './snail1155.json'
import ERC_721_OpenSea_Abi from './opensea_abi_721.json';
import ERC_1155_OpenSea_Abi from './opensea_abi_1155.json';
import Matic_Auction_Abi from './matic_Auction.json';
import ETH_Auction_Abi from './eth_Auction.json';
// import { provider } from "./walletconnect";
let prov = window.localStorage.getItem('provider')
let vari=null;
if (prov == 'walletconnect'){
//   vari = provider;
} else if (prov == 'metamask') {
	vari = window.ethereum;
}

export const Web3 = new web3(window.ethereum);
// export const walletConnect = new web3(provider);

Contract.setProvider(window.ethereum)

// Ether

export const Eth_721_Contract = new Contract(Abi, ERC_721_ETH_CONTRACT);
export const Eth_Wrapped_Contract = new Contract(AuctionAbi, WRAPPED_ETH_CONTRACT);
export const Eth_1155_CONTRACT = new Contract(ShareAbi, ERC_1155_ETH_CONTRACT);
export const ETH_721_OpenSea_Contract = new Contract(ERC_721_OpenSea_Abi, OPENSEA_ERC_721_ETH_CONTRACT);
export const ETH_1155_OpenSea_Contract = new Contract(ERC_1155_OpenSea_Abi, OPENSEA_ERC_1155_ETH_CONTRACT);
export const ETH_Auction_Contract = new Web3.eth.Contract(ETH_Auction_Abi, ETH_AUCTION_CONTRACT);
//Matic

export const MATIC_721_Contract = new Web3.eth.Contract(Abi, ERC_721_POLYGON);
export const MATIC_Wrapped_Contract = new Web3.eth.Contract(AuctionAbi, WRAPPED_MATIC_CONTRACT );
export const MATIC_1155_Contract = new Web3.eth.Contract(ShareAbi, ERC_1155_POLYGON);
export const MATIC_721_OpenSea_Contract = new Web3.eth.Contract(ERC_721_OpenSea_Abi, OPENSEA_MATIC_721_ETH_CONTRACT);
export const MATIC_1155_OpenSea_Contract = new Web3.eth.Contract(ERC_1155_OpenSea_Abi, OPENSEA_MATIC_1155_ETH_CONTRACT);
export const MATIC_Auction_Contract = new Web3.eth.Contract(Matic_Auction_Abi, MATIC_AUCTION_CONTRACT);