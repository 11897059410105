import React, {useEffect, useState} from 'react';
import book from "../../images/background/book.png";
import avtar from "../../images/background/avtar.png";
import {useNavigate} from "react-router-dom";
import {IPFS_URL} from "../../utils/config";
import API from "../../redux/API";
import './heroSection.css';
import HeaderImg from "./potImg.png";
import TextImg from "./text.png";
import numOne from "./slot_images/slot_0.png";
import numTwo from "./slot_images/slot_1.png";
import numThree from "./slot_images/slot_2.png";
import numFour from "./slot_images/slot_3.png";
import numFive from "./slot_images/slot_4.png";
import numSix from "./slot_images/slot_5.png";
import numSeven from "./slot_images/slot_6.png";
import numEight from "./slot_images/slot_7.png";
import numNine from "./slot_images/slot_8.png";
import numTen from "./slot_images/slot_9.png";
import numEleven from "./slot_images/slot_4.png";
import slotBg from "./slot_images/slot_bg.png";
import slotLights from "./slot_images/slot_light_overlay.png";

const HeroSection = () => {
    const navigate = useNavigate();
    const redirect = () => {
        navigate(`/marketplace`);
    }
    const [topArtWork, _topArtWork] = useState([]);
    const [topFile, _topFile] = useState('');
    const [index, setIndex] = useState(0);
    const timeoutRef = React.useRef(null);

    useEffect(() => {
        API({url: `/expose/user?page=1`, method: "GET"})
            .then(async (res) => {
                _topFile(res?.data[0]?.files[0] || '')
                _topArtWork(res?.data || []);
            })
            .catch((err) => {
                console.log("err", err);
            });
        return (() => {
            _topArtWork(null);
        })
    }, []);

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    useEffect(() => {
        if (topArtWork.length > 1) {
            resetTimeout();
            timeoutRef.current = setTimeout(() =>
                    setIndex((prevIndex) =>
                        prevIndex === topArtWork.length - 1 ? 0 : prevIndex + 1
                    ),
                3000
            );

            return () => {
                resetTimeout();
            };
        }
    }, [index, topArtWork]);

    return <>
        <section className="main-theme-bg pb-4">
                <div className="container">
                    {/* <div className="row pt-5 pb-5"> */}
                        {/* <div className="mb-5 position-relative d-flex align-items-center justify-content-center">
                            <div className="position-absolute">
                                <img src={slotBg} alt="" className="slot-background-image"/>
                            </div>
                            <div className="d-flex position-absolute slot-img-container">
                                <img src={numOne} alt="Overlay Image 1" className="slot-num-img"/>
                                <img src={numTwo} alt="Overlay Image 2" className="slot-num-img"/>
                                <img src={numThree} alt="Overlay Image 1" className="slot-num-img"/>
                                <img src={numFour} alt="Overlay Image 2" className="slot-num-img"/>
                                <img src={numFive} alt="Overlay Image 1" className="slot-num-img"/>
                                <img src={numSix} alt="Overlay Image 2" className="slot-num-img"/>
                                <img src={numSeven} alt="Overlay Image 1" className="slot-num-img"/>
                                <img src={numEight} alt="Overlay Image 2" className="slot-num-img"/>
                                <img src={numNine} alt="Overlay Image 1" className="slot-num-img"/>
                                <img src={numTen} alt="Overlay Image 2" className="slot-num-img"/>
                                <img src={numEleven} alt="Overlay Image 2" className="slot-num-img"/>
                                <img src={numTen} alt="Overlay Image 2" className="slot-num-img"/>
                                <img src={numSix} alt="Overlay Image 2" className="slot-num-img"/>
                            </div>
                            <div className="position-absolute">
                                <img src={slotLights} alt="" className="slot-background-image"/>
                            </div>
                        </div> */}
                    {/* </div> */}
                    <div className="row">
                        <div className="col-sm-12 col-xs-6 col-md-6">
                            <img src={HeaderImg} style={{width:"100%"}}/>
                        </div>
                        <div className="col-sm-12 col-xs-6 col-md-6 mt-2">
                            <img src={TextImg} style={{width:"100%"}}/>
                        </div>
                        {/*<div className="col-sm-12 col-xs-6 col-md-6 font-face-light">*/}
                        {/*    3000년 전 청동기 시대에는 발이 세 개인 커다란 구리출에 밥을 지어 이웃에게 골고루 나눠준 사람이 그 시대의 왕이었다고 합니다.*/}
                        {/*    <br></br><br></br>*/}
                        {/*    간태움은 2014년부터 2023년 현재까지 전 세계 46개국에 420건의 NFT 관련 원천특허 및 IP를 확보하고 있으며 동 IP 기반으로 누구나 밥을 짓고 나는 수*/}
                        {/*    있는 대형 솥인 '칸티움' 앱을 한국 에서 서비스 중입니다.*/}
                        {/*    <br></br><br></br>*/}
                        {/*    킨태움은 전 세계 혁신가들의 창업을 위한 커뮤니티로서 각자 자신의 영역에서 오디션을 통해*/}
                        {/*    최고이지하에 토달리 뒤 소정이이익?나누는글유경제는코리이고 있으며 목트 잘가지들에 게 장업의 기회를 제공하고 이웃들에게 공정적인 영향력을 주는 것을 목표로 하고*/}
                        {/*    있습니다.*/}
                        {/*    또한 간태움은 혁신과 공유를 통해 사회적 변화를 이끄는 리더들을 지원하며 창업가들이 자신의 아이디어를 현실로 구현하고 성공할 수 있도록 필요한 지원과 자원을 제공하는*/}
                        {/*    플랫폼입니다.*/}
                        {/*    <br></br><br></br>*/}
                        {/*    칸태움 커뮤니티는 서로를 지원하고 협력하여 성장하며 함께 성꽁을 이루는 가치를 추구합니다.*/}
                        {/*    우리는 한태용은 통해 현 사회와 경제의 모든 경세주제들이 혁신적인 아이디어를 나누고 지속 가능한 성장과 발전을 이루는 것에 기여할 수 있도록 항상 지원하겠습니다.*/}
                        {/*    <br></br><br></br>*/}
                        {/*    Khenteum.o는 한태운업에서 서비스 중인 모두의 창업 오디션'에서 소정의 순위에 달성한 IP 를 기초로 NFT로 발행 유통하는 전용 마켓플레이스입니다.*/}
                        {/*</div>*/}
                    </div>
                    {/* {topArtWork[index] &&
                            <div className="nft-book">
                                <img style={{maxWidth: '100%', maxHeight: '190px', display: 'flex', width: 'auto', height: 'auto', marginLeft: 'auto', marginRight: 'auto'}} src={`${IPFS_URL}${topArtWork[index]?.files[0]}` || book} alt="book" />
                                <div className="nft-book-content">
                                    <img
                                        src={ `${topArtWork[index]?.creator?.user_profile ? topArtWork[index]?.creator?.user_profile : avtar }`}
                                        alt="avtar"
                                        className="rounded-circle"
                                        style={{height:'40px',width:'40px'}}
                                    />
                                    <h1>{topArtWork[index]?.title}</h1>
                                    <p>Current Price</p>
                                    <h5>{topArtWork[index]?.sale_price || topArtWork[index]?.auction_start_price  } {topArtWork[index]?.sale_coin == 1 ? 'Matic': 'ETH'}</h5>
                                </div>
                            </div>} */}
                </div>
        </section>
    </>
}

export default HeroSection;