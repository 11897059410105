import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ethEnabled } from "../../utils/web3.function";
import API from "../../redux/API";
import { setLoggedUser, setETHAddress } from "../../redux/reducers/user";
import MetaMaskHook from "../../hooks/MetaMaskHook";
import Loader from "../../components/loader";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { Image } from "react-bootstrap";
import Img from "../../images/others/MetaMask.jpg";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "../../utils/config";
import { updateCurrentUser } from "../../utils/function";
import "../style.css";

const MetamaskModal = (props) => {
  const { modalShow, modalOpen } = props;
  const [_modalShow, _setModalShow] = useState(true);
  const navigate = useNavigate();
  const { isMetaMaskInstall, accountId, isMetaMaskConnected } = MetaMaskHook();
  const { loggedUser, ETHAddress } = useSelector((s) => s?.user);
  const dispatch = useDispatch();
  const [loading, _loading] = useState(false);
  const [sweetAlert, _alert] = useState({
    show: false,
    title: "",
    type: "success",
  });

  useEffect(() => {
    return () => {
      _loading(null);
      _alert(null);
    };
  }, []);

  const closeModal = () => {
    _setModalShow(false);
  };

  const userUpdate = async () => {
    await updateCurrentUser(loggedUser)
      .then(async (response) => {
        await dispatch(setLoggedUser(response));
        _alert({
          title: "Wallet Connected Successfully...!",
          show: true,
          type: "success",
        });
      })
      .catch((err) => {
        _alert({ show: true, title: err.message, type: "error" });
      })
      .finally(() => {
        _loading(false);
      });
  };

  const linkMeta = async () => {
    if (isMetaMaskInstall) {
      if (isMetaMaskConnected) {
        try {
          await ethEnabled();
          const walletKey = {
            eth_key: accountId,
          };
          _loading(true);
          API({ url: `user/connect-wallet`, data: walletKey, method: "PUT" })
            .then(async (res) => {
              await dispatch(setETHAddress(accountId));

              _loading(false);
              modalOpen();
              await userUpdate();
            })
            .catch((err) => {
              _loading(false);
              _alert({ title: err?.message, show: true, type: "error" });
            });
        } catch (err) {
          _alert({ title: err.message, show: true, type: "error" });
        }
      } else {
        _alert({
          show: true,
          type: "warning",
          title: ErrorMessage.connectMetamask,
        });
        await ethEnabled();
      }
    } else {
      _alert({
        title: ErrorMessage.metamaskInstallation,
        show: true,
        type: "warning",
      });
    }
  };

  const getMetamaskAddress = (value) => {
    // _ETHAddress(value);
  };

  const redirect = (page) => {
    if (page === "main_page") {
      navigate(page);
      modalOpen();
    } else {
      navigate(page);
      modalOpen();
    }
  };
  return (
    <>
      <Modal show={modalShow && _modalShow}  centered>
        <Modal.Body>
          <div className="d-flex justify-content-end">
            <button className="btn-blue" onClick={closeModal}>
              X
            </button>
          </div>
          {ETHAddress == null || ETHAddress === "undefined" ? (
            <div className="container p-5">
              <div className="row d-flex justify-content-center align-items-center text-center">
                <div>
                  <p
                    className="font_color"
                    style={{ fontSize: "32px", fontWeight: 700 }}
                  >
                    Connect Wallet
                  </p>
                  <span
                    className="font10 font_color_black"
                    style={{ lineHeight: 2 }}
                  >
                    Enter or paste your Metamask wallet address, or you can
                    connect it directly
                  </span>
                </div>
                <div className="mt-5">
                  <div className="d-flex flex-column justify-content-between">
                    <input
                      placeholder="Enter Wallet Address"
                      className="modalInput font_color_black text-truncate"
                      value={ETHAddress}
                      onChange={(e) => getMetamaskAddress(e.target.value)}
                    />
                    <Image
                      src={Img}
                      height={50}
                      width={50}
                      style={{ marginLeft: 10, marginTop: "-61px" }}
                    />
                  </div>
                </div>

                <div className="mt-5">
                  <span className="font_color">-or-</span>
                </div>

                <div className="mt-5 col-12 col-lg-8">
                  <button
                    className=" modalBtn bg_white fw-bolder"
                    onClick={() => linkMeta()}
                  >
                    Direct Metamask Connect
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-end ">
                <button className="btn-blue" onClick={() => modalOpen()}>
                  X
                </button>
              </div>
              <div className="container p-5">
                <div className="row d-flex justify-content-center align-items-center text-center">
                  <div>
                    <h2
                      className="font_color fw-bolder"
                      style={{ fontSize: "32px" }}
                    >
                      Wallet Connected!
                    </h2>
                    <span
                      className="font10 font_color_black"
                      style={{ lineHeight: 2 }}
                    >
                      You have sucessfully connected your wallet, now you can
                      start bidding or upload your own art!
                    </span>
                  </div>
                  <div className="mt-5 col-8">
                    <button
                      className=" modalBtn  bg_white fw-bolder"
                      onClick={() => redirect("/")}
                    >
                      Discover Arts
                    </button>
                    <button
                      className=" modalBtn bg_white fw-bolder  mt-5"
                      onClick={() => redirect("/upload-nft")}
                    >
                      Upload My Own Art
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
      <SweetAlert
        type={sweetAlert?.type}
        title={sweetAlert?.title}
        onConfirm={() => _alert((s) => ({ ...s, show: false }))}
        show={sweetAlert?.show}
      />
      <Loader show={loading} />
    </>
  );
};

export default MetamaskModal;
