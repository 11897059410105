
//import styles from '@/styles/Home.module.css'
import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar } from 'swiper';
 import 'swiper/swiper.css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';
import './index.css';
import nftDetail0 from './assets/nft_detail0.png';
import nftDetail1 from "./assets/nft_detail1.png";
import nftDetail1Pc from "./assets/nft_detail1_pc.png";
import nftDetail2 from "./assets/nft_detail2.png";
import nftDetail3 from "./assets/nft_detail3.png";
import nftDetail4 from "./assets/nft_detail4.png";
import nftDetail5 from "./assets/nft_detail5.png";
import nftDetail6 from "./assets/nft_detail6.png";
import nftDetail7 from "./assets/nft_detail7.png";
import nftDetail8 from "./assets/nft_detail8.png";
import nftDetailScroll from "./assets/nft_detail_scroll.png";
import nftDetailScrollPc from "./assets/nft_detail_scroll_pc.png";
import productDesc1 from "./assets/product_desc1.png";
import productDesc2 from "./assets/product_desc2.png";
import targetMarket1 from "./assets/target_market1.png";
import targetMarket2 from "./assets/target_market2.png";
import targetMarket3 from "./assets/target_market3.png";
import targetMarket4 from "./assets/target_market4.png";
// import priceTag from "./assets/price_tag_230125.jpg";
import bottomNFW from "./assets/bottomNFW.png";
import arrowLeftIco from "./assets/arrow_left_simple(white).svg";
import arrowRightIco from "./assets/arrow_right_simple(white).svg";
import arrowDownIco from "./assets/arrow_down_simple(white).svg";
import fingerLeft from "./assets/finger_left.svg";


const swiperList = [
  { id: 1, src: nftDetail1, pcSrc: nftDetail1Pc },
  { id: 2, src: nftDetail2, pcSrc: null },
  { id: 3, src: nftDetail3, pcSrc: null },
  { id: 4, src: nftDetail4, pcSrc: null },
  { id: 5, src: nftDetail5, pcSrc: null },
  { id: 6, src: nftDetail6, pcSrc: null },
  { id: 7, src: nftDetail7, pcSrc: null },
  { id: 8, src: nftDetail8, pcSrc: null },
  { id: 9, src: nftDetailScroll, pcSrc: nftDetailScrollPc },
];

export default function App() {
  // const swiper = new Swiper('.swiper', {
  //   // Install modules
  //   modules: [Navigation, Pagination,],
  //   speed: 500,
  //   navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev',
  //   },
  //   // ...
  // });

  //const { nftSchedule } = props;
  const [exposureSwiper, setExposureSwiper] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [exposureMaterial, setExposureMaterial] = useState(false);
  // const router = useNavigate();

  const _onSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
  };

  /**
   * Swiper navigation
   * @param {string} direction
   */
  const handleNavigation = (direction) => {
    const prevButton = document.querySelector(".swiper-button-prev");
    if (direction === "prev" && prevButton) {
      prevButton.click();
    }
    const nextButton = document.querySelector(".swiper-button-next");
    if (direction === "next" && nextButton) {
      nextButton.click();
    }
  };

  /**
   * ScrollDown : 541px 스크롤 다운
   * scrollBehavior: smooth로 스크롤 이동 후 initial
   */
  const scrollDown = () => {
    document.querySelector(".layout_content_container").style.scrollBehavior =
      "smooth";
    document.querySelector(".layout_content_container").scrollTop = 570;
    setTimeout(
      () =>
      (document.querySelector(
        ".layout_content_container"
      ).style.scrollBehavior = "initial"),
      500
    );
  };

  /**
   * 원자재 정보 토글
   */
  const toggleMaterial = () => {
    setExposureMaterial((prevState) => !prevState);
  };

  useEffect(() => {
    if (!exposureSwiper) {
      setTimeout(() => setExposureSwiper(true), 2000);
    }
  }, []);

  /**
   * 구매하기 페이지로 이동
   */
  const handleBuyNft = () => {
    //router.push("/nft/buy");
  };

  /**
   * NFT 보관함으로 이동
   */
  const movePossession = () => {
    //router.push("/nft/possession");
  };
  //console.log(nftSchedule);

  return (

    <main className='main'>
      <div className="container layout_content_container">
        <h1 className="title">{`KWM-NFW 특징`}</h1>
        {exposureSwiper ? (
          <div className="image_container">
            {activeIndex > 0 && (
              <img
                src={arrowLeftIco}
                alt="arrow_left_white"
                className="arrow_left"
                onClick={() => handleNavigation("prev")}
              />
            )}
            <Swiper
              modules={[Navigation, Pagination, Scrollbar]}
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              slidesPerView={1}
              onSlideChange={_onSlideChange}
            >
              {swiperList.map((item, i) => (
                <SwiperSlide key={item.id} style={{ textAlign: "center" }}>
                  {item.pcSrc ? (
                    <picture>
                      <source
                        srcSet={item.pcSrc}
                        media="(min-width: 1200px)"
                      />
                      <img src={item.src} alt="nft_detail" width="100%" />
                    </picture>
                  ) : (
                    <img src={item.src} alt="nft_detail" width="100%" />
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
            {activeIndex < swiperList.length - 1 && (
              <img
                src={arrowRightIco}
                alt="arrow_right_white"
                className="arrow_right"
                onClick={() => handleNavigation("next")}
              />
            )}
          </div>
        ) : (
          <div className="image_prerender">
            <img src={nftDetail0} alt="nft_detail" />
          </div>
        )}
        {exposureSwiper && (
          <img
            src={arrowDownIco}
            alt="arrow_down"
            className="arrow_down"
            onClick={() => scrollDown()}
          />
        )}
        <section className="product_desc">
          <h2 className="title">상품 설명</h2>
          <p>{`코리아워런트 KWM-NFW의 인증번호는 카네어스가 제공하게 될 선도거래소(Khanaires.ai)에서 60여 종의 국내외 원자재에 대해 선도거래 계약 주문과 주문대행을 할 수 있는 회원임을 증명하는 회원권 NFW를 의미합니다.`}</p>
          <img
            src={productDesc1}
            alt="productDesc1"
            className="w_100"
            style={{ marginBottom: "7px" }}
          />
          <p>{`카네어스의 선도거래소(Khanaires.ai)는 각각의 원자재 매입상과 상품 공급자와의 사이를 선도계약(Forward Contract)으로 연결하는 플랫폼으로써 모든 계약의 건에 대하여 상품의 실물 인도와 실시간 2차 가공이나 물류 정보 등이 담긴 상품창고증권(Warehouse Warrant)서비스를 제공하는 것을 목적으로 합니다.`}</p>
          <img
            src={productDesc2}
            alt="productDesc2"
            className="w_100"
            style={{ marginBottom: "4px" }}
          />
          <p>{`2018년부터 카네어스는 국제 선물거래 시장 및 블록체인 서비스와 관련하여 마드리드 국제상표출원 제도를 이용하여 미국과 영국을 포함한 다수의 국가에 280여건의 트레이드마크 특허를 선점하고 있으며 향후 카네어스의 선도거래소(Khanaires.ai)에서 취급하게 될 국내외 원자재의 NFW 마켓플레이스(각각의 거래소)의 트레이드마크는 아래와 같습니다.`}</p>
          <p className="collapse_wrapper" onClick={() => toggleMaterial()}>
            <em>원자재 NFW 마켓플레이스 트레이드마크</em>
            <img src={fingerLeft} alt="more_info" />
          </p>
          {exposureMaterial && (
            <p className="material">
              <em>ACTIVA</em>, ALUMINIUM, ANTIMONY, BISMUTH, <em>CHROMIUM</em>
              , <em>CRUDE</em>, INDIUM, LANTHANUM, <em>LEAD</em>,
              <em>LITHIUM</em>, <em>MANGANESE</em>, <em>MOLYBDENUM</em>,{" "}
              <em>PALLADIUM</em>, <em>RUBBER</em>, <em>SILICA</em>,{" "}
              <em>SILICON</em>, <em>TUNGSTEN</em>, TANTALITE, <em>URANIUM</em>
              , <em>VANADIUM</em>, <em>ZINC</em>, AMETHYST, APATITE, ARSENIC,
              BERYLLIUM, BLUE GREEN ALGAE, CERIUM, COLUMBIUM, DIOPSIDE,
              DYSPROSIUM, EUROPIUM, GADOLINIUM, GALLIUM, GERMANIUM,{" "}
              <em>GRAPHITE</em>, HAFNIUM, IRONORE, MAGNESIUM, NEODYMIUM,{" "}
              <em>NONI</em>, POTASH, PRASEODYMIUM, RHENIUM, RHODIUM,
              RHTHENIUM, SAMARIUM,
              <em>SELENIUM</em>, SERPENTINE, SHUNGITE, STANNUM, TELLURIUM,
              TERBIUM, YTTRIUM, <em>ZEOLITE</em>, ZIRCONIUM
            </p>
          )}
          <p>{`※모든 원자재들은 생산지들이 다르고 물성이 모두 달라 각각 채굴 방법 및 선별 그리고 유통과정에서 발생하는 단위 거래와 물류의 방법들이 모두 다르기 때문에 상기 원자재들은 각각의 개별거래소로 개발될 예정이며, 각 거래소들마다 회원제로 영위하기 위하여 각각의 원자재 별 회원권 NFW 발행을 채택하였습니다.\n\n단, KWM-NFW 소지자는 Khanaires.ai에 대한 소프트웨어 저작권의 잠정적인 지분에 대한 소유권자이므로 중앙에서 서비스하는 모든 원자재에 대해서 주문권과 주문 대행권 및 각각의 원자재 거래소가 회원권 NFW 등을 발행할 때마다 소정의 로열티 수수료 수입을 수령할 수 있는 특별한 혜택을 가집니다.`}</p>
        </section>
        <section className="target_market">
          <h2 className="title">목표 시장</h2>
          <img
            src={targetMarket1}
            alt="targetMarket1"
            className="w_100"
            style={{ marginBottom: "20px" }}
          />
          <p>{`Khanaires.ai는 국내외 양식장의 어패류와 소나 돼지와 같은 축산물 등의 농수산물은 물론 바이오 에너지의 원료인 해조류부터 2차 전지용 광물 및 재생산 단계의 유전 등과 같이 인류의 의식주와 관련하여 실생활에 반드시 필요한 모든 유형의 원자재 상품(Commodity)등의 시장에 대해 생산자와 매집상을 직접 입도선매 할 수 있는 거래 기능 제공 및 중개하는 중앙 시장 기능 서비스 제공을 목표로 합니다.`}</p>
          <figure style={{ marginBottom: "20px" }}>
            <img src={targetMarket2} alt="targetMarket2" className="w_100" />
            <figcaption>입도선매(Forward Transaction)</figcaption>
          </figure>
          <h3 className="sub_title">세계 유일 선도거래 플랫폼</h3>
          <p>{`나아가 입도선매했던 모든 상품들이 필요한 제조업체들은 물론 최종 소비자들에게도 국내외 원자재 상품들의 생산과 유통 이력에 대한 정보 제공 서비스를 구축하는 것을 목표로 하고 있습니다.\n\n동 시장의 육성을 위해 카네어스(주)는 앱 칸태움을 통해 소비자가 생산자가 되는 1차 100만 명의 회원을 영입하는 것을 목표로 하고 있습니다.`}</p>
          <h4 className="badge_title">KWM-NFW 인도 시기</h4>
          <em className="badge_em">2023년 5월 22일 월요일</em>
          <h4 className="badge_title">KWM-NFW 인도 장소</h4>
          <em className="badge_em mb_30px">
            Khanteum.io(KWM-NFW)회원 커뮤니티 사이트
          </em>
          <h3 className="sub_title">사용</h3>
          <p>{`코리아워런트KWM-NFW(Forward Transaction Bidding Certification Membership NFW)는 향후 카네어스㈜가 제공하는 국내외 60여 종류의 원자재의 선도거래소(Khanaires.ai)에 대한 주문 입찰권을 증명하는 회원권이며, 동 선도거래소에 대한 소프트웨어 저작권 지분으로 총 10,000매만 발행됩니다.`}</p>
          <img
            src={targetMarket3}
            alt="targetMarket3"
            className="w_100"
            style={{ marginBottom: "34px" }}
          />
          <h3 className="sub_title">로열티 수입의 원천</h3>
          <p>{`당 회원권은 약 60여 종의 국내외 원자재 선도거래소 소프트웨어 프로그램 저작권 지분으로 10,000매 중 총 2,000매의 유동화로 인하여 전체 소프트웨어 지분의 20%에 해당되고, 동 거래소의 개발 완료 후 진행되는 소프트웨어 저작권에 대해선 회원권 100매가 1% 지분에 해당됩니다.\n\n향후 카네어스에서 서비스하는 선도거래소(Khanaires.ai)에서 발행 유통하는 각각의 원자재들에 대한 발행 및 유통 수수료에 대해 동 KWM-NFW 소지자는 소정의 로열티를 지급받게 됩니다.`}</p>
          <figure style={{ marginBottom: "30px" }}>
            <img src={targetMarket4} alt="targetMarket4" className="w_100" />
            <figcaption>
              회원권의 시세는 회원들의 투표로 결정합니다.
            </figcaption>
          </figure>
          <h3 className="sub_title">시장가격 조성</h3>
          <p>{`KWM-NFW는 총 10,000매만 한정해서 회원권(Membership)NFW로 발행될 예정입니다. 이 중 NFW 고유 번호 NO.1~NO.2000호 까지만 매각 대상이며, 회원권의 가치 유지를 위하여 나머지 8,000매는 카네어스㈜ 소유로 매당 2억원 아래로는 회사 보유분은 매각이 제한됩니다.(단, 회원들과 협의 시 회사 보유분의 제한 가격은 변동될 수 있습니다.) KWM-NFW 회원권의 시세는 항상 회원들의 투표로 결정합니다.`}</p>
        </section>
        <section className="option_issue">
          <h2 className="title">옵션 교부</h2>
          <ol>
            <li>{`KWM-NFW는 모두의 창업 오디션의 첫 출전 상품으로 금번 KWM-NFW를 구매하신 모든 회원들은 칸태움 앱의 ‘프로듀서’ 지위가 되어 KWM-NFW 외에도 카네어스(주)가 운영하고 있는 받을 권리 공유시스템에 의해 ‘총액 합산제 로열티 배당 제도(Total Royalty Dividend System)’의 로열티 지분을 분배 받을 수 있는 자격이 부여됩니다. `}</li>
            <li>{`KWM-NFW를 구매한 모든 회원들은 2023년 5월 14일까지 ‘모두의 창업 오디션’ 방송 프로그램에서 자신이 행사한 푸쉬 대금만큼 원 저작권자의 저작권 지분을 교부 받는 방법을 통하여 칸태움 앱에서 서비스하는 ‘모두의 창업 오디션’의 PD의 지위에 임명되며, 2023년 5월 15일 저작권 지분을 교부 받게 됩니다. `}</li>
            <li>{`모두의 창업 오디션에 참여한 모든 PD들의 푸쉬를 합산하여 푸쉬 지분(로열티 받을 권리)을 분배 받을 수 있습니다. (2023년 1월부터 실시 예정)`}</li>
            <li>{`KWM-NFW를 구매한 모든 회원들은 경합형 선도거래 시스템과 받을 권리 공유시스템의 개발자의 받을 권리 지분 상품인 ‘칸태움 밸류’를 푸쉬 행사 대금에 대해 칸태움 밸류의 해당 시세 가격에 무상 교부합니다.`}</li>
          </ol>

          <img
            src={bottomNFW}
            alt="price_tag"
            className="w_100 price_tag"
            style={{ marginBottom: "35px" }}
          />
          <button className="buy_button" onClick={() => alert('임시 화면입니다.')}>
            NFW 보관함
          </button>

          {/* {!nftSchedule || new Date() > new Date(nftSchedule.end_date) ? (
              <button className="buy_button" onClick={() => movePossession()}>
                NFT 보관함
              </button>
            ) : (
              <button className="buy_button" onClick={() => handleBuyNft()}>
                구매 하기
              </button>
            )} */}
        </section>
      </div>
    </main>
  )
}
