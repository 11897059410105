import React, {useEffect, useState} from 'react';
import {Button, Form, Modal, Spinner} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {HashLink as Link} from "react-router-hash-link";

import {useGoogleLogin} from 'react-google-login';
import SweetAlert from 'react-bootstrap-sweetalert';
import {useDispatch} from "react-redux";
import API from "../../redux/API";
import {GoogleClientId} from "../../utils/config";
import {setLoggedUser} from "../../redux/reducers/user";
import Loader from "../../components/loader";
import google from "../../images/logo/ggogle.png";
import login from "../../images/background/login.png";

import HeroSection from "../../components/HeroSection";
import {BsEye, BsEyeSlash} from "react-icons/bs";
import './login.css';
import { gapi } from 'gapi-script';

function Login() {
    // login
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [loading, _loading] = useState(false)
    const [email, _email] = useState('');
    const [password, _password] = useState('');
    const [alert, _alert] = useState({show: false, title: '', type: 'success'});
    const [showMyPassword,_showMyPassword] = useState(false);

    useEffect(() => {
        function start() {
            gapi.client.init({
              clientId: GoogleClientId,
              scope: 'email',
            });
          }
      
          gapi.load('client:auth2', start);

        return (() => {
            _email('');
            _password('')
        });
    }, []);

    const handleSubmit = async (event) => {

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            _loading(true)
            API({url: 'user/login', data: {email, password}}).then(async res => {
                _alert({show: true, title: 'Login success', type: 'success'})
                await dispatch(setLoggedUser(res.data.data))
                localStorage.setItem('user', JSON.stringify(res.data.data));
                navigate('/profile')
                _loading(false)
            }).catch(err => {
                _loading(false)
                const msg = err?.message ? err.message : 'something went wrong';
                _alert({show: true, title: msg, type: 'error'})
                if (err?.message === 'User does not exist') {
                    setTimeout(() => {
                        navigate('/signup')
                    }, 1500);
                }
            })
        }
        setValidated(true);
    };
    const onSuccess = ({profileObj, tokenId, ...res}) => {
        const param = {
            "email": profileObj.email,
            "fullName": profileObj.name,
            "googleId": profileObj.googleId,
            "idToken": tokenId
        }
        _loading(true)
        API({url: 'user/google-login', data: param}).then(async res => {
            _alert({show: true, title: 'Login success', type: 'success'})
            await dispatch(setLoggedUser(res.data.data))
            localStorage.setItem('user', JSON.stringify(res.data.data));
            setTimeout(() => {
                navigate('/profile')
            }, 500);
            _loading(false)
        }).catch(err => {
            _loading(false)
            const msg = err.message ? err.message : 'something went wrong';
            _alert({show: true, title: msg, type: 'error'})
        })
    }
    const onFailure = (err) => {
        console.log('err XXXXX', err);
    }

    const {signIn, loaded} = useGoogleLogin({
        onSuccess,
        onFailure,
        clientId: GoogleClientId,
        isSignedIn: true,
        cookiepolicy: 'single_host_origin',
        accessType: 'offline'
    })

    const showPasswordInput = () => {
        let x = document.getElementById("myInputPassword");
        if (x.type === "password" ) {
            x.type = "text";
            _showMyPassword(true);
        } else {
            x.type = "password";
            _showMyPassword(false);
        }
    }

    return (
        <>
            <div className="login-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="login-image">
                                <img src={login} alt="logo"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="login-box">
                                <div className="row text-center">
                                    <div className="col welcome-text">
                                        <h1>Log in</h1>
                                        <div className="d-flex justify-content-center"></div>
                                    </div>
                                </div>
                                <div className="row text-center">
                                    <div className="col login-link mt-3">
                                        Don't have an account yet?
                                        <Link to="/signup/#top" className="text-blue underline">
                                            Sign up
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="signin-container">
                                        <div className="signin-form">
                                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>E-mail(ID)</Form.Label>
                                                    <Form.Control
                                                        name="email"
                                                        type="email"
                                                        placeholder=""
                                                        onChange={(e) => _email(e.target.value)}
                                                        required
                                                        style={{outline:'none',border:0}}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Email is Required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Password</Form.Label>
                                                    <Form.Control
                                                        name="password"
                                                        type="password"
                                                        id="myInputPassword"
                                                        onChange={(e) => _password(e.target.value)} required
                                                    />
                                                    <div style={{ position: "absolute", right: "4%", top: '68%', cursor: "pointer" }}>
                                                        {showMyPassword ? <BsEyeSlash onClick={() => showPasswordInput()}/>:<BsEye onClick={() => showPasswordInput()}/>}
                                                    </div>
                                                    <Form.Control.Feedback type="invalid">
                                                        Password is Required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Link to="/reset-password">
                                                    <p className="text-blue underline mt-5">Forgot password?</p>
                                                </Link>

                                                <div className="login-button">
                                                    {loading ?
                                                    <button
                                                        type="submit"
                                                        className="register-submit button-gradient-color btn btn-primary border-0"
                                                    >
                                                        Log in<Spinner animation="border" size="sm" className="ms-2"/>
                                                    </button> :   <button
                                                            type="submit"
                                                            className="register-submit button-gradient-color btn btn-primary border-0"
                                                        >
                                                            Log in
                                                        </button>}
                                                    <button type="button" onClick={signIn} className="google-login btn btn-primary">
                                                        <img src={google} alt="google"/>
                                                        <span className="align-self-start"> Log in </span>
                                                    </button>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <LoginWarning
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <SweetAlert type={alert.type} title={alert.title} onConfirm={() => _alert(s => ({...s, show: false}))}
                        show={alert.show}/>
            <Loader show={loading}/>
        </>
    );
}

function LoginWarning({show, onHide}) {
    return (
        <Modal
            centered
            backdrop="static"
            show={show}
            aria-labelledby="contained-modal-title-vcenter">
            <Modal.Body className="login-wranning-body">
                <div className="text-center mb-4 mt-3 login-wraning-message">
                    This is not a registered account <br/> Would you like to become a member?
                </div>
                <div className="d-flex justify-content-around">
                    <Button onClick={onHide} className="login-button-wrapper back-button mr-2">Back</Button> <Button
                    onClick={onHide} className="login-button-wrapper confirm-button">Confirm</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default Login;
