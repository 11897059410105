import React, {useEffect, useState} from "react";
import { Form, FormControl, Row} from 'react-bootstrap'
import API from "../../redux/API";
import searchIcon from "../../images/others/search-icon.svg";
import {useNavigate} from "react-router-dom";
import SearchHeaderLayout from "../../components/SearchHeaderlayout";
import Loader from "../../components/loader";
import _ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import Switch from "react-switch";
import NftArtCard from "../../components/NftArtCard";
import './Marketplace.css';

const Marketplace = () => {

    let baseUrl = 'user/art-work/search?page=1&limit=3&nft_type=0';
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [categoriesList, setCategoriesList] = useState([]);
    const [activeCategory, setActiveCategory] = useState([]);
    const [artPhoto, _artPhoto] = useState([]);
    const [artVideo, _artVideo] = useState([]);
    const [art, _art] = useState([]);
    const [artMusic, _artMusic] = useState([]);
    const [artEbook, _artEbook] = useState([]);
    const [artWebToon, _artWebToon] = useState([]);
    const [searchCategory, _searchCategory] = useState([]);
    const [page, _page] = useState(1);
    const [lastPage, _lastPage] = useState(false);
    const [alert, _alert] = useState({show: false, title: '', type: 'success' || 'error'});
    const [checked, _checked] = useState({
        filterPhoto: false,
        filterVideo: false,
        filterArt: false,
        filterMusic: false,
        filterEbook: false,
        filterWebtoon: false
    });

    useEffect(() => {
        API({url: 'user/art-work/category/fixed', method: "GET"}).then(async res => {
            setCategoriesList(res.data.data)
        }).catch(error => {
            setIsLoading(false);
        });

        return(() => {
            setCategoriesList([]);
            setActiveCategory([]);
            _artPhoto([]);
            _artPhoto([]);
            _art([]);
            _artMusic([]);
            _artEbook([]);
            _artWebToon([]);
            _searchCategory([]);
        })
    }, []);


    useEffect(() => {

        categoryPhotosNft(false,'0');
        categoryVideosNft(false,'1');
        categoryArtNft(false,'2');
        categoryMusicNft(false,'3');
        categoryEbookNft(false,'4');
        categoryWebtoonNft(false,'5');
    }, []);

    useEffect(() => {
        if (typeof activeCategory === 'number' && activeCategory !== '') {
            searchByCategory()
        }
    }, [activeCategory]);

    const searchByCategory = () => {
        setIsLoading(true);
        const marketPlaceApi = `user/art-work/search?page=1&limit=10&art_work_category=${activeCategory}`;
        API({url: marketPlaceApi, method: "GET"}).then(async res => {
            const data = res.data.data;
            if(data.length > 0){
                _searchCategory(res.data.data);
            }else{
                _alert({show: true, type: "warning", title: "Searching Data not Available ...!"});
            }

        }).catch((err) => console.log('errors', err)).finally(() => setIsLoading(false));
    }

    const searchResult = (searchData) => {

        const marketPlaceApi = `user/art-work/search?search=${searchData}`;
        if (searchData !== '') {
            setIsLoading(true);
            API({url: marketPlaceApi, method: "GET"}).then(async res => {
                const data = res.data.data;
                if(data.length > 0){
                    _searchCategory(res.data.data);
                }else{
                    _alert({show: true, type: "warning", title: "Searching Data not Available ...!"});
                }

            }).catch((err) => console.log('errors', err)).finally(() => setIsLoading(false));
        } else {
            _searchCategory([]);
        }
    }

    const printValue = _.debounce(value => searchResult(value), 1000);
    const onChange = async ({target}) => {
        await printValue(target.value)
    };


    const getArtMarketPlaceNft = (filter = '0', category) => {
        API({url: `${baseUrl}&sortBy&${filter}&art_work_category=${category}`, method: "GET"}).then(async res => {

            try {
                setIsLoading(true);
                if (category === '0') {
                    _artPhoto(res.data.data);
                } else if (category === '1') {
                    _artVideo(res.data.data);
                } else if (category === '2') {
                    _art(res.data.data);
                } else if (category === '3') {
                    _artMusic(res.data.data);
                } else if (category === '4') {
                    _artEbook(res.data.data);
                } else if (category === '5') {
                    _artWebToon(res.data.data);
                }

                _lastPage(res.data.lastPage)
            } finally {
                setIsLoading(false);
            }

        }).catch(error => {
            console.log('error', error)
            setIsLoading(false);
        });
    }

    const categoryPhotosNft = (e,category) => {
        _checked({...checked, filterPhoto: e});
        let filter = '';
        if (e) {
            filter = 'created_at';
        } else {
            filter = 'popular';
        }
        getArtMarketPlaceNft(filter, category)
    }

    const categoryVideosNft = (e,category) => {
        _checked({...checked, filterVideo: e});
        let filter = '';
        if (e) {
            filter = 'created_at';
        } else {
            filter = 'popular';
        }
        getArtMarketPlaceNft(filter, category)
    }

    const categoryArtNft = (e,category) => {
        _checked({...checked, filterArt: e});
        let filter = '';
        if (e) {
            filter = 'created_at';
        } else {
            filter = 'popular';
        }
        getArtMarketPlaceNft(filter, category)
    }

    const categoryMusicNft = (e,category) => {
        _checked({...checked, filterMusic: e});
        let filter = '';
        if (e) {
            filter = 'created_at';
        } else {
            filter = 'popular';
        }
        getArtMarketPlaceNft(filter, category)
    }

    const categoryEbookNft = (e,category) => {
        _checked({...checked, filterEbook: e});
        let filter = '';
        if (e) {
            filter = 'created_at';
        } else {
            filter = 'popular';
        }
        getArtMarketPlaceNft(filter, category)
    }

    const categoryWebtoonNft = (e,category) => {
        _checked({...checked, filterWebtoon: e});
        let filter = '';
        if (e) {
            filter = 'created_at';
        } else {
            filter = 'popular';
        }
        getArtMarketPlaceNft(filter, category)

    }


    return (
        <>
            <section className="container sectionMargin mt-5" style={{marginTop: '5%'}}>
                <div className="row">
                    <div className="nft-search">
                        <Form.Select
                            aria-label="select"
                            className="select-arrow"
                            onChange={(e) => setActiveCategory(Number(e.target.value))}
                        >
                            {categoriesList.map((item, index) => <option key={index}
                                                                         value={item.id}>{item.category_name}</option>)}
                        </Form.Select>

                        <div className="search-field">
                            <FormControl
                                placeholder="Search"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                onChange={onChange}
                            />
                            <img src={searchIcon} alt="icon"/>
                        </div>
                        <button className="btn gradient-green-btn" onClick={() => navigate('/upload-nft')}>Issue NFW
                        </button>
                    </div>

                    {searchCategory?.length > 0 ? <>
                            {!isLoading && searchCategory.length > 0 ? <>
                                    <div className="mt-5">
                                        <h4 className="fw-bold">Search By Category</h4>
                                        <Row className="">
                                            {searchCategory?.map((item, index) =>
                                                <NftArtCard cardData={item} key={index} isMarketPlace={true} _alert={_alert}/>
                                            )}
                                        </Row>
                                    </div>
                                </>
                                :
                                <div className="d-flex justify-content-center align-items-center mt-5">
                                    <h4>Searching Data not Available ...!</h4>
                                </div>
                            }
                        </> :
                        <div>
                            <div className="mt-5">
                                <div className="d-flex justify-content-between align-items-center">
                                    {/*<h4 className="fw-bold">Photos by popularity</h4>*/}

                                    <div className=" mouse-pointer">
                                        <Switch
                                            onChange={(e)=>categoryPhotosNft(e,0)}
                                            checked={checked.filterPhoto}
                                            className="react-switch"
                                            uncheckedIcon={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "100%",
                                                        fontSize: 15,
                                                        color: "#0a1748",
                                                        paddingRight: 20,
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    Popularity
                                                </div>
                                            }
                                            checkedIcon={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "100%",
                                                        fontSize: 15,
                                                        color: "#0a1748",
                                                        paddingRight: 2,
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    Latest
                                                </div>
                                            }
                                            width={120}
                                            height={40}
                                        />
                                    </div>
                                </div>
                                {!isLoading && artPhoto.length > 0 ? <>
                                        <Row className="">
                                            {artPhoto?.filter((val) => {
                                                if (val.selling_available == true) {
                                                    return val;
                                                }
                                            }).map((item, index) => <NftArtCard cardData={item} key={index}
                                                                                        isMarketPlace={true} _alert={_alert}/>)}
                                        </Row>
                                        <div className="mt-3 d-flex justify-content-center align-items-center">
                                            <span className="fw-bold mouse-pointer" onClick={() => navigate('/marketplace/category/0')}>load more</span>
                                        </div>
                                    </>
                                    :
                                    <div className="d-flex justify-content-center align-items-center mt-5">
                                        <h4>Coming Soon</h4>
                                    </div>
                                }
                            </div>
                            <div className="mt-5">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="fw-bold">회원권</h4>
                                    <div className=" mouse-pointer">

                                        <Switch
                                            onChange={(e)=>categoryVideosNft(e,1)}
                                            checked={checked.filterVideo}
                                            className="react-switch"
                                            uncheckedIcon={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "100%",
                                                        fontSize: 15,
                                                        color: "#0a1748",
                                                        paddingRight: 20,
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    Popularity
                                                </div>
                                            }
                                            checkedIcon={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "100%",
                                                        fontSize: 15,
                                                        color: "#0a1748",
                                                        paddingRight: 2,
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    Latest
                                                </div>
                                            }
                                            width={120}
                                            height={40}
                                        />
                                    </div>
                                </div>

                                {!isLoading && artVideo.length > 0 ? <>
                                        <Row className="">
                                            {artVideo?.filter((val) => {
                                                if (val.selling_available == true) {
                                                    return val;
                                                }
                                            }).map((item, index) => <NftArtCard cardData={item} key={index}
                                                                                        isMarketPlace={true} _alert={_alert}/>)}
                                        </Row>
                                        <div className="mt-3 d-flex justify-content-center align-items-center">
                                            <span className="fw-bold mouse-pointer" onClick={() => navigate('/marketplace/category/1')}>load more</span>
                                        </div>
                                    <h1>test</h1>
                                    </>
                                    :
                                    <div className="d-flex justify-content-center align-items-center mt-5">
                                        <h4>Coming Soon</h4>
                                    </div>
                                }
                            </div>
                            <div className="mt-5">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="fw-bold">특허권</h4>
                                    <div className=" mouse-pointer">
                                        <Switch
                                            onChange={(e)=>categoryArtNft(e,2)}
                                            checked={checked.filterArt}
                                            className="react-switch"
                                            uncheckedIcon={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "100%",
                                                        fontSize: 15,
                                                        color: "#0a1748",
                                                        paddingRight: 20,
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    Popularity
                                                </div>
                                            }
                                            checkedIcon={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "100%",
                                                        fontSize: 15,
                                                        color: "#0a1748",
                                                        paddingRight: 2,
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    Latest
                                                </div>
                                            }
                                            width={120}
                                            height={40}
                                        />
                                    </div>
                                </div>

                                {!isLoading && art.length > 0 ? <>
                                        <Row className="">
                                            {art?.filter((val) => {
                                                if (val.selling_available == true) {
                                                    return val;
                                                }
                                            })
                                            .map((item, index) => <NftArtCard cardData={item} key={index}
                                                                                   isMarketPlace={true} _alert={_alert}/>)}
                                        </Row>
                                        <div className="mt-3 d-flex justify-content-center align-items-center">
                                            <span className="fw-bold mouse-pointer" onClick={() => navigate('/marketplace/category/2')}>load more</span>
                                        </div>
                                    </>
                                    :
                                    <div className="d-flex justify-content-center align-items-center mt-5">
                                        <h4>Coming Soon </h4>
                                    </div>
                                }
                            </div>
                            <div className="mt-5">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="fw-bold">디자인</h4>
                                    <div className=" mouse-pointer">
                                        <Switch
                                            onChange={(e)=>categoryMusicNft(e,3)}
                                            checked={checked.filterMusic}
                                            className="react-switch"
                                            uncheckedIcon={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "100%",
                                                        fontSize: 15,
                                                        color: "#0a1748",
                                                        paddingRight: 20,
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    Popularity
                                                </div>
                                            }
                                            checkedIcon={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "100%",
                                                        fontSize: 15,
                                                        color: "#0a1748",
                                                        paddingRight: 2,
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    Latest
                                                </div>
                                            }
                                            width={120}
                                            height={40}
                                        />
                                    </div>
                                </div>

                                {!isLoading && artMusic.length > 0 ? <>

                                        <Row className="">
                                            {/* eslint-disable-next-line array-callback-return */}
                                            {artMusic?.filter((val) => {
                                                if (val.selling_available === true) {
                                                    return val;
                                                }
                                            }).map((item, index) => <NftArtCard cardData={item} key={index}
                                                                                        isMarketPlace={true} _alert={_alert}/>)}
                                        </Row>
                                        <div className="mt-3 d-flex justify-content-center align-items-center">
                                            <span className="fw-bold mouse-pointer" onClick={() => navigate('/marketplace/category/3')}>load more</span>
                                        </div>
                                    </>
                                    :
                                    <div className="d-flex justify-content-center align-items-center mt-5">
                                        <h4>Coming Soon</h4>
                                    </div>
                                }
                            </div>
                            <div className="mt-5">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="fw-bold">상표권</h4>
                                    <div className=" mouse-pointer">
                                        <Switch
                                            onChange={(e)=>categoryEbookNft(e,4)}
                                            checked={checked.filterEbook}
                                            className="react-switch"
                                            uncheckedIcon={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "100%",
                                                        fontSize: 15,
                                                        color: "#0a1748",
                                                        paddingRight: 20,
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    Popularity
                                                </div>
                                            }
                                            checkedIcon={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "100%",
                                                        fontSize: 15,
                                                        color: "#0a1748",
                                                        paddingRight: 2,
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    Latest
                                                </div>
                                            }
                                            width={120}
                                            height={40}
                                        />
                                    </div>
                                </div>

                                {!isLoading && artEbook.length > 0 ? <>

                                        <Row className="">
                                            {artEbook?.filter((val) => {
                                                if (val.selling_available == true) {
                                                    return val;
                                                }
                                            }).map((item, index) => <NftArtCard cardData={item} key={index}
                                                                                        isMarketPlace={true} _alert={_alert}/>)}
                                        </Row>
                                        <div className="mt-3 d-flex justify-content-center align-items-center">
                                            <span className="fw-bold mouse-pointer" onClick={() => navigate('/marketplace/category/4')}>load more</span>
                                        </div>
                                    </>
                                    :
                                    <div className="d-flex justify-content-center align-items-center mt-5">
                                        <h4>Coming Soon</h4>
                                    </div>
                                }
                            </div>
                            <div className="mt-5">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="fw-bold">저작권</h4>
                                    <div className=" mouse-pointer">
                                        <Switch
                                            onChange={(e)=>categoryWebtoonNft(e,5)}
                                            checked={checked.filterWebtoon}
                                            className="react-switch"
                                            uncheckedIcon={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "100%",
                                                        fontSize: 15,
                                                        color: "#0a1748",
                                                        paddingRight: 20,
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    Popularity
                                                </div>
                                            }
                                            checkedIcon={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "100%",
                                                        fontSize: 15,
                                                        color: "#0a1748",
                                                        paddingRight: 2,
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    Latest
                                                </div>
                                            }
                                            width={120}
                                            height={40}
                                        />
                                    </div>
                                </div>

                                {!isLoading && artWebToon.length > 0 ? <>

                                        <Row className="">
                                            {artWebToon?.filter((val) => {
                                                if (val.selling_available == true) {
                                                    return val;
                                                }
                                            }).map((item, index) => <NftArtCard cardData={item} key={index}
                                                                                          isMarketPlace={true} _alert={_alert}/>)}
                                        </Row>
                                        <div className="mt-3 d-flex justify-content-center align-items-center">
                                            <span className="fw-bold mouse-pointer" onClick={() => navigate('/marketplace/category/5')}>load more</span>
                                        </div>
                                    </>
                                    :
                                    <div className="d-flex justify-content-center align-items-center mt-5">
                                        <h4>Coming Soon </h4>
                                    </div>
                                }
                            </div>
                        </div>}


                    <div
                        className="container-fluid mt-5 d-flex justify-content-center align-items-center"
                    >
                        {lastPage &&
                            <button
                                className="btn-blue"
                                onClick={() => _page(page + 1)}
                            >
                                Load More
                            </button>}
                    </div>
                </div>
            </section>
            <Loader show={isLoading}/>
            <SweetAlert type={alert.type} title={alert.title} onConfirm={() => _alert(s => ({...s, show: false}))}
                        show={alert.show}/>
        </>
    );
}

export default Marketplace;
