import React  from "react";
import Modal from 'react-bootstrap/Modal';
import cancelVector from '../../../images/others/Vector.png';
import {Image} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import '../../style.css';

const KycModal = ({kycModal, showKycModal}) => {

    const navigate = useNavigate();

    return (
        <>
            <Modal
                show={kycModal}
                centered
                // onHide={show}
            >
                <Modal.Body>
                    <div className="float-end mouse-pointer me-3" style={{zIndex: 20}} onClick={() => showKycModal()}>
                        <Image src={cancelVector} height={18} width={18}/>
                    </div>
                    <div className="container p-5 mt-3">
                        <div className="row d-flex justify-content-center align-items-center text-center">
                            <div>
                                <p className="font_color fw-bolder" style={{fontSize: '32px'}}>Alert!</p>
                                <div className="col-12 text-center">
                                     <span className="font10" style={{lineHeight: 2, color: '#000000'}}>
                                    Available after KYC verification.
                                    Please complete KYC verification.
                                </span>
                                </div>
                            </div>

                            <div className="mt-5">
                                <button
                                    className="btnBgBlue modalBtn fw-bolder"
                                    onClick={() => navigate('/kyc/status')}
                                >
                                    Go to KYC Verification
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default KycModal;



