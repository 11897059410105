import React, {useCallback, useEffect, useState} from "react";
import {Nav, Row, Tab} from "react-bootstrap";
import HeroSection from "../../components/HeroSection";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import {Formik} from "formik";
import * as YUP from 'yup';
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../../components/loader";
import API from "../../redux/API";
import {useDispatch, useSelector} from "react-redux";
import KycVerifiedModal from "../../Modals/KycVerifiedModal";
import {kycStatus, countryList, idTypeList} from "../../utils/config";
import {setLoggedUser} from "../../redux/reducers/user";
import {updateCurrentUser} from "../../utils/function";
import "../Login/login.css";
import "./kyc.css";

export const stepConfig = {
    first: 'first',
    second: 'second',
    third: 'third'
}

function Kyc() {


    const gender = [{lable: 'Male', value: 'male'}, {lable: 'Female', value: 'female'}];

    let initialValues = {
        name: '',
        date_of_birth: '',
        contact: '',
        gender: '',
        country: '',
        id_type: '',
        id_image_back: '',
        id_image_front: '',
        id_arc: '',
    }

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    let formSchema = {
        name: YUP.string().required('name is required'),
        date_of_birth: YUP.string().required('DOB is required'),
        contact: YUP.string().matches(phoneRegExp, 'Phone number is not valid').required('Contact is required').min(7, 'Minimal value 7').max(14, "Maximum value 14"),
        gender: YUP.string().required('Gender is required'),
        country: YUP.string().required('country is required'),
        id_arc: YUP.string().required('ID(ARC) is required'),
    }

    const {loggedUser} = useSelector(s => s.user);
    const dispatch = useDispatch();
    const [step, _step] = useState(stepConfig.first);
    const [frontImageFile, _frontImageFile] = useState([]);
    const [backImageFile, _backImageFile] = useState([]);
    const [schemaValidation, _schemaValidation] = useState(YUP.object().shape(formSchema));
    const [alert, _alert] = useState({show: false, title: '', type: 'success' || 'error'});
    const [isLoading, setIsLoading] = useState(false);
    const [isFormSubmit, _isFormSubmit] = useState(false);
    const [verifiedModal, _verifiedModal] = useState(false);
    const [formInitialValues, _formInitialValues] = useState(initialValues);


    useEffect(() => {
        initialValues = {
            ...initialValues,
            gender: gender[0].value,
            country: countryList[0].value,
            id_type: idTypeList[0].value
        }
        _formInitialValues(initialValues);
    }, []);

    const showVerifiedModal = () => {
        _verifiedModal(!verifiedModal);
    }

    useEffect(() => {
        if (loggedUser?.kyc_status === kycStatus.waiting || (loggedUser?.kyc_status === kycStatus.verified && loggedUser?.kycVerified)) {
            showVerifiedModal();
        }
    }, [])

    useEffect(() => {
        if (step === 'first') {
            _schemaValidation(YUP.object().shape(formSchema));
        } else if (step === 'second') {
            formSchema = {
                ...formSchema,
                id_type: YUP.string().required('Id Type  is required'),
            }
            _schemaValidation(YUP.object().shape(formSchema));
        }

    }, [step]);


    const handleImageFront = useCallback(acceptedFiles => {
        if (acceptedFiles.length === 1) {
            _frontImageFile([...acceptedFiles])
        } else {
            _alert({show: true, type: "warning", title: 'Please  select Valid Front Image!.....'});
        }


    }, [frontImageFile]);

    const handleImageBack = useCallback(acceptedFiles => {
        if (acceptedFiles.length === 1) {
            _backImageFile([...acceptedFiles])
        } else {
            _alert({show: true, type: "warning", title: 'Please  select Valid Back Image!.....'});
        }

    }, [backImageFile]);


    const removeFrontImageFile = (file) => () => {
        const newFiles = [...frontImageFile];
        newFiles.splice(newFiles.indexOf(file), 1);
        _frontImageFile(newFiles);
    };


    const removeBackImageFile = (file) => () => {
        const newFiles = [...backImageFile];
        newFiles.splice(newFiles.indexOf(file), 1);
        _backImageFile(newFiles);
    };

    const fileValidation = () => {
        if (frontImageFile.length < 1) {
            _alert({show: true, type: "warning", title: 'Please  select the Front Image!.....'});
            return false;
        } else if (backImageFile.length < 1) {
            _alert({show: true, type: "warning", title: 'Please  select the Back Image!.....'});
        }
        return true
    }

    const convertJsonToFormData = (jsonData) => {
        const kycFormData = new FormData();
        backImageFile.forEach(file => {
            kycFormData.append('id_image_back', file);
        });
        frontImageFile.forEach(file => {
            kycFormData.append('id_image_front', file);
        });

        Object.keys(jsonData).forEach(key => {
            if (key === 'name') {
                kycFormData.append('name', jsonData[key]);
            } else if (key === 'date_of_birth') {
                kycFormData.append('date_of_birth', jsonData[key]);
            } else if (key === 'contact') {
                kycFormData.append('contact', jsonData[key]);
            } else if (key === 'gender') {
                kycFormData.append('gender', jsonData[key]);
            } else if (key === 'country') {
                kycFormData.append('country', jsonData[key]);
            } else if (key === 'id_type') {
                kycFormData.append('id_type', jsonData[key]);
            } else if (key === 'id_arc') {
                kycFormData.append('id_arc', jsonData[key]);
            }
        });
        return kycFormData;
    }

    const submitFormData = (formValue) => {

        setIsLoading(true);
        const formData = convertJsonToFormData(formValue)
        API({url: '/user/kyc-submit', data: formData, method: 'PUT'}).then(async res => {
            _alert({show: true, title: res.message, type: 'success'});
            _isFormSubmit(true);
            _step(stepConfig.third);

            // update the user
            setTimeout(async () => {
                await updateCurrentUser(loggedUser)
                    .then(async (res) => {
                        await dispatch(setLoggedUser(res));
                    }).catch((err) => {
                        _alert({show: true, title: err.message, type: 'error'});
                    })
            }, 500);

        }).catch(err => {
            _alert({show: true, title: err.message, type: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const handleSubmit = async (e, action) => {
        let ToDate = new Date();
        if (e) {
            if (new Date(e.date_of_birth).getTime() >= ToDate.getTime()) {
                _alert({show: true, title: "The DOB must be Smaller or Equal to today date", type: 'warning'});
                return false;
            } else {

                _step(stepConfig.second);
                if (step === 'second') {
                    const validateFiles = fileValidation();
                    if (validateFiles) {
                        const submitFormDataValue = {
                            ...e,
                            id_image_front: frontImageFile,
                            id_image_back: backImageFile
                        }
                        submitFormData(submitFormDataValue)
                    }
                }
                return true
            }
        } else {
            return false
        }
    };

    const goToPrevious = () => {
        _step(stepConfig.first);
    }

    return (
        <>
            <div className="kyc-page">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-8 text-center">
                            <div className="col welcome-text">
                                <h1>KYC Verification</h1>
                            </div>
                            <div className="container text-center">
                                <Tab.Container id="left-tabs-example" activeKey={step}>
                                    <Row className="d-flex ">
                                        <div className="d-flex justify-content-center align-item-center ">
                                            <Nav variant="pills" className="flex-row my-3">
                                                <Nav.Item className="mx-3 mx-lg-5">

                                                    <Nav.Link
                                                        eventKey={stepConfig.first}
                                                    >1</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="mx-3 mx-lg-5">
                                                    <Nav.Link eventKey={stepConfig.second}
                                                    >2</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="mx-3  mx-lg-5">
                                                    <Nav.Link eventKey={stepConfig.third}
                                                    >3</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                        <div className="wizard-main">
                                            <div className="wizard-text-style">Information</div>
                                            <div className="wizard-text-space wizard-text-style">Photo Upload</div>
                                            <div className="wizard-text-style">Confirmation</div>

                                        </div>
                                    </Row>
                                    <Row>
                                        <Formik
                                            onSubmit={handleSubmit}
                                            initialValues={formInitialValues}
                                            validationSchema={schemaValidation}
                                            enableReinitialize
                                        >
                                            {({
                                                  handleSubmit,
                                                  handleChange,
                                                  handleBlur,
                                                  values,
                                                  touched,
                                                  isValid,
                                                  errors,

                                              }) => (

                                                <div className="">
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey={stepConfig.first}>
                                                            <Step1
                                                                gender={gender}
                                                                countryList={countryList}
                                                                _step={_step}
                                                                handleChange={handleChange}
                                                                values={values}
                                                                errors={errors}
                                                                handleSubmit={handleSubmit}
                                                            />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey={stepConfig.second}>
                                                            <Step2
                                                                idTypeList={idTypeList}
                                                                _step={_step}
                                                                handleChange={handleChange}
                                                                handleSubmit={handleSubmit}
                                                                values={values}
                                                                frontImageFile={frontImageFile}
                                                                backImageFile={backImageFile}
                                                                handleImageFront={handleImageFront}
                                                                handleImageBack={handleImageBack}
                                                                removeFrontImageFile={removeFrontImageFile}
                                                                removeBackImageFile={removeBackImageFile}
                                                                errors={errors}
                                                                isLoading={isLoading}
                                                                goToPrevious={goToPrevious}
                                                            />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey={stepConfig.third}>
                                                            <Step3 isFormSubmit={isFormSubmit}/>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </div>
                                            )}
                                        </Formik>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SweetAlert type={alert.type} title={alert.title} onConfirm={() => _alert(s => ({...s, show: false}))}
                        show={alert.show}/>
            <Loader show={isLoading}/>
            <KycVerifiedModal showVerifiedModal={showVerifiedModal} verifiedModal={verifiedModal}/>
        </>
    );
}

export default Kyc;
