import React from "react";
import {Form} from "react-bootstrap";
import "./kyc.css";

function Step1({_step, handleChange, values, errors,handleSubmit,countryList,gender}) {

    return (
        <>
            <div className="kyc-container mt-5">
                <div>
                    <Form.Group className="mb-3">
                        <Form.Label className="d-flex label-style">Name</Form.Label>
                        <Form.Control
                            value={values.name}
                            onChange={handleChange}
                            name="name"
                            placeholder=""
                            className="bottom-boder-radius"
                            required
                        />
                    </Form.Group>
                    <div className="d-flex justify-content-start red-color">
                        {errors.name && <p>{errors.name}</p>}
                    </div>

                    <Form.Group className="mb-3">
                        <div className="d-flex justify-content-start flex-wrap">
                            <div className="d-flex align-items-center">
                                <Form.Label className="d-flex label-style flex-wrap">
                                    Date of Birth
                                </Form.Label>
                            </div>
                            <div className="d-flex align-items-center mx-0 mx-md-2">
                            <span className="kyc-small-text">
                              Enter the first 6 digits of your social security number
                            </span>
                            </div>
                        </div>
                        <Form.Control
                            name="date_of_birth"
                            type="date"
                            value={values.date_of_birth}
                            placeholder=""
                            onChange={handleChange}
                            id="myInputPassword"
                            className="bottom-border-radius"
                        />
                        <div className="d-flex justify-content-start red-color">
                        {errors.date_of_birth && <p>{errors.date_of_birth}</p>}
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label className="d-flex label-style">Gender</Form.Label>

                        <div className="d-flex ">
                            {gender.map((item, index) =>
                                <div className="form-check-inline" key={index}>
                                    <input
                                        className="mouse-pointer radio-style mx-1 form-check-input"
                                        type="radio"
                                        name="gender"
                                        onChange={handleChange}
                                        id="formHorizontalRadios1"
                                        value={item.value}
                                        checked={item.value === values.gender}
                                    />

                                    <label className="radio-label" htmlFor="inlineRadio1">
                                        {item.lable}
                                    </label>
                                </div>)}
                        </div>
                        <div className="d-flex justify-content-start red-color">
                            {errors.gender && <p>{errors.gender}</p>}
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className="d-flex label-style">Contact</Form.Label>
                        <Form.Control
                            type="text"
                            value={values.contact}
                            name="contact"
                            placeholder=""
                            onChange={handleChange}
                            className="bottom-boder-radius"
                        />
                        <div className="d-flex justify-content-start red-color">
                        {errors.contact && <p>{errors.contact}</p>}
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="d-flex label-style">Country</Form.Label>
                        <div className="forms">
                            <select name="country" className="form-style" onChange={handleChange}>
                                {countryList.map((item, index) => (
                                    <option key={index} value={item.value}>
                                        {item.lable}
                                    </option>
                                ))}
                            </select>
                            <div className="d-flex justify-content-start red-color">
                            {errors.country && <p>{errors.country}</p>}
                            </div>
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="d-flex label-style">ID(ARC)</Form.Label>
                        <Form.Control
                            value={values.id_arc}
                            onChange={handleChange}
                            name="id_arc"
                            placeholder=""
                            className="bottom-boder-radius"
                            required
                        />
                    </Form.Group>
                    <div className="d-flex justify-content-start red-color">
                        {errors.id_arc && <p>{errors.id_arc}</p>}
                    </div>

                    <div className="login-button">
                        <button type="button" onClick={() => handleSubmit()} className="register-submit button-gradient-color btn btn-primary border-0 blue-color">Next</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Step1;
