import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import { Formik} from "formik";
import * as yup from 'yup';

import '../../serviceCenter.css';
import API from "../../../../redux/API";

function InquiryRegistration() {

    // const initialValues = {
    //     title: '',
    //     content: ''
    // }
    const [initialValues, _initialValues] = useState({
        title: '',
        content: ''
    })
    const user = JSON.parse(localStorage.getItem('user'));
    const schema = yup.object().shape({
        title: yup.string().required('Title is Required'),
        content: yup.string().required("Content is Required")
    })
    const navigate = useNavigate();
    const [loading, _loading] = useState(false);


    const handleSubmitForm = (e) => {
        const {title, content} = e;
        if(title && content && user.email) {
            const payload = {
                nickname: user.email,
                inquiry: e.title,
                content: e.content,
                isAnswer: "no",
                user_id: user.user_id
            }
            API({url: `question/register`,method: 'POST', data: payload})
                .then((data) => {
                    _initialValues({title: '',content: ''});
                    navigate('/inquiry');
                })
                .catch((e) => console.log("Error : ",e))
                .finally(() => "");
        }

    }
    return (
        <>

            <div className="container mb-5 w-100">
                <div className="row justify-content-center ptb-3 p-2 pt-5 p-lg-2 ">
                    <div className="col-12 ">
                        <h1 className="robot-font fw-bolder text-center pt-5 regal-blue-color">
                            One-on-one inquiry
                        </h1>
                    </div>
                    <div className="col-12 robot-font mt-5 registrationCard ">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={schema}
                            onSubmit={handleSubmitForm}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                touched,
                                isValid,
                                errors
                                }) => (
                                <>
                                    <div className="row p-4">
                                        <div>
                                            <span className="lables">title</span>
                                            <input
                                                name="title"
                                                value={values.title}
                                                onChange={handleChange}
                                                className="textInput h-65"
                                            />
                                            {errors.title && <p className="textColorRed">{errors.title}</p>}
                                        </div>
                                        <div className="mt-5">
                                            <span className="lables">Content</span>
                                            <textarea
                                                name="content"
                                                value={values.content}
                                                onChange={handleChange}
                                                rows={5}
                                                cols={15}
                                                className="textInput"
                                            />
                                            {errors.content && <p className="textColorRed">{errors.content}</p>}
                                        </div>
                                        <div className="mt-5">
                                            <p className="textColorRed">Please enter your request. A support
                                                representative will respond as soon as possible.</p>
                                        </div>
                                        <div className="mt-3">
                                            <button className="registerBtn" onClick={()=>handleSubmit()}>Register</button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Formik>

                    </div>
                </div>
            </div>
        </>
    );
}

export default InquiryRegistration;
