import React from 'react';
import ReactDOM from 'react-dom';


const PrivacyPolicy = () => {
    return <>
        <div className="container">
            <h3 className="mt-4"> 칸테움IO 개인정보 처리방침</h3>
            <p>
                주식회사 카네어스(이하“회사”)은 ｢개인정보 보호법｣에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보
                처리방침을 수립·공개합니다.
            </p>
            <h3>
                제1조 개인정보의 처리 목적
            </h3>
            <p>
                회사는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며 이용 목적이 변경될 때는 별도의 동의를 구할 예정입니다.
            </p>
            <div className="ps-3">
                <h5> 1. 사이트의 회원가입 및 관리 </h5>
                <p className="ps-2">회원 가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별과 인증, 회원자격 유지나 관리, 제한적 본인확인제 시행에 따른 본인 확인, 만
                    14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 각종 고지 및 통지, 서비스 부정 이용 방지, 고충 처리, 분쟁 조정을 위한 기록 보존 등을 목적으로
                    개인정보를 처리합니다. 당 사이트에 로그인하면 회원가입은 자동처리 됩니다.</p>
                <h5> 2. 재화 또는 서비스 제공</h5>
                <p className="ps-2">서비스 및 콘텐츠 제공, 본인인증, 요금 결제 및 정산 맞춤 서비스 제공 등을 목적으로 개인정보를 처리합니다.</p>
                <h5> 3. 고충처리 </h5>
                <p className="ps-2">민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락, 통지, 처리결과 통보의 목적으로 개인정보를 처리합니다.</p>
                <h5> 4. 마케팅 및 광고로의 활용</h5>
            </div>
            <h3 className="mt-3">
                제2조 개인정보의 처리 및 보유기간
            </h3>
            <div className="ps-2">
                <h5> 1. 회사는 법령에 따른 개인정보 보유, 이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유, 이용기간 내에서 개인정보를 처리,
                    보유합니다.</h5>
                <h5> 2. 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</h5>

                <p>가. 사이트 회원 가입 및 관리: 사업자/단체 사이트 탈퇴 시까지</p>
                <p> 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지</p>
                <ul className="ps-2">
                    <li>관계 법령 위반에 따른 수사, 조사 등이 진행 중인 경우에는 해당 수사, 조사 종료 시까지</li>
                    <li>사이트 이용에 따른 채권, 채무관계 잔존 시에는 해당 채권, 채무관계 정산 시까지</li>
                </ul>

                <p>나. 재화 또는 서비스 제공: 재화, 서비스 공급완료 및 요금 결제, 정산 완료시까지</p>
                <p>다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료 시까지</p>
                <ul className="ps-2">
                    <li>「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시, 광고, 계약내용 및 이행 등 거래에 관한 기록
                    </li>
                    <li>
                        <ul className="ps-2">
                            <li>표시, 광고에 관한 기록: 6개월</li>
                            <li>계약 또는 청약철회, 대금결제, 재화 등의 공급 기록: 5년</li>
                            <li>소비자 불만 또는 분쟁처리에 관한 기록: 3년</li>
                        </ul>
                    </li>
                    <li>「통신 비밀 보호법」에 따른 통신사실확인자료 보관</li>
                    <li>
                        <ul className="ps-2">
                            <li>가입자 전기통신일시, 개시, 종료시간, 상대방 가입자번호, 사용 도수, 발신기지국 위치추적자료: 1년</li>
                            <li>컴퓨터통신, 인터넷 로그기록자료, 접속지 추적 자료: 3개월</li>
                        </ul>
                    </li>
                </ul>
            </div>

            <h3>
                제3조 개인정보의 제3자 제공
            </h3>
            <h5>
                1. 회사는 정보주체의 개인정보를 제1조 개인정보의 처리 목적에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 ｢개인정보 보호법｣ 제17조 및
                제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
            </h5>
            <h5>
                2. 회사(는) 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
            </h5>
            <div className="ps-3">
                <p>가. 서비스 결제 및 환불: 네스트페이, 카카오페이 </p>
                <p>나. 서비스 제공을 위한 클라우드 인프라 관리, 회원정보 및 결제정보 보관: AWS </p>
                <p>다. 뉴스레터 및 이메일 발송: 스티비 </p>
            </div>

            <h3>
                제4조 정보주체와 법정대리인의 권리, 의무 및 행사방법
            </h3>

            <p>
                1. 정보주체는 회사에 대해 언제든지 개인정보 열람, 정정, 삭제, 처리정지 요구 등의 권리를 행사할 수 있습니다.
            </p>
            <p>
                2. 제1항에 따른 권리 행사는 회사에 대해 개인정보보호법 시행령 제41조제1항에 따라서 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에 대해
                지체없이 조치하겠습니다.
            </p>
            <p> 3. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지
                제11호 서식에 따른 위임장을 제출하셔야 합니다.
            </p>
            <p> 4. 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.
            </p>
            <p> 5. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
            </p>
            <p> 6. 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
            </p>
            <h3>
                제5조 처리하는 개인정보 항목
            </h3>
            <h5>
                회사 다음의 개인정보 항목을 처리하고 있습니다.
            </h5>
            <ol>
                <li>
                    홈페이지 회원 가입 및 관리
                    <ul>
                        <li>
                            필수항목: 성명, 비밀번호, 전화번호, 이메일 주소
                        </li>
                    </ul>
                </li>
                <li>
                    재화 또는 서비스 제공
                    <ul>
                        <li>
                            필수항목: 성명, 아이디, 비밀번호, 주소, 전화번호, 이메일 주소, 신용카드번호, 은행계좌정보
                        </li>
                        <li>
                            선택항목: 관심분야, 과거 구매내역
                        </li>
                    </ul>
                </li>
                <li>
                    인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.
                    <ul>
                        IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록
                    </ul>
                </li>
            </ol>

            <h3>
                제6조 개인정보의 파기
            </h3>

            <ol>
                <li>
                    회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
                </li>
                <li>
                    정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의
                    데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
                    <ul>
                        <li>보존하는 개인정보 항목: 계좌정보, 거래날짜</li>
                    </ul>
                </li>
                <li>개인정보 파기의 절차 및 방법은 다음과 같습니다.</li>
            </ol>

            <p>가. 파기절차 </p>
            <p> 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</p>
            <p> 나. 파기방법</p>
            <p> 회사는 전자적 파일 형태로 기록, 저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록, 저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.</p>


            <h3>
                제7조 개인정보의 안전성 확보조치
            </h3>
            <p>
                회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
            </p>
            <ol>
                <li>관리적 조치: 내부관리계획 수립, 시행, 정기적 직원 교육 등</li>
                <li>기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치</li>
                <li>물리적 조치: 전산실, 자료보관실 등의 접근통제</li>
            </ol>
            <h5>
                제8조 개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항
            </h5>

            <ol>
                <li>
                    회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
                </li>
                <li>
                    쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
                </li>
            </ol>
            <p> 가. 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해
                사용됩니다.
            </p><p> 나. 쿠키의 설치∙운영 및 거부: 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다.
        </p><p> 다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</p>

            <h3>
                제9조 개인정보 보호책임자
            </h3>
            <p>
                1. 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고
                있습니다.
            </p>
            <p> 가. 개인정보 보호책임자 </p>
            <ul>
                <li>성명: CS책임자이름</li>
                <li> 직책: 담당자</li>
                <li>연락처: 02-855-9285,<a href="mailto:cs@khanaires.com">cs@khanaires.com</a> </li>
            </ul>

            <p> 2. 정보 주체는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실
                수 있습니다. 회사는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다. </p>

            <h3>제10조 추가적인 이용·제공 판단기준</h3>

            <p>
                회사는 ｢개인정보 보호법｣ 제15조제3항 및 제17조제4항에 따라 ｢개인정보 보호법 시행령｣ 제14조의2에 따른 사항을 고려하여 정보주체의 동의 없이 개인정보를 추가적으로
                이용·제공할 수 있습니다.
            </p>
            <p>
                이에 따라 회사가 정보주체의 동의 없이 추가적인 이용·제공을 하기 위해서 다음과 같은 사항을 고려하였습니다.
            </p>
            <ul>
                <li>개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집 목적과 관련성이 있는지 여부</li>
                <li>개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인 이용·제공에 대한 예측 가능성이 있는지 여부</li>
                <li>개인정보의 추가적인 이용·제공이 정보주체의 이익을 부당하게 침해하는지 여부</li>
                <li>가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부</li>
            </ul>

            <h3>
                제11조 개인정보 열람청구
            </h3>

            <p>
                정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.
            </p>
            <p>
                회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
            </p>
            <ul>
                개인정보 열람청구 접수, 처리 부서
            </ul>
            <p>
                부서명: CS센터
            </p>
            <ul>
                <li>담당자: CS책임자이름</li>
                <li>연락처: 02-855-9285, <a href="qmailto:cs@khanaires.com">cs@khanaires.com</a> </li>
            </ul>

            <h3>
                제12조 권익침해 구제방법
            </h3>

            <p>
                정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타
                개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.

            </p>
            <ol>
                <li>개인정보분쟁조정위원회: (국번없이) 1833-6972 (<a href="http://www.kopico.go.kr/">www.kopico.go.kr</a>)</li>
                <li>개인정보침해신고센터: (국번없이) 118 (<a href="http://privacy.kisa.or.kr/">privacy.kisa.or.kr</a>)</li>
                <li>대검찰청: (국번없이) 1301 (<a href="http://www.spo.go.kr/">www.spo.go.kr</a>)</li>
                <li>경찰청: (국번없이) 182 (<a href="http://cyberbureau.police.go.kr/">cyberbureau.police.go.kr</a>)</li>
            </ol>

            <p>
                「개인 정보 보호법」 제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는
                부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
            </p>
            <p> 신규 서비스(제품이나 소프트웨어 등) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여 기회 제공 등을 목적으로 개인정보를 처리합니다.
            </p>
            <p>제13조 개인정보 처리방침 변경</p>
            <p>이 개인정보 처리방침은 2023. 06. 01부터 적용됩니다.</p>

        </div>
    </>
}

export default PrivacyPolicy;