import React, {useState} from "react";
import {Card, Spinner} from "react-bootstrap";
import plusIcon from "../../../../images/background/plus-icon.png";
import minusIcon from "../../../../images/background/minus-icon.png";
import Loader from "../../../../components/loader";
import SweetAlert from "react-bootstrap-sweetalert";
import {contractType} from "../../../../utils/config";
import '../Art-Work-Details.css';

const PlaceBid = (props) => {

    const {
        nftDetails,
        _bidAmount,
        purchaseNFT,
        bidAmount,
        isMetaMaskTransaction,
        isAPITransaction,
        placeBid,
        _quantity,
        quantity
    } = props;

    const [loading, _loading] = useState(false);
    const [sweetAlert, _alert] = useState({show: false, title: '', type: 'success' || 'error'});
    const increment = () => {
        const addEther = bidAmount + 1;
        _bidAmount(addEther)
    }

    const decrement = () => {
        const removeEther = bidAmount - 1;
        if (removeEther <= 0 && removeEther < nftDetails?.auction_start_price) {
            _alert({show: true, type: "warning", title: "Please Make correct Bid"})
        } else {
            _bidAmount(removeEther)
        }
    }

    const closeSweetAlertHandler = (s) => {
        _alert(s => ({...s, show: false}));
    }
    return <>
        <div>
            <div className="increment-section">

                <div className="increment-box">
                    <div className="d-flex justify-content-end">
                        <span className="btn-blue mouse-pointer" onClick={placeBid}>X</span>
                    </div>
                    <div className="d-flex justify-content-center align-items-center h-50">
                        <div className="increment-decrement-section d-flex justify-content-center align-items-center">
                            <div className="decrement mouse-pointer" onClick={() => decrement()}><img src={minusIcon}
                                                                                                      alt="icon"/></div>
                            <div className="value-box">
                                <input
                                    type="number"
                                    value={`${bidAmount}`}
                                    className="Montserrat"
                                    onChange={(e) => _bidAmount(e.target.value)}
                                />
                                <span className="fw-bold">ETH</span>
                            </div>
                            <div className="increment mouse-pointer" onClick={() => increment()}><img src={plusIcon} alt="icon"/></div>
                        </div>
                    </div>
                    {nftDetails?.contract_type === contractType.erc1155 &&
                        <div className="mt-2">
                            <h6>Quantity</h6>
                            <input type="number" value={quantity} onChange={(e) => _quantity(e.target.value)}
                                   className="inquiry-box-shadow w-60" id="royalty" style={{height: '35px'}}/>
                        </div>
                    }
                    {!nftDetails?.is_current_owner &&
                        <div className="d-flex justify-content-center mt-5">
                            {isMetaMaskTransaction || isAPITransaction ?
                                <button
                                    className="btn-blue"
                                    onClick={() => purchaseNFT()}
                                >
                                    Place a Bid
                                    <Spinner animation="border" size="sm" className=" ms-2"/>
                                </button>
                                : <button
                                    className="btn-blue"
                                    onClick={() => purchaseNFT()}
                                >
                                    Place a Bid
                                </button>
                            }
                        </div>}
                </div>
                <div className="bid-container">
                    <Card.Body>
                        <Card.Subtitle>Last Bid</Card.Subtitle>
                        <Card.Title>{nftDetails?.highest_bid} ETH</Card.Title>
                    </Card.Body>

                    <Card.Body>
                        <Card.Subtitle>Open Bid</Card.Subtitle>
                        <Card.Title>{nftDetails?.auction_start_price} ETH</Card.Title>
                    </Card.Body>
                </div>
            </div>
        </div>
        <SweetAlert type={sweetAlert.type} title={sweetAlert.title} onConfirm={() => closeSweetAlertHandler()}
                    show={sweetAlert.show}/>
        <Loader show={loading}/>
    </>
}

export default PlaceBid;
