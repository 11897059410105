import Modal from 'react-bootstrap/Modal';
import {Button} from 'react-bootstrap';
import './TermsModal.css';
import React from "react";

function TermsModal({show, onHide}) {
    return (
        <>
          <div className="container mt-3 mb-3">
              <h2>칸태움아이오 이용약관</h2>
              <h4 className="mt-2">제 1 조 [이용 목적]</h4>
              <p>본 이용약관은 카네어스와 칸태움의 이용약관에 동의한 칸태움 회원(이하 "회원"이라고 합니다)이 카네어스워런트주식회사(이하 "회사"라고 합니다)가 제공하는 칸태움아이오 서비스(이하 "서비스"라고 합니다) 내에서 NFW 를 거래함에 있어 회사와 회원의 권리와 
의무, 기타 필요한 사항을 규정함을 목적으로 합니다.</p>
              <h4 className="mt-2">제 2 조 [정의]</h4>
              이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
              <ol>
                  <li> "NFW"란, 블록체인 기반의 디지털 자산으로서, 이미지, 동영상, 음악, 서적 등의 "디지털 저작물"에 대한 메타데이터 정보를 포함한 상태로 발행되는 대체 불가능한 토큰(Non-Fungible Warrant)을 의미합니다.
                  </li>
                  <li> “NFW”란, 블록체인 기반의 디지털 자산으로서, 이미지, 동영상, 음악, 서적 등의 "디지털 저작물"에 대한 메타데이터 정보를 포함한 상태로 발행되는 대체 불가능한 워런트(Non-Fungible Warrant)을 의미합니다.
                  </li>
                  <li> "디지털 저작물"이란, NFW 에 연계된 디지털 이미지, 동영상, 음악, 서적 등의 디지털 형태의 저작물을 의미합니다.
                  </li>
                  <li> "디지털 저작물 온라인 전시장"이란, NFW 와 연계된 "디지털 저작물"을 조회하고 감상할 수 있는 웹사이트 및 앱 등의 온라인 화면 및 그 구현에 필요한 서버, 데이터베이스 등을 의미합니다.
                  </li>
                  <li> "NFW 마켓플레이스"란, NFW 를 거래할 수 있는 온라인 상의 장소 및 그 구현에 필요한 서버, 데이터베이스 등을 의미합니다. 별도의 기재가 없는 한 본 약관에서 "NFW 마켓플레이스"라 함은 회사가 제공하는 서비스인 칸태움아이오를 의미합니다. 
                  </li>
                  <li> "NFW 발행인"이란, NFW 를 발행하여 최초로 배포하는 자를 의미합니다.
                  </li>
                  <li> "NFW IP"란 다음 각 목의 행위를 하는데 필요한 일체의 적법한 권한을 의미합니다.
                    <ul>
                        <li>              o	가. "디지털 저작물" 제작 ["디지털 저작물" 자체에 대한 지식재산권을 취득하거나 원 저작물 저작권자와의 디지털 저작물 제작 위탁계약 등을 통하여 "디지털 저작물" 제작에 필요한 지식재산권 또는 이에 관한 사용권을 허락 받은 경우를 포함한다]
                        </li>
                        <li>              o	나. "디지털 저작물"과 연계된 NFW 발행 및 유통
                        </li>
                        <li>              o	다. 발행 및 유통된 NFW 를 보유한 자에게 "NFW 보유자의 권리"(제3 조에서 정의됨) 보장
                        </li>
                    </ul>
                  </li>
                  <li>  "외부 입, 출금"이란, 회사가 운영하는 디지털 자산 지갑과, 회사 외의 제3 자가 운영하는 디지털 자산 지갑 주소 또는 외부의 개인 지갑 주소 사이에 NFW 를 포함한 디지털 자산을 입, 출금을 하는 행위를 의미합니다.
                  </li>
              </ol>

              <h4 className="mt-2">제 3 조 [NFW 보유자의 권리]</h4>
              <ol>
                  <li> NFW 보유자는 "NFW 발행인" 또는 적법한 권한을 가진 제3 자가 해당 NFW 와 관련하여 NFW 보유자에게 부여하거나 허용한 권리(이하 "NFW 보유자의 권리" 라고 합니다)를 행사할 수 있으며, 각 NFW 별로 "NFW 보유자의 권리"의 내용은 다를 수 있습니다.
                  </li>
                  <li> "NFW 보유자의 권리"가 서비스 내에 표시되는 경우, 그러한 표시는 해당 NFW 에 부여된 권리를 완전히 반영하지 않을 수 있으며 NFW 를 구매하려는 자는 각 NFW 별로 부여되는 권리의 구체적인 내용을 "NFW 발행인"이 제공하는 웹페이지 등을 통해 확인할 필요가 있습니다
                  </li>
                  <li> NFW 는 일반적으로 저작권, 상표권을 포함한 지식재산권이나 초상권, 상품화 권리 등 특정 권리 그 자체를 의미하지 않고, NFW 를 보유하는 것이 NFW 와 연계된 디지털 저작물을 보유하거나 디지털 저작물에 관한 저작권 등의 지식재산권 자체를 취득하는 것을 의미하지 않습니다. 단, "NFW 발행인" 또는 "NFW IP" 보유자가 달리 정한 경우에는 그러하지 않습니다.
                  </li>
                  <li> "NFW 보유자의 권리"는 NFW 를 적법하게 보유하고 있는 동안에만 행사할 수 있고, NFW 를 타인에게 양도하거나 이전한 경우, NFW 를 양도하거나 이전한 회원은 더 이상 "NFW 보유자의 권리"를 행사할 수 없습니다. "회사"가 공식적으로 지원하는 방식이 아닌 경우이거나 관련 법령을 위반하는 방식으로 NFW 를 이전 받은 자에게는 "NFW 보유자의 권리"가 보장되지 않을 수 있습니다.
                  </li>
              </ol>

              <h4 className="mt-2">제 4 조 [NFW 관련 유의사항]</h4>
              <ol>
                  <li> 칸태움아이오에서는 카네어스주식회사가 서비스하고 있는 칸태움앱에서 소정의 순위에 달성한 디지털저작물이나 무형자산 및 IP 들을 NFW 화 하거나 카네어스주식회사와 NFW 를 발행에 대해 계약을 체결한 형태의 NFW 만 발행, 유통됩니다. 칸태움마켓플레이스는 칸태움 외 다른 경로의 참가자들의 NFW 는 발행하지 않습니다.
                  </li>
                  <li> 현재 칸태움아이오에서 거래되는 NFW 는 폴리곤 또는 이더리움 NFW 프로토콜에 기반한 NFW 이며, 회사는 새로운 NFW 체인과 프로토콜의 거래를 지원할 수 있습니다.
                  </li>
                  <li> 회사는 NFW 거래 중개자이며, NFW 거래 당사자가 아닙니다. NFW, "NFW 보유자의 권리", "NFW IP" 나 NFW 거래에 관한 책임은 "NFW 발행인" 및/또는 판매자가 부담하고, 회사는 "NFW 발행인" 과 판매자를 대리하거나 NFW 에 연계된 "디지털 저작물"의 진정성, "NFW IP", "NFW 보유자의 권리"의 완전성 등에 대하여 어떠한 보증도 하지 않습니다. 단, 회사의 고의 또는 과실로 인하여 손해가 발생한 경우에는 법령에서 정한 범위 내에서 회사가 책임을 부담합니다.
                  </li>
                  <li> 회사가 실시하는 별도의 개발 지원이 없는 한 NFW 는 다른 체인의 지갑으로 전송되지 않습니다. 어느 NFW 가 그 기반 체인이 아닌 다른 체인의 지갑으로 전송된 경우, 회사의 고의, 과실이 없는 한 회사는 손해배상, 복구지원 등 일체의 책임을 부담하지 않습니다.
                  </li>
                  <li> 회사는 칸태움 및 칸태움아이오 내에서 NFW 거래 중개에 관한 홍보 목적으로 NFW 에 연계된 디지털 저작물을 이용할 수 있습니다.
                  </li>
                  <li> "디지털 저작물 온라인 전시장" 개설 여부는 각 NFW 별로 달라질 수 있고, 회사는 각 NFW 의 "디지털 저작물 온라인 전시장" 개설을 보장하지 않습니다.
                  </li>
                  <li> NFW 이전에 따른 제세공과금이 발생할 경우 이는 NFW 를 이전한 자가 부담하여야 합니다.
                  </li>
              </ol>

              <h4 className="mt-2">제 5 조 [NFW 서비스 관련 유의사항]</h4>
              <ol>
                  <li>회사는 "NFW 마켓플레이스"에서 제공되는 서비스의 범위(거래지원하는 NFW 체인이나 프로토콜의 종류, 브릿지 제공 여부, 개별 NFW 의 거래 지원 여부, 거래 지원 기간, 외부 입∙출금 지원 여부를 포함하며, 이에 한정하지 않습니다)에 관해서 독자적인 판단 권한을 보유하며 각 NFW 별로 제공되는 서비스의 범위는 상이할 수 있습니다.
                  </li>
                  <li>NFW 발행은 원칙적으로 회사가 하며 향후 필요시 크리에이터 가 할 수도 있습니다. 최초 NFW 발행을 할 때는 로그인 계정과 지갑주소를 회사에 제출하여야 하며 회사는 대리인으로 유저의 NFW 를 발행합니다. 단, 의뢰자가 동의하여 제출한 정보는 회사가 NFW 마켓 외의 다른 목적으로 사용할 수 없습니다. 본인의 실수로 발생된 모든 문제에 대하여 회사는 책임을 지지 않습니다.
                  </li>
                  <li>서비스 내 화면에, (i) "NFW 마켓플레이스"에서 배포 또는 거래될 수 있는 모든 NFW 관련 정보("NFW 발행인", 크리에이터, 디지털 저작물 관련 정보 등) 및 (ii) 회원의 닉네임, 거래 정보(거래 일시, 거래 금액, 현재 판매 여부, 판매 희망 금액 등), 서비스 내 활동 등이 공개될 수 있습니다.
                  </li>
                  <li>"NFW 발행인"이 기존에 배포되었거나 배포 중인 NFW 에 대해 조치를 취할 필요가 있음을 소명하는 경우(NFW, 그 메타데이터 또는 배포 관련 데이터에 오류가 있어 이를 시정할 필요가 있는 등의 사유를 포함하며, 이에 한정하지 않습니다), 회사는 NFW 의 배포 및/또는 거래를 정지하고, "NFW 발행인"이 오류가 없는 NFW 를 발행하기 위해 필요한 조치(해당 NFW 소각 및 오류 없는 새로운 NFW 발행 등을 포함하며 이에 한정하지 않습니다)를 취하도록 할 수 있습니다. 회사는 본 항의 조치에 협력한 후 5 영업일 이내에 조치의 사유 및 내용을 회원에게 통지합니다.
                  </li>
                  <li>"NFW 발행인"은 NFW 와 관련하여 이벤트를 진행할 수 있으며, 회사는 "NFW 발행인"이 요청하는 경우 관련 내용을 서비스 내에 공지할 수 있습니다. 해당 NFW 관련 이벤트의 책임 주체는 "NFW 발행인"이며, 회사는 고의 또는 과실이 없는 한 해당 NFW 관련 이벤트에 관하여 책임을 부담하지 않습니다. 회사는 "NFW 발행인"이 회사의 서비스를 통하지 않고 진행하는 이벤트에 관하여 책임을 부담하지 않습니다.
                  </li>
                  <li>회사는 다음 각 호의 사유에 해당하는 경우 개별 NFW 의 거래 지원을 정지, 중단하거나 종료할 수 있으며, 거래 지원이 정지 또는 종료된 NFW 는 외부 입금이 지원되지 않습니다.
                    <ul>
                        <li> o 제5 조 제3 항에 해당하는 경우
                        </li>
                        <li> o "디지털 저작물"에 대한 권리 내지 "NFW IP"에 대한 침해가 의심되는 경우(제8 조에 해당하는 경우를 포함하며 이에 한정하지 않습니다)
                        </li>
                        <li> o NFW 발행인"이 회사의 특수관계인(「상법 시행령」 제34 조 제4 항 각 호에 따른 특수관계인을 말합니다)으로 확인된 경우
                        </li>
                        <li> o NFW 가 기반한 체인 및/또는 프로토콜의 운영이 중단되는 경우
                        </li>
                    </ul>
                  </li>
                  <li> 본 조 제5 항에 따라 NFW 에 대한 거래 지원을 정지하거나 종료하는 경우, 제5 항 제2 호의 경우를 제외하고, (i) 회사는 기존에 외부 입∙ 출금이 허용되던 NFW 의 거래 지원이 종료된 경우, 거래 지원 종료일로부터 적어도 삼십(30)일의 기간 동안 해당 NFW 의 외부 출금을 지원하고, (ii) 외부 입∙출금이 허용되지 않던 NFW 의 경우 해당 NFW 에 연계된 디지털 저작물을 다운로드할 수 있도록 지원합니다(본 조항에 따라 다운로드 받은 디지털 저작물은 "NFW 보유자의 권리"에 의하여 부여된 범위 내의 목적 및 방법으로만 이용할 수 있습니다)
                  </li>
                  <li> 다음 각 호의 사유에 해당하는 경우 회사는 NFW 거래 중개 서비스의 일부 또는 전부 종료할 수 있으며, 이 경우 거래 중개 서비스가 종료된 NFW 를 보유한 자는 회사의 서비스 내에서 "NFW 보유자의 권리"를 더 이상 행사할 수 없습니다. 회사가 거래 중개 서비스의 일부 또는 전부를 종료한 경우 본 조 제6 항을 준용합니다. 서비스 일부 또는 전부 종료에 관한 사전 통지 방법, 종료 후 외부 출금 지원 등 본 조에서 정하지 않은 사항은 카네어스 및 칸태움의 서비스 이용약관에서 정한 바에 의합니다.
                    <ul>
                        <li> o 폴리곤이 NFW 프로토콜의 운영을 중단하는 경우
                        </li>
                        <li> o 국가 기관의 지시, 권고 또는 관련 법령에 의할 때 기존 NFW 관련
                        </li>
                    </ul>
                    서비스의 전부 또는 일부를 종료할 필요가 있는 경우
                  </li>
              </ol>

              <h4 className="mt-2">제 6 조 [금지 행위 및 해지]</h4>
              <ol>
                  <li> 회원은 칸태움아이오에서 유통된 NFW 및 이에 연계된 디지털 저작물과 관련하여 다음 각 호의 행위를 할 수 없습니다. 단, 제1 호 내지 제4 호의 경우 "NFW 발행인" 또는 "NFW IP"의 보유자가 달리 정한 경우에는 그러하지 않습니다.
                  </li>
                  <li> 디지털 저작물을 수정, 왜곡하여 게시하는 행위
                  </li>
                  <li> 본인 또는 제3 자의 제품 또는 서비스를 광고하는 등 디지털 저작물을 영리 목적으로 이용하는 행위
                  </li>
                  <li> 디지털 저작물과 연계된 별도의 NFW 를 발행하는 행위
                  </li>
                  <li> "NFW 보유자의 권리"에 따라 이용 가능한 디지털 저작물을 악용하여, 별도의 지식재산권을 등록, 취득하는 행위
                  </li>
                  <li> 특정 회원과 통정하여 매매하는 방식 또는 옥션 방식으로 진행되는 거래에 수 개의 주문을 제출하는 방식 등을 통하여 NFW 가격을 비정상적으로 변동시키는 행위
                  </li>
                  <li> 기타 상기 각 호에 준하는 행위
                  </li>
                  <li> 회원이 전항을 위반한 경우 회사는 서비스 이용을 제한함과 동시에 시정요구를 할 수 있습니다. 시정요구에도 불구하고 상당한 기간 내에 시정되지 않거나 2 회 이상 반복적으로 같은 위반행위를 하는 경우에는 카네어스 및 칸태움의 서비스 이용계약을 해지할 수 있습니다.
                  </li>
                  <li> 회원은 NFW 를 보유한 상태에서는 서비스를 탈퇴할 수 없습니다. 회원은 보유한 NFW 를 처분한 후 서비스를 탈퇴할 수 있습니다. 단, 회원은 회사에게 NFW 의 소각 또는 처분을 요청한 후 서비스를 탈퇴할 수 있으며, 회사가 회원의 요청에 응한 경우 회원은 소각 또는 처분을 요청한 NFW 의 반환을 요청할 수 없습니다. 단, 회사의 고의 또는 과실이 있는 경우에는 그러하지 않습니다.
                  </li>
            </ol>
              
              <h4 className="mt-2">제 7 조 [NFW 거래 방법 관련 유의사항]</h4>
              "칸태움 드롭 NFW"를 칸태움아이오 내에서 발행하여 최초로 배포하는 거래(이하 "NFW 1 차 거래")는 모두 조건부 지정가 거래를 채택하였으며 기타 회사와 회원의 투표로 정하여 안내하는 방식으로 진행됩니다. 회원은 NFW 1 차 거래 참여시 설명되어 있는 각 판매 방식을 완전히 숙지하고, 거래에 참여해야 합니다. 회사는 NFW 1 차 거래 시, 가격 조작을 목적으로 한 허위 입찰을 방지하기 위하여 입찰 취소를 제한할 수 있습니다.

              <h4 className="mt-2">제 8 조 [디지털 저작물 관련 이의제기]</h4>
                <ol>
                  <li> "회사"가 제공하는 "NFW 마켓플레이스"를 통하여 유통되는 NFW 및 해당 NFW 에 연계된 "디지털 저작물"에 의하여 본인의 저작권 기타 권리가 침해된다고 주장하는 자(이하 "권리주장자")는 해당 사실을 소명하여 "회사"에 해당 NFW 의 이용 및 유통을 중단할 것을 요청할 수 있습니다.
                  </li>
                  <li> "회사"는 제1 항에 따라 NFW 의 이용 및 유통 중단을 요구 받은 경우에는 즉시 해당 NFW 에 대한 거래 지원을 중단하고, 권리자의 NFW 를 보유하고 있는 회원 및 "NFW 발행인"에게 해당 사실을 통보하여야 합니다.
                  </li>
                  <li> 제2 항에 따라 통보를 받은 "NFW 발행인"이 자신에게 정당한 권리가 있음을 소명하면서 NFW 의 이용 및 유통 재개를 요구하는 경우, "회사"는 재개요구사실 및 재개예정일을 권리주장자와 NFW 를 보유하고 있는 회원에게 지체없이 통보하고 해당 예정일에 "NFW 보유자의 권리"를 재개합니다. 다만, 권리주장자가 재개 예정일 전에 회사에 "NFW 발행인"의 침해행위에 대하여 소를 제기한 사실을 통보한 경우에는 그러하지 않습니다.
                  </li>
                  <li> 특정 NFW 와 이에 연계된 디지털 저작물에 관한 권리 또는 "NFW IP" 관련하여 "권리주장자"가 권리 침해를 주장하는 경우, "회사"는 본 조 및 제5 조 제5 항에서 정한 바에 따라 해당 NFW 의 거래 중개 등 서비스 제공을 중단하고, 지체없이 " 권리주장자"와 "NFW 발행인"에게 해당 사실을 알립니다. "회사"는 해당 문제의 해결을 위하여 "권리주장자"와 "NFW 발행인"간 협의를 주선하여 관련 분쟁이 조속히 해결되도록 노력합니다. 회사는 관련 분쟁이 종결되면 분쟁 종결의 조건에 따라 중단되었던 NFW 거래 중개를 재개할 수 있습니다.
                  </li>
                  <li> NFW 발행인과 권리주장자 사이의 분쟁이 해결되지 않아 해당 NFW 를 보유하고 있는 회원에게 손해가 발생하더라도 회사는 이에 대하여 책임지지 않습니다. 단, 회사의 고의 또는 과실로 인하여 손해가 발생한 경우에는 법령에서 정한 범위 내에서 회사가 책임을 부담합니다.                    
                  </li>
                </ol>

              <h4 className="mt-2">제 9 조 [카네어스 및 칸태움의 이용약관과의 관계]</h4>
              <ol>
                <li>본 이용약관에 명시되지 않은 사항은 카네어스와 칸태움의 약관 및 관계 법령에서 정하는 바에 따르며, 관계 법령에서 정하지 아니한 사항은 상관례를 따릅니다.</li>
                <li>본 이용약관과 업비트 이용약관이 충돌할 경우 본 이용약관이 우선하여 적용됩니다.</li>
              </ol>

              <h4 className="mt-2">제 10 조 (준거법 및 분쟁 해결 방법)</h4>
              본 약관과 관련된 분쟁 발생 시 대한민국 법을 적용합니다.


          </div>
        </>
    );
}

export default TermsModal;