import React, {useEffect, useState} from 'react';
import SettingIcon from "../../images/others/setting-icon.svg";
import EditIcon from "../../images/others/edit-icon.svg";
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import {UserNickName} from "../../utils/function";
import _ from 'lodash';
import API from "../../redux/API";
import UserImg from '../../images/others/user-img.png';
import SweetAlert from "react-bootstrap-sweetalert";
import {Spinner} from "react-bootstrap";
import Loader from "../loader";

const UserProfileLayout = ({loggedUser,openFollowListModal, openWithdrawModal}) => {

    const location = useLocation();
    const navigate = useNavigate();
    const userId = useParams();
    const [nickName, _nickName] = useState('');
    const [description, _description] = useState(''); 
    const [userPhoto, _userPhoto] = useState('');
    const [email, _email] = useState('')
    const [sweetAlert, _alert] = useState({show: false, title: '', type: 'success' || 'error'});
    const [otherUser, _otherUser] = useState('');
    const [loading, _loading] = useState(false);
    const [isEdited, setEdit] = useState(false);

    const getTheOtherUserProfile = () => {
        if(userId?.userId){
            API({url: `user/details?user_id=${userId?.userId}`, method: 'GET',})
                .then(res => {
                    const userData = res.data.data;
                    _otherUser(userData);
                    userData?.nickName ? _nickName(userData.nickName) : _nickName(UserNickName(userData?.email))
                    userData?.description ? _description(userData?.description) : _description('Creative artist who love collecting art and in love with simple 3D abstract arts')
                    _userPhoto(userData?.profile_image)
                    _email(userData?.email);
                }).catch(error => {
                _alert({show: true, type: "error", title: error.message});
            })
        }
    }

    useEffect(() => {
        if (loggedUser?.user_id !== userId.userId && !_.isEmpty(userId)) {
            getTheOtherUserProfile();
        } else {
            loggedUser?.nickname ? _nickName(loggedUser.nickname) : _nickName(UserNickName(loggedUser?.email))
            _userPhoto(loggedUser?.profile_image)
            _email(loggedUser?.email);
            _description(loggedUser?.description)
        }

        return (()=>{
            _userPhoto(null);
            _email(null);
        })
    }, []);
    const closeSweetAlertHandler = (s) => {
        _alert(s => ({...s, show: false}));
    }

    useEffect(() => {
    
    },[isEdited])

    const followUser = () => {
        if(loggedUser){
            _loading(true)
            const userData = {"user_to_follow_id": otherUser?.user_id};
            API({url: `user/follow`, data: userData, method: 'POST',})
                .then(res => {
                    _alert({show: true, type: "success", title: "following...!"});
                    // _userFollow(true)
                    getTheOtherUserProfile();
                }).catch(error => {
                _alert({show: true, type: "error", title: error.message});
            }).finally(() => _loading(false))
        }else{
            _alert({show: true, type: "warning", title: "Login First...!"});
        }
    }

    const changeDescription = () => {
        let descriptionData = {description: description} 
        API({
            url: 'user/change-description',
            data: descriptionData,
            method: 'PUT'
        }).then(async res => {
            _alert({show: true, type: "success", title: 'Update User Profile Successfully!...'});
        }).catch(err => {
            _alert({show: true, title: err?.message, type: 'error'});
        }).finally(() => _loading(false));

        setEdit(false);
    }

    const unFollowUser = () => {
        if(loggedUser){
            _loading(true)
            const userData = {"user_to_unfollow_id": otherUser?.user_id};
            API({url: `user/unfollow`, data: userData, method: 'POST',})
                .then(res => {
                    _alert({show: true, type: "success", title: "UnFollowing...!"});
                    // _userFollow(false);
                    getTheOtherUserProfile();
                }).catch(error => {
                _alert({show: true, type: "error", title: error.message});
            }).finally(() => _loading(false))
        }else {
            _alert({show: true, type: "warning", title: "Login First...!"});
        }
    }

    return <>
        <div className="profile-image ">
            <img
                src={`${userPhoto || UserImg}`}
                alt="image"
                className="rounded-circle"
                // height="150"
                style={{height:'150px',width:'150px'}}
            />
            {/* <Link to="/edit-profile">
                <img src={EditIcon} alt="image" className="edit-icon"/>
            </Link> */}
        </div>

        {/*ternary operator*/}

        {nickName.length > 20 ? (
            <h5 style={{color:'#162869', fontWeight:'bold',marginTop:'6%'}}>{nickName}</h5>
        ) : (
            <h3>{nickName}</h3>
        )}

        <p>{email}</p>
        {otherUser == '' ?
        <>
            {!isEdited ?
                <p>{description}
                <img src={EditIcon} alt="image" className="edit-icon relative" onClick={() => setEdit(true)}/>
                </p> :
                <>
                    <input value={description} onChange={(e) => _description(e.target.value)}></input>
                    <button className='btn-blue btn-white' type="button" onClick={() => changeDescription()}>Save</button>
                </>
            }
        </>:<>{description}</>
        }
       
        {(loggedUser?.user_id !== userId.userId && !_.isEmpty(userId)) ?

            <div className="w-100">
                {otherUser?.is_following ? <>
                        {loading ? <button
                                className="btn-lg btn-blue btn-following"
                                type="button"
                            >
                                Following <Spinner animation="border" size="sm" className=" ms-2"/>
                            </button> :
                            <button
                                className="btn-lg btn-blue btn-following"
                                type="button"
                                onClick={() => unFollowUser()}
                            >
                                Following
                            </button>}
                    </>
                    :  <>
                    {loading ?
                        <button
                            className="btn-lg btn-blue"
                            type="button"
                        >
                            Follow <Spinner animation="border" size="sm" className=" ms-2"/>
                        </button> : <button
                            className="btn-lg btn-blue"
                            type="button"
                            onClick={() => followUser()}
                        >
                            Follow
                        </button>
                    }
                </>
                }
            </div>
            :
            <div>
                <button className={`${location.pathname === '/edit-profile' ? 'btn-blue' : 'btn-blue btn-white'} btn-lg`} type="button" onClick={() => navigate('/edit-profile')}><img
                    src={SettingIcon} alt="icon"/> Edit Profile
                </button>
                <div className="nft-btns">
                    <button className={`${location?.pathname === '/pending-nft' ? 'btn-blue' : 'btn-blue btn-white'}`} type="button" onClick={() => navigate('/pending-nft')}>Pending
                        NFW
                    </button>
                    <button className={`${location?.pathname === '/upload-nft' ? 'btn-blue' : 'btn-blue btn-white'}`} type="button" onClick={() => navigate('/upload-nft')}>Issue NFW</button>
                </div>
                {location.pathname === '/profile' &&
                    <button className="btn-blue btn-white btn-lg mb-3" type="button" onClick={() => openWithdrawModal()}>Withdraw Coins</button>
                }  
            </div>
        }
        {location.pathname === '/profile' && <button className="btn-blue btn-white btn-lg mb-3" type="button" onClick={() => openFollowListModal()}>
            Follow List
        </button> }
        <p>3D, Illustration, Abstract, Arts, Gradient, Minimalist, Trendy</p>
        <SweetAlert type={sweetAlert.type} title={sweetAlert.title} onConfirm={() => closeSweetAlertHandler()}
                    show={sweetAlert.show}/>
        <Loader show={loading}/>
    </>
}

export default UserProfileLayout;
