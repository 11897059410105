import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment-timezone";
import API from "../../../../redux/API";
import Loader from "../../../../components/loader";

function InquiryAnswer() {
  const navigate = useNavigate();
  const [answer, setAnswer] = useState("");
  const [loading, _loading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    _loading(true);
    API({ url: `/question/${id}`, method: "GET" })
      .then((data) => {
        setAnswer(data?.data);
      })
      .catch((e) => console.log("Error : ", e))
      .finally(() => _loading(false));
  }, []);
  return (
    <>
      <div className="container mb-5 w-100">
        <div className="row justify-content-center ptb-3 p-2 pt-5 p-lg-2">
          <div className="col-12 ">
            <h1 className="robot-font fw-bolder text-center pt-5 regal-blue-color">
              One-on-one inquiry
            </h1>
          </div>
          <div className="col-12 robot-font mt-5 ">
            <div className="row ">
              <div className="col-lg-12 col-12">
                <div className="d-flex justify-content-between border-top border-bottom pt-3">
                  <div>
                    <p
                      className=" ms-3 fw-bold font-cool-purple-color"
                      style={{ fontSize: "10px", margin: 0 }}
                    >
                      Notice
                    </p>
                    <p
                      className="ms-3 fw-bold regal-blue-color"
                      style={{ margin: 0 }}
                    >
                      {" "}
                      {/*{noticeData["title"]}*/}
                      {answer?.inquiry}
                    </p>
                  </div>
                  <div className="text-center">
                    <p
                      className="me-3 regal-blue-color"
                      style={{ margin: 0, fontSize: "14px" }}
                    >
                      {moment(answer?.created_at).format("YYYY-MM-DD")}
                      {/*{moment(noticeData["created_at"]).format('YYYY-MM-DD')}*/}
                    </p>
                  </div>
                </div>
                <div className="border-bottom">
                  <div className="pt-5 pb-5 ms-3 regal-blue-color ">
                    <div className="row">
                      <div className="col-12 col-lg-5">
                        <p
                          className="me-3 regal-blue-color"
                          style={{ fontSize: "14px", lineHeight: "50px" }}
                        >
                          {answer?.content}
                          {/*{noticeData["content"]}*/}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-bottom">
                  <div className="pt-5 pb-5 ms-3 regal-blue-color ">
                    <div className="row">
                      {answer?.isAnswer && (
                        <div className="col-12 col-lg-5">
                          <p
                            className="me-3 regal-blue-color"
                            style={{ fontSize: "14px", lineHeight: "50px" }}
                          >
                            {answer?.content}
                            {/*{noticeData["content"]}*/}
                          </p>
                        </div>
                      )}
                      <div className="col-12">
                        <p className="me-3 regal-blue-color">
                          (If you are using the new website and have any
                          inconveniences or other opinions, please leave them
                          through the one-on-one inquiry board.)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-5">
                  <button
                    className="listBtn"
                    style={{ background: "#162869", color: "white" }}
                    onClick={() => navigate("/inquiry")}
                  >
                    list
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader show={loading} />
    </>
  );
}

export default InquiryAnswer;
