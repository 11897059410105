import React, {useEffect, useRef, useState} from 'react';
import {Row} from "react-bootstrap";
import SearchHeaderLayout from "../../components/SearchHeaderlayout";
import Loader from "../../components/loader";
import SweetAlert from "react-bootstrap-sweetalert";
import {useNavigate} from "react-router-dom";
import NftArtCard from "../../components/NftArtCard";
import {StackedCarousel, ResponsiveContainer} from 'react-stacked-center-carousel';
import Slide from './Real-Estate-slider'
import {IPFS_URL} from "../../utils/config";
import API from "../../redux/API";
import _ from "lodash";
import './nftRealEstate.css';

const NftRealEstate = () => {

    const navigate = useNavigate();
    const [search, _search] = useState(false);
    const [realEstimateNftData, _realEstimateNftData] = useState([]);
    const [loading, _loading] = useState(false);
    const [page, _page] = useState(1);
    const [alert, _alert] = useState({show: false, title: '', type: 'success' || 'error'});
    const [mostLiked, _mostLiked] = useState([]);
    const [lastPage, _lastPage] = useState(false);
    const [nftData, _setNftData] = useState([]);


    const ref = useRef();
    useEffect(() => {
        getRealEstateNftData();
        getTopMostLikedData()
    }, [page])

    const getTopMostLikedData = () => {
        API({url: 'user/art-work/most-liked', method: "GET"})
            .then(async (res) => {
                _mostLiked(res.data)
            })
            .catch((error) => console.log("errors", error))
            .finally(() => _loading(false));
    }

    const getRealEstateNftData = () => {
        _loading(true);
        const apiUrlNftEstimate = `user/art-work/search?limit=9&verification=verified&page=${page}`;
        API({url: apiUrlNftEstimate, method: "GET"})
            .then(async (res) => {

                _lastPage(res?.data?.lastPage);
                const data = res.data.data;
                if (data.length > 0) {
                    _realEstimateNftData([...realEstimateNftData, ...data]);
                }

            })
            .catch((error) => console.log("errors", error))
            .finally(() => _loading(false));
    }


    useEffect(() => {

        const slidData = mostLiked?.map((item) => {
                return {
                    image: `${IPFS_URL}${item?.nft_token[0]?.file}`,
                    title: item?.title,
                    is_liked: item?.is_liked,
                    art_work_id: item?.art_work_id,
                    totalLikes: item?.totalLikes,
                    creator_nickname: item?.creator_nickname,
                    user_id: item?.creator?.user_id
                }
            }
        )
        _setNftData(slidData)
    }, [mostLiked]);

    const onCenterSlideDataIndexChange = (newIndex) => {
        ref.current = {
            art_work_id: mostLiked[newIndex].art_work_id,
            id: mostLiked[newIndex]?._id,
        }
    };


    const ResponsiveCarousel = ({slideData, newData}) => {
        const ref = React.useRef(StackedCarousel);

        let newSlideData = slideData.length < 3 ? [...slideData, ...slideData, ...slideData] : [...slideData];
        return (
            <div className='realEstateSlider'>
                <ResponsiveContainer carouselRef={ref} render={(parentWidth, carouselRef) => {
                    let currentVisibleSlide = 5;
                    if (parentWidth <= 1440) currentVisibleSlide = 3;
                    else if (parentWidth <= 1080) currentVisibleSlide = 1;
                    return (<>
                            <StackedCarousel
                                ref={carouselRef}
                                data={newSlideData}
                                carouselWidth={parentWidth}
                                slideWidth={525}
                                slideComponent={Slide}
                                maxVisibleSlide={5}
                                currentVisibleSlide={currentVisibleSlide}
                                useGrabCursor={true}
                                onActiveSlideChange={onCenterSlideDataIndexChange}

                            />
                        </>
                    )
                }}/>
            </div>
        )
    }

    return <>
        <section id="real-estate" className="container">
            {search ?
                <section className="container sectionMargin mt-5">
                    <div className="row">
                        <div className="col-12 m-auto d-flex justify-content-center align-items-center">
                            <h1>{`This is the search result for "${search}".`}</h1>
                        </div>
                    </div>
                </section>
                : <>
                    <section className="container  mt-5">
                        <div className="row mt-5">
                            <div className="col-12 m-auto">
                                {nftData?.length > 0 &&
                                    <div className="d-flex justify-content-center align-items-center">
                                        <ResponsiveCarousel newData={true} slideData={nftData}/>
                                    </div>}
                            </div>
                        </div>
                    </section>

                    <section className="container sectionMargin mt-5">
                        <Row>
                            <div className="d-flex justify-content-center align-items-center flex-column">
                                <div>
                                    <span className="realEstateContent">Find out more about the sale information you are interested in.</span>
                                </div>
                                <div>
                                    <button className="btn-blue mt-5"
                                            onClick={() => navigate(`/art-work/details/${ref.current.art_work_id}/${ref.current.id}`)}>View More
                                    </button>
                                </div>
                            </div>
                        </Row>
                    </section>
                </>}
            <section className="container sectionMargin mt-5">
                <Row>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h3>NEW NFT Real Estate</h3>
                        </div>
                        <div>
                            <button className="gradient-green-btn" onClick={() => navigate('/upload-nft')}> Issue NFW real estate
                            </button>
                        </div>
                    </div>
                    <div className="m-auto d-flex justify-content-center align-items-center flex-column">
                        <div className="w-100">
                            {!loading && realEstimateNftData.length > 0 ? <>
                                    <Row className="">
                                        {realEstimateNftData?.map((item, index) => <NftArtCard cardData={item}
                                                                                               key={index}
                                                                                               _alert={_alert}/>)}
                                    </Row>
                                </>
                                :
                                <div className="d-flex justify-content-center align-items-center mt-5">
                                    <h3>Real Estate Data not Found</h3>
                                </div>
                            }
                        </div>
                    </div>
                </Row>
            </section>
            <section className="container sectionMargin mt-5">
                <div className="row">
                    <div className="col-12 m-auto d-flex justify-content-center align-items-center mouse-pointer">
                        {!lastPage && <span className="btn-blue" onClick={() => _page(page + 1)}>see more</span>}
                    </div>
                </div>
            </section>
        </section>
        <Loader show={loading}/>
        <SweetAlert type={alert.type} title={alert.title} onConfirm={() => _alert(s => ({...s, show: false}))}
                    show={alert.show}/>
    </>
}

export default NftRealEstate;
