import {Image, Modal, Spinner} from "react-bootstrap";
import cancelVector from "../../images/others/Vector.png";
import React, {useEffect, useState} from "react";
import NftFormat from "../../components/NftFormat";
import creatorImg from "../../images/others/creator.jpg";
import {UserNickName} from "../../utils/function";
import {AdminAccountId, contractType, ErrorMessage, formOfSaleValue, networkID} from "../../utils/config";
import {ethEnabled} from "../../utils/web3.function";
import {useSelector} from "react-redux";
import MetaMaskHook from "../../hooks/MetaMaskHook";
import {Web3} from "../../utils/web3";
import API from "../../redux/API";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../../components/loader";
import {useNavigate} from "react-router-dom";

const NftPurchaseModal = ({show, nftData, closePurchaseModal, getMyNftData}) => {

    const navigate = useNavigate();
    const [loading, _loading] = useState(false);
    const [isMetaMaskTransaction, setMetaMaskTransaction] = useState(false);
    const [isAPITransaction, setAPITransaction] = useState(false);
    const [sweetAlert, _alert] = useState({show: false, title: '', type: 'success' || 'error'});
    const {ETHAddress, loggedUser} = useSelector(s => s.user);
    const {isMetaMaskInstall, accountId, networkVersion, isDataLoading, isMetaMaskConnected} = MetaMaskHook();
    const feesData = {
        usage: nftData?.adminFeeData?.data?.usage || true,
        admin_fee: nftData?.adminFeeData?.data?.fees?.admin_fee || 0,
    }

    useEffect(() => {
        return (() => {
            _alert("");
        })
    },[])

    const purchaseNft = async () => {

        const isMetamaskvalid = await metamaskValidation();
        if(isMetamaskvalid){
            if (nftData.sale_coin == 0) {
                if (networkVersion == networkID.ETH) {
                  await etherTransaction();  
                } else {
                  _alert({
                    show: true,
                    type: "warning",
                    title: ErrorMessage.ETH_Network_Version,
                  });
                }
              } else if (nftData.sale_coin == 1) {
                if (networkVersion == networkID.MATIC) {
                  await maticTransaction();
                } else {
                  _alert({
                    show: true,
                    type: "warning",
                    title: ErrorMessage.MATIC_Network_Version,
                  });
                }
              } else {
                _alert({
                  show: true,
                  type: "warning",
                  title: 'Wrong Network',
                });
              }
        }
    }

    const etherTransaction = async () => {
        const amount = feesData.usage ? Number(nftData?.sale_price) + Number(feesData.admin_fee) : Number(nftData?.sale_price);
        let createApiResponse = {
            transactionHash: {},
            id: nftData?._id,
            form_of_sale: nftData?.form_of_sale,
            art_work_id: nftData?.art_work_id,
            amount: nftData?.sale_price,
            real_estate_type: nftData?.real_estate_type,
            fees:{
                usage: feesData.usage,
                admin_fee :feesData.admin_fee
            }
        };
        if (nftData?.contract_type === contractType.erc721) {
            const ethValue = Web3.utils.toWei((amount).toString(), 'ether');
            // fixed price
            if (nftData?.form_of_sale === formOfSaleValue[0]) {

                setMetaMaskTransaction(true);
                const params = {
                    from: accountId,
                    to: AdminAccountId,
                    value: ethValue.toString(),
                    // gas: 9000000,
                    // gasPrice: Web3.utils.toWei('50', 'gwei')
                };
                await Web3.eth.sendTransaction(params).then((res) => {
                    setAPITransaction(true);
                    createApiResponse.transactionHash = res;

                    API({
                        url: 'user/art-work/purchase',
                        data: createApiResponse,
                    }).then(res => {
                        _alert({show: true, type: "success", title: res?.data?.message});
                    }).catch(error => {
                        console.log('error 3', error?.message);
                        _alert({show: true, type: "error", title: error.message});
                    }).finally(() => {
                        setAPITransaction(false);
                        _loading(false);
                        closePurchaseModal(false,null);
                        getMyNftData();
                    })
                }).catch((err) => {
                    _alert({show: true, type: "warning", title: err.message});
                }).finally(() => setMetaMaskTransaction(false));
            }
        }else{
                //fixed Price
                if (nftData?.form_of_sale === formOfSaleValue[0]) {

                    // const finalPrice = (quantity * data?.sale_price).toFixed(15);
                    let finalPrice = (nftData?.quantity * nftData?.sale_price).toFixed(15);
                    const totalSalePrice = Web3.utils.toWei(finalPrice.toString());
                    setMetaMaskTransaction(true);

                    const params = {
                        from: accountId,
                        to: AdminAccountId,
                        value: totalSalePrice.toString(),
                        // gas: 9000000,
                        // gasPrice: 152000000000
                    };

                    await Web3.eth.sendTransaction(params).then((res) => {

                        setAPITransaction(true);
                        createApiResponse.transactionHash = res;
                        createApiResponse.sale_quantity = nftData?.quantity;

                        API({
                            url: 'user/art-work/purchase-share',
                            data: createApiResponse,
                        }).then(res => {
                            _alert({show: true, type: "success", title: res?.data?.message});
                            setTimeout(() => {
                                navigate('/profile')
                            }, 1500);

                        }).catch(error => {
                            _alert({show: true, type: "error", title: error.message});
                        }).finally(() => setAPITransaction(false))

                    }).catch((err) => {
                        _alert({show: true, type: "warning", title: err.message});
                    }).finally(() => setMetaMaskTransaction(false));
            } else {
                _alert({show: true, type: "warning", title: `Please enter appropriate Quantity`});
            }
        }
    }

    const maticTransaction = async () => {
        const amount = feesData.usage ? Number(nftData?.sale_price) + Number(feesData.admin_fee) : Number(nftData?.sale_price);
        let createApiResponse = {
            transactionHash: {},
            id: nftData?._id,
            form_of_sale: nftData?.form_of_sale,
            art_work_id: nftData?.art_work_id,
            amount: nftData?.sale_price,
            real_estate_type: nftData?.real_estate_type,
            fees:{
                usage: feesData.usage,
                admin_fee :feesData.admin_fee
            }
        };
        
        if (nftData?.contract_type === contractType.erc721) {
            const maticValue = Web3.utils.toWei((amount).toString(), 'ether');
            // fixed price
            if (nftData?.form_of_sale === formOfSaleValue[0]) {

                setMetaMaskTransaction(true);
                const params = {
                    from: accountId,
                    to: AdminAccountId,
                    value: maticValue.toString(),
                    gas: 9000000,
                    gasPrice: Web3.utils.toWei('150', 'gwei')
                };
                await Web3.eth.sendTransaction(params).then((res) => {
                    setAPITransaction(true);
                    createApiResponse.transactionHash = res;

                    API({
                        url: 'user/art-work/purchase',
                        data: createApiResponse,
                    }).then(res => {
                        _alert({show: true, type: "success", title: res?.data?.message});
                    }).catch(error => {
                        console.log('error 3', error?.message);
                        _alert({show: true, type: "error", title: error.message});
                    }).finally(() => {
                        setAPITransaction(false);
                        _loading(false);
                        closePurchaseModal(false,null);
                        getMyNftData();
                    })
                }).catch((err) => {
                    _alert({show: true, type: "warning", title: err.message});
                }).finally(() => setMetaMaskTransaction(false));
            }
        } else {
            //fixed Price
            if (nftData?.form_of_sale === formOfSaleValue[0]) {
                let finalPrice = (nftData?.quantity * nftData?.sale_price).toFixed(15);
                const totalSalePrice = Web3.utils.toWei(finalPrice.toString());
                setMetaMaskTransaction(true);
                const params = {
                    from: accountId,
                    to: AdminAccountId,
                    value: totalSalePrice.toString(),
                    gas: 9000000,
                    gasPrice: Web3.utils.toWei('150', 'gwei')
                }

                await Web3.eth.sendTransaction(params).then((res) => {
                   
                    setAPITransaction(true);
                    createApiResponse.transactionHash = res;
                    createApiResponse.sale_quantity = nftData?.quantity;
                    
                    API({
                        url: 'user/art-work/purchase-share',
                        data: createApiResponse,
                    }).then(res => {
                        _alert({show: true, type: "success", title: res?.data?.message});
                        setTimeout(() => {
                            navigate('/profile')
                        }, 1500);

                    }).catch(error => {
                        _alert({show: true, type: "error", title: error.message});
                    }).finally(() => setAPITransaction(false))
                }).catch((err) => {
                    _alert({show: true, type: "warning", title: err.message});
                }).finally(() => setMetaMaskTransaction(false));
            } else {
                _alert({show: true, type: "warning", title: `Please enter appropriate Quantity`});
            }
        }
    }

    const metamaskValidation = async () => {
        if (loggedUser?.user_id) {
            if (nftData && nftData?.is_current_owner === false) {
                if (!isDataLoading) {
                    if (isMetaMaskInstall) {
                        console.log(accountId)
                        if (isMetaMaskConnected) {
                            // check validation for backend and current metamask need to same
                            if (ETHAddress && accountId === ETHAddress) {
                                return true;
                            } else {
                                if (ETHAddress) {
                                    _alert({
                                        show: true, type: "warning", title: ErrorMessage.selectLinkWallet
                                    });
                                } else {
                                    _alert({
                                        show: true, type: "warning", title: ErrorMessage.updateWallet
                                    });
                                }
                            }
                        } else {
                            _alert({show: true, type: "warning", title: ErrorMessage.connectMetamask});
                            await ethEnabled();
                        }
                    } else {
                        _alert({show: true, type: "error", title: ErrorMessage.metamaskInstallation});
                    }
                }

            } else {
                _alert({show: true, type: "warning", title: ErrorMessage.ownerOfNAft});
            }

        } else {
            _alert({show: true, type: "warning", title: ErrorMessage.checkLoggedUser});
        }

        return false;
    }

    const closeSweetAlertHandler = (s) => {
        _alert(s => ({...s, show: false}));
    }

    return(
        <>
            <Modal
                show={show}
                centered
                size="lg"
            >
            <Modal.Body>
                <div className="float-end mouse-pointer me-3" onClick={() => closePurchaseModal(false,null)}>
                    <Image src={cancelVector} height={18} width={18} />
                </div>
                <div className="container mt-3 p-3">
                    <div className="mt-3 w-100">
                        <div className="position-absolute p-2 cardLable">
                                <span
                                    className="fw-bold regal-blue-color  inquiry-box-shadow">{nftData?.real_estate_type ? 'Real Estate' : 'Normal'}</span>
                        </div>

                        <div style={{ height: '250px' }}>
                            <NftFormat type={nftData?.title_image_format}
                                       file={nftData?.title_image} />
                        </div>
                    </div>
                    <div className="mt-3">
                        <h2 className="regal-blue-color">{nftData?.title}</h2>
                    </div>
                    <div className="mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="mouse-pointer"
                                 onClick={() => navigator(`/profile/${nftData?.creator?.user_id}`)}>
                                <Image className="rounded-circle"
                                       src={`${nftData?.creator?.creator_profile_image ?
                                           nftData?.creator?.creator_profile_image :
                                           creatorImg}`}
                                       height={45} width={45} />
                                <span
                                    className="fw-bold ms-2">{nftData?.creator?.creator_nickname ? nftData?.creator?.creator_nickname : UserNickName(nftData?.creator?.creator_email)}</span>
                            </div>
                            <div className="mouse-pointer"
                                 onClick={() => navigator(`/profile/${nftData?.current_owner?.current_owner_id}`)}>
                                <Image className="rounded-circle"
                                       src={`${nftData?.current_owner?.current_owner_profile_image ?
                                           nftData?.current_owner?.current_owner_profile_image :
                                           creatorImg}`}
                                       height={45} width={45} />
                                <span className="fw-bold ms-2">
                                        <span
                                            className="fw-bold ms-2 ">{nftData?.current_owner?.current_owner_nickname}</span>
                                    </span>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <span className="fw-bolder font_color_black">Sale Price</span>
                        <input
                            type="text"
                            name="fixed_price"
                            placeholder="Fixe Price"
                            className="purchaseModalInputDisable font_color_black mb-3"
                            value={nftData?.sale_price || 0}
                            disabled
                        />
                    </div>
                    {
                        feesData?.usage && (
                            <>
                                <span className="fw-bolder font_color_black">Transaction Fees</span>
                                <input
                                    type="text"
                                    name="fixed_price"
                                    placeholder="Fixed Price"
                                    className="purchaseModalInputDisable font_color_black mb-3"
                                    value={feesData.admin_fee || 0}
                                    disabled
                                />
                                <span className="fw-bolder font_color_black">Sale Price</span>
                                <input
                                    type="text"
                                    name="fixed_price"
                                    placeholder="Fixed Price"
                                    className="purchaseModalInputDisable font_color_black"
                                    value={Number(nftData?.sale_price) + Number(feesData.admin_fee) || 0}
                                    disabled
                                />
                            </>
                        )
                    }
                    <div className="mt-5">
                        {loading ?
                            <button className="btnBgBlue modalBtn fw-bolder">
                                Purchase <Spinner animation="border" size="sm" className=" ms-2"/>
                            </button> :
                            <button className="btn-blue-view modalBtn fw-bolder" onClick={() => purchaseNft()}>
                                Purchase
                            </button>
                        }
                    </div>
                </div>
            </Modal.Body>
            </Modal>
            <Loader show={loading || isMetaMaskTransaction || isAPITransaction}/>
            <SweetAlert type={sweetAlert.type} title={sweetAlert.title} onConfirm={() => closeSweetAlertHandler()}
                        show={sweetAlert.show}/>
        </>
    )
}
export default NftPurchaseModal;