import React, {useEffect, useState} from 'react';
import {Image, Modal} from "react-bootstrap";
import cancelVector from "../../images/others/Vector.png";
import API from "../../redux/API";
import Loader from "../../components/loader";
import avtar from "../../images/background/avtar.png";
import {Link} from "react-router-dom";
import vectorImg from '../../images/others/VectorArrow.jpg';
import {useSelector} from "react-redux";
import Switch from "react-switch";

const FollowListModal = ({show, openFollowListModal}) => {
    const {loggedUser} = useSelector(s => s.user);
    const [followList, _followList] = useState({
        followersList: [],
        followingList: [],
    });
    const [followers, _followers] = useState([]);
    const [followings, _followings] = useState([]);
    const [loading, _loading] = useState(false);
    const userListType = [
        {lable: 'My Followers', value: 'followers'},
        {lable: 'Following List', value: 'followings'},
    ];
    const [currentUserListType, _currentUserListType] = useState(userListType[0].value);
    const currentListType = currentUserListType === "followers" ? followers : followings

    useEffect(() => {
        _loading(true);
        getFollowers();
        getFollowingList();
        _loading(false);
        return (() => {
            _followers([]);
            _followings([]);
            _currentUserListType("");
        })
    },[]);

    const getFollowers = () => {
        API({url: `user/my-followers/${loggedUser?.user_id}`, method: 'GET'})
            .then((data) => {
                _followers(data?.data)
            })
            .catch((e) => console.log("Error : ", e));
    }

    const getFollowingList = () => {
        API({url: `user/my-followings/${loggedUser?.user_id}`, method: 'GET'})
            .then((data) => {
                _followings(data?.data)
            })
            .catch((e) => console.log("Error : ", e));
    }

    return (
        <>
            <Modal
                show={show}
                centered
                size="md"
            >
                <Modal.Header>
                    <div className="w-100 d-flex justify-content-between align-items-center"
                         onClick={() => openFollowListModal(false)}>
                        <div style={{fontWeight: 800, color: '#162869', fontSize: '32px'}}>{true ? "My followers" : "Following List"}</div>
                        <Image className="mouse-pointer" src={cancelVector} height={18} width={18}/>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="forms">
                        <select className="form-style mouse-pointer" value={currentUserListType}
                                onChange={(e) => _currentUserListType(e.target.value)}>
                            {userListType?.map((item, index) =>
                                <option key={`${item.value} + ${index}`} value={item.value}>{item.lable}</option>
                            )}

                        </select>
                    </div>
                    <div className="followListContainer mt-3">
                        {
                            currentListType?.length > 0 ? currentListType?.map((user, index) => (
                                    <Link key={index} to={`/profile/${user?.user_id}`}  className="text-decoration-none">
                                        <div className=" d-flex justify-content-between align-items-center mb-2 border-bottom border-1 pb-1">
                                            <div className="d-flex align-items-center">
                                                <div className="userAvatar"><img src={avtar} alt=""/></div><span style={{fontWeight: 800, color: '#162869',fontSize: '16px'}}>{user?.nickname || user?.email}</span>
                                            </div>
                                            <img src={vectorImg} height="20" width="20"/>
                                        </div>
                                    </Link>
                                ))
                                : <div style={{fontWeight: 800, color: '#162869', fontSize: '16px'}} className="text-center">No Data Found !..</div>
                        }
                    </div>
                </Modal.Body>
                <Loader show={loading}/>
            </Modal>
        </>
    )
}

export default FollowListModal;