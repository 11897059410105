import React, {useEffect, useState} from "react";
import {Button, Row, Col, Card} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import API from "../../redux/API";
import avtar from "../../images/background/avtar.png";
import arrowIcon from "../../images/background/arrow-icon.png";
import popularArtImg1 from "../../images/background/popular-art-1.png";
import HeroSection from "../../components/HeroSection";
import NftArtCard from "../../components/NftArtCard";
import left_arrow from "../../images/others/left_arrow.svg";
import right_arrow from "../../images/others/right_arrow.svg";
import Switch from "react-switch";
import {IPFS_URL} from "../../utils/config";
import {UserNickName} from "../../utils/function";
import Loader from "../../components/loader";
import SweetAlert from "react-bootstrap-sweetalert";
import "./Home.css";

function Home() {


    const navigate = useNavigate();
    const [loading, _loading] = useState({
        popularCreator: false,
        popularArt: false,
        nftEstimate: false,
        marketplace: false,
        topCreators: false,
    });
    const [popularCreator, _popularCreator] = useState([]);

    const [checked, _checked] = useState(false);
    const [topArtWork, _topArtWork] = useState([]);
    const [nftEstimate, _nftEstimate] = useState([]);
    const [nftEstimateSliceData, _nftEstimateSliceData] = useState([]);
    const [marketplace, _marketplace] = useState([]);
    const [popularArt, _popularArt] = useState([]);
    const [artWorkData, _artWorkData] = useState([]);
    const [creatorPage, _creatorPage] = useState(1);
    const [currentPage, _currentPage] = useState(false);
    const [lastPage, _lastPage] = useState(false);
    const [popularArtPage, _popularArtPage] = useState({next: false, prev: true});
    const [estimateNftPage, _estimateNftPage] = useState({next: false, prev: true});
    const [alert, _alert] = useState({show: false, title: '', type: 'success' || 'error'});
    let baseUrl = 'user/art-work/search?page=1&limit=3&nft_type=0';
    // const [eBookData, setEbookData] = useState('');

    useEffect(() => {
        _loading({
            popularCreator: true,
            popularArt: true,
            nftEstimate: true,
            marketplace: true,
            topCreators: true,
        });

        //Get top Creations
        API({url: `user/art-work/top`, method: "GET"})
            .then(async (res) => {
                _topArtWork(res?.data?.data || []);
            })
            .catch((err) => {
                console.log("err", err);
            })
            .finally(() => _loading({...loading, topCreators: false}));

        //fetch the data for Popular creator
        getPopularCreator(1);
        //fetch the data for Popular Arts
        getPopularArtWork();
        //fetch the data for nftEstimate
        getRealEstateNftData();
        //market place NFT Data
        getMarketPlaceNftData();
        // getEbookData();
        return(() => {
            _loading("");
            _popularCreator([]);
            _topArtWork([]);
            _nftEstimate([]);
            _nftEstimateSliceData([]);
            _marketplace([]);
            _popularArt([]);
            _artWorkData([]);
        })

    }, []);
 
    useEffect(() => {
        getPopularCreator();
    },[creatorPage])
    

    useEffect(() => {
        _loading({
            marketplace: true,
        });

    return(() => {
        _loading("");
        _marketplace([]);
    })
}, [checked]);

    //get marketplace Data
    const getMarketPlaceNftData = () => {
        let filter = '';
        if (checked) {
            filter = 'created_at';
        } else {
            filter = 'popular';
        }
        _loading({...loading, marketplace: true});
        const apiUrlNftEstimate = `user/art-work/market-place?limit=6&sortBy=${filter}&nft_type=0`;
        API({url: apiUrlNftEstimate, method: "GET"})
            .then(async (res) => {
                _marketplace(res?.data?.data || []);
                // console.log("res", res?.data?.data );
            })
            .catch((error) => console.log("errors", error))
            .finally(() => _loading({...loading, marketplace: false}));
    }

    // const getEbookData = (filter = '0') => {
    //     const apiUrlNftEstimate = `${baseUrl}&sortBy&${filter}&art_work_category=4`;
    //     API({url: apiUrlNftEstimate, method: "GET"})
    //         .then(async (res) => {
    //             setEbookData(res?.data?.data || []);
    //         })
    //         .catch((error) => console.log("errors", error))
    //         .finally(() => _loading({...loading, marketplace: false}));
    // }

    //get New NFT Estimate
    const getRealEstateNftData = () => {

        const apiUrlNftEstimate = `user/art-work/search?limit=6&verification=verified&pending=false`;
        API({url: apiUrlNftEstimate, method: "GET"})
            .then(async (res) => {

                const estimateData = res.data.data;
                const sliceData = estimateData.slice(0, 3);
                _nftEstimate(estimateData);
                _nftEstimateSliceData(sliceData || []);

            })
            .catch((error) => console.log("errors", error))
            .finally(() => _loading({...loading, nftEstimate: false}));
    }

    //get popular creators
    const getPopularCreator = () => {
        _loading({...loading, popularCreator: true})
        API({
            url: `user/creators?&limit=6&page=${creatorPage}`, method: "GET",
        }).then(async (res) => {
            _popularCreator(res?.data?.data || []);
            _currentPage(res.data?.currentPage);
            _lastPage(res.data?.lastPage);
        }).catch((err) => {
            console.log("err", err);
        }).finally(() => _loading({...loading, popularCreator: false}));
    }

    //get popular art Work
    const getPopularArtWork = (pages) => {
        _loading({...loading, popularArt: true})
        const apiUrl = "user/art-work/most-liked";
        API({url: apiUrl, method: "GET"})
            .then(async (res) => {
                const popularArtWorkData = res.data;
                const sliceData = popularArtWorkData.slice(0, 3);
                _artWorkData(popularArtWorkData);
                _popularArt(sliceData || []);
            })
            .catch((error) => console.log("errors", error))
            .finally(() => _loading({...loading, popularArt: false}));

    }


    const fetchPopularArtWork = (event) => {

        if (event === 'next' && popularArtPage.next === true) {
            _alert({show: true, type: "warning", title: 'This is last Page!...'});
            return false
        } else if (event === 'prev' && popularArtPage.prev === true) {
            _alert({show: true, type: "warning", title: 'This is First Page!...'});
            return false
        } else {
            if (event === 'next') {
                const nextLength = popularArt.length;
                const sliceData = artWorkData.slice(nextLength, 6);
                _popularArtPage({prev: false, next: true})
                _loading({...loading, popularArt: true});
                _popularArt(sliceData);
                _loading({...loading, popularArt: false})

            } else {
                const sliceData = artWorkData.slice(0, 3);
                _popularArtPage({next: false, prev: true})
                _loading({...loading, popularArt: true});
                _popularArt(sliceData);
                _loading({...loading, popularArt: false})
            }
        }
    }

    const fetchPopularCreator = (event) => {

        if(event === 'next' && lastPage === true){
            _alert({show: true, type: "warning", title: 'This is last Page!...'});
            return false
        }else if(event === 'prev' && currentPage <= 1){
            _alert({show: true, type: "warning", title: 'This is first Page!...'});
            return false
        }else{
            if (event === 'next') {
                _creatorPage(creatorPage + 1)
            } else {
                if (currentPage !== 1) {
                    _creatorPage(creatorPage - 1)
                }
            }
        }


    }

    const fetchEstimateData = (event) => {

        if (event === 'next' && estimateNftPage.next === true) {
            _alert({show: true, type: "warning", title: 'This is last Page!...'});
            return false
        } else if (event === 'prev' && estimateNftPage.prev === true) {
            _alert({show: true, type: "warning", title: 'This is last Page!...'});
            return false
        } else {
            if (event === 'next') {
                const nextLength = nftEstimateSliceData.length;
                const sliceData = nftEstimate.slice(nextLength, 6);
                _estimateNftPage({prev: false, next: true})
                _loading({...loading, nftEstimate: true});
                _nftEstimateSliceData(sliceData);
                _loading({...loading, nftEstimate: false})

            } else {
                const sliceData = nftEstimate.slice(0, 3);
                _estimateNftPage({next: false, prev: true})
                _loading({...loading, nftEstimate: true});
                _nftEstimateSliceData(sliceData);
                _loading({...loading, nftEstimate: false})
            }
        }
    }

    const handleChange = () => {
        _checked(!checked);
    };


    return (
        <>
            {/*<section>*/}
            {/*    <HeroSection topArtWork={topArtWork}/>*/}
            {/*</section>*/}
            <section className="container" style={{marginTop: "5%"}}>
            {/* <section className="container sectionMargin mt-5">
                    <Row>
                        <Col md={12} className="mt-3">
                            <div className="d-flex justify-content-between mb-4">
                                <div>
                                    <h3 className="fw-bold regal-blue-color">NFT BOOK</h3>
                                </div>
                            </div>
                            <Row>
                                <Col md={12}>
                                    { eBookData.length > 0 ? (
                                        <Row className="d-flex justify-content-center">
                                            {eBookData?.map((item, index) => <NftArtCard cardData={item} key={index} _alert={_alert}/>)}
                                        </Row>
                                    ) : (
                                        <h4 className="noDataAvailableMessage">
                                            Marketplace Data Not Available
                                        </h4>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </section> */}

                {/*<section className="container sectionMargin mt-5">*/}
                {/*    <Row>*/}
                {/*        <Col md={12} className="mt-3">*/}
                {/*            <div className="d-flex justify-content-between mb-4">*/}
                {/*                <div>*/}
                {/*                    <h3 className="fw-bold regal-blue-color">New NFT Estimate</h3>*/}
                {/*                </div>*/}
                {/*                <div className="d-flex justify-content-around align-items-center mouse-pointer">*/}

                {/*                    <button*/}
                {/*                        className="rounded-circle arrowBtn me-2 border-0"*/}
                {/*                        onClick={() => fetchEstimateData('prev')}*/}
                {/*                    >*/}
                {/*                        <img src={left_arrow} alt="left_arrow" height={15}/>*/}
                {/*                    </button>*/}
                {/*                    <button*/}
                {/*                        className="rounded-circle arrowBtn border-0"*/}
                {/*                        onClick={() => fetchEstimateData('next')}*/}
                {/*                    >*/}
                {/*                        <img src={right_arrow} alt="left_arrow" height={15}/>*/}
                {/*                    </button>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <Row>*/}
                {/*                <Col md={12}>*/}
                {/*                    {!loading.nftEstimate && nftEstimateSliceData.length > 0 ? (*/}
                {/*                        <Row className="d-flex justify-content-center">*/}
                {/*                            {nftEstimateSliceData?.map((item, index) => <NftArtCard cardData={item} key={index} _alert={_alert}/>)}*/}
                {/*                        </Row>*/}
                {/*                    ) : (*/}
                {/*                        <h4 className="noDataAvailableMessage">*/}
                {/*                            NFT Estimate Data Not Available...!*/}
                {/*                        </h4>*/}
                {/*                    )}*/}
                {/*                </Col>*/}
                {/*            </Row>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*</section>*/}

                <section className="container sectionMargin mt-5">
                    <div className="d-flex justify-content-between mb-4">
                        <div>
                            <h3 className="fw-bold regal-blue-color">Market Place</h3>
                        </div>
                        <div className="d-flex justify-content-around align-items-center mouse-pointer">
                            <Switch
                                onChange={handleChange}
                                checked={checked}
                                className="react-switch"
                                uncheckedIcon={
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            color: "#0a1748",
                                            paddingRight: 20,
                                            fontWeight: 600,
                                        }}
                                    >
                                        Popularity
                                    </div>
                                }
                                checkedIcon={
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            color: "#0a1748",
                                            paddingRight: 2,
                                            fontWeight: 600,
                                        }}
                                    >
                                        Latest
                                    </div>
                                }
                                width={120}
                                height={40}
                            />
                        </div>
                    </div>
                    {!loading.marketplace && marketplace.length > 0 ? (
                        <Row className="d-flex justify-content-center">
                            {marketplace?.filter((val) => {
                                // if (val.selling_available ==true) {
                                     return val;
                                // }   
                            }).map((item, index) => <NftArtCard cardData={item} key={index} _alert={_alert}/>)}
                        </Row>
                    ) : (
                        <h4 className="noDataAvailableMessage">
                            Marketplace Data Not Available
                        </h4>
                    )}
                </section>
                <section className="container sectionMargin mt-5">
                    <div className="d-flex justify-content-between mb-4">
                        <div>
                            <h3 className="fw-bold regal-blue-color">From Khanteum Creator</h3>
                        </div>
                        <div className="d-flex justify-content-around align-items-center mouse-pointer">
                            <button
                                className="rounded-circle arrowBtn me-2 border-0 mouse-pointer"
                                onClick={() => fetchPopularCreator('prev')}

                            >
                                <img src={left_arrow} alt="left_arrow" height={15}/>
                            </button>

                            <button
                                className="rounded-circle arrowBtn border-0 mouse-pointer"
                                onClick={() => fetchPopularCreator('next')}

                            >
                                <img src={right_arrow} alt="left_arrow" height={15}/>
                            </button>
                        </div>
                    </div>
                    <Row>
                        <div className="popular-creator">
                            <Col md={12}>
                                {!loading.popularCreator && popularCreator.length > 0 ?  <ul>
                                    {popularCreator?.map((item, index) =>
                                        <li key={item?._id} className="mouse-pointer"
                                            onClick={() => navigate(`/profile/${item?.user_id}`)}>
                                            <div className="popular-list-details">
                                                <div className="avtar-name">
                                                    <img src={item?.profile_image || avtar} alt="avtar"
                                                         className="card-avtar rounded-circle"/>
                                                    <h4>{item?.nickName ? item?.nickName : UserNickName(item?.email)}</h4>
                                                </div>
                                                <div className="list-price">{item?.totalEthSold} ETH</div>
                                            </div>
                                        </li>
                                    )}
                                </ul> : (
                                    <h4 className="noDataAvailableMessage">
                                        Popular creators Data Not Available
                                    </h4>
                                )}
                            </Col>
                        </div>
                    </Row>
                </section>

                <section className="container sectionMargin mt-5">
                    <div className="d-flex justify-content-between mb-4">
                        <div>
                            <h3 className="fw-bold regal-blue-color">Intellectual property</h3>
                        </div>
                        <div
                            className="d-flex justify-content-around align-items-center mouse-pointer">
                            <button
                                className="rounded-circle arrowBtn me-2 border-0"
                                onClick={() => fetchPopularArtWork('prev')}
                                id="arrowBtn"
                            >
                                <img src={left_arrow} alt="left_arrow" height={15}/>
                            </button>
                            <button
                                className="rounded-circle arrowBtn border-0"
                                onClick={() => fetchPopularArtWork('next')}
                            >
                                <img src={right_arrow} alt="left_arrow" height={15}/>
                            </button>
                        </div>
                    </div>

                    <Row className="justify-content-center mb-4">

                        {!loading.popularArt && popularArt.length > 0 ? popularArt?.map((item) =>
                                <Col xs={12} md={4} key={item?._id} style={{marginBottom:"10px"}}>
                                    <Card style={{backgroundColor: "#8D1DC9"}} >
                                        <Card.Body>
                                            <div>
                                                <div>
                                                    <div style={{ position: 'absolute',zIndex:'3' ,background:'rgba(255,255,255,0.3)',color:'white',marginLeft:'0',marginTop:'0', padding:'1%', borderBottomRightRadius:'20%'}}>
                                                    <span className="">Views: {item.views}</span>
                                                </div>
                                                <Card.Img
                                                    variant="top"
                                                    src={`${IPFS_URL}${item?.nft_token[0]?.file}` || popularArtImg1}
                                                    alt="img"
                                                    className="popular-img"
                                                    style={{height: 'auto', width: "100%"}}
                                                />
                                                </div>

                                            </div>
                                            <div>
                                                <Card.Title style={{color:"white"}}>
                                                    {item?.title}
                                                </Card.Title>
                                                <Card.Text className="fixed-content" style={{color:"white"}}>
                                                    {item?.description}
                                                </Card.Text>
                                                <Button
                                                    variant="default"
                                                    className="Montserrat btn-readmore"
                                                    onClick={()=>navigate(`/art-work/details/${item?.art_work_id}/${item?._id}`)}
                                                >
                                                 <div style={{color:"white"}}>   Read More <img src={arrowIcon} alt="icon"/></div>
                                                </Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>)
                            : (
                                <h4 className="noDataAvailableMessage">
                                    Popular Art Data Not Available
                                </h4>
                            )}
                    </Row>
                </section>
            </section>
            <Loader show={loading?.popularCreator || loading?.popularArt || loading?.nftEstimate || loading?.marketplace}/>
            <SweetAlert type={alert.type} title={alert.title} onConfirm={() => _alert(s => ({...s, show: false}))} show={alert.show}/>
        </>
    );
}

export default Home;
