import React, {useState,useEffect} from "react";
import {AuctionMemoized} from "../AuctionTime";
import {Card, Col,} from "react-bootstrap";
import avtar from "../../images/background/avtar.png";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import { useLocation, useRouteMatch } from 'react-router-dom';
import API from "../../redux/API";
import {AiOutlineHeart, AiTwotoneHeart} from "react-icons/ai";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../loader";
import NftFormat from "../NftFormat";
import "./artCard.css";
import { BASE_URL } from "../../utils/config";
import axios from "axios";



const NftArtCard = ({cardData, isMarketPlace = false,_alert}) => {

    const navigate = useNavigate();
    const {loggedUser} = useSelector(s => s.user);
    const [loading, _loading] = useState(false);
    const [likeCount, _likeCount] = useState(Number(cardData?.totalLikes));
    const [timesSold, setTimesSold] = useState(0);


    useEffect(() => {
        // getPurchaseHistory();
    }, []);

    //get purchase history in axios
    const getPurchaseHistory = async() => {
        

        //axios call
        const res = await axios.get(BASE_URL+'/user/art-work/purchase-history-by-id?art_work_id='+cardData?.art_work_id)
        .then(res => {
            let data = res?.data?.data?.data
            
            let length = data.length;
            console.log('Card Data',length);
            setTimesSold(length);
        }).catch(err => {
            console.log(err);
        })
    }


    const like = () => {
        if (loggedUser?.user_id) {
            _loading(true);
            API({url: `user/art-work/like?art_work_id=${cardData?.art_work_id}&id=${cardData?._id}`, method: "PUT"}).then(async res => {
                cardData.is_liked = !cardData?.is_liked;
                _alert({show: true, title:res?.data?.message, type: 'success'});
                if (cardData.is_liked) {
                    let plus = likeCount + 1
                    _likeCount(plus)
                } else {
                    let minus = (likeCount - 1);
                    _likeCount(minus);
                }

            }).catch(error =>  _alert({show: true, title: error.message, type: 'error'})).finally(() => _loading(false));
        } else {
            _alert({show: true, title: 'please login first', type: 'warning'})
        }
    }

    return (<>
            <Col xs={12} md={4} className="mt-5">
                <Card style={{background: '#8D1DC9', color: 'white'}}>
                    {cardData?.form_of_sale === 'auction' &&
                        <div className="artwork-calendar mt-2 ms-2">
                            <AuctionMemoized auction_End_Time={cardData?.auction_end_time}/>
                        </div>
                    }

                    <div>
                    <NftFormat type={cardData?.title_image_format} views={cardData?.views} file={cardData?.title_image}/>
                    </div>

                    <Card.Body>
                        <div className="card-detail-section">
                            <div className="card-name mouse-pointer" onClick={()=>navigate(`/profile/${cardData?.creator?.user_id}`)}>
                                <img src={cardData?.creator?.user_profile || avtar}
                                     alt="avtar"
                                     className="card-avtar rounded-circle"
                                /> {cardData?.creator?.creator_nickname}
                            </div>
                            <div className="heart-icon d-flex justify-content-center align-items-center mouse-pointer" onClick={() => like()}>
                                {
                                    cardData?.is_liked ?
                                        <AiTwotoneHeart size="20" style={{color: 'red'}}/>
                                        :
                                        <AiOutlineHeart size="20" style={{color: 'white'}}/>
                                }
                                {Number(likeCount)}
                            </div>
                            
                        </div>
                        {timesSold > 0 &&< div><p>{timesSold}</p></div>} 
                        <Card.Title style={{color:'white'}}>{cardData?.title}</Card.Title>
                        {cardData?.form_of_sale === 'auction' ?
                            <div className="bidbox-section">
                                <div className="bid-box">
                                    <p>Current Bid</p>
                                    <h4>{cardData?.highestBid ? Number(cardData?.highestBid).toFixed(5) : 0} {cardData?.sale_coin == 1 ? 'Matic': 'ETH'}</h4>
                                </div>
                                <div className="bid-box">
                                    <p>Open Bid</p>
                                    <h4>{Number(cardData?.auction_start_price).toFixed(7)} {cardData?.sale_coin == 1 ? 'Matic': 'ETH'}</h4>
                                </div>
                            </div> :
                            <div style={{background: '#8D1DC9', color:' white'}}>
                            <div className="bidbox-section">
                                <div className="bid-box w-100">
                                    <p>Current Price</p>
                                    <h4>{Number(cardData?.sale_price).toFixed(7)} {cardData?.sale_coin == 1 ? 'Matic': 'ETH'}</h4>
                                    
                                </div>
                                
                            </div>
                            {cardData?.korean_price ? 
                            <div className="bidbox-section">
                                <div className="bid-box w-100">
                                    <h4>{cardData?.korean_price} KRW</h4>
                                    
                                </div>
                                
                            </div>
                            : ''}
                            </div>
                        }
                    </Card.Body>
                    <Card.Footer>
                        {isMarketPlace ? <>
                                {cardData?.form_of_sale === 'auction' ? <button
                                    className="btn-card"
                                    onClick={() => navigate(`/art-work/details/${cardData?.art_work_id}/${cardData?._id}`)}>Place Bid
                                </button> : <button
                                    className="btn-card"
                                    onClick={() => navigate(`/art-work/details/${cardData?.art_work_id}/${cardData?._id}`)}>Buy
                                </button>}

                            </>

                            : <button
                                className="btn-card"
                                onClick={() => navigate(`/art-work/details/${cardData?.art_work_id}/${cardData?._id}`)}>View More
                            </button>
                        }

                    </Card.Footer>
                </Card>
            </Col>
            <Loader show={loading}/>

        </>
    )
};

export default NftArtCard;
