import { useEffect, useState } from "react";
import HeroSection from "../../components/HeroSection";
import {Button, Row, Col, Card} from "react-bootstrap";
import NftArtCard from "../../components/NftArtCard";
import API from "../../redux/API";
let baseUrl = 'user/art-work/search?page=1&limit=3&nft_type=0';


function NftBook() {

    const [eBookData, setEbookData] = useState('');
    const [alert, _alert] = useState({show: false, title: '', type: 'success' || 'error'});

    const getEbookData = (filter = '0') => {
        const apiUrlNftEstimate = `${baseUrl}&sortBy&${filter}&art_work_category=4`;
        API({url: apiUrlNftEstimate, method: "GET"})
            .then(async (res) => {
                setEbookData(res?.data?.data || []);
            })
            .catch((error) => console.log("errors", error))
            .finally(() => _loading({...loading, marketplace: false}));
    }

    useEffect(() => {
        getEbookData();
    },[])

    const [loading, _loading] = useState({
        popularCreator: false,
        popularArt: false,
        nftEstimate: false,
        marketplace: false,
        topCreators: false,
    });

    const [topArtWork,_topArtWork] = useState([]);

    return (
        <>
        <section>
            {/* <HeroSection topArtWork={topArtWork} /> */}
        </section>
        <section className="container" style={{ marginTop: "5%" }}>
                <section className="container sectionMargin mt-5">
                    <Row>
                        <Col md={12} className="mt-3">
                            <div className="d-flex justify-content-between mb-4">
                                <div>
                                    <h3 className="fw-bold regal-blue-color">NFT BOOK</h3>
                                </div>
                            </div>
                            <Row>
                                <Col md={12}>
                                    {eBookData.length > 0 ? (
                                        <Row className="d-flex justify-content-center">
                                            {eBookData?.filter((val) => {
                                                if (val.selling_available == true) {
                                                    return val;
                                                }
                                            }).map((item, index) => <NftArtCard cardData={item} key={index} _alert={_alert} />)}
                                        </Row>
                                    ) : (
                                        <h4 className="noDataAvailableMessage">
                                            Marketplace Data Not Available
                                        </h4>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </section>
            </section>
        </>
            
        )        

    }

export default NftBook
