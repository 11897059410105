import React, { useState, useEffect } from "react";
import { BiSearch } from "react-icons/bi";
import moment from "moment-timezone";
import axios from "axios";
import Pagination from "react-bootstrap/Pagination";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../../utils/config";
import Loader from "../../../../components/loader";
import "../../serviceCenter.css";
import API from "../../../../redux/API";

function EnquiryQuestion() {
  const [inquiry, setInquiry] = useState([]);
  const [search, _search] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, _currentPage] = useState(1);

  useEffect(() => {
    getInquiry();
  }, []);

  const getInquiry = () => {

    API({
      url: `question/all`,
      method: "GET",
    }).then((res) => {
      setInquiry(res?.data);
      setIsLoading(false);
    }).catch((e) => console.log("Error : ",e));
  };

  return (
    <>
      <div id="inquiry" className="container mb-5 w-100">
        <div className="row justify-content-center ptb-3 p-2 pt-5 p-lg-2">
          <div className="col-12 ">
            <h1 className="robot-font fw-bolder text-center pt-5 regal-blue-color">
              One-on-one inquiry
            </h1>
          </div>
          <div className="col-12 d-flex justify-content-end">
            <div className="">
              <input
                type="text"
                className="inputSearch"
                placeholder="search..."
                // onChange={(e) => _search(e.target.value)}
              />
              <BiSearch
                size="20"
                style={{ color: "#A9DEEE" }}
                className=" searchIcon"
              />
              <div className="w-100">
                <button
                  className="btn-blue"
                  onClick={() => navigate("/inquiry-registration")}
                >
                  Registration
                </button>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row mainRow">
              <div className="col-lg-12 col-12 overflow-auto p-0 ps-sm-4 pe-sm-4 table-wrapper">
                <table className="robot-font table-text-color w-100 mt-5 text-center border-top  border-bottom border-color">
                  <thead className="headBackground">
                    <tr>
                      <th className="border-bottom border-color p-3 regal-blue-color">
                        NO
                      </th>
                      <th className="border-bottom border-start border-color p-3 regal-blue-color w-50 text-uppercase">
                        Title
                      </th>
                      <th className="border-bottom border-start border-color p-3 regal-blue-color w-25 text-uppercase">
                        Registration Date
                      </th>
                      <th className="border-bottom border-start border-color p-3 regal-blue-color w-25 text-uppercase">
                        View Answer
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading &&
                      inquiry?.map((k, i) => {
                        return (
                          <tr key={k?._id}>
                            <td className="border-bottom border-color pt-3 pb-3 regal-blue-color">
                              {i + 1}
                            </td>
                            <td className="border-bottom border-start border-color pt-3 pb-3 regal-blue-color">
                              {k?.inquiry}
                            </td>

                            <td className="border-bottom border-start border-color pt-3 pb-3  regal-blue-color">
                              {moment(k?.created_at).format(
                                "YYYY-MM-DD, H:mm:ss"
                              )}
                            </td>
                            <td className="d-flex justify-content-center align-items-center  border-bottom border-start border-color pt-3 pb-3">
                              <button
                                className="btn-blue"
                                onClick={() => navigate(`/inquiry-answer/${k?.question_id}`)}
                                // disabled={k.isAnswer === "false"}
                              >
                                View
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="col-12 col-lg-12 mt-3 ps-2 ">
                {!isLoading && inquiry?.data?.data?.length ? (
                  <Pagination className="justify-content-center">
                    <Pagination.Item
                      onClick={() => _currentPage(1)}
                      disabled={currentPage === 1}
                    >
                      {" "}
                      First
                    </Pagination.Item>

                    <Pagination.Item
                      onClick={() => _currentPage((p) => p - 1)}
                      disabled={currentPage === 1}
                    >
                      Prev
                    </Pagination.Item>
                    <Pagination.Item disabled> {currentPage}</Pagination.Item>
                    <Pagination.Item
                      onClick={() => _currentPage((p) => p + 1)}
                      disabled={currentPage === inquiry?.count}
                    >
                      Next
                    </Pagination.Item>
                    <Pagination.Item
                      onClick={() => _currentPage(inquiry?.count)}
                      disabled={currentPage === inquiry?.count}
                    >
                      End
                    </Pagination.Item>
                  </Pagination>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader show={isLoading} />
    </>
  );
}

export default EnquiryQuestion;
