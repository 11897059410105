import React from "react";
import {Card, Spinner} from "react-bootstrap";
import avtar from "../../../../images/background/avtar.png";
import {UserNickName} from "../../../../utils/function";
import {useNavigate} from "react-router-dom";
import {ERC_721_ETH_CONTRACT, ERC_721_POLYGON, contractType} from "../../../../utils/config";
import '../Art-Work-Details.css';

const PurchaseNft = (props) => {
    const {
        nftDetails,
        placeBid,
        formOFSale,
        purchaseNFT,
        isAPITransaction,
        isMetaMaskTransaction,
        _quantity,
        quantity,
        _totalPrice,
        totalPrice,
        loggedUser
    } = props;
    const navigate = useNavigate();

    const redirect = (userId) => {
        navigate(`/profile/${userId}`)
    }

    const feesData = {
        usage: nftDetails?.adminFeeData?.data?.usage || false,
        admin_fee: nftDetails?.adminFeeData?.data?.fees?.admin_fee || 0,
    }

    const setTotalPrice = ({target}) => {
        _quantity(target.value);
        if(feesData.usage){
            _totalPrice((Number(target.value) * Number(nftDetails?.sale_price)) + feesData.admin_fee)
        }else{
            _totalPrice(Number(target.value) * Number(nftDetails?.sale_price))
        }

    }

    return <>
        <div>
            <p>{nftDetails?.description}</p>
            {nftDetails?.url ? 
            <div className="url-box">
                <a href={nftDetails?.url} target="_blank" style={{"textDecoration":"none"}}>
                    <div className="mouse-pointer" style={{"color":"white"}}>
                   상세보기
                </div>
                </a>
            </div>: <></>
            }

            <div className="artist-section">
                <div className="artist-box">
                    <img src={nftDetails?.creator?.user_profile || avtar} alt="image" className="rounded-circle"
                         height="40" width="35"/>
                    <div className="artist-details mouse-pointer"
                         onClick={() => redirect(nftDetails?.creator?.user_id)}>
                        <p>Artist</p>
                        <p className="text-break"><strong>{UserNickName(nftDetails?.creator?.creator_email)}</strong></p>
                    </div>
                </div>
                <div className="artist-box">
                    <img src={nftDetails?.current_owner?.current_owner_profile || avtar} alt="image"
                         className="rounded-circle" height="40" width="35"/>
                    <div className="artist-details mouse-pointer"
                         onClick={() => redirect(nftDetails?.current_owner?.current_owner_id)}>
                        <p>Owner</p>
                        <p className="text-break"><strong>{UserNickName(nftDetails?.current_owner?.current_owner_email)}</strong></p>
                    </div>
                </div>

            </div>
            {nftDetails?.form_of_sale === 'waiting_for_sale' ?
                <div className=" d-flex justify-content-start  flex-column">
                    <h5 className="fw-bold regal-blue-color">The Art-work is not Available For Purchase/Bid</h5>
                    <button className="btn-blue btn-white mt-2 w-25" onClick={() => navigate('/profile')}>Go to list
                    </button>
                </div>

                :
                <div>
                    {formOFSale === 'auction' ?
                        <div>
                            <div className="bid-container">
                                <Card.Body>
                                    <Card.Subtitle>Last Bid</Card.Subtitle>
                                    <Card.Title>{nftDetails?.highest_bid} {nftDetails?.sale_coin == 1 ? 'Matic' : 'ETH'}</Card.Title>
                                </Card.Body>

                                <Card.Body>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <Card.Subtitle>Open Bid</Card.Subtitle>
                                            <Card.Title>{nftDetails?.auction_start_price} {nftDetails?.sale_coin == 1 ? 'Matic' : 'ETH'}</Card.Title>
                                        </div>

                                        {nftDetails?.adminFeeData?.data?.usage === true &&
                                        <div className="ml-15">
                                            <Card.Subtitle>Admin Fees</Card.Subtitle>
                                            <Card.Title>{nftDetails?.auction_start_price} {nftDetails?.sale_coin == 1 ? 'Matic' : 'ETH'}</Card.Title>
                                        </div>}
                                    </div>
                                </Card.Body>
                            </div>

                            {!nftDetails?.is_current_owner ?
                                <div className="d-flex justify-content-center mt-4">
                                    <button className="btn-blue" onClick={() => placeBid()}>Place a Bid</button>
                                </div> : nftDetails?.auction_ended ?
                                    <span className="fw-bolder regal-blue-color">Auction Over</span> :
                                    <div className="mt-4 text-center">
                                        <h6 className="fw-bolder regal-blue-color">You Are Owner of Art-work</h6>
                                    </div>}
                        </div>
                        :
                        <>
                        <div className="bid-container d-flex flex-wrap">
                            <Card.Body>
                                <Card.Subtitle style={{"paddingBottom":"10px"}}>Contract Address</Card.Subtitle>
                                <a href={`https://${nftDetails?.sale_coin == 1 ? "polygonscan.com" : "etherscan.io" }/nft/${nftDetails?.sale_coin == 1 ? ERC_721_POLYGON : ERC_721_ETH_CONTRACT }/${nftDetails?.nft_token[0].token_id}`}>
                                    <Card.Subtitle style={{"fontWeight":"bold" , "color":"blue"}}>
                                        <div className="" style={{maxWidth:"90vw", wordWrap:"break-word"}}>
                                            {nftDetails?.sale_coin == 1 ? ERC_721_POLYGON : ERC_721_ETH_CONTRACT }
                                        </div>
                                    </Card.Subtitle>
                                </a>            
                            </Card.Body>
                            <Card.Body>
                                <Card.Subtitle style={{"paddingBottom":"10px"}}>Token Id</Card.Subtitle>
                                <Card.Subtitle style={{"fontWeight":"bold" , "color":"blue"}}>{nftDetails?.nft_token[0].token_id} </Card.Subtitle>
                            </Card.Body>
                        </div>
                        <div className="bid-container">
                            <Card.Body>
                                <Card.Subtitle style={{"paddingBottom":"10px"}}>Chain</Card.Subtitle>
                                <Card.Subtitle style={{"fontWeight":"bold" , "color":"blue"}}>{nftDetails?.sale_coin == 1 ? "Polygon" : "Ethereum" }</Card.Subtitle>
                            </Card.Body>
                        </div>
                        <div className="bid-container d-flex flex-wrap">
                            <Card.Body>
                                <Card.Subtitle>Current Price</Card.Subtitle>
                                <Card.Title>{nftDetails?.sale_price} {nftDetails?.sale_coin == 1 ? 'Matic' : 'ETH'}</Card.Title>
                            </Card.Body>
                            
                            {nftDetails?.isPricePublic ? <Card.Body>
                                <Card.Subtitle>Korean Price</Card.Subtitle>
                                <Card.Title>{nftDetails?.korean_price} KRW</Card.Title>
                            </Card.Body>
                            :<></>}
                            {
                                feesData.usage && (
                                    <>
                                        <Card.Body>
                                            <Card.Subtitle>Transactio Fees</Card.Subtitle>
                                            <Card.Title>{feesData.admin_fee} {nftDetails?.sale_coin == 1 ? 'Matic' : 'ETH'}</Card.Title>
                                        </Card.Body>
                                        <Card.Body>
                                            <Card.Subtitle>Total Price</Card.Subtitle>
                                            <Card.Title><h5 className="totalPrice">{totalPrice}</h5> {nftDetails?.sale_coin == 1 ? 'Matic' : 'ETH'}</Card.Title>
                                        </Card.Body>
                                    </>
                                )
                            }
                        </div>
                            { nftDetails?.quantity > 0 &&
                            <div className="bid-container">
                                {(nftDetails?.contract_type === contractType.erc1155) &&
                                    <div className="d-flex justify-content-start align-items-start flex-column w-50">
                                        <h6>Quantity</h6>
                                        <input
                                            type="number"
                                            placeholder="Enter Quantity"
                                            className="purchaseModalInput inquiry-box-shadow w-35"
                                            id="royalty" style={{height: '35px'}}
                                            min="1"
                                            max={nftDetails?.quantity}
                                            value={quantity}
                                            onChange={setTotalPrice}
                                        />
                                    </div>}
                                <Card.Body>
                                    {loggedUser?.kycVerified ?
                                    !nftDetails?.is_current_owner ? <>
                                            {isAPITransaction || isMetaMaskTransaction ?
                                                <button className="btn-blue">Purchase <Spinner animation="border" size="sm" className="white ms-2"/>
                                                </button> : <button className="btn-blue" onClick={() => purchaseNFT()}>Purchase</button>}
                                        </> :
                                        <div className="col-12">
                                            <h6 className="fw-bolder regal-blue-color">You Are Owner of Art-work</h6>
                                        </div>
                                    :<div className="col-12">
                                    <h6 className="fw-bolder regal-blue-color">You are not KYC Verified to purchase</h6>
                                </div>}
                                </Card.Body>
                            </div>
                            }
                        </>
                    }
                </div>}
        </div>
    </>
}

export default PurchaseNft;