import React, {useCallback, useEffect, useState} from "react";
import Loader from "../../../components/loader";
import SweetAlert from "react-bootstrap-sweetalert";
import {useDispatch, useSelector} from "react-redux";
import {Col, Row, Spinner} from "react-bootstrap";
import SendIcon from "../../../images/others/send-icon.svg";
import {Formik} from "formik";
import {useDropzone} from "react-dropzone";
import * as yup from "yup";
import {BsEye, BsEyeSlash} from "react-icons/bs";
import {useNavigate} from "react-router-dom";
import API from "../../../redux/API";
import ProfileCard from '../../../components/ProfileCard';
import {updateCurrentUser} from "../../../utils/function";
import {logout, setLoggedUser} from "../../../redux/reducers/user";
import {useGoogleLogout} from "react-google-login";
import {GoogleClientId} from "../../../utils/config";

const EditProfileForm = () => {

    const {loggedUser} = useSelector(s => s.user);

    let passwordPtn = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z]).{8,}$/;
    let validationSchema = yup.object().shape({
        nickName: yup.string().required('Nick name is required'),
        password: yup.string().matches(passwordPtn, ' 8 or more characters including letters, numbers, and special characters'),
        confirm_password: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
    })

    const typeValidator = (file) => {
        if (file.size > (100 * 1024 * 1024)) {
            return {
                code: "size-too-large",
                message: `file size is larger than 100MB`
            };
        }
        return null;
    };


    let initialValue = {
        email: loggedUser?.email,
        password: '',
        confirm_password: '',
        nickName: loggedUser?.nickname
    }
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {signOut} = useGoogleLogout({clientId: GoogleClientId});
    const [formInitialValues, _formInitialValues] = useState(initialValue);
    const [loading, _loading] = useState(false);
    const [sweetAlert, _alert] = useState({show: false, title: '', type: 'success' || 'error'});
    const [myFiles, setMyFiles] = useState([]);
    const [fileTypes] = useState(['image/jpeg', 'image/png']);
    const [passwordShow, _passwordShow] = useState(false);
    const [c_password, _c_password] = useState(false);


    useEffect(() => {
        _formInitialValues({...formInitialValues, useEmail: loggedUser?.email});
        return (() => {
            return _formInitialValues(null);
        })
    }, [loggedUser]);


    const closeSweetAlertHandler = (s) => {
        _alert(s => ({...s, show: false}));
    }

    const onDrop = useCallback(acceptedFiles => {

        if (acceptedFiles.length === 1) {
            setMyFiles([...acceptedFiles])
            const userPhoto = new FormData();
            acceptedFiles.forEach(file => {
                userPhoto.append('profile_image', file);
            });
            userPhoto.append('is_avatar', false);
        } else {
            _alert({show: true, type: "warning", title: 'Please Select Valid Image File!...'});
        }


    }, [myFiles])

    const {getRootProps, getInputProps} = useDropzone({
        multiple: false,
        accept: fileTypes,
        maxFiles: 10,
        minSize: 1,
        maxSize: 100 * 1024 * 1024,
        validator: typeValidator,
        onDrop,
    });

    const userUpdate = async () => {
        await updateCurrentUser(loggedUser)
            .then(async (response) => {
                await dispatch(setLoggedUser(response));
            }).catch((err) => {
                _alert({show: true, title: err.message, type: 'error'});
            }).finally(() => {
                _loading(false);
            });
    }

    const loggedOut = async() => {
        signOut();
        dispatch(logout());
        _loading(true)
        await localStorage.removeItem('user');
        setTimeout(() => {
            navigate('/')
            _loading(false);
        }, 1000);
    }

    const updateUserProfile =async (e, action) => {
        let userData = (e.password === '') ? {nickName: e.nickName, email: e.email}: e;

        _loading(true);
        if (myFiles.length > 0) {
            const userPhoto = new FormData();
            myFiles.forEach(file => {
                userPhoto.append('profile_image', file);
            });
            userPhoto.append('is_avatar', false);
            API({url: 'user/upload-profile', data: userPhoto, method: 'PUT'}).then(async res => {
                    _alert({show: true, type: "success", title: 'Profile image uploaded Successfully!...'});
                    await userUpdate();
                }).catch(error => {
                    _alert({show: true, type: "error", title: error?.message});
                    setMyFiles([]);
                }).finally(() => {
                _loading(false);
            })
        }

        API({
            url: 'user/complete-setup',
            data: userData
        }).then(async res => {
            _alert({show: true, type: "success", title: 'Update User Profile Successfully!...'});
            if(e.password) {
                await loggedOut();
            } else {
                action.resetForm();
            }
        }).catch(err => {
            _alert({show: true, title: err?.message, type: 'error'});
        }).finally(() => _loading(false));
    }


    const handleSubmit = (e, action) => {

        // if(e.password !== e.confirm_password){
        //     _alert({show: true, type: "warning", title: 'Confirm password must Be match!...'});
        // }
        // else{
        updateUserProfile(e, action)
        // }

    }

    const showMyPassword = () => {
        let x = document.getElementById("myInputPassword");
        if (x.type === "password") {
            x.type = "text";
            _passwordShow(true);
        } else {
            x.type = "password";
            _passwordShow(false);
        }
    }

    const showMyConfirmPassword = () => {
        let x = document.getElementById("confirm_password");
        if (x.type === "password") {
            x.type = "text";
            _c_password(true);
        } else {
            x.type = "password";
            _c_password(false);
        }
    }


    return <>

        <Row>
            <Col sm={12} md={5} lg={4}>
                <ProfileCard loggedUser={loggedUser}/>
            </Col>
            <Formik
                initialValues={formInitialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({
                      handleSubmit,
                      handleChange,
                      errors,
                      values,

                  }) => (
                    <>
                        <Col sm={12} md={7} lg={8}>
                            <Row className="justify-content-center">
                                <Col sm={12} md={10} lg={8}>
                                    <div className="myprofile-form-section">

                                        <h1>Edit Profile</h1>
                                        <div className="form-group title-image-section ">
                                            <label className="form-label robot-font fw-bold regal-blue-color fs-5 mb-3">
                                                Profile Image
                                            </label>

                                            {myFiles?.length <= 0 &&
                                                <div className="image-upload">
                                                    <div {...getRootProps({className: 'dropzone'})}>
                                                        <input {...getInputProps()} type="file"/>
                                                        <img src={SendIcon} alt="icon" className="send-icon"/>
                                                        <h3>Drag or Drop an Image</h3>
                                                        <p>2000 x 2000px PNG or JPG files recommended</p>
                                                    </div>
                                                </div>
                                            }
                                            {myFiles?.map((file, index) =>
                                                <div className="image-uploaded" key={`${file.lastModified} + ${index}`}>
                                                    <img src={URL.createObjectURL(file)} alt="img"
                                                         className="img-fluid w-100"/>
                                                </div>
                                            )}
                                        </div>
                                        {!myFiles?.length <= 0 &&
                                            <div className="form-group flex-direction">
                                                {myFiles.map((file, index) =>
                                                    <div className="attach-file"
                                                         key={`${file.lastModified} + ${index + 1}`}>
                                                        {file.name ? file.name : 'File name'}
                                                        {/*<img src={CloseIcon} alt="icon" className="close-icon mouse-pointer"*/}
                                                        {/*     // onClick={removeFile(file)}*/}
                                                        {/*/>*/}
                                                    </div>)}
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <button type="button" className="btn-blue">Search File</button>
                                                </div>
                                            </div>
                                        }
                                        <div className="form-group mt-5" style={{position: "relative"}}>
                                            <label className="form-label robot-font fw-bold regal-blue-color fs-5 mb-3">
                                                My Email
                                            </label>
                                            <input
                                                name="email"
                                                type="text"
                                                value={values.email || ''}
                                                id="royalty"
                                                className="art-work-form-control-height form-control "
                                                disabled
                                            />
                                            <p style={{
                                                position: "absolute",
                                                top: '83px',
                                                right: '17px',
                                                color: '#5376FF'
                                            }}>Verified!</p>
                                        </div>
                                        <div className="form-group mt-5">
                                            <label className="form-label robot-font fw-bold regal-blue-color fs-5 mb-3">
                                                NickName
                                            </label>
                                            <input
                                                name="nickName"
                                                type="text"
                                                value={values.nickName}
                                                onChange={handleChange}
                                                id="royalty"
                                                className="art-work-form-control-height form-control "
                                            />
                                            {errors.nickName &&
                                                <h6 style={{color: 'tomato', marginTop: '5px'}}>{errors.nickName}</h6>}
                                        </div>
                                        <div className="form-group mt-5" style={{position: "relative"}}>
                                            <label className="form-label robot-font fw-bold regal-blue-color fs-5 mb-3">
                                                Password
                                            </label>
                                            <input
                                                name="password"
                                                type="password"
                                                value={values.password}
                                                onChange={handleChange}
                                                id="myInputPassword"
                                                className="art-work-form-control-height form-control inputBox"

                                            />


                                            <div style={{
                                                position: "absolute",
                                                top: '83px',
                                                right: '17px',
                                                cursor: 'pointer'
                                            }}>
                                                {passwordShow ? <BsEyeSlash onClick={() => showMyPassword()}/> :
                                                    <BsEye onClick={() => showMyPassword()}/>}
                                            </div>
                                            {errors.password &&
                                                <h6 style={{color: 'tomato', marginTop: '5px'}}>{errors.password}</h6>}
                                        </div>
                                        <div className="form-group mt-5" style={{position: "relative"}}>
                                            <label className="form-label robot-font fw-bold regal-blue-color fs-5 mb-3">
                                                Re-enter Password
                                            </label>
                                            <input
                                                name="confirm_password"
                                                type="password"
                                                value={values.confirm_password}
                                                onChange={handleChange}
                                                className="art-work-form-control-height form-control inputBox"
                                                id="confirm_password"
                                                onPaste={(e) => e.preventDefault()}
                                            />

                                            <div style={{
                                                position: "absolute",
                                                top: '83px',
                                                right: '17px',
                                                cursor: 'pointer'
                                            }}>
                                                {c_password ? <BsEyeSlash onClick={() => showMyConfirmPassword()}/> :
                                                    <BsEye onClick={() => showMyConfirmPassword()}/>}
                                            </div>
                                            {errors.confirm_password && <h6 style={{
                                                color: 'tomato',
                                                marginTop: '5px'
                                            }}>{errors.confirm_password}</h6>}
                                        </div>
                                        <div className="royalty-btns mt-5">
                                            <button className="btn-blue btn-white"
                                                    onClick={() => navigate('/profile')}
                                            >My NFW
                                            </button>
                                            {loading ?
                                                <button className="btn-blue" onClick={() => handleSubmit()}
                                                        type="submit">
                                                    Save<Spinner animation="border" size="sm" className=" ms-2"/>
                                                </button> :
                                                <button className="btn-blue" onClick={() => handleSubmit()}
                                                        type="submit">
                                                    Save
                                                </button>}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </>
                )}
            </Formik>
        </Row>
        <Loader show={loading}/>
        <SweetAlert type={sweetAlert.type} title={sweetAlert.title} onConfirm={() => closeSweetAlertHandler()}
                    show={sweetAlert.show}/>
        {/*<MetamaskModal modalOpen={openModal} modalShow={show}/>*/}

    </>
}

export default EditProfileForm;