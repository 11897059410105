import React, {useState} from 'react';
import './stepProgressBar.css';

const StepProgressBar = ({currentStep}) => {
    var element1 = document.getElementById("step1");
    var element2 = document.getElementById("step2");
    var element3 = document.getElementById("step3");
    if (currentStep == 2) {
        element1.classList.add("completed");
        element2.classList.add("active");
    } else if (currentStep == 3) {
        element1.classList.add("completed");
        element2.classList.add("completed");
        element3.classList.add("active");
    }
    console.log(currentStep);
  
    return <>
            <div class="stepper-wrapper">
  <div id="step1" class="stepper-item active">
    <div class="step-counter">1</div>
    <div class="step-name">Money Sent</div>
  </div>
  <div id="step2"  class="stepper-item">
    <div class="step-counter">2</div>
    <div class="step-name">Royalty Distributed</div>
  </div>
  <div id="step3" class="stepper-item">
    <div class="step-counter">3</div>
    <div class="step-name">Eth Transaction Done</div>
  </div>
</div>
    </>

}

export default StepProgressBar;