import React from 'react';
import {AiOutlineSearch} from "react-icons/ai";
import './searchHeaderLayout.css';

const SearchHeaderLayout = ({pageName,isMarketPlace = false,onChange,searchRealEstateNft}) =>{


    return <>
        <section className="searchHeader">
            <div className="row headerInnerDiv">
                <div className="col-12 col-lg-8 m-auto d-flex flex-wrap justify-content-between align-items-center">
                    <div>
                        <span className="pageName">{pageName}</span>
                    </div>
                    <div className="position-relative d-flex justify-content-start align-items-center text-center" style={{width:'250px'}}>
                        <AiOutlineSearch size={30} color="#A9DEEE" className="searchIconRealEstate position-absolute text-truncate"/>
                        <input
                            placeholder="Search item, collector.."
                            className="headerSearchRealEstate "
                            onChange={isMarketPlace ? onChange : searchRealEstateNft}
                        />
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default SearchHeaderLayout;